import { Tag } from "antd";
import dayjs from "dayjs";

const columns = () => [
    {
        title: "ID",
        width: 80,
        align: "center",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "用户名",
        width: 80,
        align: "center",
        dataIndex: "username",
        key: "username",
        render: (_, record) => {
            return <>
                {record?.isvip === 1 ? 'VIP' : ''}
                <img alt="头像" src={record?.avatar} width={30}/>
                <div>{record?.username}</div>
            </>
            
        }
    },
    {
        title: "昵称",
        width: 100,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
    },
    {
        title: "用户类型",
        width: 110,
        align: "center",
        dataIndex: "isvip",
        key: "isvip",
        render: value => (
            value === 1 ? 'vip会员' : '普通用户'
        )
    },
    {
        title: "书币",
        width: 100,
        align: "center",
        dataIndex: "score",
        key: "score",
    },
    {
        title: "书券",
        width: 100,
        align: "center",
        dataIndex: "ticket",
        key: "ticket",
    },
    {
        title: "关注",
        width: 100,
        align: "center",
        dataIndex: "isguanzhu",
        key: "isguanzhu",
        render: value => (
            value === 1 ? <Tag color="success">已关注</Tag> : <Tag color="error">未关注</Tag>
        )
    },
    {
        title: "累计充值",
        width: 110,
        align: "center",
        dataIndex: "recharge",
        key: "recharge",
    },
    {
        title: "注册时间",
        width: 110,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "最近变更渠道时间",
        width: 110,
        align: "center",
        dataIndex: "update_promotions_time",
        key: "update_promotions_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "最后登录时间",
        width: 110,
        align: "center",
        dataIndex: "last_login_time",
        key: "last_login_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
]

export default columns