import { Button, Space } from "antd"
import { Link } from "react-router-dom";

const columns = () => [
    {
        title: "小组",
        width: 100,
        align: "center",
        dataIndex: "name",
        key: "name",
        fixed: 'left',
    },
    {
        title: "日期",
        width: 100,
        align: "center",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "消耗",
        width: 95,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "回收",
        width: 100,
        align: "center",
        dataIndex: "count_money",
        key: "count_money",
    },
    {
        title: "回收率",
        width: 100,
        align: "center",
        dataIndex: "hui_shou_lv",
        key: "hui_shou_lv",
    },
    {
        title: "支付人数",
        width: 100,
        align: "center",
        dataIndex: "pay_man",
        key: "pay_man",
    },
    {
        title: "付费成本",
        width: 100,
        align: "center",
        dataIndex: "pay_cost",
        key: "pay_cost",
    },
    {
        title: "客单价",
        width: 100,
        align: "center",
        dataIndex: "per_money",
        key: "per_money",
    },
    {
        title: "在消耗计划",
        width: 100,
        align: "center",
        dataIndex: "zaikai_plan",
        key: "zaikai_plan",
    },
    {
        title: "审核通过计划",
        width: 100,
        align: "center",
        dataIndex: "audit_plan",
        key: "audit_plan",
    },
    {
        title: "所有计划",
        width: 100,
        align: "center",
        dataIndex: "all_plan",
        key: "all_plan",
    },
    {
        title: "操作",
        width: 230,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        render: (_, record) => (
            <Space size='small'>
                <Button>
                    <Link to="/admin/statistics/theater/push_plt/recycle_plt" state={{org_id: record?.id}}>小组详情</Link>
                </Button>
                <Button type="primary">
                    <Link to="/admin/statistics/theater/recycle_group/group_trend" state={{id: record?.id, name: record?.name}}>组付费趋势</Link>
                </Button>
            </Space>
        )
    },
]

export default columns