import { Space, Typography } from "antd"

const options = [
    '现代言情', '历史架空', '古代言情', '社会风云',
    '悬疑推理', '仙侠奇缘', '游戏科幻', '玄幻仙侠',
    '都市异能', '精彩书库', '年代种田'
]

const columns = ({redirectChapter}) => [
    {
        title: "封面",
        width: 100,
        align: "center",
        dataIndex: "image",
        key: "image",
        render: (_, record) => (
            <img src={`https://novel.suyueyun.com/public/${record?.attach?.path}`} alt="封面" width={100} />
        )
    },
    {
        title: "名称",
        width: 500,
        // align: "center",
        dataIndex: "title",
        key: "title",
        render: (_, record) => {
            return <>
                <div>
                    <Typography.Link type="success" onClick={() => redirectChapter(record)}>{record.title}</Typography.Link>
                </div>
                <div style={{color: '#845C41'}}>
                    <Space>
                        [共{record?.chapter_count}章]
                        [{record?.xstype === '0' ? '连载中' : '已完结'}]
                    </Space>
                </div>
                <div style={{color: '#999999'}}>第{record.gzzj}章开始关注</div>
                <div>{record.desc}</div>
            </>
        }
    },
    {
        title: "男|女频",
        width: 100,
        align: "center",
        dataIndex: "cid",
        key: "cid",
        render: value => (
            value === 2 ? '男频' : '女频'
        )
    },
    {
        title: "篇幅",
        width: 90,
        align: "center",
        dataIndex: "changduan",
        key: "changduan",
        render: value => (
            value === 1 ? '长篇' : '短篇'
        )
    },
    {
        title: "类型",
        width: 120,
        align: "center",
        dataIndex: "tstype",
        key: "tstype",
        render: text => (
            options[text]
        )
    },
    {
        title: "指数",
        width: 100,
        align: "center",
        dataIndex: "zhishu",
        key: "zhishu",
    },
    {
        title: "评级",
        width: 100,
        align: "center",
        dataIndex: "grade",
        key: "grade",
    },
    {
        title: "外推次数",
        width: 100,
        align: "center",
        dataIndex: "tgcs",
        key: "tgcs",
    },
    {
        title: "内推次数",
        width: 100,
        align: "center",
        dataIndex: "tgcs1",
        key: "tgcs1",
    },
]

export default columns