import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Select,
  Space,
  Tag,
} from "antd";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getDefaultPitcher,
  getMaterialOptions,
  getPitcherList,
  materialAdd,
} from "../../../../api";
import { getMaterialPlaylet } from "../../../../api/playlet";
import { CloseCircleOutlined } from "@ant-design/icons";
import { getActiveOrg } from "../../../../api/permission";
import { useEffect } from "react";
import dayjs from "dayjs";
import VideoModal from "./videoModal";
import PitcherModal from "./PitcherModal";
import "./add.less";

const { TextArea } = Input;

export default function Add() {
  const [materialType, setMaterialType] = useState(1);
  const [materialCategory, setMaterialCategory] = useState(0);
  const [marterialBelong, setMarterialBelong] = useState(2);
  const [isShowVideoModal, setIsShowVideoModal] = useState(false);
  const [materialVideos, setMaterialVideos] = useState([]);
  const [deleteMaterialVideos, setDeleteMaterialVideos] = useState([]);
  const [attachIds, setAttachIds] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [pitcher, setPitcher] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pitcherLoading, setPitcherLoading] = useState(false);
  const [isShowPitcherModal, setIsShowPitcherModal] = useState(false);
  const [selectedPitcher, setSelectedPitcher] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [platformType, setPlatformType] = useState("playlet");
  const [playletOptions, setPlayletOptions] = useState([]);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Promise.all([
      getOptions(),
      getToushou(),
      getDefaultToushou(),
      getPlaylets(),
    ]);
  };

  // 获取剧场
  const getPlaylets = async () => {
    const res = await getMaterialPlaylet();
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item.aliasname, value: item.app_type };
      });
      setPlayletOptions(newList);
    }
  };

  // 素材类型
  const getOptions = async () => {
    const res = await getMaterialOptions();
    const categoryOptions = res?.data.map((value, key) => {
      return { label: value, value: key };
    });
    setMaterialOptions(categoryOptions);
  };

  // 获取组织架构数据
  const getOrganization = async () => {
    const res = await getActiveOrg();
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.name, value: item.id };
      });
      setOrganizationOptions(newList);
    }
  };

  // 获取投手数据
  const getToushou = async (params = {}) => {
    setPitcherLoading(true);
    const res = await getPitcherList(params);
    const { data } = res;
    if (res?.code === 200) {
      const result = data?.map((item) => {
        item.key = item.id;
        return item;
      });
      setPitcher(result);
      setPitcherLoading(false);
    }
  };

  // 投手名称筛选
  const pitcherOnSearch = (params) => {
    getToushou(params);
  };

  const layout = {
    labelCol: { xl: { span: 2 } },
    wrapperCol: { xl: { span: 16 } },
  };

  // 点击上传文件弹窗
  const uploadModal = (type) => {
    setIsShowVideoModal(true);
  };

  // 关闭上传视频弹窗
  const closeVideo = () => {
    setIsShowVideoModal(false);
  };

  // 关闭投手归属弹窗
  const closePitcher = () => {
    setIsShowPitcherModal(false);
    getToushou();
  };

  // 确定已完成上传
  const handleSure = (videoList) => {
    let newVideoList = videoList.filter((item) => {
      let url = item.url;
      return !deleteMaterialVideos.includes(url);
    });
    let ids = newVideoList.map((item) => {
      return item.attachId;
    });
    setAttachIds(ids);
    let urls = newVideoList.map((item) => {
      return item.url;
    });
    setMaterialVideos(urls);
    closeVideo();
  };

  // 确认选中投手
  const handleSelected = (selectKeys) => {
    const filtered = pitcher.filter((item) => {
      return selectKeys.includes(item.id);
    });
    setSelectedPitcher(filtered);
    form.setFieldsValue({ toushou: filtered });
  };

  // 获取默认投手
  const getDefaultToushou = async () => {
    const res = await getDefaultPitcher();
    const { data } = res;
    if (res?.code === 200) {
      setSelectedPitcher(data?.lists);
      form.setFieldsValue({
        category: parseInt(data?.category) || 0,
        toushou: data?.lists,
        platform: data?.platform || platformType,
      });
    }
  };

  const belongChange = async (e) => {
    let value = e.target.value;
    setMarterialBelong(value);
    if (value === 2) {
      getDefaultToushou();
    }
  };

  // 选择投手弹窗
  const selectToushou = () => {
    setIsShowPitcherModal(true);
    getOrganization();
  };

  const pitcherTagClose = (value) => {
    const selectedKeys = form.getFieldValue("toushou");
    const newKeys = selectedKeys.filter((item) => {
      return item.id !== value;
    });
    form.setFieldsValue({ toushou: newKeys });
  };

  // 表单提交
  const handleSubmit = async (values) => {
    setLoading(true);
    if (materialVideos.length === 0) {
      message.error("请上传视频素材", 2);
      setLoading(false);
      return;
    }
    let date;
    if (values["publish_time"]) {
      date = values["publish_time"].format("YYYY-MM-DD HH:mm:ss");
    }

    if (values["tags"]) {
      let tags = values["tags"].replace(/^\s\s*/, "").replace(/\s\s*$/, "");
      let newTags = tags.split("\n").map((item) => {
        return item.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
      });
      values.tags = newTags;
    }

    let params = {
      ...values,
      material_files: materialVideos,
      publish_time: date,
      attachIds,
    };
    if (values?.platform === 'novel') {
      params.cate = 2;
    }
    const res = await materialAdd(params);
    if (res?.code === 200) {
      message.success("素材添加成功", 2);
      setTimeout(() => {
        setLoading(false);
        navigate("/admin/material/video");
      }, 2000);
    } else {
      setLoading(false);
    }
  };

  // 组装默认的素材名称（当前日期 + 后台登录账号或昵称 + 3位随机数）
  const getMaterialTitle = () => {
    let currentDate = dayjs().format("YYYYMMDD");
    let genRandom = ((Math.random() * (999 - 100 + 1)) | 0) + 100;
    let adminUser = localStorage.getItem("shortname")
      ? localStorage.getItem("shortname")
      : localStorage.getItem("username");
    return currentDate + "_" + adminUser + "_" + genRandom;
  };

  // 关闭（取消）已选资源
  const resourceClose = (url) => {
    let filtered = materialVideos.filter((value) => {
      return value !== url;
    });
    setMaterialVideos(filtered);
    setDeleteMaterialVideos([...deleteMaterialVideos, url]);
  };

  // 平台切换
  const onPlatformChange = (e) => {
    let value = e.target.value;
    setPlatformType(value);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  const onBookNameChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    form.setFieldsValue({ tags: value });
  };

  return (
    <div className="resource-local-add">
      <Card title="添加视频素材">
        <Form
          {...layout}
          onFinish={handleSubmit}
          initialValues={{
            title: getMaterialTitle(),
            platform: platformType,
            category: materialCategory,
          }}
          form={form}
        >
          <Form.Item
            name="title"
            label="素材名称"
            rules={[{ required: true, message: "素材名称不能为空" }]}
          >
            <Input placeholder="请输入素材名称" />
          </Form.Item>
          <Form.Item name="type" label="素材类型" initialValue={materialType}>
            <Radio.Group
              onChange={(e) => {
                setMaterialType(e.target.value);
              }}
            >
              <Radio value={1}>竖版视频</Radio>
              <Radio value={2}>横版视频</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="素材文件" name="material_files">
            <Button
              type="primary"
              ghost
              onClick={() => {
                uploadModal(materialType);
              }}
            >
              上传视频
            </Button>
            {materialVideos.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  border: "1px solid #F0F0F0",
                  flexWrap: "wrap",
                  marginTop: 10,
                }}
              >
                {materialVideos.map((url, key) => {
                  return (
                    <div
                      style={{
                        marginBottom: 5,
                        marginRight: 10,
                        paddingLeft: 5,
                        paddingTop: 5,
                        height: 160,
                        position: "relative",
                      }}
                      key={key}
                    >
                      <CloseCircleOutlined
                        style={{
                          position: "absolute",
                          // top: 0,
                          right: 0,
                          zIndex: 1,
                          cursor: "pointer",
                          fontSize: 20,
                          color: "red",
                        }}
                        onClick={() => resourceClose(url)}
                      />
                      <video
                        controls
                        src={url}
                        width={materialType === 1 ? 120 : 300}
                        poster={`${url}?x-oss-process=video/snapshot,t_1,f_jpg,w_${
                          materialType === 1 ? 100 : 300
                        },h_${materialType === 1 ? 266 : 100},m_fast,ar_auto`}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </Form.Item>
          <Form.Item
            label="投放类别"
            name="platform"
            rules={[{ required: true, message: "选择投放平台" }]}
          >
            <Radio.Group
              options={[
                { label: "小说", value: "novel" },
                { label: "短剧", value: "playlet" },
              ]}
              onChange={onPlatformChange}
            />
          </Form.Item>

          <Form.Item
            label={platformType === "playlet" ? "短剧名称" : "小说名称"}
            name="book_name"
            rules={[{ required: true }]}
          >
            <Input
              allowClear
              placeholder={
                platformType === "playlet" ? "输入短剧名称" : "输入小说名称"
              }
              style={{ width: "50%" }}
              onChange={onBookNameChange}
            />
          </Form.Item>

          <Form.Item name="category" label="分类">
            <Radio.Group
              options={materialOptions}
              onChange={(e) => {
                setMaterialCategory(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item name="tags" label="素材标签">
            <TextArea placeholder="多个标签换行" allowClear rows={6} />
          </Form.Item>
          <Form.Item name="publish_time" label="发布时间">
            <DatePicker showTime format="YYYY-MM-DD HH:mm" />
          </Form.Item>
          <Form.Item name="belong" label="归属" initialValue={marterialBelong}>
            <Radio.Group onChange={(e) => belongChange(e)}>
              <Radio value={1}>公有</Radio>
              <Radio value={2}>私有</Radio>
            </Radio.Group>
          </Form.Item>
          {marterialBelong === 2 ? (
            <Form.Item label="投手归属" name="toushou">
              <Input
                readOnly
                style={{ backgroundColor: "#eee" }}
                onClick={selectToushou}
              />
              {/* <Select options={pitcher} mode='multiple' allowClear defaultValue={defaultPitcher} filterOption={filterOption} /> */}
              {selectedPitcher ? (
                <div style={{ marginTop: 5 }}>
                  {selectedPitcher.map((item) => {
                    return (
                      <Tag
                        closable
                        key={item.id}
                        style={{ marginTop: 5 }}
                        onClose={() => pitcherTagClose(item.id)}
                      >
                        {item.nickname}
                      </Tag>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item label colon={false}>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                提交
              </Button>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
              >
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>

      {/* 选择归属投手弹窗 */}
      <PitcherModal
        isShow={isShowPitcherModal}
        closeModal={closePitcher}
        list={pitcher}
        handleSure={handleSelected}
        onSearch={pitcherOnSearch}
        orgOptions={organizationOptions}
        loading={pitcherLoading}
      />

      {/* 添加视频素材弹窗 */}
      <VideoModal
        isShow={isShowVideoModal}
        closeVideo={closeVideo}
        handleSure={handleSure}
      />
    </div>
  );
}
