import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  materialDelete,
  materialList,
  handleMaterialLock,
  handleMaterialShare,
  handleUpdateStatus,
  getMaterialDetail,
  getPitcherList,
  updateMaterialShare,
} from "../../../../api";
import Table from "../../../../components/TableHasTotal";
import columns from "./columns";
import {
  getFilterSuCai,
  getFilterTouShou,
  getMaterialLabels,
  getTotalCost,
} from "../../../../api/advertise";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default function Local() {
  const [selectKeys, setSelectKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [toushou, setToushou] = useState([]);
  const [upUser, setUpUser] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [remarks, setRemarks] = useState();
  const [status, setStatus] = useState(0);
  const [modifyId, setModifyId] = useState(0);
  const [filterTouShouOptions, setFilterTouShouOptions] = useState([]);
  const [filterSucaiOptions, setFilterSucaiOptions] = useState([]);
  const [totalCost, setTotalCost] = useState(-1);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [materialLabels, setMaterialLabels] = useState([]);
  const [materialDetail, setMaterialDetail] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showType, setShowType] = useState(1);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [shareForm] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    getList(conditions);
    getFilterPitcher();
    getFilterMaterialer();
    getLabels();
  }, []);

  const getList = async (params = {}) => {
    setLoading(true);
    const res = await materialList({ ...params, photo: 1 });
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
      setToushou(data?.toushou);
      setUpUser(data?.upUser);
    }
  };

  // 获取列表的投手筛选数据
  const getFilterPitcher = async () => {
    const res = await getFilterTouShou();
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list?.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setFilterTouShouOptions(newList);
    }
  };

  // 获取列表的素材手筛选数据
  const getFilterMaterialer = async () => {
    const res = await getFilterSuCai();
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list?.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setFilterSucaiOptions(newList);
    }
  };

  // 获取素材标签
  const getLabels = async (params = {}) => {
    const res = await getMaterialLabels(params);
    const { data } = res;
    if (res?.code === 200) {
      let labels = data?.labels;
      let newLabels = labels.map((item) => {
        return { label: item, value: item };
      });
      setMaterialLabels(newLabels);
    }
  };

  const layout = {
    labelCol: { xl: { span: 6 } },
    wrapperCol: { xl: { span: 16 } },
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
    },
  };

  // 表单筛选查询
  const handleSearch = async (values) => {
    let createdAt = [];
    if (values["create_time"]) {
      createdAt = values["create_time"].map((date) => {
        return date.format("YYYY-MM-DD");
      });
      values.create_time = createdAt;
    }
    setConditions(values);
    let submit = false;
    for (const key in values) {
      if (values.hasOwnProperty.call(values, key)) {
        const element = values[key];
        if (element) {
          submit = true;
        }
      }
    }
    // 只有有筛选参数时才获取总消耗
    getList(values);
    if (submit) {
      const res = await getTotalCost({ ...values, photo: 1 });
      const { data } = res;
      if (res?.code === 200) {
        setTotalCost(data?.total_cost);
      }
    } else {
      setTotalCost(-1);
    }
  };

  // 单个删除
  const materialDel = (id) => {
    confirm({
      title: "确定要删除该素材？",
      cancelText: "取消",
      okText: "确定",
      onOk: async () => {
        const res = await materialDelete({ ids: [id] });
        if (res?.code === 200) {
          message.success("删除成功", 1);
          const newList = list.filter((item) => {
            return item.id !== id;
          });
          setList(newList);
        }
      },
    });
  };

  // 批量删除
  const batchDel = () => {
    confirm({
      title: "确定对选中的素材进行批量删除？",
      cancelText: "取消",
      okText: "确定",
      onOk: async () => {
        const res = await materialDelete({ ids: selectKeys });
        if (res?.code === 200) {
          message.success("删除成功", 1);
          const newList = list.filter((item) => {
            return selectKeys.indexOf(item.id) === -1;
          });
          setList(newList);
        }
      },
    });
  };

  // 素材上锁|解锁
  const materialLock = (record) => {
    let title = record.lock === 1 ? "确认解锁该素材" : "确定要锁定该素材";
    confirm({
      title: title,
      cancelText: "取消",
      okText: "确定",
      onOk: async () => {
        let lock = record.lock === 1 ? 0 : 1;
        const res = await handleMaterialLock({ ids: [record.id], lock });
        if (res?.code === 200) {
          let msg = record.lock === 1 ? "素材解锁成功" : "素材上锁成功";
          message.success(msg, 1);
          const newList = list.map((item) => {
            if (item.id === record.id) {
              item["lock"] = lock;
            }
            return item;
          });
          setList(newList);
        }
      },
    });
  };

  // 批量一键上锁
  const batchLock = () => {
    confirm({
      title: "确定对选中的素材进行一键上锁？",
      cancelText: "取消",
      okText: "确定",
      onOk: async () => {
        const res = await handleMaterialLock({ ids: selectKeys, lock: 1 });
        if (res?.code === 200) {
          message.success("上锁成功", 1);
          const newList = list.map((item) => {
            if (selectKeys.indexOf(item.id) !== -1) {
              item["lock"] = 1;
            }
            return item;
          });
          setList(newList);
          setSelectKeys([]);
        }
      },
    });
  };

  // 获取投手数据
  const getToushou = async () => {
    const res = await getPitcherList();
    const { data } = res;
    if (res?.code === 200) {
      const result = data?.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(result);
    }
  };

  // 素材共享|取消共享
  const materialShare = async (record) => {
    if (record?.show_type === 1) {
      message.info("该素材已是公有素材，无需共享", 2);
      return false;
    }
    setShareModalOpen(true);
    const res = await getMaterialDetail({ id: record?.id });
    if (res?.code === 200) {
      const {
        data: { material },
      } = res;
      setShowType(material?.show_type);
      shareForm.setFieldsValue({ show_type: material?.show_type });
      setMaterialDetail(material);
      let ids = material?.toushouid?.split(",");
      let newIds = ids.map((id) => {
        return parseInt(id);
      });
      getToushou();
      shareForm.setFieldsValue({ id: material?.id, toushouid: newIds });
    }
  };

  // 全选或全不选
  const selectRows = (selectRows) => {
    setSelectKeys(selectRows);
  };

  // 批量操作按钮是否可点击操作
  const hasSelected = selectKeys.length > 0;

  // 每页显示条数切换回调
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
  };

  // 排序
  const tableOnChange = (pagination, filters, sorter) => {
    let field = sorter?.field;
    let orderType = sorter?.order;
    let params = {};
    if (orderType === "descend" && field) {
      params = { ...conditions, order: field + "_" + "desc" };
    } else if (orderType === "ascend" && field) {
      params = { ...conditions, order: field + "_" + "asc" };
    } else {
      params = conditions;
    }
    getList({
      ...params,
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  // 修改备注 | 状态
  const handleRemark = (record) => {
    setStatusModalOpen(true);
    setRemarks(record.remarks);
    setStatus(record.status);
    console.log(record);
    setModifyId(record.id);
  };

  // 修改备注|状态关闭弹窗
  const modalClose = () => {
    setStatusModalOpen(false);
    form1.resetFields();
  };

  const statusOptions = [
    { label: "关闭", value: 0 },
    { label: "开启", value: 1 },
  ];

  // 关闭共享素材弹窗
  const shareModalClose = () => {
    setShareModalOpen(false);
    setConfirmLoading(false);
    shareForm.resetFields();
  };

  // 处理共享素材编辑
  const handleUpdateShare = async () => {
    setConfirmLoading(true);
    let values = shareForm.getFieldsValue();
    let params = {
      ids: [values?.id],
      show_type: values?.show_type
    }
    if (values?.show_type === 2) {
      params.toushou_ids = values?.toushouid
    }
    const res = await updateMaterialShare(params);
    if (res?.code === 200) {
      message.success("素材共享成功", 2);
      setTimeout(() => {
        shareModalClose();
        getList();
      }, 1000);
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 处理修改备注状态
  const handleUpdateRemark = async () => {
    const values = form1.getFieldValue();
    const res = await handleUpdateStatus(values);
    if (res?.code === 200) {
      message.success("修改成功", 1);
      const newList = list.map((item) => {
        if (item.id === values.id) {
          item.remarks = values.remarks;
          item.status = values.status;
        }
        return item;
      });
      setList(newList);
      modalClose();
    }
  };

  const belongChange = (e) => {
    let value = e.target.value;
    setShowType(value);
  };

  return (
    <div className="local">
      <Card style={{ marginBottom: 10 }}>
        <Form {...layout} form={form} onFinish={handleSearch}>
          <Row gutter={12}>
            <Col xl={{ span: 8 }}>
              <Form.Item name="title" label="素材名称">
                <Input placeholder="素材名称" />
              </Form.Item>
            </Col>
            <Col xl={{ span: 8 }}>
              <Form.Item name="id" label="素材ID">
                <Input placeholder="素材ID" />
              </Form.Item>
            </Col>
            <Col xl={{ span: 8 }}>
              <Form.Item name="toushou_id" label="投手筛选">
                <Select
                  allowClear
                  placeholder="请选择投手"
                  options={filterTouShouOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xl={{ span: 8 }}>
              <Form.Item
                name="uploader"
                label="上传用户筛选"
                labelCol={{ xl: { span: 6 } }}
              >
                <Select
                  allowClear
                  placeholder="请选择上传用户"
                  style={{ width: 250 }}
                  options={filterSucaiOptions}
                />
              </Form.Item>
            </Col>
            <Col xl={{ span: 8 }}>
              <Form.Item name="create_time" label="上传日期">
                <RangePicker
                  style={{ width: "100%", textAlign: "center" }}
                  size="middle"
                />
              </Form.Item>
            </Col>
            <Col xl={{ span: 8 }}>
              <Form.Item name="label" label="素材标签">
                <Select
                  allowClear
                  showSearch
                  placeholder="选择素材标签"
                  options={materialLabels}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
            <Col xl={{ span: 2 }}>
              <Form.Item label colon={false} {...formItemLayout}>
                <Space>
                  <Button type="primary" size="small" htmlType="submit">
                    查询
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    重置
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          {totalCost >= 0 ? (
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item label colon={false}>
                  <Typography.Text type="danger">
                    总消耗：{totalCost}
                  </Typography.Text>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Form>
      </Card>
      <Card
        title={
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => navigate("/admin/material/image/add_img")}
            >
              添加图片素材
            </Button>
            <Button type="primary" size="small" disabled={!hasSelected} onClick={batchLock}>
              一键上锁
            </Button>
            <Button
              type="primary"
              size="small"
              danger
              onClick={batchDel}
              disabled={!hasSelected}
            >
              批量删除
            </Button>
          </Space>
        }
      >
        <Table
          dataSource={list}
          columns={columns({
            materialDel,
            materialLock,
            materialShare,
            handleRemark,
          })}
          selectRows={selectRows}
          loading={loading}
          pagesize={pagesize}
          page={page}
          perPage={perPage}
          total={total}
          onChange={tableOnChange}
        />
      </Card>

      {/* 修改备注|状态 */}
      <Modal
        title="修改备注|状态"
        open={statusModalOpen}
        onCancel={modalClose}
        onOk={handleUpdateRemark}
      >
        <Form
          initialValues={{ remarks: remarks, status, id: modifyId }}
          onFinish={handleUpdateRemark}
          form={form1}
        >
          <Form.Item label="备注" name="remarks">
            <Input />
          </Form.Item>
          <Form.Item label="状态" name="status">
            <Radio.Group options={statusOptions} />
          </Form.Item>
          <Form.Item name="id" colon={false} style={{ display: "none" }}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* 共享素材 */}
      <Modal
        title={
          <>
            共享素材
            <Divider />
          </>
        }
        open={shareModalOpen}
        onCancel={shareModalClose}
        onOk={handleUpdateShare}
        width={650}
        confirmLoading={confirmLoading}
      >
        <Form labelCol={{ span: 3 }} form={shareForm}>
          <Form.Item name="id" colon={false} hidden>
            <Input />
          </Form.Item>
          <Form.Item label="素材">
            <div>{materialDetail?.title}</div>
            {materialDetail?.file_id ? (
              <video
                alt=""
                controls
                src={materialDetail?.url}
                controlsList="nodownload"
                preload="none"
                width={220}
                poster={`${materialDetail?.url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
              />
            ) : (
              <img
                alt=""
                src={materialDetail?.url}
                // width={300}
              />
            )}
          </Form.Item>
          <Form.Item label="归属" name="show_type">
            <Radio.Group onChange={(e) => belongChange(e)}>
              <Radio value={1}>公有</Radio>
              <Radio value={2}>私有</Radio>
            </Radio.Group>
          </Form.Item>
          {showType === 2 ? (
            <Form.Item label="投手归属" name="toushouid">
              <Select
                mode="multiple"
                options={pitcherOptions}
                allowClear
                filterOption={filterOption}
              />
            </Form.Item>
          ) : (
            ""
          )}
        </Form>
      </Modal>
    </div>
  );
}
