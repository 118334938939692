import { Button, Card, DatePicker, Form, Select, Table, Typography } from "antd";
import React from "react";
import { useState } from "react";
import { getAllPlaylet } from "../../../api/playlet";
import { useEffect } from "react";
import columns from "./columns";
import { yiTouPlayletSummary } from "../../../api/statistics";
import { numDiv, numMulti } from "../../../util/calculate";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [playlets, setPlaylets] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(0);

  useEffect(() => {
    getPlaylet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await yiTouPlayletSummary(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setLoading(false);
    }
  };

  // 获取自研短剧-所有剧场
  const getPlaylet = async () => {
    const res = await getAllPlaylet({ app_type: 1007 });
    if (res?.code === 200) {
      const { data } = res;
      const lists = data.lists.map((item) => {
        return { tab: item.aliasname, key: item.app_type };
      });
      let appType = data.lists[0]?.app_type;
      setActiveTabKey(appType);
      setPlaylets(lists);
      getList({ app_type: appType });
    }
  };

  // tab标签切换
  const onTabChange = (value) => {
    getList({ app_type: value });
    setActiveTabKey(value);
  };

  // 筛选表单提交
  const onSubmit = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      values.range_date = rangeDate
    }
    getList({ ...values, app_type: activeTabKey });
  }

  return (
    <Card
      tabList={playlets}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      tabProps={{size: 'small'}}
    >
      <Card type="inner" title={
        <Form layout="inline" onFinish={onSubmit}>
          <Form.Item label='日期' name='range_date'>
            <RangePicker />
          </Form.Item>
          <Form.Item label='投放平台' name='platform'>
            <Select allowClear placeholder='选择投放平台' options={[
              {label: '微信小程序', value: 1},
              {label: '抖音小程序', value: 2},
            ]} style={{width: 150}} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">确定</Button>
          </Form.Item>
        </Form>
        
      }>
        <Table
          sticky
          dataSource={list}
          columns={columns()}
          loading={loading}
          pagination={false}
          bordered={true}
          scroll={{ x: 500 }}
          summary={(pageData) => {
            let totalCost = 0;
            let totalMoneyCount = 0;
            let totalNewUser = 0;
            let totalCount = 0;
            let totalRecharge = 0;
            let totalFirstPayUser = 0;
            let totalFirstDayPay = 0;
            let totalFirstDayFirstPay = 0;
            let totalActive = 0;
            let totalRepeatNums = 0;
            let totalRepeatPayNums = 0;
            pageData.forEach(
              ({
                cost,
                money,
                new_user,
                count,
                total_money,
                first_pay_user,
                first_day_pay,
                first_day_first_pay,
                active,
                repeat_total_nums,
                repeat_pay_nums,
              }) => {
                totalCost += parseFloat(cost);
                totalMoneyCount += parseFloat(money);
                totalNewUser += parseInt(new_user);
                totalCount += parseInt(count);
                totalRecharge += parseFloat(total_money);
                totalFirstPayUser += first_pay_user;
                totalFirstDayPay += parseFloat(first_day_pay);
                totalFirstDayFirstPay += parseFloat(first_day_first_pay);
                totalActive += parseInt(active);
                totalRepeatNums += parseInt(repeat_total_nums);
                totalRepeatPayNums += parseInt(repeat_pay_nums);
              }
            );
            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={2} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                      <Text>{totalCost?.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      <Text>{totalMoneyCount?.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      <Text>
                        {parseInt(totalCost)
                          ? numMulti(
                              numDiv(totalMoneyCount, totalCost),
                              100
                            ).toFixed(2) + "%"
                          : "0%"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      <Text>
                        {
                          parseInt(totalCount) ? numDiv(totalFirstDayPay, totalCount).toFixed(2) : 0
                        }
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <Text>{totalNewUser}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>{totalCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="center">
                      <Text>
                        {parseInt(totalNewUser)
                          ? numMulti(
                              numDiv(totalCount, totalNewUser),
                              100
                            ).toFixed(2) + "%"
                          : "0%"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="center">
                      <Text>
                        {parseInt(totalRepeatNums)
                          ? numMulti(
                              numDiv(totalRepeatPayNums, totalRepeatNums),
                              100
                            ).toFixed(2) + "%"
                          : "0%"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="center">
                      <Text>{totalRecharge?.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="center">
                      <Text>{totalFirstDayPay?.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="center">
                      <Text>{totalFirstDayFirstPay?.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="center">
                      <Text>
                        {parseInt(totalFirstPayUser)
                          ? numDiv(totalCost, totalCount).toFixed(2)
                          : 0}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="center">
                      <Text>{totalActive}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </Card>
    </Card>
  );
}
