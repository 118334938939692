import React, { useEffect } from 'react'
import { useState } from 'react'
import ResultComponent from '../Public/Result'
import { getAllPlaylet, playletResult } from '../../../api/playlet'


export default function Result() {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [loading, setLoading] = useState(false)
  const [playlets, setPlaylets] = useState([])
  const [conditions, setConditions] = useState([])

  useEffect(() => {
    getList()
    getPlaylet()
  }, [])

  const getList = async (params = {}) => {
    setLoading(true)
    let condition = {}
    if (params.app_type === undefined) {
      delete params.app_type
    }
    condition = { app_type: 1007, ...params }
    const res = await playletResult(condition)
    if (res?.code === 200) {
      const { data } = res
      if (data?.code === 0) {
        const {data:{list, total_num}} = data
        setList(list)
        setTotal(total_num)
      }
      setLoading(false)
    }
  }

  // 获取自研短剧-所有剧场
  const getPlaylet = async () => {
    const res = await getAllPlaylet({app_type: 1007})
    if (res?.code === 200) {
      const {data} = res
      const lists = data.lists.map((item) => {
        return {label: item.aliasname, value: item.app_type}
      })
      setPlaylets(lists)
    }
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPageSize(size)
    getList({ ...conditions, page, pageSize: size, app_type: 1007 })
  }

  const onSearch = params => {
    setConditions(params)
    getList(params)
  }

  // 传到子组件的参数
  const propsData = {
    list, total, page, loading,
    pagesize, perPage: pageSize,
    onSearch, app_type: 1007, category: 4,
    playlets, pageOptions: [20, 30, 40], 
  }

  return (
    <ResultComponent {...propsData} />
  )
}
