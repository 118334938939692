import { Button, Space, Typography, Popconfirm } from "antd";
import { numDiv, numMulti, numSub } from "../../../../util/calculate";
import { Link } from "react-router-dom";

const columns = ({
  updatePlanStatus,
  deletePlan,
  switchover,
  showTimeDivision,
  updateBidNum, //获得已修改出价次数
  editBid, //修改出价input
  saveBid, //出价提交
  showOperateLog, //广告日志
}) => [
  {
    title: "备注",
    width: 120,
    align: "center",
    dataIndex: "remark",
    key: "remark",
    editable: true,
    render: (value) => <Link>{value || "-"}</Link>,
  },
  {
    title: "广告名称",
    width: 120,
    align: "center",
    dataIndex: "ad_name",
    key: "ad_name",
    render: (value, record) => (
      <>
        {value}
        <Button
          type="link"
          size="small"
          style={{ fontSize: 12 }}
          onClick={() => showOperateLog(record)}
        >
          操作日志
        </Button>
      </>
    ),
  },
  {
    title: "广告ID",
    width: 100,
    align: "center",
    dataIndex: "ad_id",
    key: "ad_id",
    render: (value, record) => (
      <Link
        to={`https://ad.oceanengine.com/superior/promote-manage/ad?aadvid=${record?.advertiser_id}&searchInput=%2522${value}%2522`}
        target="_blank"
      >
        {value}
      </Link>
    ),
  },
  {
    title: "所属账户",
    width: 130,
    align: "center",
    dataIndex: "advertiser_name",
    key: "advertiser_name",
  },
  {
    title: "广告状态",
    width: 120,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (_, record) => {
      return (
        <>
          <div style={{ marginBottom: 10 }}>{record?.statusTxt}</div>
          {record?.plan_ad_create?.status === "OK" ||
          record?.plan_ad_create?.status === "AD_STATUS_DELIVERY_OK" ? (
            <div>
              <Space>
                <Button
                  type="primary"
                  size="small"
                  ghost
                  onClick={() => updatePlanStatus(record, "DISABLE")}
                >
                  暂停
                </Button>
                <Button
                  type="primary"
                  size="small"
                  danger
                  onClick={() => deletePlan(record)}
                >
                  删除
                </Button>
              </Space>
            </div>
          ) : record?.plan_ad_create?.status === "DISABLED" ||
            record?.plan_ad_create?.status === "AD_STATUS_DISABLE" ? (
            <div>
              <Space>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => updatePlanStatus(record, "ENABLE")}
                >
                  启动
                </Button>
                <Button
                  type="primary"
                  size="small"
                  danger
                  onClick={() => deletePlan(record)}
                >
                  删除
                </Button>
              </Space>
            </div>
          ) : (
            ""
          )}
        </>
      );
    },
  },
  {
    title: "广告预算",
    width: 90,
    align: "center",
    dataIndex: "budget",
    key: "budget",
    render: (_, record) => record?.plan_ad_create?.budget,
  },
  {
    title: "出价",
    width: 80,
    align: "center",
    dataIndex: "cpa_bid",
    key: "cpa_bid",
    render: (_, record) => {
      let cpaBid = record?.plan_ad_create?.cpa_bid ?? 0;
      let adStatus = record?.play_ad_create?.status;
      if (cpaBid > 0 && adStatus !== "DELETED") {
        return (
          <Popconfirm
            title="新出价"
            description={() => editBid(record)}
            onConfirm={() => saveBid(record)}
            okText="修改"
            cancelText="取消"
          >
            <Button
              type="link"
              size="small"
              onClick={() => updateBidNum(record)}
            >
              {cpaBid}
            </Button>
          </Popconfirm>
        );
      } else {
        return cpaBid;
      }
    },
  },
  {
    title: "消耗",
    width: 90,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    sorter: {},
    render: (value, record) => (
      <>
        {value}
        <Button
          type="link"
          size="small"
          onClick={() => showTimeDivision(record)}
        >
          查看详情
        </Button>
      </>
    ),
  },
  {
    title: "回收",
    width: 90,
    align: "center",
    dataIndex: "money",
    key: "money",
    sorter: {},
  },
  {
    title: "回收率",
    width: 90,
    align: "center",
    dataIndex: "rate",
    key: "rate",
    render: (_, record) => {
      let rate = record?.cost
        ? numMulti(numDiv(record?.money, record?.cost), 100).toFixed(2)
        : 0;
      if (rate > 130) {
        return (
          <Typography.Text type="success" style={{ fontSize: 12 }}>
            {rate + "%"}
          </Typography.Text>
        );
      } else if (rate >= 0 && rate < 100) {
        return (
          <Typography.Text type="danger" style={{ fontSize: 12 }}>
            {rate + "%"}
          </Typography.Text>
        );
      } else if (rate >= 100 && rate <= 130) {
        return (
          <Typography.Text style={{ fontSize: 12 }}>
            {rate + "%"}
          </Typography.Text>
        );
      }
    },
  },
  {
    title: "实际转化成本",
    width: 100,
    align: "center",
    dataIndex: "real_convert_cost",
    key: "real_convert_cost",
  },
  {
    title: "转化成本",
    width: 100,
    align: "center",
    dataIndex: "convert_cost",
    key: "convert_cost",
  },
  {
    title: "转化数",
    width: 100,
    align: "center",
    dataIndex: "convert",
    key: "convert",
    sorter: {},
  },
  {
    title: "转化率(%)",
    width: 90,
    align: "center",
    dataIndex: "convert_rate",
    key: "convert_rate",
  },
  {
    title: "卡回传",
    width: 100,
    align: "center",
    dataIndex: "upnums",
    key: "upnums",
    sorter: {},
  },
  {
    title: "激活数",
    width: 80,
    align: "center",
    dataIndex: "active",
    key: "active",
  },
  {
    title: "激活成本",
    width: 80,
    align: "center",
    dataIndex: "active_cost",
    key: "active_cost",
  },
  {
    title: "预估赔付",
    width: 100,
    align: "center",
    dataIndex: "claim",
    key: "claim",
    render: (_, record) => {
      let cpa_bid = record?.plan_ad_create?.cpa_bid;
      let convert_cost = record?.convert_cost;
      let convert = record?.convert;
      let claim = 0;
      if (cpa_bid > 0) {
        claim =
          numDiv(numSub(convert_cost, cpa_bid), cpa_bid) * 1 > 0.2
            ? numMulti(numSub(convert_cost, cpa_bid), convert) * 1
            : 0;
      }
      return claim === 0 ? 0 : claim.toFixed(2);
    },
  },
  {
    title: "回传数据",
    width: 80,
    align: "center",
    dataIndex: "back_data",
    key: "back_data",
    render: (_, record) => {
      let nums = record?.convert + record?.upnums;
      let appType = record?.playlet_promotion?.app_type;
      let url;
      switch (appType) {
        case 1002:
          url = "/admin/playlet/yingtan/back_result_yt?ad_id=" + record?.ad_id;
          break;
        case 1000:
          url = "/admin/playlet/lingyang/back_result_ly?ad_id=" + record?.ad_id;
          break;
        case 1001:
          url = "/admin/playlet/chumo/back_result_cm?ad_id=" + record?.ad_id;
          break;
        case 1003:
          url = "/admin/playlet/huasheng/back_result_hs?ad_id=" + record?.ad_id;
          break;
        case 1004:
          url = "/admin/playlet/youhe/back_result_yh?ad_id=" + record?.ad_id;
          break;
        case 1005:
          url =
            "/admin/playlet/dianzhong/back_result_dz?ad_id=" + record?.ad_id;
          break;
        case 1008:
          url = "/admin/playlet/shanhai/back_result_sh?ad_id=" + record?.ad_id;
          break;
        case 1009:
          url = "/admin/playlet/zhangyue/back_result_zy?ad_id=" + record?.ad_id;
          break;
        case 1010:
          url = "/admin/playlet/tianqiao/back_result_tq?ad_id=" + record?.ad_id;
          break;
        case 1011:
          url = "/admin/playlet/fanqie/back_result_fq?ad_id=" + record?.ad_id;
          break;
        case 1012:
          url = "/admin/playlet/jiezou/back_result_jz=" + record?.ad_id;
          break;
        default:
          break;
      }
      return nums > 0 && url !== undefined ? (
        <Link to={url} target="_blank">
          查看
        </Link>
      ) : (
        ""
      );
    },
  },
  {
    title: "展示数",
    width: 100,
    align: "center",
    dataIndex: "show",
    key: "show",
  },
  {
    title: "点击数",
    width: 100,
    align: "center",
    dataIndex: "click",
    key: "click",
  },
  {
    title: "点击率",
    width: 100,
    align: "center",
    dataIndex: "ctr",
    key: "ctr",
  },
  {
    title: "平均点击单价",
    width: 120,
    align: "center",
    dataIndex: "avg_click_costs",
    key: "avg_click_costs",
  },
  {
    title: "平均千次展示费用",
    width: 150,
    align: "center",
    dataIndex: "avg_show_cost",
    key: "avg_show_cost",
  },
  {
    title: "项目ID",
    width: 130,
    align: "center",
    dataIndex: "campaign_id",
    key: "campaign_id",
  },
  {
    title: "项目名称",
    width: 150,
    align: "center",
    dataIndex: "campaign_name",
    key: "campaign_name",
  },
  {
    title: "广告主ID",
    width: 150,
    align: "center",
    dataIndex: "advertiser_id",
    key: "advertiser_id",
    ellipsis: true,
  },
  {
    title: "操作",
    width: 125,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    render: (_, record) => (
      <Space>
        {/* <Button type="primary" ghost>回传数据</Button> */}
        <Button
          type="primary"
          size="small"
          ghost
          onClick={() => switchover(record, "tab3")}
        >
          素材
        </Button>
      </Space>
    ),
  },
];

export default columns;
