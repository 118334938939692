import {
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  Radio,
  Checkbox,
  Flex,
  Space,
  Row,
  Col,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { backConfigDetail } from "@/api/delivery";
import styles from "./index.module.less";

const { Option } = Select;

export default function AddOrEditModel(props) {
  const { open, id, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(new Map());

  useEffect(() => {
    getDetail();
  }, []);

  // 获取策略详情
  const getDetail = async () => {
    setLoading(true);
    try {
      const res = await backConfigDetail({ id });
      console.log(res, "resssss");
      if (res?.code === 200) {
        setData(res?.data || {});
      }
    } catch (err) {
      console.log(err, "errerrerr");
    } finally {
      setLoading(false);
    }
  };

  const transformText = (data, key) => {
    const curItem = data.find((item) => item.value === key);
    return curItem?.label || "";
  };

  return (
    <Drawer
      title="回传规则详情"
      width={480}
      onClose={onClose}
      open={open}
      loading={loading}
      classNames={{
        body: styles.drawerBodyWrapper,
      }}
      footer={
        <Flex justify="flex-end">
          <Space size={16}>
            <Button type="primary" onClick={onClose}>
              返回
            </Button>
          </Space>
        </Flex>
      }
    >
      <Row>
        <Col span={10} className={styles.formTitle}>
          规则信息
        </Col>
      </Row>
      <Row>
        <Col span={6} className={styles.formItemTitle}>
          规则名称
        </Col>
        <Col span={18}>{data.name}</Col>
      </Row>
      <Row>
        <Col span={6} className={styles.formItemTitle}>
          回传模式
        </Col>
        <Col span={18}>{data.backModeView}</Col>
      </Row>
      <Row>
        <Col span={6} className={styles.formItemTitle}>
          时间窗口
        </Col>
        <Col span={18}>{data.backWindowView}</Col>
      </Row>

      <Row>
        <Col span={10} className={styles.formTitle}>
          关键行为配置
        </Col>
      </Row>
      <Row>
        <Col span={6} className={styles.formItemTitle}>
          关键行为指标
        </Col>
        <Col span={18}>{data.keyBehaviorsView?.join("、") || ""}</Col>
      </Row>

      {data.minIpu && (
        <>
          <Row>
            <Col span={10} className={styles.formTitle}>
              IPU指标
            </Col>
          </Row>
          <Row>
            <Col span={6} className={styles.formItemTitle}>
              最小值
            </Col>
            <Col span={18}>{data.minIpu}</Col>
          </Row>
          {data.maxIpu && (
            <Row>
              <Col span={6} className={styles.formItemTitle}>
                最大值
              </Col>
              <Col span={18}>{data.maxIpu}</Col>
            </Row>
          )}
        </>
      )}
      {data.minArpu && (
        <>
          <Row>
            <Col span={10} className={styles.formTitle}>
              ARPU指标
            </Col>
          </Row>
          <Row>
            <Col span={6} className={styles.formItemTitle}>
              最小值
            </Col>
            <Col span={18}>{data.minArpu}</Col>
          </Row>
          {data.maxArpu && (
            <Row>
              <Col span={6} className={styles.formItemTitle}>
                最大值
              </Col>
              <Col span={18}>{data.maxArpu}</Col>
            </Row>
          )}
        </>
      )}

      <Row>
        <Col span={10} className={styles.formTitle}>
          扣量设置
        </Col>
      </Row>
      <Row>
        <Col span={6} className={styles.formItemTitle}>
          回传方式
        </Col>
        <Col span={18}>{data.backTypeView}</Col>
      </Row>
      {data.backType === 2 && (
        <>
          <Row>
            <Col span={6} className={styles.formItemTitle}>
              回传数
            </Col>
            <Col span={18}>{data.backNum}</Col>
          </Row>
          <Row>
            <Col span={6} className={styles.formItemTitle}>
              扣除数
            </Col>
            <Col span={18}>{data.cutBackNum}</Col>
          </Row>
          {data.startBackLimit && (
            <Row>
              <Col span={6} className={styles.formItemTitle}>
                前几条不传数
              </Col>
              <Col span={18}>{data.startBackLimit}</Col>
            </Row>
          )}
        </>
      )}
    </Drawer>
  );
}
