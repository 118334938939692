import { numDiv, numMulti } from "../../../../../util/calculate";

const colunms = () => [
  {
    title: "累计消耗",
    width: 80,
    align: "center",
    dataIndex: "total_cost",
    key: "total_cost",
  },
  {
    title: "累计充值",
    width: 80,
    align: "center",
    dataIndex: "total_money",
    key: "total_money",
  },
  {
    title: "回收率",
    width: 80,
    align: "center",
    dataIndex: "rate",
    key: "rate",
    render: (_, record) => {
        let rate =
          parseFloat(record?.total_cost) && parseFloat(record?.total_money)
            ? numMulti(numDiv(record?.total_money * 1, record?.total_cost * 1), 100).toFixed(2) +
              "%"
            : 0;
        return <div>{rate}</div>;
      },
  },
  {
    title: "微小总计消耗",
    width: 100,
    align: "center",
    dataIndex: "mini_cost",
    key: "mini_cost",
  },
  {
    title: "微小总计回收",
    width: 100,
    align: "center",
    dataIndex: "mini_money",
    key: "mini_money",
  },
  {
    title: "微小总计盈亏",
    width: 100,
    align: "center",
    dataIndex: "mini_profit",
    key: "mini_profit",
  },
  {
    title: "抖小总计消耗",
    width: 100,
    align: "center",
    dataIndex: "byte_cost",
    key: "byte_cost",
  },
  {
    title: "抖小总计回收",
    width: 100,
    align: "center",
    dataIndex: "byte_money",
    key: "byte_money",
  },
  {
    title: "抖小总计盈亏",
    width: 100,
    align: "center",
    dataIndex: "byte_profit",
    key: "byte_profit",
  },
];

export default colunms;
