import { Card, Form, Input, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../../components/TableNoSelection";
import { getAdvertiserList } from "../../../api/advertise";
import { getEventList } from "../../../api/asset";
import { Link } from "react-router-dom";
import columns from "./columns";

const {Search} = Input

export default function Index() {
  const [list, setList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [currentAccountId, setCurrentAccountId] = useState()
  const [loading, setLoading] = useState(false);
  const [assetLoading, setAssetLoading] = useState(false);
  const [advertiserOptions, setAdvertiserOptions] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getAccountList();
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    if (!params?.asset_id) {
      setAssetLoading(true);
    }
    setLoading(true);
    const res = await getEventList(params);
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      setList(list);
      setEventList(data?.event_list);
    }
    setAssetLoading(false);
    setLoading(false);
  };

  // 获取筛选的广告主账户
  const getAccountList = async () => {
    const res = await getAdvertiserList();
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item?.account_name, value: item?.account_id };
      });
      setAdvertiserOptions(newList);
      let firstAccountId = list ? list[0]?.account_id : 0;
      setCurrentAccountId(firstAccountId)
      form.setFieldsValue({ advertiser_id: firstAccountId });
      getList({ advertiser_id: firstAccountId });
    }
  };

  // 账户切换筛选
  const onAdvertiserChange = (value) => {
    getList({ advertiser_id: value });
  };

  // 资产名称筛选
  const onAppNameSearch = (value) => {
    getList({advertiser_id: currentAccountId, app_name: value})
  }

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };
  
  const onAssetSearch = (assetId) => {
    getList({advertiser_id: currentAccountId, asset_id: assetId})
  }
  

  return (
    <Card
      title={
        <Form layout="inline" form={form}>
          <Form.Item label="账户" name="advertiser_id">
            <Select
              options={advertiserOptions}
              showSearch
              filterOption={filterOption}
              style={{ width: 300 }}
              onChange={onAdvertiserChange}
            />
          </Form.Item>
          <Form.Item label="资产名称">
            <Search allowClear placeholder="请输入关键词" onSearch={onAppNameSearch}/>
          </Form.Item>
        </Form>
      }
    >
      <div style={{ width: "100%", display: "flex" }}>
        <Card style={{ width: "20%", marginRight: 20 }}>
          <ul>
            {assetLoading ? (
              <Spin />
            ) : (
              list?.map((item) => {
                return (
                  <li key={item.id} style={{ marginBottom: 10 }} onClick={() => onAssetSearch(item.asset_id)}>
                    <Link>
                      <div>{item.app_name}</div>
                      <div>{item.asset_id}</div>
                    </Link>
                  </li>
                );
              })
            )}
          </ul>
        </Card>

        <Card style={{ width: "77%" }}>
          <Table
            dataSource={eventList}
            columns={columns()}
            loading={loading}
            pagination={false}
          />
        </Card>
      </div>
    </Card>
  );
}
