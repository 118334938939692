import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../../../components/TableHasTotal";
import columns from "./columns";
import {
  getMaterialOptions,
  getMaterialTitle,
  materialTitleAdd,
  materialTitleCategory,
  materialTitleCategoryAdd,
  materialTitleDelete,
  materialTitleEdit,
} from "../../../../api";

const { confirm } = Modal;
const { TextArea } = Input;

export default function Title() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectKeys, setSelectKeys] = useState([]);
  const [addType, setAddType] = useState("single");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [roleKeys, setRoleKeys] = useState([]);
  const [categoryShow, setCategoryShow] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [conditions, setConditions] = useState([])

  const [form] = Form.useForm();
  const [categorForm] = Form.useForm();

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取标题列表
  const getList = async (params = {}) => {
    const res = await getMaterialTitle(params);
    const { data } = res;
    if (res?.code === 200) {
      let lists = data?.lists;
      let roleKeys = data?.roleKeys
      const newLists = lists.map((item) => {
        item.key = item.id;
        return item;
      });
      setList(newLists);
      setTotal(data?.total);
      if (roleKeys.includes('playlet') || roleKeys.includes('explaylet')) {
        getTitleCategory()
      } else {
        getOptions();
      }
      setRoleKeys(roleKeys);

    }
  };

  // 修改标题
  const updateTitle = (record) => {
    let category = parseInt(record?.category);
    form.setFieldsValue({ title: record?.title, category, id: record?.id, label: record?.label });
    setAddType("edit");
    setIsModalOpen(true);
  };

  // 删除素材
  const deleteSource = (record) => {
    confirm({
      title: "确认删除素材标题?",
      onOk: async () => {
        const res = await materialTitleDelete({ ids: [record.id] });
        if (res?.code === 200) {
          message.success("删除成功", 1);
          setTimeout(() => {
            getList({...conditions,pageNum: page,pageSize:  perPage});
          }, 1000);
        }
      },
    });
  };

  // 批量删除素材
  const batchDelete = () => {
    confirm({
      title: "确认删除所选中的素材标题?",
      onOk: async () => {
        const res = await materialTitleDelete({ ids: selectKeys });
        if (res?.code === 200) {
          message.success("删除成功", 1);
          setTimeout(() => {
            setSelectKeys([]);
            getList({...conditions,pageNum: page,pageSize:  perPage});
          }, 1000);
        }
      },
    });
  };

  // 素材类型
  const getOptions = async () => {
    const res = await getMaterialOptions();
    const categoryOptions = res?.data.map((value, key) => {
      return { label: value, value: key };
    });
    setMaterialOptions(categoryOptions);
  };

  // 标题分类
  const getTitleCategory = async () => {
    const res = await materialTitleCategory()
    if (res?.code === 200) {
      const {data} = res
      const categoryOptions = data?.lists.map((item) => {
        return { label: item.name, value: (parseInt(item.id) + 11)};
      });
      setMaterialOptions(categoryOptions);
    }
  }

  // 添加单条广告素材
  const addTitle = () => {
    setAddType("single");
    setIsModalOpen(true);
  };

  // 批量添加
  const addBatchTitle = () => {
    setAddType("batch");
    setIsModalOpen(true);
  };

  // 关闭弹窗
  const handleClose = () => {
    form.resetFields();
    setIsModalOpen(false);
    setSubmitLoading(false);
  };

  // 点击弹窗确认事件
  const submit = (values) => {
    setSubmitLoading(true);
    if (values?.category === undefined) {
      message.error("请选择分类", 1);
      setSubmitLoading(false);
      return false;
    }
    let title = clearBr(values?.title).split("::");
    let label = values?.label ? clearBr(values?.label) : ''
    let formData = { category: values.category, title, label };
    // 添加素材
    if (addType === "single" || addType === "batch") {
      create(formData);
    } else {
      let id = form.getFieldValue("id");
      formData.id = id;
      // 修改素材
      update(formData);
    }
  };

  // 添加素材
  const create = async (values) => {
    setSubmitLoading(true);
    const res = await materialTitleAdd(values);
    if (res?.code === 200) {
      message.success("添加成功", 1);
      setTimeout(() => {
        handleClose();
        getList();
        setSubmitLoading(false);
      }, 1000);
    } else {
      setSubmitLoading(false);
    }
  };

  // 修改素材
  const update = async (values) => {
    setSubmitLoading(true);
    const res = await materialTitleEdit(values);
    if (res?.code === 200) {
      message.success("修改成功", 1);
      setTimeout(() => {
        handleClose();
        getList();
        setSubmitLoading(false);
      }, 1000);
    }
  };

  // 是否有选中行
  const hasSelected = selectKeys.length > 0;

  // 弹窗表单布局
  const formItemLayout = {
    wrapperCol: {
      xl: { span: 21 },
    },
  };

  // 添加分类弹窗
  const addTitleCategory = () => {
    setCategoryShow(true);
  };

  // 关闭分类弹窗
  const modalCategoryClose = () => {
    categorForm.resetFields()
    setCategoryShow(false);
    setCategoryLoading(false)
  };

  // 处理表单提交添加分类
  const categoryFormSubmit = async values => {
    setCategoryLoading(true)
    const res = await materialTitleCategoryAdd(values)
    if (res?.code === 200) {
      message.success('分类添加成功')
      setTimeout(() => {
        modalCategoryClose()
      }, 500);
    } else {
      setCategoryLoading(false)
    }
  }

  const selectRows = (selectRows) => {
    setSelectKeys(selectRows);
  };

  // 不同的页数选择切换
  const pageSize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ ...conditions, page, pageSize: size });
  };

  // 搜索查询
  const onSearch = (value) => {
    setConditions({title: value})
    getList({ title: value });
  };

  function clearBr(key) {
    key = key.replace(/<\/?.+?>/g, "::");
    key = key.replace(/[\r\n]/g, "::");
    return key;
  }

  // 多标题添加
  const getTextValue = (e) => {
    // let key = e.target.value;
  };

  return (
    <div className="title">
      <Card
        title={
          <Space>
            <Button type="primary" size="small" onClick={addTitle}>
              添加标题
            </Button>
            <Button type="primary" size="small" onClick={addBatchTitle}>
              批量添加标题
            </Button>
            {roleKeys.includes("super") ? (
              <Button type="primary" size="small" onClick={addTitleCategory}>
                添加标题分类
              </Button>
            ) : (
              ""
            )}
            <Button
              type="primary"
              size="small"
              danger
              disabled={!hasSelected}
              onClick={batchDelete}
            >
              批量删除标题
            </Button>
          </Space>
        }
        extra={
          <Input.Search
            placeholder="输入标题查询"
            enterButton="搜索"
            allowClear
            onSearch={onSearch}
            size="small"
          />
        }
      >
        <Table
          columns={columns({ updateTitle, deleteSource })}
          dataSource={list}
          selectRows={selectRows}
          pagesize={pageSize}
          page={page}
          total={total}
          perPage={perPage}
        />
      </Card>

      {/* 添加标题 | 批量添加标题弹窗 */}
      <Modal
        title={
          addType === "single"
            ? "素材标题添加"
            : addType === "edit"
            ? "素材标题编辑"
            : "批量素材标题添加"
        }
        open={isModalOpen}
        onCancel={handleClose}
        footer={null}
      >
        <Form form={form} onFinish={submit}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          {addType === "single" || addType === "edit" ? (
            <Form.Item
              name="title"
              rules={[{ required: true, message: "请输入标题" }]}
            >
              <Input placeholder="请输入标题" allowClear />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                {...formItemLayout}
                name="title"
                rules={[{ required: true, message: "请输入标题" }]}
              >
                <TextArea
                  placeholder="多个标题换行"
                  allowClear
                  rows={6}
                  onPressEnter={getTextValue}
                />
              </Form.Item>
            </>
          )}
          <Form.Item name='label'>
            <Input allowClear placeholder="输入标题标签" />
          </Form.Item>
          <Form.Item name="category">
            <Radio.Group options={materialOptions} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button onClick={handleClose}>取消</Button>
              <Button htmlType="submit" type="primary" loading={submitLoading}>
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      {/* 添加标题分类 */}
      <Modal
        title="添加分类"
        open={categoryShow}
        onCancel={modalCategoryClose}
        footer={null}
      >
        <Divider />
        <Form
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ xl: { span: 16 } }}
          form={categorForm}
          onFinish={categoryFormSubmit}
        >
          <Form.Item
            label="分类名称"
            name="name"
            rules={[{ required: true, message: "分类名称不能空" }]}
          >
            <Input allowClear placeholder="请输分类名称" />
          </Form.Item>
          <Form.Item
            label="分类类型"
            name="type"
            rules={[{ required: true, message: "请选择类型" }]}
            initialValue={1}
          >
            <Radio.Group
              options={[
                { label: "男频", value: 1 },
                { label: "女频", value: 2 },
              ]}
            />
          </Form.Item>
          <Form.Item label="排序" name="sort" initialValue={0}>
            <InputNumber min={0} style={{ width: "50%" }} />
          </Form.Item>
          <Form.Item label colon={false} style={{ textAlign: "right" }}>
            <Space>
              <Button onClick={modalCategoryClose}>取消</Button>
              <Button type="primary" htmlType="submit" loading={categoryLoading}>确定</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
