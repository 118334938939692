const columns = () => [
    {
      title: "推广链接名称",
      width: 120,
      align: "center",
      dataIndex: "promotion_name",
      key: "promotion_name",
      fixed: "left",
    },
    {
      title: "投手(优化师)",
      width: 100,
      align: "center",
      dataIndex: "nickname",
      key: "nickname",
      render: (_,record) => (
        record?.admin_user?.nickname || '-'
      )
    },
    {
      title: "投放内容",
      width: 100,
      align: "center",
      dataIndex: "book_name",
      key: "book_name",
    },
    {
      title: "投放媒体",
      width: 80,
      align: "center",
      dataIndex: "media",
      key: "media",
      render: (_) => (
        <div>头条2.0</div>
      )
    },
    {
      title: "应用类型",
      width: 80,
      align: "center",
      dataIndex: "app_type",
      key: "app_type",
      render: (_, record) => {
        let type = record?.promotion_ad?.project_ad?.micro_promotion_type
        let app = type && type === 'WECHAT_APP' ? '微信小程序' : (type === 'BYTE_APP' ? '抖音小程序' : '-')
        return <div>{app}</div>
      }
    },
    {
      title: "应用名称",
      width: 80,
      align: "center",
      dataIndex: "app_name",
      key: "app_name",
      render: (_, record) => {
        let name = record?.playlet_push?.app_package?.aliasname || '未知'
        return <div>{name}</div>
      }
    },
    {
      title: "实时ROI",
      width: 80,
      align: "center",
      dataIndex: "roi_rate",
      key: "roi_rate",
    },
]

export default columns