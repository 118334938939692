import { Button, Space } from "antd";
import dayjs from "dayjs";

const columns = () => [
  {
    title: "id",
    width: 120,
    align: "center",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "广告 id",
    width: 120,
    align: "center",
    dataIndex: "aid",
    key: "aid",
  },
  {
    title: "广告名称",
    width: 120,
    align: "center",
    dataIndex: "ad_name",
    key: "ad_name",
  },
  {
    title: "原转化数",
    width: 120,
    align: "center",
    dataIndex: "convert",
    key: "convert",
  },
  {
    title: "创建时间",
    width: 120,
    align: "center",
    dataIndex: "create_time",
    key: "create_time",
  },
  {
    title: "回传数量",
    width: 120,
    align: "center",
    dataIndex: "back_nums",
    key: "back_nums",
  },
//   {
//     title: "操作",
//     width: 140,
//     align: "center",
//     dataIndex: "operation",
//     key: "operation",
//     render: (_, record) => {
//       return (
//         <Space>
//           {/*<Button*/}
//           {/*  color="primary"*/}
//           {/*  variant="text"*/}
//           {/*  size="small"*/}
//           {/*  onClick={() => handleReUp(record)}*/}
//           {/*>*/}
//           {/*  重新回传*/}
//           {/*</Button>*/}
//           <Button
//             type="link"
//             size="small"
//             onClick={() => onDetail(record)}
//           >
//             回传详情
//           </Button>
//         </Space>
//       )
//     },
//   },
];

export default columns;
