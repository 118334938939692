import { Link } from 'react-router-dom';
import {numDiv, numMulti} from '../../../util/calculate'

const columns = () => [
  {
    title: "日期",
    width: 70,
    align: "center",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "添加老剧",
    width: 100,
    align: "center",
    dataIndex: "old_playlet",
    key: "old_playlet",
    editable: true,
    render: value => (
      value ? <Link>{value}</Link> : <Link>——</Link>
    )
  },
  {
    title: "当日新增短剧",
    width: 100,
    align: "center",
    dataIndex: "new_playlet",
    key: "new_playlet",
    render: (_, record) => {
        return record?.new_playlet && record?.new_playlet?.map((val) => {
        return <div>{val}</div>;
      });
    },
  },
  {
    title: "当日在投剧 消耗/总回收 情况",
    width: 400,
    align: "left",
    dataIndex: "new_playlet",
    key: "new_playlet",
    render: (_, record) => {
        return record?.active_playlet && record?.active_playlet?.map((item) => {
            return <div>
                <div style={{display: 'flex'}}>
                    <div style={{marginRight:10}}>{item?.theater?.aliasname}</div>
                    <div style={{marginRight:10}}>{`<<${item?.playlet_book?.book_name}>>`}</div>
                    <div style={{marginRight:10}}>消耗：{item?.cost}</div>
                    <div style={{marginRight:10}}>总回收：{item?.money}</div>
                    <div style={{marginRight:10}}>回收率：{parseFloat(item?.cost) ? numMulti(numDiv(item?.money, item?.cost).toFixed(4), 100) + '%' : 0}</div>
                </div>
            </div>
        })
    }
  },
];

export default columns;
