import { Link } from "react-router-dom";
import { numDiv, numMulti } from "../../../../util/calculate";
import { Button } from "antd";
import dayjs from "dayjs";

const columns = ({ profitBtnShow, showProfit, profitLoading, showDetail }) => [
  {
    title: "日期",
    width: 80,
    align: "center",
    dataIndex: "day",
    key: "day",
    fixed: "left",
  },
  {
    title: "新增用户",
    width: 80,
    align: "center",
    dataIndex: "new_user",
    key: "new_user",
  },
  {
    title: "累计充值",
    width: 80,
    align: "center",
    dataIndex: "total_money",
    key: "total_money",
  },
  {
    title: "累计消耗",
    width: 80,
    align: "center",
    dataIndex: "total_cost",
    key: "total_cost",
  },
  {
    title: "首发剧NB充值",
    width: 80,
    align: "center",
    dataIndex: "new_noBid_money",
    key: "new_noBid_money",
  },
  {
    title: "首发剧NB消耗",
    width: 80,
    align: "center",
    dataIndex: "new_noBid_cost",
    key: "new_noBid_cost",
  },
  {
    title: "赠款消耗",
    width: 80,
    align: "center",
    dataIndex: "grant_cost",
    key: "grant_cost",
  },
  {
    title: "赔付",
    width: 80,
    align: "center",
    dataIndex: "claim",
    key: "claim",
    editable: true,
    render: (value) => <Link>{value}</Link>,
  },
  {
    title: "回收率",
    width: 80,
    align: "center",
    dataIndex: "rate",
    key: "rate",
    render: (_, record) => {
      let rate =
        parseFloat(record?.total_cost) && parseFloat(record?.total_money)
          ? numMulti(
              numDiv(record?.total_money * 1, record?.total_cost * 1),
              100
            ).toFixed(2) + "%"
          : 0;
      return <div>{rate}</div>;
    },
  },
  {
    title: "盈亏",
    width: 80,
    align: "center",
    dataIndex: "profit",
    key: "profit",
    render: (value, record) => {
      return (
        <>
          {record?.day === dayjs().format("YYYYMMDD") && profitBtnShow ? (
            <Button size="small" onClick={() => showProfit(record)} loading={profitLoading}>盈亏值</Button>
          ) : (
            <div>{value}</div>
          )}
          <Button type="link" onClick={() => showDetail(record)}>
            查看详情
          </Button>
        </>
      );
    },
  },
];

export default columns;
