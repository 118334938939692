import { Typography } from "antd";

const pitcherColumns = () => [
  {
    title: "投手|短剧信息",
    width: 180,
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    render: (_, record) => (
      <ul>
        <li>
          投手:
          <Typography.Text type="danger">
            {record?.user?.nickname}
          </Typography.Text>
        </li>
        <li>
          短剧名称: <Typography.Text>{record?.book_name}</Typography.Text>
        </li>
      </ul>
    ),
  },
  {
    title: "用户人数",
    width: 180,
    dataIndex: "member_info",
    key: "member_info",
    render: (_, record) => (
      <ul>
        <li>
          <Typography.Text type="danger">
            新增用户数: {record?.new_user}
          </Typography.Text>
        </li>
        <li>首日充值人数: {record?.count}</li>
        <li>首日充值笔数: {record?.first_pay_num}</li>
        <li>新用户付费率: {record?.new_pay_rate || 0}</li>
      </ul>
    ),
  },
  {
    title: "充值金额",
    width: 180,
    dataIndex: "member_info",
    key: "member_info",
    render: (_, record) => (
      <ul>
        <li>
          <Typography.Text type="danger">
            累计充值: {record?.total_money || 0}
          </Typography.Text>
        </li>
        <li>微小充值: {record?.mini_money || 0}</li>
        <li>抖小充值: {record?.byte_money || 0}</li>
        <li>当日充值金额: {record?.first_day_pay || 0}</li>
        <li>当日首充金额: {record?.first_day_first_pay || 0}</li>
        <li>付费成本: {record?.paycosts || 0}</li>
      </ul>
    ),
  },
  {
    title: "累计回本率",
    width: 180,
    dataIndex: "cost",
    key: "cost",
    sorter: (a, b) => a.cost - b.cost,
    sortDirections: ['descend'],
    render: (_, record) => (
      <ul>
        <li>
          <Typography.Text type="danger">
            今日消耗: {record?.cost || 0}
          </Typography.Text>
        </li>
        <li>微小消耗: {record?.mini_cost || 0}</li>
        <li>抖小消耗: {record?.byte_cost || 0}</li>
        <li>回收: {record?.money || 0}</li>
        <li>回收率: {record?.huishoulv || 0}</li>
        <li>今日复充率: {record?.repeat_rate || 0}</li>
        <li>首日客单价: {record?.kedans || 0}</li>
      </ul>
    ),
  },
];

export default pitcherColumns;
