import { Button, Card, DatePicker, Form, Select, Space } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import {getAllBooks} from '../../../api/book'
import {chapterReadRecord} from '../../../api/statistics'
import Table from '../../../components/TableNoSelection'
import columns from "./columns";

const { RangePicker } = DatePicker;

export default function Index() {
  const [list, setList] = useState([]);
  const [bookOptions, setBookOptions] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getBooks()
  }, [])

  // 获取所有的书籍
  const getBooks = async () => {
    const res = await getAllBooks()
    if (res?.code === 200) {
        const {data} = res
        const list = data.lists.map((item) => {
            return {label: item.title, value: item.id}
        })
        setBookOptions(list)
    }
  }

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true)
    const res = await chapterReadRecord(params)
    if (res?.code === 200) {
        const {data} = res
        console.log(data);
        setList(data?.lists)
        setLoading(false)
    }
  }

  // 查询表单提交
  const onSubmit = values => {
    let rangeDate
    if (values['range_date']) {
        rangeDate = values['range_date'].map((date) => {
            return date.format('YYYY-MM-DD')
        })
        delete values.range_date
        values.range_date = rangeDate
    }
    getList(values)
  }

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  return (
    <Card
      title={
        <Form layout="inline" onFinish={onSubmit}>
          <Form.Item label="选择书籍" name="book_id">
            <Select
              placeholder="请选择书籍"
              allowClear
              showSearch
              filterOption={filterOption}
              options={bookOptions}
              style={{ width: 180 }}
            />
          </Form.Item>
          <Form.Item name='range_date'>
            <RangePicker />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">确定</Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
        <Table 
            dataSource={list}
            columns={columns()}
            pagination={false}
            loading={loading}
        />
    </Card>
  );
}
