import { Form, Input, Popover, Typography } from "antd"

const {Text} = Typography

const columns = ({onChange}) => [
    {
        title: "日期",
        width: 100,
        align: "center",
        dataIndex: "day",
        key: "day",
        fixed: 'left',
    },
    {
        title: "新增用户",
        width: 100,
        align: "center",
        dataIndex: "new_user",
        key: "new_user",
    },
    {
        title: "累计充值",
        width: 100,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "成本",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'>
                            <Input onKeyDown={(e) => onChange(e, 'cost', record?.id)} />
                        </Form.Item>
                    </Form> 
                } 
                title="修改成本" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
    {
        title: "自动计算头条成本",
        width: 120,
        align: "center",
        dataIndex: "cal_cost",
        key: "cal_cost",
    },
    {
        title: "赔付",
        width: 100,
        align: "center",
        dataIndex: "claim",
        key: "claim",
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'>
                        <Input onKeyDown={(e) => onChange(e, 'claim', record?.id)} />
                        </Form.Item>
                    </Form> 
                } 
                title="修改赔付" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
    {
        title: "回本率",
        width: 80,
        align: "center",
        dataIndex: "huiben_lv",
        key: "huiben_lv",
    },
    {
        title: "今日复充率",
        width: 110,
        align: "center",
        dataIndex: "repeat_rate",
        key: "repeat_rate",
    },
    {
        title: "今日首充率",
        width: 110,
        align: "center",
        dataIndex: "first_pay_rate",
        key: "first_pay_rate",
    },
    {
        title: "新用户付费率",
        width: 110,
        align: "center",
        dataIndex: "new_pay_rate",
        key: "new_pay_rate",
    },
    {
        title: "首日充值人数",
        width: 100,
        align: "center",
        dataIndex: "first_pay_user",
        key: "first_pay_user",
    },
    {
        title: "首日首充|首日充值",
        width: 150,
        align: "center",
        dataIndex: "first_money",
        key: "first_money",
        render: (_, record) => {
            return `${record?.first_day_first_pay} | ${record?.firstDay}`
        }
    },
    {
        title: "首日首充客单价|首日客单价",
        width: 180,
        align: "center",
        dataIndex: "per_sale",
        key: "per_sale",
        render: (_, record) => {
            return `${record?.firstPer} | ${record?.per}`
        }
    },
    {
        title: "总充值人数|总客单价",
        width: 180,
        align: "center",
        dataIndex: "total_pay",
        key: "total_pay",
        render: (_, record) => {
            return `${record?.total_pay_user} | ${record?.perPrice}`
        }
    },
    {
        title: "当天付费人数成本|总付费人数成本",
        width: 180,
        align: "center",
        dataIndex: "day_cost",
        key: "day_cost",
        render: (_, record) => {
            return `${record?.firstPercent} | ${record?.totalPercent}`
        }
    },
]

export default columns