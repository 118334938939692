import { Button, Space, Tag } from "antd"

const columns = ({ handleEdit, handleDelete }) => [
    {
        title: "菜单名称",
        width: 180,
        align: "center",
        dataIndex: "title",
        key: "title",
        ellipsis: true,
    },
    {
        title: "排序",
        width: 100,
        align: "center",
        dataIndex: "sort",
        key: "sort",
    },
    {
        title: "状态",
        width: 100,
        align: "center",
        dataIndex: "status",
        key: "status",
        render: text => (
            text === 1 ? <Tag color="green">正常</Tag> : <Tag color="red">停用</Tag>
        )
    },
    {
        title: "创建时间",
        width: 150,
        align: "center",
        dataIndex: "created_at",
        key: "created_at",
    },
    {
        title: "操作",
        width: 180,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        fixed: 'right',
        render: (_, record) => (
            <Space>
                <Button type="primary" onClick={() => {handleEdit(record)}}>编辑</Button>
                <Button type="primary" danger onClick={() => {handleDelete(record)}}>删除</Button>
            </Space>
        )
    },
    
]

export default columns