import React, { useCallback } from "react";
import Table from "../../../../components/TableNoSelection";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  message,
} from "antd";
import columns from "./columns";
import yitouColumns from "./yitouColumns";
import { playletVirtualBack, resultReUp } from "../../../../api/playlet";
import { addAdvertiseBack, updateAdvertiseBack } from "@/api/delivery";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Detail from "./Detail";

const FORMAT = "YYYY-MM-DD HH:mm:ss";

export default function Index(props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailId, setDetailId] = useState(null);

  const { list, total, page, perPage, pagesize, category, ad_id, isSuper } =
    props;

  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  if (ad_id) {
    searchForm.setFieldsValue({ aid: ad_id });
  }

  // 当前的路径
  const pathKey = useLocation().pathname;
  const navigate = useNavigate();

  const onFormSubmit = values => {
    props.onSearch(values);
  };

  // 重新回传
  const handleReUp = async record => {
    const res = await updateAdvertiseBack({
      id: record?.id,
      appType: 1011,
      ...record,
    });
    if (res?.code === 200) {
      message.success("重新回传成功", 1);
      props.onSearch();
    }
  };

  // 打开详情页
  const onDetail = useCallback(record => {
    const id = record?.id;
    setDetailId(id);
  }, []);

  // 关闭详情页
  const closeDetail = useCallback(() => {
    setDetailId(null);
  }, []);

  const getColumns = () => {
    if (category) {
      return yitouColumns({ handleReUp });
    } else {
      return columns({ handleReUp, onDetail });
    }
  };

  // 表单条件重置
  const formReset = () => {
    searchForm.resetFields();
    navigate(pathKey, { state: null });
  };

  // 添加虚拟回传弹窗
  const addVirtualModal = () => {
    setShow(true);
  };

  // 关闭虚拟回传弹窗
  const modalClose = () => {
    form.resetFields();
    setLoading(false);
    setShow(false);
  };

  // 虚拟回传表单提交
  const onVirtualFormSubmit = async values => {
    try {
      setLoading(true);
      const res = await addAdvertiseBack({
        appType: 1011,
        adId: values?.aid,
        num: values?.numbers,
        configType: props.configType || undefined,
      });
      if (res?.code === 200) {
        message.success("虚拟回传操作成功");
        setTimeout(() => {
          modalClose();
          props.onSearch();
        }, 500);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  // 展示详情页
  if (detailId) {
    return <Detail closeDetail={closeDetail} id={detailId} />;
  }

  return (
    <>
      <Card
        title={
          <Form layout="inline" onFinish={onFormSubmit} form={searchForm}>
            <Form.Item label="广告ID" name="aid">
              <Input allowClear placeholder="输入计划ID" />
            </Form.Item>
            <Form.Item label="日期" name="day">
              <DatePicker.RangePicker
                showTime
                format={FORMAT}
                placeholder={["开始日期", "结束日期"]}
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button onClick={formReset}>重置</Button>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </Space>
            </Form.Item>
          </Form>
        }
      >
        <div style={{ marginBottom: 10 }}>
          <Button type="primary" onClick={addVirtualModal}>
            添加虚拟回传
          </Button>
        </div>
        <Table
          dataSource={list}
          columns={getColumns()}
          total={total}
          page={page}
          perPage={perPage}
          pagesize={pagesize}
          loading={props.loading}
          pageOptions={props.pageOptions}
        />
      </Card>

      {/* 添加虚拟回传弹窗 */}
      <Modal
        open={show}
        onCancel={modalClose}
        title="添加虚拟回传"
        footer={null}
      >
        <Divider />
        <Form
          labelCol={{ sm: { span: 5 } }}
          wrapperCol={{ xl: { span: 16 } }}
          form={form}
          onFinish={onVirtualFormSubmit}
        >
          <Form.Item
            label="广告ID"
            name="aid"
            rules={[{ required: true, message: "请输入广告id" }]}
          >
            <Input allowClear placeholder="输入广告id" />
          </Form.Item>
          <Form.Item label="回传数量" name="numbers">
            <InputNumber
              allowclear="true"
              placeholder="输入虚拟回传数 (不填默认1)"
              style={{ width: "100%" }}
              min={1}
              // max={isSuper ? 10000 : 3}
              max={10000}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "right", marginLeft: "59%" }}>
            <Space>
              <Button onClick={modalClose}>取消</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
