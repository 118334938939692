import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  List,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
  message,
} from "antd";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  asyncFilterList,
  getAdvertiserList,
  getMaterialLabels,
  getMaterialMan,
  materialAsyncTaskCreate,
} from "../../../../api/advertise";
import { useEffect } from "react";
import { getMaterialOptions } from "../../../../api";
import "./addTask.less";
import {
  createFromIconfontCN,
  DownOutlined,
  UpOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;

export default function Index() {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [conditions, setConditions] = useState([]);
  const [materialLabels, setMaterialLabels] = useState([]);
  const [sucaiOptions, setSucaiOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [selectRowKeys, setSelectRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [accountSelectLoading, setAccountSelectLoading] = useState(false);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Promise.all([
      getList(),
      getLabels(),
      getSucai(),
      getCategory(),
      getAdvertisers(),
    ]);
  };

  const fetchData = async (params = {}) => {
    return await asyncFilterList({ ...params });
  };

  const getList = (params = {}) => {
    setListLoading(true);
    // 返回promise对象
    const p = fetchData(params);
    p.then((res) => {
      const { data } = res;
      if (res?.code === 200) {
        setList([...list, ...data?.lists]);
        setListLoading(false);
      }
    });
  };

  // 布局
  const layout = {
    labelCol: { xl: { span: 3 } },
    wrapperCol: { xl: { span: 24 } },
  };

  // 获取加载更多
  const loadMore = () => {
    let p1 = page + 1;
    const p = fetchData({ ...conditions, page: p1 });
    p.then((res) => {
      const { data } = res;
      if (res?.code === 200) {
        let existsIds = list.map((item) => item.id);
        let lists = data?.lists;
        let newList = lists.filter((item) => {
          return !existsIds.includes(item.id);
        });
        setList([...list, ...newList]);
      }
    });
    setPage(p1);
  };

  // 条件查询
  const onHandleSearch = (values) => {
    setListLoading(true);
    let createdAt;
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.range_date;
    }
    let params = { ...values, create_time: createdAt };
    setConditions(params);
    const p = fetchData(params);
    p.then((res) => {
      const { data } = res;
      if (res?.code === 200) {
        setList(data?.lists);
        setListLoading(false);
      } else {
        setListLoading(false);
      }
    });
    // 重新从第1页开始
    setPage(1);
  };

  // 获取广告主列表
  const getAdvertisers = async (params = {}) => {
    const res = await getAdvertiserList(params);
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item?.account_name, value: item?.account_id };
      });
      setAdvertiserOptions(newList);
      setAccountSelectLoading(false);
    }
  };

  // 获取素材标签
  const getLabels = async () => {
    const res = await getMaterialLabels();
    const { data } = res;
    if (res?.code === 200) {
      let labels = data?.labels;
      let newLabels = labels.map((item) => {
        return { label: item, value: item };
      });
      setMaterialLabels(newLabels);
    }
  };

  // 获取筛选的素材手
  const getSucai = async () => {
    const res = await getMaterialMan();
    const { data } = res;
    if (res?.code === 200) {
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setSucaiOptions(newLists);
    }
  };

  // 获取素材分类
  const getCategory = async () => {
    const materialOptions = await getMaterialOptions();
    const options = materialOptions?.data.map((value, key) => {
      return { label: value, value: key };
    });
    setCategoryOptions(options);
  };

  // 点击选中素材
  const onSelect = (record) => {
    if (selectRowKeys.includes(record?.id)) {
      const newRows = selectRowKeys.filter((item) => {
        return item !== record?.id;
      });
      setSelectRowKeys(newRows);
    } else {
      const newRows = [...selectRowKeys, record?.id];
      setSelectRowKeys(newRows);
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 素材排序
  const handleSorted = (value) => {
    setListLoading(true);
    setList([]);
    setConditions({ ...conditions, order: value ? value : "" });
    const p = fetchData({ ...conditions, order: value ? value : "" });
    p.then((res) => {
      const { data } = res;
      if (res?.code === 200) {
        setList(data?.lists);
        setListLoading(false);
      }
    });
  };

  // 确认提交
  const handleSubmit = async () => {
    setLoading(true);
    let values = form1.getFieldsValue(["pre_time", "advertiser_ids"]);
    let createdAt;
    if (values["pre_time"]) {
      createdAt = values["pre_time"].format("YYYY-MM-DD HH:mm");
      delete values.pre_time;
    }
    values.pre_time = createdAt;
    if (!values["advertiser_ids"]) {
      message.error("请选择广告主", 2);
      return false;
    }

    if (selectRowKeys.length === 0) {
      message.error("请选择素材", 2);
      return false;
    }
    let params = { ...values, material_ids: selectRowKeys };
    const res = await materialAsyncTaskCreate(params);
    if (res?.code === 200) {
      message.success("添加成功", 2);
      setTimeout(() => {
        setLoading(false);
        navigate("/admin/material/async");
      }, 1000);
    } else {
      message.success("添加失败", 2);
      setLoading(false);
    }
  };

  // 全选
  const checkALL = () => {
    const rowIds = list.map((item) => {
      return item.id;
    });
    setSelectRowKeys(rowIds);
  };

  // 取消全选
  const checkCancel = () => {
    setSelectRowKeys([]);
  };

  const MyIcon = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/c/font_3965974_nemuvicw5a.js", // 在 iconfont.cn 上生成
  });

  // 是否显示组员的账户
  const onShowChange = (e) => {
    setAccountSelectLoading(true);
    let value = e.target.value;
    if (value === 1) {
      getAdvertisers();
    } else {
      getAdvertisers({ type: "all" });
    }
  };

  return (
    <>
      <Card
        title="添加同步素材任务"
        extra={
          <Button onClick={() => navigate(-1)} size="small">
            返回
          </Button>
        }
        styles={{ body: { marginTop: -20 }, header: { fontSize: 14 } }}
      >
        <Form
          {...layout}
          form={form1}
          style={{ marginTop: 5, marginBottom: -20 }}
        >
          <Form.Item label="预约执行时间" name="pre_time">
            <DatePicker showTime format="YYYY-MM-DD HH:mm" />
          </Form.Item>
          <Form.Item label="显示组员账户" name initialValue={1}>
            <Radio.Group
              options={[
                { label: "否", value: 1 },
                { label: "是", value: 2 },
              ]}
              onChange={onShowChange}
            />
          </Form.Item>
          <Form.Item label="上传到所选广告主" name="advertiser_ids">
            <Select
              mode="multiple"
              allowClear
              placeholder="选择一个或多个广告主"
              loading={accountSelectLoading}
              showSearch
              filterOption={filterOption}
              options={advertiserOptions}
              autoClearSearchValue={false}
            />
          </Form.Item>
        </Form>
      </Card>
      <Card
        styles={{ header: { paddingTop: 10 } }}
        title={
          <Form
            initialValues={{ type: 1, show_type: 2 }}
            form={form}
            onFinish={onHandleSearch}
            size="small"
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="素材类型" name="type">
                  <Radio.Group>
                    <Radio value={1}>竖版视频</Radio>
                    <Radio value={3}>横版大图</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="素材归属" name="show_type">
                  <Radio.Group>
                    <Radio value={1}>公有</Radio>
                    <Radio value={2}>私有</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="分类" name="category">
                  <Select
                    allowClear
                    placeholder="选择素材分类"
                    showSearch
                    filterOption={filterOption}
                    options={categoryOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="时间筛选" name="range_date">
                  <RangePicker />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="素材名称" name="title">
                  <Input allowClear placeholder="素材名称" />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="所属素材手" name="uid">
                  <Select
                    allowClear
                    placeholder="选择素材手"
                    showSearch
                    filterOption={filterOption}
                    options={sucaiOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="素材ID" name="id">
                  <Input allowClear placeholder="素材id" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={15}>
                <Form.Item label="标签" name="label">
                  <Select
                    allowClear
                    placeholder="选择素材标签"
                    showSearch
                    filterOption={filterOption}
                    options={materialLabels}
                    mode="multiple"
                    autoClearSearchValue={false}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="转化素材" name="is_convert">
                  <Select
                    allowClear
                    placeholder="请选择"
                    options={[
                      { label: "转化素材", value: 1 },
                      { label: "非转化素材", value: 0 },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item label="低效素材" name="is_low">
                  <Select
                    allowClear
                    placeholder="请选择"
                    options={[
                      { label: "是", value: 1 },
                      { label: "否", value: 0 },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label colon={false} labelCol={{ span: 18 }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
                <Button onClick={() => form.resetFields()}>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
        style={{ marginTop: 5 }}
      >
        <div
          style={{
            marginBottom: 5,
            marginTop: -18,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ marginRight: "auto" }}>
            <Space>
              <Button size="small" onClick={checkALL}>
                全选
              </Button>
              <Button size="small" onClick={checkCancel}>
                取消全选
              </Button>
            </Space>
          </div>
          <div>
            <Space>
              <Button
                icon={<UpOutlined />}
                onClick={() => handleSorted("cost_asc")}
                size="small"
              >
                消耗升序
              </Button>
              <Button
                icon={<DownOutlined />}
                onClick={() => handleSorted("cost_desc")}
                size="small"
              >
                消耗降序
              </Button>
              <Button
                icon={<UpOutlined />}
                onClick={() => handleSorted("convert_asc")}
                size="small"
              >
                转化升序
              </Button>
              <Button
                icon={<DownOutlined />}
                onClick={() => handleSorted("convert_desc")}
                size="small"
              >
                转化降序
              </Button>
              <Button
                icon={<MinusOutlined />}
                onClick={() => handleSorted(undefined)}
                size="small"
              >
                取消排序
              </Button>
            </Space>
          </div>
        </div>
        <div
          id="scrollableDiv"
          style={{
            paddingTop: 10,
            height: 400,
            overflow: "auto",
            padding: "0 16px",
            border: "1px solid rgba(140, 140, 140, 0.35)",
          }}
        >
          <InfiniteScroll
            dataLength={list.length}
            next={loadMore}
            hasMore={list.length > 10}
            endMessage={<Divider plain>已全部加载完成 🤐</Divider>}
            scrollableTarget="scrollableDiv"
          >
            <List
              grid={{
                gutter: 16,
                column: 5,
              }}
              dataSource={list}
              loading={listLoading}
              renderItem={(item) => (
                <List.Item key={item.email}>
                  <div
                    style={{
                      overflowX: "hidden",
                      width: 200,
                      marginLeft: -16,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{ padding: 2, marginLeft: "8%" }}
                      className={selectRowKeys.includes(item.id) ? "live" : ""}
                      onClick={() => onSelect(item)}
                    >
                      <div style={{ textAlign: "center", marginBottom: 5 }}>
                        <div style={{ fontWeight: "bold" }}>
                          {item.type === 1 ? "竖版视频" : "横版大图"}
                          {item.is_convert ? (
                            <Space>
                              <MyIcon type="icon-qiang" />
                              <span>({item.convert})</span>
                            </Space>
                          ) : (
                            ""
                          )}
                        </div>
                        <Tooltip title={item.title}>{item.title}</Tooltip>
                      </div>
                      {item.file_id ? (
                        <video
                          width="100%"
                          src={item?.attach_video?.oss_url}
                          alt=""
                          controls
                          preload="none"
                          poster={`${item?.attach_video?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                        />
                      ) : (
                        <img
                          width="100%"
                          src={item?.attach_img?.oss_url}
                          alt=""
                        />
                      )}
                      <div
                        style={{
                          display: "flex",
                          backgroundColor: "#eee",
                          justifyContent: "space-evenly",
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <div style={{ marginRight: 3, color: "red" }}>
                          ￥{item?.cost}
                        </div>
                        <div style={{ marginRight: 3, color: "green" }}>
                          使用:{item?.use_num}
                        </div>
                        <div>拒:{item?.audit_fail_num}</div>
                      </div>
                    </div>
                    {selectRowKeys.includes(item.id) ? (
                      <div
                        style={{
                          fontSize: 35,
                          position: "absolute",
                          left: "50%",
                          top: "36%",
                        }}
                      >
                        <MyIcon type="icon-xuanzhong-copy" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      </Card>
      <Card
        style={{ marginTop: 10 }}
        title={
          selectRowKeys.length > 0 ? (
            <div>已选中 {selectRowKeys.length} 个素材</div>
          ) : (
            ""
          )
        }
        extra={
          <Space>
            <Button>取消</Button>
            <Button type="primary" onClick={handleSubmit} loading={loading}>
              确定
            </Button>
          </Space>
        }
      ></Card>
    </>
  );
}
