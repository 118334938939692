import { Table } from "antd";
import { Link } from "react-router-dom";

const IAADetail = ({ data, date }) => {
  const columns = [
    {
      title: "投手",
      dataIndex: "presentation",
      render: (text, record) => (
        <Link
          to={`/admin/statistics/theater/push_plt/profit_statistics`}
          state={{ admin_id: Number(record.adminId), target_date: date }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "消耗",
      dataIndex: "cost",
      render: (text) => text.toFixed(2),
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: "ecpm收益",
      dataIndex: "ecpm",
      render: (text) => text.toFixed(2),
      sorter: (a, b) => a.ecpm - b.ecpm,
    },
    {
      title: "回收率",
      dataIndex: "rate",
      render: (text) => (text * 100).toFixed(2) + "%",
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "盈亏",
      dataIndex: "profit",
      render: (text) => text.toFixed(2),
      sorter: (a, b) => a.profit - b.profit,
    },
  ];

  return (
    <Table bordered columns={columns} dataSource={data} pagination={false} />
  );
};

export default IAADetail;
