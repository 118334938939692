import React, { useEffect, useState } from "react";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import { Button, Card, DatePicker, Form, Select, Space } from "antd";
import {
  playletTimerBook,
  playletTimerStatistics,
} from "../../../api/statistics";

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [playletOptions, setPlayletOptions] = useState([]);

  useEffect(() => {
    getPlaylets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await playletTimerStatistics(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists)
      setLoading(false);
    }
  };

  // 获取所有的短剧
  const getPlaylets = async () => {
    const res = await playletTimerBook();
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.book_name, value: item.book_id };
      });
      setPlayletOptions(newLists);
      // 默认获取消耗最多的短剧分时数据
      if (lists.length) {
        getList({ book_id: lists[0]?.book_id });
      }
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 表单提交
  const onSubmit = (values) => {
    let date;
    if (values["date"]) {
      date = values["date"].format("YYYYMMDD");
      values.date = date;
    }
    getList(values);
  };

  return (
    <Card
      title={
        <Form layout="inline" onFinish={onSubmit}>
          <Form.Item label="日期" name="date">
            <DatePicker />
          </Form.Item>
          <Form.Item label="短剧" name="book_id">
            <Select
              allowClear
              showSearch
              placeholder='选择短剧'
              filterOption={filterOption}
              options={playletOptions}
              style={{ width: 220 }}
            />
          </Form.Item>
          <Form.Item label colon={false}>
            <Space>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Table
        dataSource={list}
        columns={columns()}
        pagination={false}
        loading={loading}
      />
    </Card>
  );
}
