import { Card } from "antd";
import React, { useEffect, useState } from "react";
import IAA from "./IAA";
import IAP from "./IAP";
import { filterPitchers } from "../../../api/statistics";

export default function Index() {
  const [activeTabKey, setActiveTabKey] = useState(1);
  const [pitcherOptions, setPitcherOpitions] = useState([]);

  useEffect(() => {
    getPitcher();
  }, []);

  // 获取筛选的投手信息
  const getPitcher = async () => {
    const res = await filterPitchers();
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists
      let newList = list?.map((item) => {
        return {label: item.nickname, value: item.id}
      })
      setPitcherOpitions(newList)
    }
  };

  const onTabChange = (value) => {
    setActiveTabKey(value);
  };

  const contentList = {
    1: <IAA admins={pitcherOptions} />,
    2: <IAP admins={pitcherOptions} />,
  };

  return (
    <Card
      tabList={[
        { key: 1, tab: "IAA" },
        { key: 2, tab: "IAP" },
      ]}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      size="small"
    >
      {contentList[activeTabKey]}
    </Card>
  );
}
