import { Button, Card, Form, Input, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../../components/TableNoSelection";
import createColumns from "./createColumns";
import shareColumns from "./shareColumns";
import { getLandingList } from "../../../api/asset";
import { useLocation } from "react-router-dom";
import { getAdvertiserList } from "../../../api/advertise";

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [PerPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [activeTypeTabKey, setActiveTypeTabKey] = useState(1);
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [conditions, setConditions] = useState([])

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search.substring(1));
  let taskNo = searchParams.get("taskNo");

  useEffect(() => {
    getData({ tab: 1, task_no: taskNo });
  }, []);

  const getData = (params = {}) => {
    Promise.all([getList(params), getAccountList()]);
  };

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getLandingList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setTotal(data?.total);
    }
    setLoading(false);
  };

  // 获取筛选的广告主账户
  const getAccountList = async () => {
    const res = await getAdvertiserList();
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item?.account_name, value: item?.account_id };
      });
      setAdvertiserOptions(newList);
    }
  };

  // 根据不同的tab切换获取不同的列表展示
  const getColumns = () => {
    let columns;
    if (activeTypeTabKey === 1) {
      columns = createColumns();
    } else {
      columns = shareColumns();
    }
    return columns;
  };

  // tab切换
  const onTypeTabChange = (key) => {
    setActiveTypeTabKey(key);
    getList({ tab: key });
  };

  // 搜索表单提交
  const onSubmit = (values) => {
    let rangeDate;
    if (values?.range_date) {
      rangeDate = values.range_date.map((date) => {
        return date.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    setConditions(values)
    getList(values);
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ ...conditions, page, pageSize: size });
  };

  return (
    <>
      <Card
        title={
          <Form layout="inline" onFinish={onSubmit} initialValues={{status: 'all'}}>
            <Form.Item label="账户" name="advertiser_id">
              <Select
                allowClear
                placeholder="选择账户"
                options={advertiserOptions}
                style={{ width: 300 }}
              />
            </Form.Item>
            <Form.Item label="状态" name="status">
              <Select
                placeholder="选择状态"
                options={[
                  { label: "全部", value: "all" },
                  { label: "已上线", value: "ENABLE" },
                  { label: "已下线", value: "EDIT" },
                  { label: "审核不通过", value: "AUDIT_REJECTED" },
                  { label: "禁止或删除", value: "DELETE" },
                ]}
                style={{ width: 120 }}
              />
            </Form.Item>
            <Form.Item label="落地页" name="keyword">
              <Input placeholder="输入落地页名称或ID" />
            </Form.Item>
            {/* <Form.Item label="创建时间" name="range_date">
              <RangePicker />
            </Form.Item> */}
            <Form.Item label colon={false}>
              <Space>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
        styles={{ body: { display: "none" } }}
      />
      <Card
        tabList={[
          { key: 1, tab: "我创建的落地页" },
          // { key: 2, tab: "共享的落地页" },
        ]}
        activeTabKey={activeTypeTabKey}
        onTabChange={onTypeTabChange}
        size="small"
        style={{ marginTop: 10 }}
      >
        <Table
          dataSource={list}
          columns={getColumns()}
          total={total}
          page={page}
          PerPage={PerPage}
          loading={loading}
          bordered={true}
          pagesize={pagesize}
        />
      </Card>
    </>
  );
}
