import React, { useState } from "react";

import AddProject from "./AddProject";
import AddPlan from "./AddPlan";
import AddPlanCopy from "./AddPlan/copy";
import { useLocation } from "react-router-dom";

export default function Add() {
  const [step, setStep] = useState(0);
  const [projectData, setProjectData] = useState({});

  const { state } = useLocation();

  // 下一步
  const onNext = (data) => {
    setStep(1);
    setProjectData(data);
  };

  // 上一步
  const returnPrevious = (data) => {
    setStep(0);
    setProjectData(data);
  };

  return state?.operate && state?.operate === "copy" ? (
    <AddPlanCopy {...state?.record} />
  ) : (
    <>
      {step === 0 ? (
        <AddProject projectData={projectData} onSubmit={onNext} />
      ) : (
        <AddPlan projectData={projectData} returnPre={returnPrevious} />
      )}
    </>
  );
}
