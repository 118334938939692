import {
  Button,
  Modal,
  Space,
  Table,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import {
  DeleteOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { attachVideoAdd } from "../../../../api";
import {
  breakPointMultipartUpload,
  pauseUpload,
  resumeUpload,
} from "../../../../lib/TosClient";
import { getFileNameUUID } from "../../../../lib/AliOss";

const { confirm } = Modal;

export default function Index(props) {
  const [attach, setAttach] = useState([]);
  const { isShow, closeVideo, handleSure } = props;
  const [fileQueuedList, setFileQueuedList] = useState([]);
  const [files, setfiles] = useState([]);
  const [removeFiles, setRemoveFiles] = useState([]);
  const [uploaderDisable, setUploaderDisable] = useState(true);

  // 上传文件的状态信息（大小，进度等）
  const columns = [
    {
      title: "文件名",
      dataIndex: "filename",
      key: "filename",
      width: 150,
      align: "center",
    },
    {
      title: "大小",
      dataIndex: "size",
      key: "size",
      width: 80,
      align: "center",
      render: (value) => {
        let size = parseInt(value) / 1024 / 1024;
        return size.toFixed(2) + "M";
      },
    },
    {
      title: "进度",
      dataIndex: "progress",
      key: "progress",
      width: 80,
      align: "center",
    },
    {
      title: "状态",
      dataIndex: "statusName",
      key: "statusName",
      width: 100,
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      width: 100,
      align: "center",
      render: (_, record) => (
        <Space>
          {record.status === "UPLOADING" ? (
            <Tooltip title="暂停">
              <Button
                icon={<PauseOutlined />}
                type="primary"
                ghost
                onClick={() => handlePauseUpload(record)}
              ></Button>
            </Tooltip>
          ) : (
            ""
          )}
          {record.status === "PAUSE" ? (
            <Tooltip title="恢复上传">
              <Button
                icon={<PlayCircleOutlined />}
                type="primary"
                ghost
                onClick={() => recoverUpload(record)}
              ></Button>
            </Tooltip>
          ) : (
            ""
          )}
          <Button icon={<DeleteOutlined />} onClick={() => onRemove(record)}>
            移除
          </Button>
        </Space>
      ),
    },
  ];

  // 暂停上传
  const handlePauseUpload = (record) => {
    pauseUpload(record?.objName);
    const newFileList = fileQueuedList.map((item) => {
      if (item.key === record.key) {
        item.status = "PAUSE";
      }
      return item;
    });
    setFileQueuedList(newFileList);
  };

  // 重新恢复上传
  const recoverUpload = async (record) => {
    const filtered = files.filter((file) => {
      return file.uid === record.key;
    });
    let file = filtered[0];
    let name = file.name;
    let ext = name.substring(name.lastIndexOf("."));
    let path = record.objName;
    const res = await resumeUpload(path, file, { actualProgress });

    // 处理保存素材到attach表中
    if (res?.statusCode === 200) {
      const { data } = res;
      let videoURL = data?.Location;
      let md5 = data?.ETag;
      let size = file?.size;
      let attachment = attach;
      const rs = await attachVideoAdd({
        md5,
        name,
        path,
        ext,
        size,
        oss_url: videoURL,
      });
      if (rs?.code === 200) {
        attachment.push({ attachId: rs?.data?.attachId, url: videoURL });
        setAttach(attachment);
      }
    }
  };

  // 选择视频时触发事件
  const handleChange = ({ file, fileList }) => {
    let newFileList = fileList.filter((item) => {
      let fileKeys = removeFiles.map((file) => {
        return file.key;
      });
      return !fileKeys.includes(item.uid);
    });
    let fileArr = files;
    fileArr.push(file);
    let filterList = newFileList.filter((file) => {
      let fileNames = [];
      if (fileQueuedList) {
        fileNames = fileQueuedList.map((item) => {
          return item?.filename;
        });
      }
      return !fileNames.includes(file.name);
    });
    let newList = [];
    if (filterList && filterList.length > 0) {
      newList = filterList.map((item) => {
        return {
          key: item?.uid,
          filename: item?.name,
          size: item?.size,
          progress: "0%",
          status: "START",
          statusName: "待开始",
        };
      });
    }
    setfiles(fileArr);
    setFileQueuedList([...fileQueuedList, ...newList]);
    setUploaderDisable(false);
  };

  // 移除
  const onRemove = (record) => {
    let newList = fileQueuedList.filter((item) => {
      return item.filename !== record.filename;
    });
    // 这里主要是过滤被移除的attachId
    Promise.all(files).then((items) => {
      let delFiltered = items.filter((file) => {
        return file.name === record.filename;
      });
      if (delFiltered.length > 0) {
        let delAttachIds = delFiltered.map((item) => {
          return item.attachId;
        });
        let newAttach = attach.filter((item) => {
          return !delAttachIds.includes(item.attachId);
        });
        setAttach(newAttach);
      }
      // 还未上传完成移除素材
      let retFiltered = items.filter((file) => {
        return file.name !== record.filename;
      });
      setfiles(retFiltered);
    });

    setFileQueuedList(newList);
    setRemoveFiles([...removeFiles, record]);
  };

  // 主要用于手动上传
  const beforeUpload = () => {
    return false;
  };

  const uploadProps = {
    name: "file",
    onChange: handleChange,
    showUploadList: false,
    multiple: true,
    beforeUpload,
  };

  // 实时更新进度
  const actualProgress = (p, objName, file) => {
    let updateFileList = fileQueuedList;
    let res = updateFileList.map((item) => {
      //文件上传中时更新文件状态和进度条
      if (item.key === file?.uid) {
        let progress = parseFloat(p * 100);
        item.progress = progress.toFixed(2) + "%";
        item.status = progress === 100 ? "UPLOADED" : "UPLOADING";
        item.statusName = progress === 100 ? "已完成" : "上传中";
        item.objName = objName;
      }
      return item;
    });
    setFileQueuedList(res);
  };

  // 点击开始批量上传
  const handleUpload = async () => {
    if (files && files.length > 0) {
      let filtered = files.filter((item) => {
        return item.size > 300 * 1024 * 1024;
      });
      if (filtered && filtered.length > 0) {
        let name = filtered[0]?.name;
        message.error(`视频${name}超过300M, 请压缩后再上传`);
        setUploaderDisable(false);
        return false;
      }

      let largeFiles = files.filter((item) => {
        return item.size > 150 * 1024 * 1024;
      });

      if (largeFiles && largeFiles.length > 0) {
        confirm({
          title: "上传的视频中有超过150M, 是否继续?",
          onOk: () => {
            setUploaderDisable(true);
            doHandleUpload(files);
          },
        });
      } else {
        setUploaderDisable(true);
        doHandleUpload(files);
      }
    }
  };

  // 处理批量上传
  const doHandleUpload = async (files) => {
    let date = dayjs().format("YYYY-MM-DD");
    let newFiles = files.map(async (file) => {
      let uuid = getFileNameUUID();
      let name = file.name;
      let ext = name.substring(name.lastIndexOf("."));
      let prefix = name.substring(0, name.lastIndexOf("."));
      let path = `uploads/${date}/video/${prefix}_${uuid}${ext}`;
      const res = await breakPointMultipartUpload(path, file, {
        actualProgress,
      });
      if (res?.statusCode === 200) {
        const { data } = res;
        // 处理保存素材到attach表中
        let videoURL = data?.Location;
        let md5 = data?.ETag;
        let size = file?.size;
        let attachment = attach;
        if (videoURL !== undefined) {
          const rs = await attachVideoAdd({
            md5,
            name,
            path,
            ext,
            size,
            oss_url: videoURL,
          });
          if (rs?.code === 200) {
            const { data } = rs;
            let attachId = data?.attachId;
            attachment.push({ attachId: attachId, url: videoURL });
            file.attachId = attachId;
            setAttach(attachment);
          }
        }
        return file;
      }
    });
    setfiles(newFiles);
  };

  // 关闭弹窗
  const closeModal = () => {
    setFileQueuedList([]);
    setfiles([]);
    closeVideo();
  };

  // 确定全部上传完成
  const onSubmit = () => {
    if (fileQueuedList.length === 0) {
      closeModal();
      return false;
    }
    let filtered = fileQueuedList.filter((item) => {
      return item.status !== "UPLOADED";
    });
    if (filtered && filtered.length > 0) {
      message.error("还未完成上传，如果暂时不上传请按取消", 2);
      return false;
    }
    handleSure(attach);
    closeModal();
  };

  return (
    <>
      <Modal
        open={isShow}
        onCancel={closeModal}
        width={800}
        maskClosable={false}
        closable={false}
        onOk={onSubmit}
        destroyOnClose={true}
        title={
          <Space>
            <Upload {...uploadProps}>
              <Button type="primary" ghost>
                选择视频
              </Button>
            </Upload>
            <Button
              type="primary"
              disabled={uploaderDisable || fileQueuedList.length === 0}
              onClick={handleUpload}
            >
              开始上传
            </Button>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              (建议视频大小在150M以下,以便保证上传到头条的成功率)
            </Typography.Text>
          </Space>
        }
      >
        <Table
          dataSource={fileQueuedList}
          columns={columns}
          pagination={false}
        />
      </Modal>
    </>
  );
}
