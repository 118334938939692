import React from "react";
import { useRoutes } from "react-router-dom";
import { AliveScope } from "react-activation"; //KeepAlive路由缓存

import Admin from "../containers/Admin";
import Home from "../containers/Home";
import Login from "../containers/Login/login";
import Manager from "../containers/Home/Manager";
import Welcome from "../containers/Welcome";
import NewPlay from "../containers/Home/NewPlaylet";

import BidCalc from '@/containers/BidCalc';
import LeaveManage from '@/containers/HomePage/LeaveManage';

/* 消息列表 */
import Message from "../containers/Message";

/* 组织参数配置*/
import Sysconf from "../containers/Home/Sysconf";

/** 订单明细 */
import Order from "../containers/Order";
import OrderUser from "../containers/Order/User";

/** 素材管理 */
import VideoMaterial from "../containers/Material/Resources/Video";
import VideoMaterialOther from "../containers/Material/Resources/Video/Other";
import VideoAdd from "../containers/Material/Resources/Video/add";
import ImageMaterial from "../containers/Material/Resources/Image";
import ImageAdd from "../containers/Material/Resources/Image/add";
import Expend from "../containers/Material/Expend";
import TitleMaterial from "../containers/Material/Resources/Title";
import SyncPlan from "../containers/Material/SyncPlan";
import AddSyncPlan from "../containers/Material/SyncPlan/AddTask";
import DataDetail from "../containers/Material/Resources/Video/dataDetail";
import CreativeCost from "../containers/Material/Resources/Video/creativeDayCost";
import MaterialUpStatistics from "../containers/Material/UpStatistics";
import MaterialOpen from "../containers/Material/CustomOpen";
import AdvertiseMaterial from "../containers/Material/SyncPlan/Account";

/** 批量计划 */
import AutoStrategy from "../containers/Plan/AutoStrategy";
import Advertise from "../containers/Plan/Advertise";
import Transform from "../containers/Plan/Transform";
import TransformAdd from "../containers/Plan/Transform/add";
import TransformDetail from "../containers/Plan/Transform/Detail";
import Template from "../containers/Plan/Template";
import ThirdParty from "../containers/Plan/ThirdParty";
import AdPlan from "../containers/Plan/AdPlan";
import Verify from "../containers/Plan/Verify";
import AdvertiseNew from "../containers/Plan/AdvertiseNew";
import AdvertiseSelectOrCreate from "../containers/Plan/AdvertiseNew/selectAdd";
import AdvertiseNewAdd from "../containers/Plan/AdvertiseNew/add";
import AdvertiserNewDetail from "../containers/Plan/AdvertiseNew/Detail";
import Promotion from "../containers/Plan/Promotion";
import RechargePanel from "../containers/Plan/Promotion/Panel";
import PayTrend from "../containers/Plan/Promotion/Trend";
import Novel from "../containers/Plan/Book";
import Chapter from "../containers/Plan/Book/chapter";
import BidLimited from "../containers/Plan/Advertise/BidLimited";
import Simplify from "../containers/Plan/Simplify/add";
import Backhaul from "../containers/Plan/Backhaul";

import Opertion from "../containers/Operate";

/** 投放数据 */
import Toutiao from "../containers/Release/Toutiao";
import Search from "../containers/Release/Search";
import ReleaseAdvertise from "../containers/Release/Advertise";

/** 素材统计 */
import SucaiDailyCost from "../containers/SucaiCount/DailyCost";
import SucaiUserCost from "../containers/SucaiCount/UserCost";
import SucaiUserRealCost from "../containers/SucaiCount/UserRealCost";
import SucaiCompare from "../containers/SucaiCount/SucaiCompare";

/** 数据统计 */
import Recycle from "../containers/Statistics/Recycle";
import RecycelNovelIAA from "../containers/Statistics/Recycle/IAA"
import RecyclePlaylet from "../containers/Statistics/Recycle/Playlet";
import RecycleIAAPlaylet from "../containers/Statistics/Recycle/Playlet/Iaa";
import RecycleIAAGroup from "../containers/Statistics/RecycleIaaGroup"
import RecycleDetail from "../containers/Statistics/Recycle/Detail";
import RecycleTrend from "../containers/Statistics/Recycle/Trend";
import ToutiaoAccount from "../containers/Statistics/Toutiao";
import ToutiaoAgentAccount from "../containers/Statistics/ToutiaoAgent";
import ToutiaoPlan from "../containers/Statistics/Plan";
import DailyCost from "../containers/Statistics/DailyCost";
import RecycleGroup from "../containers/Statistics/RecycleGroup";
import RecycleGroupTrend from "../containers/Statistics/RecycleGroup/Tread";
import RecyclePlayletGroupTrend from "../containers/Statistics/RecycleGroup/TreadPlaylet";
import RecyclePlayletGroup from "../containers/Statistics/RecycleGroup/Playlet";
import BackRecord from "../containers/Statistics/Record";
import PlayletDailyCost from "../containers/Statistics/DailyCost/Playlet";
import PlayletYiTouDailyCost from "../containers/Statistics/DailyCost/YiTou";
import PlayletYiTouSummar from "../containers/Statistics/TheaterSummar";
import PlayletRecycleTrend from "../containers/Statistics/Recycle/Playlet/Trend";
import Dezhu from "../containers/Statistics/Duzhe";
import OverCharge from "../containers/Statistics/OverCharge";
import ReadRecord from "../containers/Statistics/ReadRecord";
import ThirdSummary from "../containers/Statistics/ThirdSummary";
import AdvertiserCompare from "../containers/Statistics/AdvertiserCompare";
import AdvertiserAutoRule from "../containers/Statistics/Toutiao/AuthRule";
import ProfitStatistics from "../containers/Statistics/Profit";
import ProfitDetail from "../containers/Statistics/Profit/IAP/Detail";
import ProfitGroup from "../containers/Statistics/ProfitGroup";
import PitcherPerformance from "../containers/Statistics/PitcherPerformance";
import TimerStatistics from "../containers/Statistics/Timer";
import DeliverItems from "../containers/Statistics/DeliverItems";
import AccountToutiaoAds from "../containers/Statistics/AccountAds";
import DailyPlayletBoard from "../containers/Statistics/DailyBoard";
import IAABoard from "../containers/Statistics/IAABoard";
import ECPMList from "../containers/Statistics/ECPM";
import NewECPMList from "../containers/Statistics/NewECPM";
import ListRank from "../containers/Statistics/ListRank";
import IPUUserRate from "../containers/Statistics/IPURate";
import DailyPlayletAd from '../containers/Statistics/DailyPlayletAd'
import NovelIPURate from '../containers/Statistics/NovelArpu'
import NovelECPMList from '../containers/Statistics/ECPMNovel'

/** 权限管理 */
import Organization from "../containers/permission/organization";
import Member from "../containers/permission/member";
import Role from "../containers/permission/role";
import Menu from "../containers/permission/menu";
import Authorization from "../containers/permission/role/authorization";

/** 影潭短剧 */
import PromotionYt from "../containers/Playlet/Yingtan/promotion";
import OrderYt from "../containers/Playlet/Yingtan/order";
import RecycleYt from "../containers/Playlet/Yingtan/recycle";
import ResultYt from "../containers/Playlet/Yingtan/result";

/** 羚羊短剧 */
import PromotionLy from "../containers/Playlet/Lingyang/promotion";
import OrderLy from "../containers/Playlet/Lingyang/order";
import RecycleLy from "../containers/Playlet/Lingyang/recycle";
import ResultLy from "../containers/Playlet/Lingyang/result";

/** 花生短剧 */
import PromotionHs from "../containers/Playlet/Huasheng/promotion";
import OrderHs from "../containers/Playlet/Huasheng/order";
import RecycleHs from "../containers/Playlet/Huasheng/recycle";
import ResultHs from "../containers/Playlet/Huasheng/result";

/** 触摸短剧 */
import PromotionCm from "../containers/Playlet/Chumo/promotion";
import OrderCm from "../containers/Playlet/Chumo/order";
import RecycleCm from "../containers/Playlet/Chumo/recycle";
import ResultCm from "../containers/Playlet/Chumo/result";

/** 点众短剧 */
import PromotionDz from "../containers/Playlet/Dianzhong/promotion";
import OrderDz from "../containers/Playlet/Dianzhong/order";
import RecycleDz from "../containers/Playlet/Dianzhong/recycle";
import ResultDz from "../containers/Playlet/Dianzhong/result";

/** 友和短剧 */
import PromotionYh from "../containers/Playlet/Youhe/promotion";
import OrderYh from "../containers/Playlet/Youhe/order";
import RecycleYh from "../containers/Playlet/Youhe/recycle";
import ResultYh from "../containers/Playlet/Youhe/result";

/** 山海短剧 */
import PromotionSh from "../containers/Playlet/Shanhai/promotion";
import OrderSh from "../containers/Playlet/Shanhai/order";
import RecycleSh from "../containers/Playlet/Shanhai/recycle";
import ResultSh from "../containers/Playlet/Shanhai/result";

/** 掌阅短剧 */
import PromotionZy from "../containers/Playlet/Zhangyue/promotion";
import OrderZy from "../containers/Playlet/Zhangyue/order";
import RecycleZy from "../containers/Playlet/Zhangyue/recycle";
import ResultZy from "../containers/Playlet/Zhangyue/result";

/** 天桥短剧 */
import PromotionTq from "../containers/Playlet/Tianqiao/promotion";
import OrderTq from "../containers/Playlet/Tianqiao/order";
import RecycleTq from "../containers/Playlet/Tianqiao/recycle";
import ResultTq from "../containers/Playlet/Tianqiao/result";

/** 番茄短剧 */
import PromotionFq from "../containers/Playlet/FanQie/promotion";
import OrderFq from "../containers/Playlet/FanQie/order";
import RecycleFq from "../containers/Playlet/FanQie/recycle";
import ResultFq from "../containers/Playlet/FanQie/result";
import ResultFqIaa from "../containers/Playlet/FanQie/resultIaa"

/** 节奏短剧 */
import PromotionJz from "../containers/Playlet/JieZou/promotion";
import OrderJz from "../containers/Playlet/JieZou/order";
import RecycleJz from "../containers/Playlet/JieZou/recycle";
import ResultJz from "../containers/Playlet/JieZou/result";

/** 咪咕短剧 */
import PromotionMg from "../containers/Playlet/MiGu/promotion";
import OrderMg from "../containers/Playlet/MiGu/order";
import RecycleMg from "../containers/Playlet/MiGu/recycle";
import ResultMg from "../containers/Playlet/MiGu/result";

/** 掌中云短剧 */
import PromotionZzy from "../containers/Playlet/Zhangzhongyun/promotion";
import OrderZzy from "../containers/Playlet/Zhangzhongyun/order";
import RecycleZzy from "../containers/Playlet/Zhangzhongyun/recycle";
import ResultZzy from "../containers/Playlet/Zhangzhongyun/result";

/** 漫深短剧 */
import PromotionMs from "../containers/Playlet/Mansen/promotion";
import OrderMs from "../containers/Playlet/Mansen/order";
import RecycleMs from "../containers/Playlet/Mansen/recycle";
import ResultMs from "../containers/Playlet/Mansen/result";

import ConfigRule from "../containers/Playlet/Rule";

/** 新剧 */
import PromotionNp from "../containers/Playlet/NewPlay/promotion";

/** 自研短剧 */
import AccountBinding from "../containers/Playlet/Platform/accountBinding";
import PromotionPm from "../containers/Playlet/Platform/promotion";
import OrderPm from "../containers/Playlet/Platform/order";
import RecyclePm from "../containers/Playlet/Platform/recycle";
import ResultPm from "../containers/Playlet/Platform/result";

/** 天鹅小说 */
import PromotionTe from "../containers/ThirdNovel/Swan/promotion";
import OrderTe from "../containers/ThirdNovel/Swan/order";
import RecycleTe from "../containers/ThirdNovel/Swan/recycle";
import ResultTe from "../containers/ThirdNovel/Swan/result";

/** 晴空小说 */
import PromotionQk from "../containers/ThirdNovel/QingKong/promotion";
import OrderQk from "../containers/ThirdNovel/QingKong/order";
import RecycleQk from "../containers/ThirdNovel/QingKong/recycle";
import ResultQk from "../containers/ThirdNovel/QingKong/result";

/** 常读小说 */
import PromotionCd from "../containers/ThirdNovel/ChangDu/promotion";
import OrderCd from "../containers/ThirdNovel/ChangDu/order";
import RecycleCd from "../containers/ThirdNovel/ChangDu/recycle";
import ResultCd from "../containers/ThirdNovel/ChangDu/result";

/** 进步小说 */
import PromotionPg from "../containers/ThirdNovel/Progress/promotion";
import OrderPg from "../containers/ThirdNovel/Progress/order";
import RecyclePg from "../containers/ThirdNovel/Progress/recycle";
import ResultPg from "../containers/ThirdNovel/Progress/result";

/** 最看小说 */
import PromotionZk from "../containers/ThirdNovel/ZuiKan/promotion";
import OrderZk from "../containers/ThirdNovel/ZuiKan/order";
import RecycleZk from "../containers/ThirdNovel/ZuiKan/recycle";
import ResultZk from "../containers/ThirdNovel/ZuiKan/result";

/** 贝壳小说 */
import PromotionBk from "../containers/ThirdNovel/Conch/promotion";
import OrderBk from "../containers/ThirdNovel/Conch/order";
import RecycleBk from "../containers/ThirdNovel/Conch/recycle";
import ResultBk from "../containers/ThirdNovel/Conch/result";

/** 网易IAA小说 */
import PromotionNS from "../containers/ThirdNovel/NetEasy/promotion";
import OrderNS from "../containers/ThirdNovel/NetEasy/order";
import RecycleNS from "../containers/ThirdNovel/NetEasy/recycle";
import ResultNS from "../containers/ThirdNovel/NetEasy/result";

/** 黑岩小说 */
import PromotionBR from "../containers/ThirdNovel/BlackRock/promotion";
import OrderBR from "../containers/ThirdNovel/BlackRock/order";
import RecycleBR from "../containers/ThirdNovel/BlackRock/recycle";
import ResultBR from "../containers/ThirdNovel/BlackRock/result";

/** 自研小说 */
import NovelPromotionPm from "../containers/ThirdNovel/Platform/promotion";
import NovelOrderPm from "../containers/ThirdNovel/Platform/order";
import NovelRecyclePm from "../containers/ThirdNovel/Platform/recycle";
// import NovelPayQuPm from '../containers/ThirdNovel/Platform/'
import NovelResultPm from "../containers/ThirdNovel/Platform/result";

import IaaVBackpass from "../containers/ThirdNovel/IaaVBackpass";

/** 投手自我核查**/
import SelfCheck from "../containers/SelfCheck";

/** 资产 */
import Applets from "../containers/Asset/Applets"
import Event from "../containers/Asset/Event"
import ProductLibrary from "../containers/Asset/ProductLibrary"
import AccountGenerate from "../containers/Asset/AccountGenerate"
import Landing from "../containers/Asset/Landing"
import Task from "../containers/Asset/Task"
import AutoPut from '../containers/Asset/AutoPut';
import AutoPutTable from '@/containers/Asset/AutoPutTable';

export default function Index() {
  const routers = useRoutes([
    { path: "/", element: <Login /> },
    { path: "login", element: <Login /> },
    {
      path: "admin",
      element: (
        <AliveScope>
          <Admin />
        </AliveScope>
      ),
      children: [
        /** 小说路由 */
        {
          path: "index/gailan",
          element: (
            <AliveScope>
              <Home />
            </AliveScope>
          ),
        },
        {
          path: "statistics/items/user",
          element: (
            <AliveScope>
              <OrderUser />
            </AliveScope>
          ),
        },
        { path: "index/welcome", element: <Welcome /> },
        { path: "index/manager/:id", element: <Manager /> },
        {
          path: "index/sysconf",
          element: <Sysconf />,
        } /* 代理商组织参数配置 */,
        {
          path: "index/notification",
          element: <Message />,
        } /* 代理商组织参数配置 */,
        { path: "index/new_playlet", element: <NewPlay /> } /* 新剧投放配置 */,
        { path: "material/video", element: <VideoMaterial /> },
        { path: "material/video/public", element: <VideoMaterialOther /> },
        { path: "material/video/add_video", element: <VideoAdd /> },
        { path: "material/image/add_img", element: <ImageAdd /> },
        { path: "material/video/video_data_detail", element: <DataDetail /> },
        {
          path: "material/video/video_creative_cost",
          element: <CreativeCost />,
        },
        { path: "material/image/img_data_detail", element: <DataDetail /> },
        { path: "material/image/img_creative_cost", element: <CreativeCost /> },
        { path: "material/image", element: <ImageMaterial /> },
        { path: "material/title", element: <TitleMaterial /> },
        { path: "material/expend", element: <Expend /> },
        { path: "material/async", element: <SyncPlan /> },
        { path: "material/async/add_async", element: <AddSyncPlan /> },
        { path: "material/advertise_material", element: <AdvertiseMaterial /> },
        { path: "material/up_statistics", element: <MaterialUpStatistics /> },
        { path: "material/open", element: <MaterialOpen /> },
          /* 自动化策略 */
        { path: "batch_plan/auto_policy/auto_strategy", element: <AutoStrategy /> },
        { path: "batch_plan/advertiser_list", element: <Advertise /> },
        { path: "batch_plan/transform", element: <Transform /> },
        { path: "batch_plan/transform/add_trans", element: <TransformAdd /> },
        { path: "batch_plan/transform/detail", element: <TransformDetail /> },
        { path: "batch_plan/template", element: <Template /> },
        { path: "batch_plan/othersbook", element: <ThirdParty /> },
        { path: "batch_plan/ad_plan", element: <AdPlan /> },
        { path: "batch_plan/creativeaudit", element: <Verify /> },
        { path: "batch_plan/backhaul_management", element: <Backhaul /> },
        { path: "batch_plan/advertise_new", element: <AdvertiseNew /> },
        { path: "batch_plan/advertise_new/add", element: <AdvertiseNewAdd /> },
        { path: "batch_plan/simplify_add", element: <Simplify /> },
        {
          path: "batch_plan/advertise_new/select_add",
          element: <AdvertiseSelectOrCreate />,
        },
        {
          path: "batch_plan/advertise_new/detail",
          element: <AdvertiserNewDetail />,
        },
        { path: "batch_plan/promotion", element: <Promotion /> },
        { path: "batch_plan/promotion/panel", element: <RechargePanel /> },
        { path: "batch_plan/promotion/pay_trend", element: <PayTrend /> },
        { path: "batch_plan/novel_list", element: <Novel /> },
        { path: "batch_plan/novel_list/chapter", element: <Chapter /> },
        { path: "release/toutiao_list", element: <Toutiao /> },
        { path: "release/search", element: <Search /> },
        { path: "release/adp_advertise", element: <ReleaseAdvertise /> },
        {
          path: "statistics/novel/sucaishou_count/sucai_daily_cost",
          element: <SucaiDailyCost />,
        },
        {
          path: "statistics/theater/sucaishou_count_plt/sucai_daily_cost_plt",
          element: <SucaiDailyCost />,
        },
        {
          path: "statistics/novel/sucaishou_count/user_cost",
          element: <SucaiUserCost />,
        },
        {
          path: "statistics/theater/sucaishou_count_plt/user_cost_plt",
          element: <SucaiUserCost />,
        },
        {
          path: "statistics/theater/sucaishou_count_plt/user_cost_real_plt",
          element: <SucaiUserRealCost />,
        },
        {
          path: "statistics/theater/sucaishou_count_plt/material_compare",
          element: <SucaiCompare />,
        },
        { path: "statistics/novel/items", element: <Order /> },
        { path: "statistics/novel/push/recycle", element: <Recycle /> },
        { path: "statistics/novel/push/novel_iaa_recycle", element: <RecycelNovelIAA /> },
        {
          path: "statistics/theater/push_plt/recycle_plt",
          element: <RecyclePlaylet />,
        },
        {
          path: "statistics/theater/push_plt/recycle_iaa_plt",
          element: <RecycleIAAPlaylet />,
        },
        {
          path: "statistics/theater/push_plt/iaa_group_recycle",
          element: <RecycleIAAGroup />,
        },
        { path: "statistics/novel/recycle/detail", element: <RecycleDetail /> },
        { path: "statistics/novel/recycle/trend", element: <RecycleTrend /> },
        { path: "statistics/novel/push/toutiao", element: <ToutiaoAccount /> },
        {
          path: "statistics/theater/push_plt/toutiao_plt",
          element: <ToutiaoAccount />,
        },
        {
          path: "statistics/theater/push_plt/toutiao_agtplt",
          element: <ToutiaoAgentAccount />,
        },
        { path: "statistics/toutiao/toutiao_plan", element: <ToutiaoPlan /> },
        {
          path: "statistics/theater/push_plt/toutiao_plt/auto_rule",
          element: <AdvertiserAutoRule />,
        },
        { path: "statistics/novel/push/daily_cost", element: <DailyCost /> },
        {
          path: "statistics/theater/push_plt/daily_cost_plt",
          element: <PlayletDailyCost />,
        },
        {
          path: "statistics/theater/push_plt/yitou_cost_plt",
          element: <PlayletYiTouDailyCost />,
        },
        {
          path: "statistics/theater/push_plt/yitou_summar",
          element: <PlayletYiTouSummar />,
        },
        {
          path: "statistics/push/daily_playlet_cost",
          element: <PlayletDailyCost />,
        },
        {
          path: "statistics/novel/push/recycle_group",
          element: <RecycleGroup />,
        },
        {
          path: "statistics/theater/push_plt/recycle_group_plt",
          element: <RecyclePlayletGroup />,
        },
        {
          path: "statistics/recycle_group/group_trend",
          element: <RecycleGroupTrend />,
        },
        {
          path: "statistics/theater/recycle_group/group_trend",
          element: <RecyclePlayletGroupTrend />,
        },
        {
          path: "statistics/novel/back_record_toutiao",
          element: <BackRecord />,
        },
        { path: "statistics/novel/over_charge", element: <OverCharge /> },
        { path: "statistics/novel/read_record", element: <ReadRecord /> },
        {
          path: "statistics/theater/push_plt/recycle_plt/playlet_trend",
          element: <PlayletRecycleTrend />,
        },
        {
          path: "statistics/novel/push/third_summary",
          element: <ThirdSummary />,
        },
        {
          path: "statistics/theater/push_plt/compare",
          element: <AdvertiserCompare />,
        },
        {
          path: "statistics/theater/push_plt/account_toutiao_ads",
          element: <AccountToutiaoAds />,
        },
        {
          path: "statistics/theater/push_plt/daily_playlet_board",
          element: <DailyPlayletBoard />,
        },
        { path: "permission/organization", element: <Organization /> },
        { path: "permission/member", element: <Member /> },
        { path: "permission/role", element: <Role /> },
        { path: "permission/menu", element: <Menu /> },
        { path: "permission/role/user", element: <Authorization /> },
        { path: "batch_plan/duzhe", element: <Dezhu /> },
        { path: "statistics/theater/operate", element: <Opertion /> },
        {
          path: "statistics/theater/push_plt/profit_statistics",
          element: <ProfitStatistics />,
        },
        {
          path: "statistics/theater/push_plt/profit_statistics/detail",
          element: <ProfitDetail />,
        },
        {
          path: "statistics/theater/push_plt/group_profit_statistics",
          element: <ProfitGroup />,
        },
        // 投手绩效排行统计
        {
          path: "statistics/theater/push_plt/pitcher_performance_statistics",
          element: <PitcherPerformance />,
        },
        {
          path: "statistics/theater/push_plt/timer_statistics",
          element: <TimerStatistics />,
        },
        {
          path: "statistics/theater/push_plt/deliver_items",
          element: <DeliverItems />,
        },
        {
          path: "batch_plan/advertiser_list/bid_limit_set",
          element: <BidLimited />,
        },
        { path: "statistics/theater/push_plt/iaa_list", element: <IAABoard /> },
        {
          path: "statistics/theater/push_plt/ecpm_list",
          element: <ECPMList />,
          // element: <NewECPMList />,
        },
        {
          path: "statistics/theater/push_plt/new_ecpm_list",
          // element: <ECPMList />,
          element: <NewECPMList />,
        },
        {
          path: "statistics/theater/push_plt/list_rank",
          element: <ListRank />,
        },
        {
          path: "statistics/theater/push_plt/ipu_user_rate",
          element: <IPUUserRate />,
        },
        {
          path: "statistics/novel/push/arpu_ipu_rate",
          element: <NovelIPURate />,
        },
        {
          path: "statistics/novel/push/ecpm_list",
          element: <NovelECPMList />,
        },
        {
          path: "statistics/theater/push_plt/daily_playlet_ad",
          element: <DailyPlayletAd />,
        },

        /** 短剧路由 */
        { path: "playlet/yingtan/promotion_yt", element: <PromotionYt /> },
        { path: "playlet/yingtan/order_yt", element: <OrderYt /> },
        { path: "playlet/yingtan/pay_qu_yt", element: <RecycleYt /> },
        { path: "playlet/yingtan/back_result_yt", element: <ResultYt /> },

        { path: "playlet/lingyang/promotion_ly", element: <PromotionLy /> },
        { path: "playlet/lingyang/order_ly", element: <OrderLy /> },
        { path: "playlet/lingyang/pay_qu_ly", element: <RecycleLy /> },
        { path: "playlet/lingyang/back_result_ly", element: <ResultLy /> },

        { path: "playlet/huasheng/promotion_hs", element: <PromotionHs /> },
        { path: "playlet/huasheng/order_hs", element: <OrderHs /> },
        { path: "playlet/huasheng/pay_qu_hs", element: <RecycleHs /> },
        { path: "playlet/huasheng/back_result_hs", element: <ResultHs /> },

        { path: "playlet/chumo/promotion_cm", element: <PromotionCm /> },
        { path: "playlet/chumo/order_cm", element: <OrderCm /> },
        { path: "playlet/chumo/pay_qu_cm", element: <RecycleCm /> },
        { path: "playlet/chumo/back_result_cm", element: <ResultCm /> },

        { path: "playlet/dianzhong/promotion_dz", element: <PromotionDz /> },
        { path: "playlet/dianzhong/order_dz", element: <OrderDz /> },
        { path: "playlet/dianzhong/pay_qu_dz", element: <RecycleDz /> },
        { path: "playlet/dianzhong/back_result_dz", element: <ResultDz /> },

        { path: "playlet/youhe/promotion_yh", element: <PromotionYh /> },
        { path: "playlet/youhe/order_yh", element: <OrderYh /> },
        { path: "playlet/youhe/pay_qu_yh", element: <RecycleYh /> },
        { path: "playlet/youhe/back_result_yh", element: <ResultYh /> },

        { path: "playlet/shanhai/promotion_sh", element: <PromotionSh /> },
        { path: "playlet/shanhai/order_sh", element: <OrderSh /> },
        { path: "playlet/shanhai/pay_qu_sh", element: <RecycleSh /> },
        { path: "playlet/shanhai/back_result_sh", element: <ResultSh /> },

        { path: "playlet/zhangyue/promotion_zy", element: <PromotionZy /> },
        { path: "playlet/zhangyue/order_zy", element: <OrderZy /> },
        { path: "playlet/zhangyue/pay_qu_zy", element: <RecycleZy /> },
        { path: "playlet/zhangyue/back_result_zy", element: <ResultZy /> },

        { path: "playlet/tianqiao/promotion_tq", element: <PromotionTq /> },
        { path: "playlet/tianqiao/order_tq", element: <OrderTq /> },
        { path: "playlet/tianqiao/pay_qu_tq", element: <RecycleTq /> },
        { path: "playlet/tianqiao/back_result_tq", element: <ResultTq /> },

        { path: "playlet/fanqie/promotion_fq", element: <PromotionFq /> },
        { path: "playlet/fanqie/order_fq", element: <OrderFq /> },
        { path: "playlet/fanqie/pay_qu_fq", element: <RecycleFq /> },
        { path: "playlet/fanqie/back_result_fq", element: <ResultFq /> },
        { path: "playlet/fanqie/back_result_fq_iaa", element: <ResultFqIaa /> },

        { path: "playlet/jiezou/promotion_jz", element: <PromotionJz /> },
        { path: "playlet/jiezou/order_jz", element: <OrderJz /> },
        { path: "playlet/jiezou/pay_qu_jz", element: <RecycleJz /> },
        { path: "playlet/jiezou/back_result_jz", element: <ResultJz /> },

        { path: "playlet/migu/promotion_mg", element: <PromotionMg /> },
        { path: "playlet/migu/order_mg", element: <OrderMg /> },
        { path: "playlet/migu/pay_qu_mg", element: <RecycleMg /> },
        { path: "playlet/migu/back_result_mg", element: <ResultMg /> },

        {
          path: "playlet/zhangzhongyun/promotion_zzy",
          element: <PromotionZzy />,
        },
        { path: "playlet/zhangzhongyun/order_zzy", element: <OrderZzy /> },
        { path: "playlet/zhangzhongyun/pay_qu_zzy", element: <RecycleZzy /> },
        {
          path: "playlet/zhangzhongyun/back_result_zzy",
          element: <ResultZzy />,
        },

        { path: "playlet/mansen/promotion_ms", element: <PromotionMs /> },
        { path: "playlet/mansen/order_ms", element: <OrderMs /> },
        { path: "playlet/mansen/pay_qu_ms", element: <RecycleMs /> },
        { path: "playlet/mansen/back_result_ms", element: <ResultMs /> },

        { path: "playlet/new_play/promotion_np", element: <PromotionNp /> },

        { path: "playlet/platform/promotion_pm", element: <PromotionPm /> },
        {
          path: "playlet/platform/account_binding",
          element: <AccountBinding />,
        },
        {
          path: "third_book/platform_novel/novel_account_binding",
          element: <AccountBinding />,
        },
        { path: "playlet/platform/order_pm", element: <OrderPm /> },
        { path: "playlet/platform/pay_qu_pm", element: <RecyclePm /> },
        { path: "playlet/platform/back_result_pm", element: <ResultPm /> },

        { path: "playlet/configuration_rule", element: <ConfigRule /> },

        // 第三方小说路由
        { path: "third_book/swan/promotion_ths", element: <PromotionTe /> },
        { path: "third_book/swan/order_ths", element: <OrderTe /> },
        { path: "third_book/swan/pay_qu_ths", element: <RecycleTe /> },
        { path: "third_book/swan/back_result_ths", element: <ResultTe /> },

        {
          path: "third_book/qing_kong/promotion_thk",
          element: <PromotionQk />,
        },
        { path: "third_book/qing_kong/order_thk", element: <OrderQk /> },
        { path: "third_book/qing_kong/pay_qu_thk", element: <RecycleQk /> },
        { path: "third_book/qing_kong/back_result_thk", element: <ResultQk /> },

        { path: "third_book/chang_du/promotion_thc", element: <PromotionCd /> },
        { path: "third_book/chang_du/order_thc", element: <OrderCd /> },
        { path: "third_book/chang_du/pay_qu_thc", element: <RecycleCd /> },
        { path: "third_book/chang_du/back_result_thc", element: <ResultCd /> },

        { path: "third_book/progress/promotion_thp", element: <PromotionPg /> },
        { path: "third_book/progress/order_thp", element: <OrderPg /> },
        { path: "third_book/progress/pay_qu_thp", element: <RecyclePg /> },
        { path: "third_book/progress/back_result_thp", element: <ResultPg /> },

        { path: "third_book/zui_kan/promotion_thz", element: <PromotionZk /> },
        { path: "third_book/zui_kan/order_thz", element: <OrderZk /> },
        { path: "third_book/zui_kan/pay_qu_thz", element: <RecycleZk /> },
        { path: "third_book/zui_kan/back_result_thz", element: <ResultZk /> },

        { path: "third_book/conch/promotion_thb", element: <PromotionBk /> },
        { path: "third_book/conch/order_thb", element: <OrderBk /> },
        { path: "third_book/conch/pay_qu_thb", element: <RecycleBk /> },
        { path: "third_book/conch/back_result_thb", element: <ResultBk /> },

        { path: "third_book/net_easy/promotion_ns", element: <PromotionNS /> },
        { path: "third_book/net_easy/order_ns", element: <OrderNS /> },
        { path: "third_book/net_easy/pay_qu_ns", element: <RecycleNS /> },
        { path: "third_book/net_easy/back_result_ns", element: <ResultNS /> },

        { path: "third_book/black_rock/promotion_br", element: <PromotionBR /> },
        { path: "third_book/black_rock/order_br", element: <OrderBR /> },
        { path: "third_book/black_rock/pay_qu_br", element: <RecycleBR /> },
        { path: "third_book/black_rock/back_result_br", element: <ResultBR /> },

        {
          path: "third_book/platform_novel/promotion_pm",
          element: <NovelPromotionPm />,
        },
        {
          path: "third_book/platform_novel/order_pm",
          element: <NovelOrderPm />,
        },
        {
          path: "third_book/platform_novel/pay_qu_pm",
          element: <NovelRecyclePm />,
        },
        {
          path: "third_book/platform_novel/back_result_pm",
          element: <NovelResultPm />,
        },

        // 三方小说iaa虚拟回传
        {
          path: "third_book/iaa_VBackpass",
          element: <IaaVBackpass />,
        },

        /* 数据自查 */
        { path: "index/self_check", element: <SelfCheck /> },

        /** 资产 */
        {path: "asset/applets", element: <Applets />},
        {path: "asset/product_library", element: <ProductLibrary />},
        {path: "asset/event", element: <Event />},
        {path: "asset/account_generate", element: <AccountGenerate />},
        {path: "asset/landing", element: <Landing />},
        {path: "asset/task", element: <Task />},

        {path: "batch_plan/auto_policy/auto_put", element: <AutoPut />},
        {path: "batch_plan/auto_policy/auto_put_table", element: <AutoPutTable />},

        /** 出价计算 */
        { path: "index/bid_calc", element: <BidCalc /> },
        { path: "index/leave_manage", element: <LeaveManage /> },
      ],
    },
  ]);

  return routers;
}
