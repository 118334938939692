import React from "react";
import { Card, Form, Button, Space, Select, Table } from "antd";

import styles from "./index.module.less";
import { useTable, useOptions, useColumns, useLeave } from "./hooks";
import { LeaveModal } from "./components";

const LeaveManage = () => {
  // 表单
  const [searchForm] = Form.useForm();

  const {
    tableList,
    tableLoading,
    searchLoading,
    pagination,
    onSearch,
    onRefresh,
    onReset,
  } = useTable({ searchForm });

  const { userList } = useOptions({ searchForm });

  const {
    visible,
    isEdit,
    leaveModalForm,
    leaveModalConfirmLoading,
    onEditLeave,
    onAddLeave,
    onDeleteLeave,
    onCloseLeave,
    onConfirmLeave,
  } = useLeave({ updateList: onRefresh });

  const { columns } = useColumns({
    onEditLeave,
    onDeleteLeave,
    userList,
  });

  // 操作栏
  const actions = (
    <Space>
      <Button type="primary" onClick={onAddLeave}>
        新增请假
      </Button>
    </Space>
  );

  return (
    <div className={styles.wrapper}>
      <Card className={styles.search}>
        <Form form={searchForm} layout="inline">
          <Form.Item label="用户选择" name="adminId">
            <Select
              allowClear
              showSearch
              options={userList}
              placeholder="请选择用户"
              style={{ width: 150 }}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" loading={searchLoading} onClick={onSearch}>
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Card title={actions} className={styles.table}>
        <Table
          dataSource={tableList}
          loading={tableLoading}
          columns={columns}
          rowKey={record => record.id}
          pagination={pagination}
        />
      </Card>

      {/* 增加和修改请假的弹窗 */}
      <LeaveModal
        visible={visible}
        confirmLoading={leaveModalConfirmLoading}
        onOk={onConfirmLeave}
        onCancel={onCloseLeave}
        form={leaveModalForm}
        isEdit={isEdit}
        options={{ userList }}
      />
    </div>
  );
};

export default React.memo(LeaveManage);
