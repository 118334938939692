import { Button, Space } from "antd";

const columns = ({ handleReUp, onDetail }) => [
  {
    title: "id",
    width: 120,
    align: "center",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "广告 id",
    width: 120,
    align: "center",
    dataIndex: "adId",
    key: "adId",
  },
  {
    title: "广告名称",
    width: 120,
    align: "center",
    dataIndex: "adName",
    key: "adName",
  },
  {
    title: "原转化数",
    width: 120,
    align: "center",
    dataIndex: "convertNum",
    key: "convertNum",
  },
  {
    title: "创建时间",
    width: 120,
    align: "center",
    dataIndex: "creatTime",
    key: "creatTime",
  },
  {
    title: "回传数量",
    width: 120,
    align: "center",
    dataIndex: "num",
    key: "num",
  },
  {
    title: "已回传数量",
    width: 120,
    align: "center",
    dataIndex: "returnNum",
    key: "returnNum",
  },
  {
    title: "操作",
    width: 140,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    render: (_, record) => {
      return (
        <Space>
          {/*<Button*/}
          {/*  color="primary"*/}
          {/*  variant="text"*/}
          {/*  size="small"*/}
          {/*  onClick={() => handleReUp(record)}*/}
          {/*>*/}
          {/*  重新回传*/}
          {/*</Button>*/}
          <Button
            type="link"
            size="small"
            onClick={() => onDetail(record)}
          >
            回传详情
          </Button>
        </Space>
      )
    },
  },
];

export default columns;
