import { Space, Typography } from "antd";
import { Link } from "react-router-dom";

const columns = () => [
  {
    title: "日期",
    width: 80,
    align: "center",
    dataIndex: "day",
    key: "day",
    fixed: "left",
  },
  {
    title: "推广链接",
    width: 190,
    align: "center",
    dataIndex: "promotion",
    key: "promotion",
    render: (_, record) => {
      return (
        <Space direction="vertical">
          <Typography.Text>
            名称：{record?.promotionTitle}
          </Typography.Text>
          <Typography.Text>
            <Link to={`/admin/statistics/theater/push_plt/toutiao_plt`}  onClick={() => {localStorage.setItem('system_from_ecpm_ID', record.promotionId);}}>外链ID: {record?.promotionId}</Link>
          </Typography.Text>
        </Space>
      );
    },
  },
  {
    title: "投手",
    width: 60,
    align: "center",
    dataIndex: "adminName",
    key: "adminName",
  },
  {
    title: "短剧名称",
    width: 120,
    align: "center",
    dataIndex: "bookName",
    key: "bookName",
  },
  {
    title: "剧场名称",
    width: 80,
    align: "center",
    dataIndex: "appName",
    key: "appName",
  },
  {
    title: "消耗",
    width: 60,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    sorter: {},
    render: (value) => (
      value || 0
    )
  },
  {
    title: "ECPM当日收益",
    width: 80,
    align: "center",
    dataIndex: "today_ad_cash",
    key: "today_ad_Cash",
    sorter: {},
    render: (_, record) => (
      record?.todayAdCash || 0
    )
  },
  {
    title: "ECPM总收益",
    width: 80,
    align: "center",
    dataIndex: "total_ad_cash",
    key: "total_ad_Cash",
    sorter: {},
    render: (_, record) => (
      record?.totalAdCash || 0
    )
  },
  {
    title: "新增回收率",
    width: 60,
    align: "center",
    dataIndex: "new_recovery_rate",
    key: "new_recovery_rate",
    sorter: {},
    render: (_,record) => (
      record?.newRecoveryRate === 0 ? '0.00%' : record?.newRecoveryRate + '%'
    )
  },
  {
    title: "总回收率",
    width: 60,
    align: "center",
    dataIndex: "total_recovery_rate",
    key: "total_recovery_rate",
    sorter: {},
    render: (_,record) => (
      record?.totalRecoveryRate === 0 ? '0.00%' : record?.totalRecoveryRate + '%'
    )
  },
  {
    title: "当天新建广告数",
    width: 80,
    align: "center",
    dataIndex: "ad_Count",
    key: "ad_Count",
    sorter: {},
    render: (_, record) => (record.adCount || 0)
  },
  {
    title: "当天新建广告数(有消耗)",
    width: 100,
    align: "center",
    dataIndex: "ad_New_Active_Count",
    key: "ad_New_Active_Count",
    sorter: {},
    render: (_, record) => (record.adNewActiveCount || 0)
  },
  {
    title: "在消耗广告数",
    width: 60,
    align: "center",
    dataIndex: "active_Ad_Count",
    key: "active_Ad_Count",
    sorter: {},
    render: (_, record) => (record.activeAdCount || 0)
  },
  {
    title: "当天新建账户数(含老剧)",
    width: 100,
    align: "center",
    dataIndex: "account_Count",
    key: "account_Count",
    sorter: {},
    render: (_, record) => (record.accountCount || 0)
  },
  {
    title: "在消耗账户数",
    width: 60,
    align: "center",
    dataIndex: "active_Account_Count",
    key: "active_Account_Count",
    sorter: {},
    render: (_, record) => (record.activeAccountCount || 0)
  },
];

export default columns;
