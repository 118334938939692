const columns = () => [
    {
        title: "ID",
        width: 90,
        align: "center",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "广告主",
        width: 150,
        align: "center",
        dataIndex: "advertiser_id_info",
        key: "advertiser_id_info",
        ellipsis: true,
        render: (_, record) => {
            return <>
                <div>{record?.advertiser?.account_name}</div>
                <div>({record?.advertiser_id})</div>
            </>
        }
    },
    {
        title: "预算金额",
        width: 100,
        align: "center",
        dataIndex: "budget",
        key: "budget",
    },
    {
        title: "出价",
        width: 100,
        align: "center",
        dataIndex: "cpa_bid",
        key: "cpa_bid",
    },
    {
        title: "广告状况",
        width: 100,
        align: "center",
        dataIndex: "exec_status",
        key: "exec_status",
        render: value => (
            value === 0 ? '等待脚本执行' : (
                value === 1 ? '脚本执行中' : (
                    value === 2 ? '执行成功' : (
                        value === 3 ? '执行失败' : ''
                    )
                )
            )
        )
    },
    {
        title: "项目执行结果",
        width: 100,
        align: "center",
        dataIndex: "campaign_status",
        key: "campaign_status",
        render: (_,record) => {
            let info = record?.project_ad?.result
            if (info) {
                info = JSON.parse(info)
                return `code: ${info?.code}, message: ${info?.message}`
            }
            return ''
        }
    },
    {
        title: "广告执行结果",
        width: 100,
        align: "center",
        dataIndex: "status_result",
        key: "status_result",
        render: (_, record) => {
            let info = record?.result
            if (info) {
                info = JSON.parse(info)
                return `code: ${info?.code}, message: ${info?.message}`
            }
            return ''
        }
    },
    {
        title: "素材ID",
        width: 120,
        align: "center",
        dataIndex: "material_id",
        key: "material_id",
        render: (_,record) => {
            return <>
                <div>图片: {record?.image_ids}</div>
                <div>视频: {record?.video_ids}</div>
            </>
        }
    },

]

export default columns