import { Menu } from 'antd'
import React, { useEffect, useImperativeHandle } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { createFromIconfontCN } from '@ant-design/icons';
import './left_nav.less'
import { connect } from 'react-redux';
import { saveMenu } from '../../../redux/actions/menu';
import { saveMessage } from '../../../redux/actions/message';
import { useState } from 'react';
import useWebSocket from '../../../hooks/useWebSocket';

function LeftNav(props) {

  const [curSelect,setCurSelect]=useState([window.location.href.slice(window.location.href.lastIndexOf('/')+1)])

  useImperativeHandle(props.leftNavRef, () => ({
    clearSty: () => {
      setCurSelect([])
    }
  }))

  const [openKeys, setOpenKeys] = useState([]);

  // 当前的路径
  const pathKey = useLocation().pathname
  let selectKey = pathKey.split('/')
  let defaultOpenKey = useLocation().pathname.split('/').splice(2)
  if (defaultOpenKey.includes('theater')) {
    if (defaultOpenKey.includes('sucaishou_count_plt')) {
      defaultOpenKey.push('push_plt')
    } else {
      defaultOpenKey.push('sucaishou_count_plt')
    }
    
  } else if (defaultOpenKey.includes('novel')) {
    if (defaultOpenKey.includes('sucaishou_count')) {
      defaultOpenKey.push('push')
    } else {
      defaultOpenKey.push('sucaishou_count')
    }
  }

  const navigate = useNavigate()
  const { menu } = props

  const getItem = (label, key, icon, children, type) => {
    return {
      label,
      key,
      icon,
      children,
      type,
    };
  }

  const { sendMessage, readyState, ws } = useWebSocket({
    url: process.env.REACT_APP_WEBSOCK_RUL,
    verify: true,
  });

  if (ws) {
    // 获取从服务端推送过来的数据
    ws.onmessage = (e) => {
      let data = JSON.parse(e.data);
      // 获取到的数据传递到父组件
      saveMessage(data?.lists)
    };
  }

  // 生成左侧菜单项
  const createMenu = (menus) => {
    return menus?.map((menu) => {
      if (!Reflect.has(menu, 'children')) {
        return getItem(menu.title, menu.key, <IconFont type={menu.icon} />)
      } else {
        return getItem(menu.title, menu.key, <IconFont type={menu.icon} />, createMenu(menu?.children))
      }
    })
  }

  // 切换展开父菜单
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    const rootSubmenuKeys = menu?.children?.map((item) => {
      return item?.value
    })
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const getOpenKeys = () => {
    let keys = openKeys
    if (keys.includes('novel')) {
      keys.push('push')
      keys.push('sucaishou_count')
    } else if (keys.includes('theater')) {
      keys.push('push_plt')
      keys.push('sucaishou_count_plt')
    }
    return keys
  }

  // 点击跳转路由
  function onNavigate(e) {
    let pathArr = pathKey.split('/').splice(2)
    let path = pathArr.slice(0, 1) + '/' + e.keyPath.reverse().join("/")
    if (readyState.key === 1) {
      sendMessage(
        JSON.stringify({ username: localStorage.getItem('username'), sourse: "header头部定时发送" })
      );
    }
    setCurSelect([path.slice(path.lastIndexOf('/')+1)])
   
    navigate(path)

    localStorage.removeItem('system_from_ecpm_ID');
    localStorage.removeItem('system_from_ecpm_BY_NAME')
  }

  const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/c/font_3965974_wfrxfh50ga.js",
  })

  return (
    <div className='left_nav'>
      <Menu
        selectedKeys={selectKey}
        defaultOpenKeys={defaultOpenKey}
        // openKeys={openKeys.length > 0 ? getOpenKeys() : defaultOpenKey}
        mode="inline"
        theme="dark"
        className="left-nav-menu"
        items={
          createMenu(menu?.children)
        }
        onSelect={e => onNavigate(e)}
        // selectedKeys={window.location.href.indexOf('new_ecpm_list') > -1 ? 'new_ecpm_list' : (window.location.href.indexOf('toutiao_plt') > -1 ? 'toutiao_plt' : curSelect)}
        // onOpenChange={onOpenChange}
      >
      </Menu>
    </div>
  )
}

export default connect(
  state => ({ menu: state.menu }),
  { saveMenu, saveMessage }
)(LeftNav)
