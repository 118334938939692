import { Button, Typography } from "antd";
import dayjs from "dayjs";

const columns = ({ handleReUp }) => [
  {
    title: "2.0广告ID",
    width: 150,
    align: "center",
    dataIndex: "aid",
    key: "aid",
    ellipsis: true,
  },
  {
    title: "推广外链ID",
    width: 120,
    align: "center",
    dataIndex: "promotion_external_id",
    key: "promotion_external_id",
  },
  {
    title: "订单ID",
    width: 100,
    align: "center",
    dataIndex: "order_id",
    key: "order_id",
  },
  {
    title: "订单号",
    width: 100,
    align: "center",
    dataIndex: "order_num",
    key: "order_num",
  },
  {
    title: "回传金额",
    width: 110,
    align: "center",
    dataIndex: "money",
    key: "money",
  },
  {
    title: "备注",
    width: 120,
    align: "center",
    dataIndex: "remark",
    key: "remark",
    ellipsis: true,
  },
  {
    title: "OPENID",
    width: 100,
    align: "center",
    dataIndex: "openid",
    key: "openid",
  },
  {
    title: "回传结果",
    width: 250,
    align: "center",
    dataIndex: "result",
    key: "result",
    ellipsis: true,
  },
  {
    title: "是否回传",
    width: 120,
    align: "center",
    dataIndex: "is_real_up",
    key: "is_real_up",
    render: (value) => {
      if (value === 1) {
        return <div>正常回传</div>
      } else if (value === 2) {
        return <div>金额卡掉</div>
      } else if (value === 0) {
        return <div>卡回传策略过滤</div>
      } else {
        return <Typography.Text type="danger">未匹配上</Typography.Text>
      }
    }
  },
  {
    title: "是否卡掉重新回传",
    width: 100,
    align: "center",
    dataIndex: "is_retry",
    key: "is_retry",
    render: (value) => (value === 1 ? "是" : "否"),
  },
  {
    title: "回传时间",
    width: 160,
    align: "center",
    dataIndex: "create_time",
    key: "create_time",
    render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "操作",
    width: 140,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    render: (_, record) => (
      <>
        {record?.app_type !== 1007 ? (
          record?.is_real_up === 0 || record?.is_real_up === 2 ? (
            <Button type="primary" size="small" onClick={() => handleReUp(record)}>
              重新回传
            </Button>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </>
    ),
  },
];

export default columns;
