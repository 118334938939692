export const switchOptions = [
  {
    label: '开启',
    value: 1
  },
  {
    label: '关闭',
    value: 0
  }
];

export const switchOptionKeys = {
  open: 1,
  close: 0
}

export const backModes = [
  {
    label: '关键行为模式',
    value: 1
  },
  {
    label: 'ROI变现模式',
    value: 2
  }
];

export const backWindows = [
  {
    label: '24小时',
    value: 1
  },
  {
    label: '自然日',
    value: 2,
    disabled: true,
  },
  {
    label: '自然日+3h',
    value: 3,
    disabled: true,
  }
];

export const keyBehaviors = [
  {
    label: 'IPU',
    value: 1,
    min: 'minIpu',
    max: 'maxIpu',
    title: 'IPU指标',
  },
  {
    label: 'ARPU',
    value: 2,
    min: 'minArpu',
    max: 'maxArpu',
    title: 'ARPU指标',
  }
];

export const backTypes = [
  {
    label: '全部回传',
    value: 1
  },
  {
    label: '自定义',
    value: 2
  }
]

