import React from "react";
import Table from "../../../../components/TableNoSelection";
import { Button, Card, Divider, Form, Input, InputNumber, Modal, Select, Space, message } from "antd";
import columns from "./columns";
import { useState } from "react";
import { playletVirtualBack, resultReUp } from "../../../../api/playlet";

const { Search } = Input;
const { Option } = Select;

export default function Index(props) {
  const [searchType, setSearchType] = useState(1);
  const { list, total, page, perPage, pagesize } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  const onChange = value => {
    setSearchType(value);
  };

  const selectBefore = (
    <Select defaultValue={1} onChange={onChange}>
      <Option value={1}>OPENID</Option>
      <Option value={2}>广告ID</Option>
    </Select>
  );

  // 筛选查询
  const onSearch = value => {
    let params = {};
    if (searchType === 1 && value) {
      params.openid = value;
    } else if (searchType === 2 && value) {
      params.aid = value;
    }
    props.onSearch(params);
  };

  // 重新回传
  const handleReUp = async record => {
    const res = await resultReUp({
      id: record?.id,
      app_type: record?.app_type,
    });
    if (res?.code === 200) {
      message.success("重新回传成功", 1);
      props.onSearch();
    }
  };

  // 添加虚拟回传弹窗
  const addVirtualModal = () => {
    setShow(true)
  }

  const modalClose = () => {
    setShow(false)
    setLoading(false)
  }

  // 虚拟回传表单提交
  const onVirtualFormSubmit = async values => {
    setLoading(true)
    const res = await playletVirtualBack(values)
    if (res?.code === 200) {
      message.success('虚拟回传操作成功')
      setTimeout(() => {
        modalClose()
      }, 500);
    }
  }

  return (
    <>
    <Card
      title={<Button type="primary" onClick={addVirtualModal}>添加虚拟回传</Button>}
      extra={
        <Search
          placeholder={searchType === 1 ? "输入OPENID" : "输入计划ID"}
          allowClear
          enterButton="搜索"
          addonBefore={selectBefore}
          onSearch={onSearch}
        />
      }
    >
      <Table
        dataSource={list}
        columns={columns({ handleReUp })}
        total={total}
        page={page}
        perPage={perPage}
        pagesize={pagesize}
      />
    </Card>
     {/* 添加虚拟回传弹窗 */}
      <Modal
        open={show}
        onCancel={modalClose}
        title="添加虚拟回传"
        footer={null}
      >
        <Divider />
        <Form
          labelCol={{ sm: { span: 5 } }}
          wrapperCol={{ xl: { span: 16 } }}
          form={form}
          onFinish={onVirtualFormSubmit}
        >
          <Form.Item
            label="广告ID"
            name="aid"
            rules={[{ required: true, message: "请输入广告id" }]}
          >
            <Input allowClear placeholder="输入广告id" />
          </Form.Item>
          <Form.Item label="回传数量" name="numbers">
            <InputNumber
              allowclear='true'
              placeholder="输入虚拟回传数 (不填默认1)"
              style={{ width: "100%" }}
              min={1}
              max={3}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "right", marginLeft: "59%" }}>
            <Space>
              <Button onClick={modalClose}>取消</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      </>
  );
}
