import React, {useMemo} from 'react';
import { Card, Form, Row, Col, Select, DatePicker, Space, Button, Table } from 'antd'
import dayjs from 'dayjs'

import styles from './index.module.less'
import { statusOptions, FORMAT } from './constants'
import { useTable, useColumns } from './hooks'

const defaultTime = [
  dayjs(),
  dayjs(),
]

const AutoPutTable = () => {
  const [form] = Form.useForm()

  const {
    table,
    pagination,
    tableLoading,
    searchLoading,
    onSearch,
    onReset,
  } = useTable({ form })

  const { columns } = useColumns()

  return (
    <div className={styles.wrapper}>
      <Card className={styles.search}>
        <Form form={form} onFinish={onSearch} onReset={onReset}>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="time"
                label="日期"
                initialValue={defaultTime}
              >
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  format={FORMAT}
                  placeholder={['开始日期', '结束日期']}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="status" label="执行状态">
                <Select
                  placeholder="请选择"
                  allowClear
                  options={statusOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label colon={false}>
                <Space className={styles.actions}>
                  <Button type="primary" htmlType="submit" loading={searchLoading}>查询</Button>
                  <Button htmlType="reset" loading={searchLoading}>重置</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Card className={styles.table}>
        <Table
          dataSource={table}
          columns={columns}
          loading={tableLoading}
          pagination={pagination}
          rowKey={(record) => record?.id}
        />
      </Card>
    </div>
  )
}

export default React.memo(AutoPutTable)
