const columns = () => [
    {
        title: "三方包名",
        width: 100,
        align: "center",
        dataIndex: "aliasname",
        key: "aliasname",
    },
    {
        title: "日期",
        width: 110,
        align: "center",
        dataIndex: "day",
        key: "day",
    },
    {
        title: "消耗",
        width: 95,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "回收",
        width: 100,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "回收率",
        width: 105,
        align: "center",
        dataIndex: "hui_shou_lv",
        key: "hui_shou_lv",
    },
    {
        title: "支付人数",
        width: 105,
        align: "center",
        dataIndex: "pay_man",
        key: "pay_man",
    },
    {
        title: "付费成本",
        width: 90,
        align: "center",
        dataIndex: "pay_cost",
        key: "pay_cost",
    },
    {
        title: "客单价",
        width: 90,
        align: "center",
        dataIndex: "per_money",
        key: "per_money",
    },
    {
        title: "在消耗计划",
        width: 100,
        align: "center",
        dataIndex: "zaikai_plan",
        key: "zaikai_plan",
    },
    {
        title: "审核通过计划",
        width: 105,
        align: "center",
        dataIndex: "audit_plan",
        key: "audit_plan",
    },
    {
        title: "所有计划",
        width: 105,
        align: "center",
        dataIndex: "all_plan",
        key: "all_plan",
    },
]

export default columns