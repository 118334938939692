import { Button } from "antd";

const columns = ({ onSelectedProduct }) => [
    {
        title: "商品id",
        width: 100,
        align: "center",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "活动名称",
        width: 110,
        align: "center",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "商品价格",
        width: 110,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "提示语1",
        width: 120,
        align: "center",
        dataIndex: "titilea",
        key: "titilea",
    },
    {
        title: "提示语2",
        width: 150,
        align: "center",
        dataIndex: "titileb",
        key: "titileb",
    },
    {
        title: "提示语3",
        width: 120,
        align: "center",
        dataIndex: "titilec",
        key: "titilec",
    },
    {
        title: "书币",
        width: 100,
        align: "center",
        dataIndex: "score",
        key: "score",
    },
    {
        title: "操作",
        width: 100,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        render: (_, record) => (
            <Button type="link" onClick={() => {onSelectedProduct(record, 'update')}}>选择</Button>
        )
    },
]

export default columns