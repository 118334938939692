import { TOS, TosClient } from "@volcengine/tos-sdk";
import { numDiv } from "../util/calculate";

// 创建客户端
const client = new TosClient({
  accessKeyId: "AKLTMGYyMzI5MWQzMmQ3NGNhZWE4MWE5ZTU0ZTc5OTM3MWY", // 从 STS 服务获取的临时访问密钥 AccessKey ID
  accessKeySecret:
    "TWpZeU1EVTNaVGhsT0RrNE5Ea3pNbUV3TkdReE1qazFZMlUxTjJKbVkyVQ==", // 从 STS 服务获取的临时访问密钥 AccessKey Secret
  region: "cn-beijing", // 填写 Bucket 所在地域。以华北2（北京)为例，则 "Provide your region" 填写为 cn-beijing。
  endpoint: "tos-cn-beijing.volces.com", // 填写域名地址
  bucket: "advertiser-material2",
});

// 生成 cancelToken
let cancelTokenSource = null;

// 普通分片上传
export const tosMultipartUpload = async (objName, file, options = {}) => {
  try {
    // 获取上传任务 ID
    const {
      data: { UploadId },
    } = await client.createMultipartUpload({
      key: objName,
    });

    let partSize = 5 * 1024 * 1024;
    // 文件总大小
    const totalSize = file.size;
    let partNumber = 1;
    const partsInfo = [];
    let totalPartSize = 0;

    // 串行上传每个 Part
    for (let i = 0; i * partSize < totalSize; i++) {
      let params = {
        key: objName,
        partNumber: partNumber,
        body: file.slice(i * partSize, Math.min((i + 1) * partSize, totalSize)),
        uploadId: UploadId,
        // eslint-disable-next-line no-loop-func
        progress: (p) => {
          if (p > 0) {
            let part = i + 1;
            totalPartSize = Math.min(part * partSize, totalSize);
            let percent = numDiv(totalPartSize, totalSize);
            options?.actualProgress(percent, file);
          }
        },
      };
      const uploadResult = await client.uploadPart(params);
      const eTag = uploadResult.data.ETag;
      partsInfo.push({
        eTag,
        partNumber,
      });
      partNumber++;
    }

    // 完成分片上传
    const { data } = await client.completeMultipartUpload({
      key: objName,
      parts: partsInfo,
      uploadId: UploadId,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

// 断点续传模式分片上传
export const breakPointMultipartUpload = async (objName, file, options = {}) => {
  try {
    // 分片大小最小为 5MB
    const partSize = 5 * 1024 * 1024;
    // 定义中断点。
    let abortCheckpoint;
    cancelTokenSource = TOS.CancelToken.source();

    const uploadResult = await client.uploadFile({
      key: objName,
      file,
      partSize,
      taskNum: 8,  // 分片并发数，默认为1
      cancelToken: cancelTokenSource.token,
      progress: (p, checkpoint) => {
        // 为中断点赋值。
        abortCheckpoint = checkpoint;
        options?.actualProgress(p, objName, file);
      },
    });
    return uploadResult
  } catch (error) {
    console.log(error);
  }
};

// 暂停上传
export const pauseUpload = (objName) => {
  cancelTokenSource.cancel(objName);
  console.log('cancel request');
}

// 恢复上传
export const resumeUpload = async (objName, file, options = {}) => {
  cancelTokenSource = TOS.CancelToken.source();
  // 定义中断点。
  let abortCheckpoint;
  // 分片大小最小为 5MB
  const partSize = 5 * 1024 * 1024;

  try {
    const uploadResult = await client.uploadFile({
      key: objName,
      file,
      partSize,
      checkpoint: abortCheckpoint,
      cancelToken: cancelTokenSource.token,
      progress: (p, checkpoint) => {
        abortCheckpoint = checkpoint;
        options?.actualProgress(p, objName, file);
      },
    });
    return uploadResult
  } catch (e) {
    console.log(e);
  }
};

// 单文件上传
export const tosSingleUpload = async (objName, body) => {
  // 直接使用文件路径上传文件
  await client.putObject({
    key: objName,
    body
  });

  // 查询刚刚上传对象的大小
  const { data } = await client.headObject({
    key: objName,
  });
  return data
};

// 删除对象（文件）
export const deleteObject = async (objName) => {
  try {
    const { data } = await client.deleteObject({
      key: objName,
    });
    console.log(`deleteObject key:${objName} result: `, data);
    return data;
  } catch (error) {
    console.log(error);
  }
};
