import { getRequest, postRequest } from "./request"


// 获取登录验证码
export const createCode = () => postRequest('/authorization/create_code')

// 登录(需要验证码)
export const login = (username, password, verify_code, key) => postRequest('/authorization/login', {username, password, verify_code, key})

// 修改密码
export const handleResetPasswd = data => postRequest('/authorization/reset_passwd', data)
 
// 获取菜单项
export const getMenu = data => postRequest('/admin/menu', data)
 
// 获取菜单项
export const getMenuChildren = data => postRequest('/admin/menu/children', data)

// 后台首页总览
export const statistics = () => postRequest('/admin/index')

// 分片上传验证
export const checkVideoShard = data => postRequest('/upload/check_shard', data)

// 视频文件上传合并
export const videoMergeAction = data => postRequest('/upload/video_merge', data)

// 批量上传图片
export const batchUploadImg = data => postRequest('/upload/batch_img', data)

// 从资源库获取图片|视频（主要是视频上传完成后展示用）
export const getAttachVideos = data => postRequest('upload/attach_video', data)

export const attachVideoAdd = data => postRequest('/admin/attachment/video_add', data)

// 素材列表
export const materialList = data => postRequest('/admin/material', data)

// 开放素材列表
export const materialCustomOpenList = data => postRequest('/admin/material/custom_open', data)

// 视频素材数据效果详情
export const materialDataDetail = data => postRequest('/admin/material/data_detail', data)

// 视频素材每日消耗
export const materialCreativeCost = data => postRequest('/admin/material/creative_cost', data)

// 添加素材
export const materialAdd = data => postRequest('/admin/material/add', data)

// 获取素材分类
export const getMaterialOptions = () => postRequest('/admin/material/get_options')

// 素材删除
export const materialDelete = data => postRequest('/admin/material/delete', data)

// 素材一键转公有
export const setMaterialPublic = data => postRequest('/admin/material/set_public', data)

// 素材详情
export const getMaterialDetail = data => postRequest('/admin/material/detail', data)

// 投手之间私有素材共享
export const updateMaterialShare = data => postRequest('/admin/material/update_share', data)

// 批量私有素材共享
export const batchUpdateMaterialShare = data => postRequest('/admin/material/batch_update_share', data)

// 批量开放素材
export const batchUpdateMaterialOpen = data => postRequest('/admin/material/batch_update_open', data)

// 素材锁定
export const handleMaterialLock = data => postRequest('/admin/material/lock', data)

// 素材共享
export const handleMaterialShare = data => postRequest('/admin/material/share', data)

// 修改备注|状态
export const handleUpdateStatus = data => postRequest('/admin/material/remark', data)

// 修改素材标签
export const handleUpdateLabel = data => postRequest('/admin/material/label_update', data)

// 设置重点推荐素材
export const handleUpdateRecommend = data => postRequest('/admin/material/recommend', data)

// 首页总览--管家列表
export const getManagerList = data => postRequest('/admin/index', data)

// 首页总览--数据统计
export const getStatistics = data => postRequest('/admin/index/statistics', data)

// 管家详情--账号列表
export const getManagerAccount = data => postRequest('/admin/index/manager_account', data)

// 管家详情--广告计划
export const getManagerPlan = data => postRequest('/admin/index/manager_plan', data)

// 分配素材的投手
export const getPitcherList = data => postRequest('/admin/user/pitcher', data)

// 分配素材的默认投手
export const getDefaultPitcher = data =>postRequest('/admin/user/default_pitcher', data)

// 获取素材手信息
export const getMaterialList = data => postRequest('/admin/user/material', data)

// 获取标题素材列表
export const getMaterialTitle = data => postRequest('/admin/material/title', data)

// 添加标题素材（包含批量添加）
export const materialTitleAdd = data => postRequest('/admin/material/title_add', data)

// 批量添加素材标题
export const titleBatchAdd = data => postRequest('/admin/material/title_batch_add', data)

// 修改标题素材
export const materialTitleEdit = data => postRequest('/admin/material/title_update', data)

// 批量广告里直接修改标题素材
export const materialTitleSingleUpdate = data => postRequest('/admin/material/title_single_update', data)

// 删除标题素材
export const materialTitleDelete = data => postRequest('/admin/material/title_delete', data)

// 获取标题分类列表
export const materialTitleCategory = data => postRequest('/admin/material/title_categorys', data)

// 添加标题分类
export const materialTitleCategoryAdd = data => postRequest('/admin/material/title_category_add', data)

// 素材上传统计
export const materialUpStatistics = data => postRequest('/admin/material/up_statistics', data)

// 根据选择的书本获取章节
export const getBookChapter = data => postRequest('/admin/book/chapter', data)

// 根据渠道生成推广链接
export const createChapterUrl = data => postRequest('/admin/book/generate_url', data)

// 推广链接列表
export const getPromotionList = data => postRequest('/admin/book/promotion_list', data)

// 修改备注
export const promotionRemarkEdit = data => postRequest('/admin/book/remark_edit', data)

// 推广链接修改
export const promotionUpdate = data => postRequest('/admin/book/promotion_update', data)

// 首页近7天、30天的消耗，转化数据统计
export const sumCostRangeDate = data => postRequest('/admin/index/range_data', data)

//获得代理商参数配置
export const getOrganConfig = data => postRequest('/admin/index/get_config')

//代理商组织参数配置提交
export const saveOrganConfig = data => postRequest('/admin/index/save_config',data);

// 默认充值档位面板
export const getPanelList = data => postRequest('/admin/index/panel_list',data);

// 默认充值档位状态修改
export const panelStatusUpdate = data => postRequest('/admin/index/panel_status_update',data);

// 默认充值档位最高出价限制修改
export const panelBidLimitUpdate = data => postRequest('/admin/index/panel_bidLimit_update',data);

// 获取三方剧场对应的盈亏值
export const getPlayletConfigData = data => postRequest('/admin/index/playlet_profit_config_get', data)

// 设置三方剧场对应的盈亏值
export const setPlayletConfigData = data => postRequest('/admin/index/playlet_profit_config_set', data)

// 三方剧场菜单是否显示内容
export const getPlayletOpenData = data => postRequest('/admin/index/playlet_open_data', data)

// 三方剧场菜单是否显示内容设置保存
export const savePlayletOpenData = data => postRequest('/admin/index/playlet_open_save', data)

// 配置新剧列表
export const getNewPlayletList = data => postRequest('/admin/index/new_playlet_list', data)

// 选择推广链接选择短剧
export const getPromotionPlayletList = data => postRequest('/admin/index/promotion_playlet_list', data)

// 添加短剧
export const promotionPlayletAdd = data => postRequest('/admin/book/other_add', data)

// 添加配置新剧
export const newPlayletAdd = data => postRequest('/admin/index/new_playlet_add', data)

// 编辑配置新剧
export const newPlayletUpdate = data => postRequest('/admin/index/new_playlet_update', data)

