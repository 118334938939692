const columns = () => [
    {
        title: "投手",
        width: 120,
        align: "center",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        render: (_, record) => (
            record?.user?.nickname || '-'
        )
    },
    {
        title: "渠道名称",
        width: 180,
        align: "center",
        dataIndex: "channel",
        key: "channel",
        ellipsis: true,
        render: (_, record) => (
            record?.channel?.nickname || '-'
        )
    },
    {
        title: "当日消耗",
        width: 120,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "回收",
        width: 100,
        align: "center",
        dataIndex: "money",
        key: "money",
        render: value => (
            value || 0
        )
    },
    {
        title: "回收率",
        width: 110,
        align: "center",
        dataIndex: "huishoulv",
        key: "huishoulv",
        render: value => (
            value || 0
        )
    },
    {
        title: "支付人数(首充)",
        width: 130,
        align: "center",
        dataIndex: "count",
        key: "count",
        render: value => (
            value || 0
        )
    },
    {
        title: "付费成本",
        width: 120,
        align: "center",
        dataIndex: "paycosts",
        key: "paycosts",
        render: value => (
            value || 0
        )
    },
    {
        title: "首日客单价",
        width: 120,
        align: "center",
        dataIndex: "kedans",
        key: "kedans",
        render: value => (
            value || 0
        )
    },
]

export default columns