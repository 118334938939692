import {
  Button,
  Card,
  DatePicker,
  Form,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import columns from "./columns";
import { filterPitchers, profitTrendActions } from "../../../api/statistics";
import { numAdd, numDiv, numMulti } from "../../../util/calculate";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pitcherOptions, setPitcherOptions] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getList();
    getPitchers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取列表信息
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await profitTrendActions(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setLoading(false);
    }
  };

  // 获取筛选的投手信息
  const getPitchers = async () => {
    const res = await filterPitchers({ type: "playlet" });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(newList);
      if (!data?.is_super) {
        form.setFieldsValue({ admin_id: data?.current_admin_id });
      }
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 提交表单筛选查询
  const onSubmit = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((value) => {
        return value.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    getList(values);
  };
  return (
    <Card
      title={
        <Form layout="inline" onFinish={onSubmit} form={form}>
          <Form.Item label="选择投手" name="admin_id">
            <Select
              allowClear
              placeholder="请选择投手"
              options={pitcherOptions}
              showSearch
              filterOption={filterOption}
              style={{ width: 150 }}
            />
          </Form.Item>
          <Form.Item label="日期选择" name="range_date">
            <RangePicker />
          </Form.Item>
          <Form.Item label colon={false}>
            <Space>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
              <Button onClick={() => form.resetFields()}>重置</Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Table
        sticky
        dataSource={list}
        columns={columns()}
        loading={loading}
        pagination={false}
        bordered={true}
        scroll={{ x: 500 }}
        summary={(pageData) => {
          let totalMoney = 0;
          let totalCost = 0;
          let totalNoBidMoney = 0;
          let totalNoBidCost = 0;
          let totalAdCash = 0;
          let totalNewUser = 0;
          pageData.forEach(
            ({
              total_money,
              total_cost,
              noBid_cost,
              noBid_money,
              ad_cash,
              new_user,
            }) => {
              totalMoney += parseFloat(total_money);
              totalCost += parseFloat(total_cost);
              totalNoBidMoney += parseFloat(noBid_money);
              totalNoBidCost += parseFloat(noBid_cost);
              totalAdCash += parseFloat(ad_cash);
              totalNewUser += parseInt(new_user);
            }
          );
          return (
            <>
              {pageData && pageData.length > 0 ? (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={1} align="center">
                    <Text type="danger" strong={true}>
                      汇总
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <Text style={{ fontSize: 12 }}>{totalNewUser}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <Text style={{ fontSize: 12 }}>{totalMoney.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <Text style={{ fontSize: 12 }}>{totalCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <Text style={{ fontSize: 12 }}>{totalNoBidMoney.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    <Text style={{ fontSize: 12 }}>{totalNoBidCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    <Text style={{ fontSize: 12 }}>{totalAdCash.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    <Text style={{ fontSize: 12 }}>
                      {parseFloat(totalCost) && (parseFloat(totalMoney) || totalAdCash)
                        ? numMulti(
                            numDiv(numAdd(totalMoney, totalAdCash), totalCost * 1),
                            100
                          ).toFixed(2) + "%"
                        : 0}
                    </Text>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={8} align="center">
                    <Text style={{ fontSize: 12 }}>{summaryData?.profit}</Text>
                  </Table.Summary.Cell> */}
                </Table.Summary.Row>
              ) : (
                ""
              )}
            </>
          );
        }}
      />
    </Card>
  );
}
