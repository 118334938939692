const columns = () => [
    {
        title: "任务ID",
        width: 80,
        align: "center",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "同步素材ID",
        width: 120,
        align: "center",
        dataIndex: "material_ids",
        key: "material_ids",
    },
    {
        title: "执行结果",
        width: 150,
        align: "center",
        dataIndex: "status",
        key: "status",
        render: value => {
            let statusTxt = {0: '未执行', 1: '执行中', 2: '完成', 3: '部分完成,需脚本重试失败的', 4: '失败重试中'}
            return statusTxt[value] || '-'
        }
    },
    {
        title: "广告主集合",
        align: "center",
        dataIndex: "advertiser_ids",
        key: "advertiser_ids",
        ellipsis: true,
    },
    {
        title: "失败的广告主和素材",
        align: "center",
        dataIndex: "error",
        key: "error",
        ellipsis: true,
        render: (_,record) => {
            let err_materialIds = record?.err_materialIds
            let err_advertiserIds = record?.err_advertiserIds
            if (err_materialIds && err_advertiserIds) {
                return <>
                    <div>素材: {err_materialIds}</div>
                    <div>广告主: {err_advertiserIds}</div>
                </>
            }
            return ''
        }
    },
]

export default columns