import React, { useCallback, useEffect, useState } from "react";
import { Card, Form, Button, DatePicker, Space, Select, Table } from "antd";
import dayjs from "dayjs";

import styles from "./index.module.less";
import { RankTypeEnums, rankTypes } from "./constants";
import { getPerformanceList } from "@/api/delivery";

const TODAY = dayjs();

const useColumn = () => {
  const columns = [
    {
      title: '排名',
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      title: '昵称',
      dataIndex: 'adminName',
      key: 'adminName',
    },
    {
      title: '日期',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '消耗',
      dataIndex: 'cost',
      key: 'cost',
    },
    {
      title: 'ecpm收益',
      dataIndex: 'ecpm',
      key: 'ecpm',
    },
    {
      title: '分数',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: '盈亏 (利润)',
      dataIndex: 'profit',
      key: 'profit',
    },
  ]

  return {
    columns,
  }
}

// 禁用未来日期
const disabledDate = (date) => {
  return date > dayjs().endOf("day");
}

const PitcherPerformance = () => {
  // 表单
  const [searchForm] = Form.useForm();

  const [pageInfo, setPageInfo] = useState({
    page: 0,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [tableList, setTableList] = useState([]);
  // 表格的 loading 状态
  const [tableLoading, setTableLoading] = useState(false);
  // 搜索按钮的 loading 状态
  const [searchLoading, setSearchLoading] = useState(false);

  // 获取搜索的参数
  const getSearchParams = useCallback(() => {
    const values = searchForm.getFieldsValue();

    const day = values?.day ? values?.day?.format?.("YYYY-MM-DD") : undefined;

    return {
      day,
      direction: values?.direction || RankTypeEnums.RED,
    };
  }, [searchForm]);

  // 获取列表数据
  const getList = useCallback(async () => {
    try {
      setTableLoading(true);
      const params = {
        ...getSearchParams(),
      };
      const res = await getPerformanceList(params);
      setTableList(res?.data || []);
      setTotal(res?.total || 0);
    } catch (err) {
      setTableList([]);
      setTotal(0);
    } finally {
      setTableLoading(false);
    }
  }, [getSearchParams, pageInfo]);

  // 搜索
  const onSearch = useCallback(async () => {
    setSearchLoading(true);

    await getList();

    setSearchLoading(false);
  }, [getList]);

  // 重置
  const onReset = async () => {
    searchForm.resetFields();

    await onSearch();
  };

  // 初始化的时候, 获取一次数据
  useEffect(() => {
    onSearch().catch(() => {
      console.error("初始化失败");
    });
    // eslint-disable-next-line
  }, []);

  const { columns } = useColumn()

  return (
    <div className={styles.wrapper}>
      <Card className={styles.search}>
        <Form form={searchForm} layout="inline">
          <Form.Item label="日期选择" name="day" initialValue={TODAY}>
            <DatePicker allowClear={false} disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item
            label="榜单类型"
            name="direction"
            initialValue={RankTypeEnums.RED}
          >
            <Select
              allowClear={false}
              placeholder="请选择"
              style={{ width: 150 }}
              options={rankTypes}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" loading={searchLoading} onClick={onSearch}>
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Card className={styles.table}>
        <Table
          dataSource={tableList}
          loading={tableLoading}
          columns={columns}
          rowKey={(record) => record.rank}
        />
      </Card>
    </div>
  );
};

export default React.memo(PitcherPerformance);
