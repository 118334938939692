import { Button, Space, Switch } from "antd";

const columns = ({ onStatusChange, ruleUpdate, statusLoading }) => [
  {
    title: "开关",
    width: 80,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (value, record) => (
      <Switch
        checkedChildren="开启"
        unCheckedChildren="关闭"
        checked={value === 1}
        onChange={(status, e) => onStatusChange(record, status, e)}
        loading={statusLoading === record?.id}
      />
    ),
  },
  {
    title: "规则名称",
    width: 100,
    align: "center",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
  },
  {
    title: "规则ID",
    width: 80,
    align: "center",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "应用对象",
    width: 80,
    align: "center",
    dataIndex: "apply_type",
    key: "apply_type",
    render: (value) =>
      value === 1 ? "应用至广告" : value === 2 ? "应用至项目" : "应用至账户",
  },
  {
    title: "创建者",
    width: 80,
    align: "center",
    dataIndex: "user",
    key: "user",
    render: (_, record) => record?.user?.nickname || record?.user?.username,
  },
  {
    title: "通知渠道",
    width: 80,
    align: "center",
    dataIndex: "notice_platform",
    key: "notice_platform",
  },
  {
    title: "操作",
    width: 80,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    render: (_, record) => (
      <Space>
        <Button type="primary" size="small" onClick={() => ruleUpdate(record?.id)}>
          编辑
        </Button>
        {/* <Button type="primary" danger size="small">
          删除
        </Button> */}
      </Space>
    ),
  },
];

export default columns;
