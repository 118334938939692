import React from "react";
import _ from "lodash";
import { Button, DatePicker, Form, Space, message } from "antd";
import moment from "moment";
import Pitchers from "../../../../../components/Pitchers";

const { RangePicker } = DatePicker;

const SearchComponent = ({
  withPitcher,
  dayPicker,
  onSearch,
  defaultDate,
}) => {
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    if (withPitcher) {
      values.range_date = _.map(values["range_date"], (value) => {
        return value.format("YYYY-MM-DD");
      });
      onSearch({
        adminId: values.admin_id,
        startTime: values["range_date"] ? values["range_date"][0] : "",
        endTime: values["range_date"] ? values["range_date"][1] : "",
      });
    } else {
      values.range_date = _.map(values["range_date"], (value) => {
        return value.format("YYYY-MM-DD");
      });
      onSearch({
        start: values["range_date"] ? values["range_date"][0] : "",
        end: values["range_date"] ? values["range_date"][1] : "",
      });
    }
  };

  return (
    <div>
      <Form
        layout="inline"
        onFinish={onSubmit}
        form={form}
        initialValues={{
          range_date: defaultDate || undefined,
        }}
      >
        {withPitcher ? (
          <Form.Item label="选择投手" name="admin_id">
            <Pitchers unLimit={true} form={form} />
          </Form.Item>
        ) : null}
        {dayPicker ? (
          <Form.Item label="日期选择" name="day">
            <DatePicker />
          </Form.Item>
        ) : (
          <Form.Item label="日期选择" name="range_date">
            <RangePicker
              ranges={{
                今天: [moment(), moment()],
                昨天: [
                  moment().startOf("day").subtract(1, "d"),
                  moment().startOf("day").subtract(1, "d"),
                ],
                最近一周: [
                  moment().startOf("day").subtract(6, "d"),
                  moment().endOf("day"),
                ],
                最近一个月: [
                  moment().startOf("day").subtract(30, "d"),
                  moment().endOf("day"),
                ],
              }}
              format="YYYY-MM-DD"
              allowClear={false}
            />
          </Form.Item>
        )}
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SearchComponent;
