import { useCallback, useEffect, useMemo, useState } from "react";
import { getLeaveList } from "@/api/delivery";

const useTable = data => {
  const { searchForm } = data;

  const [pageInfo, setPageInfo] = useState({
    pageNum: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [tableList, setTableList] = useState([]);
  // 表格的 loading 状态
  const [tableLoading, setTableLoading] = useState(false);
  // 搜索按钮的 loading 状态
  const [searchLoading, setSearchLoading] = useState(false);

  // 获取搜索的参数
  const getSearchParams = useCallback(() => {
    const values = searchForm.getFieldsValue();

    return {
      adminId: values.adminId,
    };
  }, [searchForm]);

  // 获取列表数据
  const getList = useCallback(
    async (_pageInfo = null) => {
      try {
        setTableLoading(true);

        // 如果有传 _pageInfo, 就更新 pageInfo
        if (_pageInfo) {
          setPageInfo({
            ...pageInfo,
            ..._pageInfo,
          });
        }

        const params = {
          ...pageInfo,
          ...getSearchParams(),
          ...(_pageInfo || {}),
        };
        const res = await getLeaveList(params);

        const data = res?.data || {};
        setTableList(data?.list || []);
        setTotal(data?.total || 0);
      } catch (err) {
        setTableList([]);
        setTotal(0);
      } finally {
        setTableLoading(false);
      }
    },
    [getSearchParams, pageInfo]
  );

  // 搜索
  const onSearch = useCallback(async () => {
    setSearchLoading(true);

    await getList({
      pageNum: 1,
    });

    setSearchLoading(false);
  }, [getList]);

  // 重置
  const onReset = async () => {
    searchForm.resetFields();

    await onSearch();
  };

  // 刷新表格
  const onRefresh = async () => {
    await getList();
  };

  // 初始化的时候, 获取一次数据
  useEffect(() => {
    onSearch().catch(() => {
      console.error("初始化失败");
    });
    // eslint-disable-next-line
  }, []);

  const pagination = useMemo(() => {
    return {
      pageSize: pageInfo?.pageSize,
      current: pageInfo?.pageNum,
      total: total,
      showSizeChanger: true,
      pageSizeOptions: [5, 10, 20, 50, 100],
      showQuickJumper: true,
      showTotal: total => `共 ${total} 条`,
      onChange: async (page, pageSize) => {
        const _pageInfo = { pageNum: page, pageSize };
        // 如果修改了 pageSize, 那么就把 pageNum 设置为 1
        if (pageSize !== pageInfo.pageSize) {
          _pageInfo.pageNum = 1;
        }
        setPageInfo(_pageInfo);
        await getList(_pageInfo);
      },
    };
  }, [pageInfo, total, getList]);

  return {
    tableList,
    tableLoading,
    searchLoading,
    pagination,
    onSearch,
    onReset,
    onRefresh,
  };
};

export default useTable;
