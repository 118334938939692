import {
    Button,
    Switch,
    Tooltip,
    message,
  } from "antd";
  import dayjs from "dayjs";
  import CopyToClipboard from "react-copy-to-clipboard";
  
  const selfColumns = ({
    onStatusChange,
    statusLoading,
  }) => [
    {
      title: "推广链接名称",
      width: 120,
      align: "center",
      dataIndex: "title",
      key: "title",
      render: (value) => {
        return <div>{value ? value : "--"}</div>;
      },
    },
    {
      title: "外链ID",
      width: 100,
      align: "center",
      dataIndex: "promotion_id",
      key: "promotion_id",
    },
    {
      title: "推广链接地址",
      width: 250,
      align: "center",
      dataIndex: "promotion_url",
      key: "promotion_url",
      ellipsis: true,
      render: (value, record) => {
        let idArr = record.id.split("@@##");
        let jianceUrl = `https://jiance.shuyueyun.cn/admin/advertise/eventclick?clickid=__CLICK_ID__&adid=__PROMOTION_ID__&ip=__IP__&ua=__UA__&__&os=__OS__&TIMESTAMP=__TS__&callback=__CALLBACK_PARAM__&uid=${
          record.admin_id || record.uid
        }&promotion_id=${idArr[0] || 0}&app_type=${
          record?.app_type
        }&advertiser_id=__ADVERTISER_ID__`;
        return (
          <>
            <Tooltip title={value}>{value}</Tooltip>
            <div style={{ marginTop: 10 }}>
              <CopyToClipboard
                text={value}
                onCopy={() => {
                  message.success("复制成功");
                }}
              >
                <Button size="small">复制推广链接</Button>
              </CopyToClipboard>
  
              <CopyToClipboard
                style={{ marginLeft: 5 }}
                text={jianceUrl}
                onCopy={() => {
                  message.success("复制成功");
                }}
              >
                <Button size="small">复制监测链接</Button>
              </CopyToClipboard>
            </div>
          </>
        );
      },
    },
    {
      title: "投放状态",
      width: 80,
      align: "center",
      dataIndex: "status",
      key: "status",
      render: (value, record) => (
        <Switch
          checkedChildren="开启"
          unCheckedChildren="关闭"
          defaultChecked={value === 1}
          loading={statusLoading === record?.id.split("@@##")[0]}
          onChange={(status, e) => onStatusChange(record, status, e)}
        />
      ),
    },
    {
      title: "投放小说",
      width: 120,
      align: "center",
      dataIndex: "book_name",
      key: "book_name",
      ellipsis: true,
    },
    {
      title: "类型",
      width: 90,
      align: "center",
      dataIndex: "type",
      key: "type",
      render: (value) => (parseInt(value) === 1 ? "自己平台投放" : "只同步数据"),
    },
    {
      title: "记录创建时间",
      width: 155,
      align: "center",
      dataIndex: "create_time",
      key: "create_time",
      render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];
  
  export default selfColumns;
  