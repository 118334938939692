import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Space,
    Table,
    Typography,
  } from "antd";
  import React from "react";
  import { useLocation, useNavigate } from "react-router-dom";
  import { useState, useEffect } from "react";
  import columns from "./columns";
  import {
    filterPitchers,
  } from "../../../api/statistics";
  import {dataCompare} from "../../../api/compare";

  
  const { Text } = Typography;
  
  export default function Index(props) {
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [pitcherOptions, setPitcherOptions] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [orderBy,setOrderBy] = useState('cost_desc');
  
    // 当前路径
    const pathKey = useLocation().pathname;
  
    const navigate = useNavigate();
    const { state } = useLocation();
    const [form] = Form.useForm();
  
    useEffect(() => {
      getList();
      getFilterPitchers();
    }, [pathKey]);
  
    // 列表数据
    const getList = async (params = {}) => {
      setLoading(true);
      if (pathKey.split("/").includes("novel")) {
        params.type = "novel";
      } else if (pathKey.split("/").includes("theater")) {
        params.type = "playlet";
      }
      const res = await dataCompare({
        admin_id: props?.admin_id || props?.conditions?.admin_id || null,
        advertiser: props?.conditions?.advertiser || null,
        advertiser_name: state?.advertise?.account_name,
        ...params,
      });
      if (res?.code === 200) {
        const { data } = res;
        let lists = data?.lists;
        setList(lists);
        setTotal(data?.total);
        setLoading(false);
      }
    };
  
  
    //获取投手数据
    const getFilterPitchers = async () => {
      let params = {};
      if (pathKey.split("/").includes("novel")) {
        params.type = "novel";
      } else if (pathKey.split("/").includes("theater")) {
        params.type = "playlet";
      }
      const res = await filterPitchers(params);
      const { data } = res;
      if (res?.code === 200) {
        let list = data?.lists;
        let newList = list?.map((item) => {
          return { label: item?.nickname, value: item.id };
        });
        setPitcherOptions(newList);
      }
    };
  
    const onChange = () => {};
  
    // 列表条件查询
    const onSearch = async(values) => {
      values.advertiser = values.keyword;
      values.order = orderBy;
      getList(values);
      setConditions(values);
      console.log(props,'onsss')
    };
  
    // 分页
    const onShowChange = (page, size) => {
      console.log('onshowchangee',size,'dafads')
      setPage(page);
      setPerPage(size);
      let values = conditions;
      values.page = page;
      values.pageSize = size;
      values.order = orderBy;
      getList(values);
    };
  
    // 搜索
    const filterOption = (inputValue, option) => {
      const { label } = option;
      return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
    };
  
  
    // 表单重置
    const formReset = () => {
      form.resetFields();
      setConditions([]);
    };
  
  
    // 消耗、转化数排序
    const onTableChange = (pagination, filter, sorter) => {
      let field = sorter.field;
      console.log(field,'fff')
      console.log(conditions,'conditonss')
      let orderType = sorter.order === "ascend" ? "asc" : "desc";
      let order = `${field}_${orderType}`;
      if (field !== undefined && order!=orderBy) {
        console.log(page,'ddd')
        setPage(1);
        setOrderBy(order);
        getList({...conditions, order });
      }
    };
  
  
    return (
      <>
        <Card
          title={
            <Form
              form={form}
              onFinish={onSearch}
              initialValues={{
                admin_id: props?.admin_id || props?.conditions?.admin_id || null,
                keyword:
                  props?.conditions?.keyword || props?.conditions?.advertiser_id,
              }}
            >
              <Row gutter={16}>
                <Col span={7}>
                  <Form.Item label="日期筛选" name="day">
                    <DatePicker size="small" style={{width: '100%'}} />
                  </Form.Item> 
                </Col>
                <Col span={5}>
                  <Form.Item label="投手" name="admin_id">
                    <Select
                      allowClear
                      placeholder="投手筛选"
                      options={pitcherOptions}
                      showSearch
                      filterOption={filterOption}
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item label="广告账户" name="keyword">
                    <Input
                      allowClear
                      placeholder="输入账号名称, ID搜索"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item style={{ marginLeft: 20 }}>
                    <Space>
                      <Button type="primary" htmlType="submit" size="small">
                        确定
                      </Button>
                      <Button onClick={formReset} size="small">
                        重置
                      </Button>
                      <Button
                        type="primary"
                        size="small"
                        ghost
                        onClick={() => navigate(-1)}
                      >
                        返回
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          }
          headStyle={{ marginTop: 10 }}
          bodyStyle={{padding:0}}
          size="small"
        >
          <Table
            sticky
            size="small"
            dataSource={list}
            columns={columns({ onChange})}
            loading={loading}
            bordered={true}
            onChange={onTableChange}
            pagination={{
              total: total,
              showSizeChanger: true,
              showQuickJumper: props.total > 50 ? true : false,
              showTotal: (total) => `共 ${total} 条`,
              pageSizeOptions: [10, 15, 20, 30],
              current: page || 1,
              onChange: onShowChange,
              pageSize: perPage,
            }}
            scroll={{ x: 500 }}
          />
        </Card>
      </>
    );
  }
  