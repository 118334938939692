import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Tabs,
  Transfer,
  message,
} from "antd";
import React from "react";
import { useState } from "react";

export default function SelectTitle(props) {
  const [activeTabKey, setActiveTabKey] = useState("tab1");
  const [selectTitles, setSelectTitles] = useState([]);
  const { show, titleClose, list, total, sure, materialOptions } = props;

  const [form] = Form.useForm();
  const items = [
    {
      key: "tab1",
      label: "标题库",
    },
  ];

  // tab切换
  const tabChange = (key) => {
    setActiveTabKey(key);
  };

  // 关闭弹窗
  const close = () => {
    titleClose();
  };

  // 筛选表单提交
  const onFormSubmit = (values) => {
    props.onSearch(values);
  };

  // 处理穿梭框切换
  const handleChange = (newTargetKeys, direction, moveKeys) => {
    props.setTarget(newTargetKeys);
    let titles = [];
    list.map((item) => {
      if (newTargetKeys.includes(item.id)) {
        titles.push({ id: item.id, title: item.title });
      }
    });
    setSelectTitles(titles);
  };

  const onSubmit = () => {
    if (selectTitles.length === 0) {
      message.error("请选择素材标题", 2);
      return false;
    }
    sure(selectTitles);
    close();
  };

  return (
    <Modal
      open={show}
      onCancel={close}
      maskClosable={false}
      closable={false}
      width={850}
      onOk={onSubmit}
      title={
        <Tabs items={items} onChange={tabChange} activeKey={activeTabKey} />
      }
      destroyOnClose={true}
    >
      <Card
        title={
          <Form layout="inline" onFinish={onFormSubmit} form={form}>
            <Form.Item label="标题分类" name="category">
              <Select
                options={materialOptions}
                allowClear
                style={{ width: 180 }}
                placeholder="筛选标题分类"
                // onChange={optionsChange}
              />
            </Form.Item>
            <Form.Item label="标签" name="label">
              <Input allowClear placeholder="输入标签" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
                <Button onClick={() => form.resetFields()}>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
        headStyle={{ overflowX: "auto" }}
      >
        <Transfer
          dataSource={list}
          showSearch
          listStyle={{ width: 360, height: 450 }}
          targetKeys={props.targetKeys}
          render={(item) => `${item.title}`}
          onChange={handleChange}
          pagination={{
            pageSize: 50,
            total,
          }}
        />
      </Card>
    </Modal>
  );
}
