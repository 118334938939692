import { DatePicker, Form, InputNumber, Radio, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { MSchedule } from "@bytedance-ad/mui-react";

const { Text } = Typography;
const { RangePicker } = DatePicker;

export default function Index(props) {
  // 排期与预算默认值
  const [budgetValue, setBudgetValue] = useState({
    pricing: "PRICING_OCPM",
    budget_mode: "BUDGET_MODE_DAY",
    budget_optimize_switch: "OFF",
  });

  const [mScheduleValue, setMScheduleValue] = useState({
    schedule_time: "",
  });

  const receiveMSchedule = (value) => {
    props.getScheduleFormData({
      ...budgetValue,
      schedule_time: value?.schedule_time,
    });
    // 不能缺少这一步
    setMScheduleValue(value);
    setBudgetValue({ ...budgetValue, schedule_time: value?.schedule_time });
  };

  // 日期范围筛选限制
  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  // 投放时间切换
  const scheduleTypeChange = (e) => {
    let value = e.target.value;
    props.getScheduleFormData({ ...budgetValue, schedule_type: value });
    setBudgetValue({ ...budgetValue, schedule_type: value });
  };

  // 日期切换
  const rangeDateChange = (values) => {
    let rangeDate;
    if (values) {
      rangeDate = values.map((date) => {
        return date.format("YYYYMMDD");
      });
      props.getScheduleFormData({
        ...budgetValue,
        start_time: rangeDate[0],
        end_time: rangeDate[1],
      });
      setBudgetValue({
        ...budgetValue,
        start_time: rangeDate[0],
        end_time: rangeDate[1],
      });
    }
  };

  // 投放时段切换
  const scheduleTimeChange = (e) => {
    let value = e.target.value;
    props.getScheduleFormData({ ...budgetValue, schedule_frame: value });
    setBudgetValue({ ...budgetValue, schedule_frame: value });
  };

  // 竞价策略切换
  const bidTypeChange = (e) => {
    let value = e.target.value;
    let params = { ...budgetValue, bid_type: value };
    if (value === "NO_BID") {
      params.budget_optimize_switch = "ON";

    }
    props.getScheduleFormData(params);
    setBudgetValue(params);
  };

  // 投放速度切换
  const bidSpeedChange = (e) => {
    let value = e.target.value;
    props.getScheduleFormData({ ...budgetValue, bid_speed: value });
    setBudgetValue({ ...budgetValue, bid_speed: value });
  };

  // 是否开户择优
  const budgetSwitchChange = (e) => {
    let value = e.target.value;
    props.getScheduleFormData({
      ...budgetValue,
      budget_optimize_switch: value,
    });
    setBudgetValue({ ...budgetValue, budget_optimize_switch: value });
  };

  // 项目预算切换
  const budgetModeChange = (e) => {
    let value = e.target.value;
    props.getScheduleFormData({ ...budgetValue, budget_mode: value });
    setBudgetValue({ ...budgetValue, budget_mode: value });
  };

  // 预算切换
  const budgetChange = (value) => {
    props.getScheduleFormData({ ...budgetValue, project_budget: value });
    setBudgetValue({ ...budgetValue, project_budget: value });
  };

  // 项目出价是否显示
  const showBidCap = () => {
    let budgetShow = 1; // 显示出价
    if (
      (budgetValue?.bid_type === "NO_BID" || props?.value?.bid_type === 'NO_BID') ||
      props?.externalAction === "AD_CONVERT_TYPE_ACTIVE" ||
      props?.delivery_mode === "MANUAL"
    ) {
      budgetShow = 0; // 不显示出价
    }
    return budgetShow;
  };

  return (
    <>
      <Form.Item
        label={
          <Text strong style={{ fontSize: 14 }}>
            投放时间
          </Text>
        }
      >
        <Radio.Group
          onChange={scheduleTypeChange}
          value={props?.value?.schedule_type}
          options={[
            { label: "从今天起长期投放", value: "SCHEDULE_FROM_NOW" },
            { label: "设置开始和结束日期", value: "SCHEDULE_START_END" },
          ]}
          optionType="button"
        />
        {props?.value?.schedule_type === "SCHEDULE_START_END" ? (
          <Form.Item style={{ marginTop: 20 }} name="range_date">
            <RangePicker
              style={{ width: 300 }}
              disabledDate={disabledDate}
              onChange={rangeDateChange}
              // value={ null }
            />
          </Form.Item>
        ) : (
          ""
        )}
      </Form.Item>
      <Form.Item
        label={
          <Text strong style={{ fontSize: 14 }}>
            投放时段
          </Text>
        }
      >
        <Radio.Group
          onChange={scheduleTimeChange}
          value={props?.value?.schedule_frame}
          options={[
            { label: "不限", value: "unlimited" },
            { label: "指定时间段", value: "assign" },
          ]}
          optionType="button"
        />
        {props?.value?.schedule_frame === "assign" ? (
          <Form.Item name="schedule_time">
            <MSchedule
              curValue={
                props?.value?.schedule_time
                  ? { schedule_time: props?.value?.schedule_time }
                  : mScheduleValue
              }
              receive={receiveMSchedule}
            />
          </Form.Item>
        ) : (
          ""
        )}
      </Form.Item>
      <Form.Item
        label={
          <Text strong style={{ fontSize: 14 }}>
            竞价策略
          </Text>
        }
        initialValue={props?.value?.bid_type || budgetValue.bid_type}
        name="bid_type"
      >
        <Radio.Group
          onChange={bidTypeChange}
          value={props?.value?.bid_type}
          options={[
            { label: "稳定成本", value: "CUSTOM" },
            { label: "控制成本", value: "UPPER_CONTROL" },
            { label: "最大转化", value: "NO_BID" },
          ]}
          optionType="button"
        />
      </Form.Item>
      {props?.value?.bid_type === "CUSTOM" ? (
        <Form.Item
          label={
            <Text strong style={{ fontSize: 14.5 }}>
              投放速度
            </Text>
          }
          initialValue={props?.value?.bid_speed}
          name="bid_speed"
        >
          <Radio.Group
            onChange={bidSpeedChange}
            value={props?.value?.bid_speed}
            options={[
              { label: "匀速", value: "BALANCE" },
              { label: "加速", value: "FAST" },
            ]}
            optionType="button"
          />
        </Form.Item>
      ) : (
        ""
      )}
      {props?.value?.bid_type === "NO_BID" ? (
        <Form.Item
          label={
            <Text strong style={{ fontSize: 14.5 }}>
              预算择优分配
            </Text>
          }
          initialValue={budgetValue.budget_optimize_switch}
          name="budget_optimize_switch"
        >
          <Radio.Group
            onChange={budgetSwitchChange}
            options={[
              { label: "不开启", value: "OFF" },
              { label: "开启", value: "ON" },
            ]}
            optionType="button"
          />
        </Form.Item>
      ) : (
        ""
      )}
      <Form.Item
        label={
          <Text strong style={{ fontSize: 14 }}>
            项目预算
          </Text>
        }
        name="budget_mode"
        initialValue={props?.value?.budget_mode}
      >
        <Radio.Group
          onChange={budgetModeChange}
          value={props?.value?.budget_mode}
          options={[
            { label: "不限", value: "BUDGET_MODE_INFINITE" },
            { label: "设置预算", value: "BUDGET_MODE_DAY" },
          ]}
          optionType="button"
        />
      </Form.Item>
      {props?.value?.budget_mode === "BUDGET_MODE_DAY" ? (
        <Form.Item
          label={
            <Text strong style={{ fontSize: 14 }}>
              日预算
            </Text>
          }
          name="project_budget"
          rules={[
            {
              required: true,
              message: `${
                (budgetValue.bid_type === "NO_BID" &&
                  budgetValue?.budget_optimize_switch === "OFF") ||
                (props?.value?.bid_type === "NO_BID" &&
                  props?.value?.budget_optimize_switch === "OFF")
                  ? "请输入预算, 不少于100元, 不超过9999999.99元"
                  : "请输入预算, 不少于300元, 不超过9999999.99元"
              }`,
            },
          ]}
        >
          <InputNumber
            addonAfter="元"
            style={{ width: 250 }}
            min={
              (budgetValue.bid_type === "NO_BID" &&
                budgetValue?.budget_optimize_switch === "OFF") ||
              (props?.value?.bid_type === "NO_BID" &&
                props?.value?.budget_optimize_switch === "OFF")
                ? 100
                : 300
            }
            max={9999999.99}
            value={budgetValue.budget}
            onChange={budgetChange}
            placeholder={
              (budgetValue.bid_type === "NO_BID" &&
                budgetValue?.budget_optimize_switch === "OFF") ||
              (props?.value?.bid_type === "NO_BID" &&
                props?.value?.budget_optimize_switch === "OFF")
                ? "取值范围: 100-9999999.99元"
                : "取值范围: 300-9999999.99元"
            }
            changeOnWheel={false}
          />
        </Form.Item>
      ) : (
        ""
      )}
      {showBidCap() === 1 ? (
        <Form.Item
          label={
            <Text strong style={{ fontSize: 14 }}>
              项目出价
            </Text>
          }
          name="project_cpa_bid"
          rules={[{ required: true, message: "设置项目出价" }]}
        >
          <InputNumber
            addonAfter="元"
            allowClear
            style={{ width: 250 }}
            min={0.01}
            max={10000}
            placeholder="取值范围: 0.01-10000元"
            changeOnWheel={false}
          />
        </Form.Item>
      ) : (
        ""
      )}
      {props.externalAction === "AD_CONVERT_TYPE_ACTIVE" &&
      props?.delivery_mode === "PROCEDURAL" ? (
        <Form.Item
          label={
            <Text strong style={{ fontSize: 14 }}>
              ROI系数
            </Text>
          }
          name="project_roi_goal"
          rules={[
            {
              required: true,
              message: "请输入ROI系数, 大于0, 不超过5",
            },
          ]}
        >
          <InputNumber
            min={0}
            max={5}
            placeholder="请输入"
            style={{ width: 250 }}
            changeOnWheel={false}
          />
        </Form.Item>
      ) : (
        ""
      )}
      <Form.Item
        label={
          <Text strong style={{ fontSize: 14 }}>
            付费方式
          </Text>
        }
        name="pricing"
        initialValue={budgetValue.pricing}
      >
        <Radio.Group
          options={[{ label: "按展示付费(oCPM)", value: "PRICING_OCPM" }]}
          optionType="button"
        />
      </Form.Item>
    </>
  );
}
