import { Card } from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";
import { virtualBackList } from "@/api/playlet";

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  // 列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await virtualBackList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setTotal(data?.total);
    }
    setLoading(false);
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ page, pageSize: size });
  };

  return (
    <Card>
      <Table
        dataSource={list}
        columns={columns()}
        total={total}
        loading={loading}
        page={page}
        perPage={perPage}
        pagesize={pagesize}
        pageOptions={[20]}
      />
    </Card>
  );
}
