import { Button, Card, DatePicker, Form, Input, Select, Space, Typography, Table } from "antd";
import React, { useEffect, useState } from "react";
import columns from "./columns";
import { getDailyPlayletAdData } from "../../../../api/statistics";
import { numDiv, numMulti } from "../../../../util/calculate";

const {Text} = Typography
const {RangePicker} = DatePicker

export default function Index(props) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    getList();
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getDailyPlayletAdData(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
    }
    setLoading(false);
  };

   // 表单查询提交
   const onSubmit = (values) => {
    let rangeDate;
    if (values?.range_date) {
      rangeDate = values.range_date.map((date) => {
        return date.format("YYYYMMDD");
      })
      values.range_date = rangeDate;
    }
    getList(values);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  return (
    <Card
      type="inner"
      title={
        <Form layout="inline" form={form} onFinish={onSubmit}>
          <Form.Item label="日期" name="range_date">
            <RangePicker style={{width: 230}} />
          </Form.Item>
          <Form.Item label="短剧" name="book_name">
            <Input allowClear placeholder="输入短剧" />
          </Form.Item>
          <Form.Item label="投手" name="admin_id">
            <Select
              placeholder="选择投手"
              allowClear
              showSearch
              filterOption={filterOption}
              style={{ width: 120 }}
              options={props?.admins || []}
            />
          </Form.Item>
          <Form.Item label colon={false}>
            <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button onClick={() => form.resetFields()}>重置</Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <div style={{ textAlign: "right", marginTop: -10 }}>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          注意：这里只统计在投放的广告，在审核中或已暂停等状态广告不作统计
        </Typography.Text>
      </div>
      <Table
        sticky
        dataSource={list}
        columns={columns()}
        loading={loading}
        pagination={false}
        bordered={true}
        scroll={{ x: 500 }}
        summary={(pageData) => {
          let totalAdCount = 0;
          let totalAccountCount = 0;
          let totalActiveAdCount = 0;
          let totalActiveAccountCount = 0;
          let totalCost = 0;
          let totalMoney = 0;
          pageData.forEach(
            ({
              ad_count,
              account_count,
              active_ad_count,
              active_account_count,
              cost,
              money,
            }) => {
              totalAdCount += ad_count;
              totalAccountCount += account_count;
              totalActiveAdCount += active_ad_count;
              totalActiveAccountCount += active_account_count;
              totalCost += parseFloat(cost);
              totalMoney += parseFloat(money);
            }
          );
          return (
            <>
              {pageData && pageData.length > 0 ? (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={3} align="center">
                    <Text type="danger" strong={true}>
                      汇总
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <Text>{totalAdCount}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <Text>{totalAccountCount}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    <Text>{totalActiveAdCount}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    <Text>{totalActiveAccountCount}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    <Text>{totalCost?.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center">
                    <Text>{totalMoney?.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center">
                    <Text>{totalCost ? numMulti(numDiv(totalMoney, totalCost), 100).toFixed(2) + '%' : '0.00%'}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              ) : (
                ""
              )}
            </>
          );
        }}
      />
    </Card>
  );
}
