import React, { useState, useEffect, useRef } from "react"
import { Form, Input } from "antd"
import "./editableCell.less"
export default function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  field,
  handleSave,
  ...restProps
}) {
  const [form] = Form.useForm()
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = (e) => {
    let value = e.target.value
    toggleEdit()
    record[field] = value
    handleSave({ ...record })
    form.resetFields()
  }

  let childNode = children
  if (editable) {
    childNode = editing ? (
      <Form form={form}>
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}
