import { useEffect, useState } from "react";
import { filterPitchers } from "@/api/statistics";
import { useLocation } from "react-router-dom";

const useOptions = ({ searchForm }) => {
  const { state } = useLocation();

  // 用户列表
  const [userList, setUserList] = useState([]);

  // 设置默认选中的用户
  const setDefaultUser = () => {
    if (state?.admin_id) {
      // 如果 url 有 admin_id, 就设置默认选中的用户
      searchForm.setFieldValue("adminId", state?.admin_id);
    } else {
      // 找 localStorage 里的用户信息, 设置默认选中的用户
      const is_super = localStorage.getItem("is_super") === "true";
      const nicknameId = localStorage.getItem("nicknameId");
      !is_super && searchForm.setFieldValue("adminId", Number(nicknameId));
    }
  };

  // 获取用户列表
  const getUserList = async () => {
    console.log('fenge start');
    const res = await filterPitchers({
      type: "playlet",
      unLimit: true,
    });

    // 列表
    let list = res?.data?.lists || [];
    list = list.map(item => {
      return { label: item.nickname, value: item.id };
    });

    // 存列表
    setUserList(list);

    // 如果列表有数据, 就设置默认选中的用户
    list.length > 0 && setDefaultUser();
  };

  useEffect(() => {
    getUserList().catch(() => {
      console.log("获取用户列表失败");
    });
    // eslint-disable-next-line
  }, []);

  return { userList };
};

export default useOptions;
