import { Card } from 'antd'
import React from 'react'
import { useState } from 'react';
import Monitor from './monitor'
import PromotionLoss from './promotionloss'
import { useLocation, useNavigate } from 'react-router';

export default function Index() {
    const [activeTabKey, setActiveTabKey] = useState('tab1');
    const [conditions, setConditions] = useState([])
    const { state } = useLocation()
    const navigate = useNavigate()

    // 当前的路径
    const pathKey = useLocation().pathname

    const tabList = [
        {
            key: 'tab1',
            tab: '项目没填监测',
        },
        {
            key: 'tab2',
            tab: '订单没关联推广id(检查推广链接后台添加)',
        },
        // {
        //     key: 'tab3',
        //     tab: '素材',
        // },
    ];

    // const switchover = (record, tab) => {
    //     setActiveTabKey(tab)
    //     localStorage.setItem('record', JSON.stringify(record))
    //     setConditions(record)
    //     navigate(pathKey)
    // }

    // 用于保存搜索的条件
    const onSearch = values => {
        setConditions(values)
    }

    const contentList = {
        // tab1: <Advertise switchover={switchover} admin_id={state?.admin_id} onSearch={onSearch} conditions={conditions}  />,
        // tab2: <Promotion switchover={switchover} admin_id={state?.admin_id} onSearch={onSearch} conditions={conditions} />,
        // tab3: <Material  admin_id={state?.admin_id} onSearch={onSearch} conditions={conditions}/>,
        tab1: <Monitor onSearch={onSearch} conditions={conditions}></Monitor>,
        tab2: <PromotionLoss></PromotionLoss>,
    };

    const onTabChange = key => {
        setActiveTabKey(key)
        localStorage.removeItem('record')
        navigate(pathKey, { state: null })
    }

    return (
        <Card
            size="small"
            tabList={tabList}
            onTabChange={onTabChange}
            activeTabKey={state ? state?.tab : activeTabKey}
            tabProps={{ size: 'small' }}
            bodyStyle={{ padding: 0 }}
        >
            {
                state ? contentList[state?.tab]
                    : contentList[activeTabKey]
            }

        </Card>
    )
}
