import { Card, Input, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import {bookMulPayStatistics} from '../../../api/statistics'

const { Search } = Input;
const { Option } = Select

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState(1);
  const [conditions, setConditions] = useState([])

  useEffect(() => {
    getList()
  }, [])

  // 获取列表记录
  const getList = async(params = {}) => {
    setLoading(true)
    const res = await bookMulPayStatistics(params)
    if (res?.code === 200) {
        const {data} = res
        setList(data?.lists)
        setTotal(data?.total)
        setLoading(false)
    }
  }

  const onChange = (value) => {
    setSearchType(value);
  };

  const selectBefore = (
    <Select defaultValue={1} onChange={onChange} style={{width: 100}}>
      <Option value={1}>小说ID</Option>
      <Option value={2}>小说名称</Option>
    </Select>
  );

  // 关键词查询
  const onSearch = value => {
    if (searchType === 1) {
        setConditions({book_id: value})
        getList({book_id: value})
    } else {
        setConditions({title: value})
        getList({title: value})
    }
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPerPage(size)
  }

  // 排序
  const handleSorter = (pagination, filter, sorter) => {
    let arrKeys = Object.keys(sorter)
    let order = ''
    if  (arrKeys.length > 0) {
        let field = sorter.field
        let orderType = sorter.order === 'ascend' ? 'asc' : 'desc'
        order = `${field}_${orderType}`
        setConditions({order})
    } 
    getList({page: pagination.current, pageSize: pagination.pageSize, order, ...conditions})
    
  }

  return (
    <>
      <Typography.Text strong>小说多充统计</Typography.Text>
      <Card
        style={{ marginTop: 10 }}
        title={
          <Search
            placeholder={searchType === 1 ? "输入小说ID" : "输入小说名称"}
            allowClear
            addonBefore={selectBefore}
            style={{width: 350}}
            onSearch={onSearch}
          />
        }
      >
        <Table
          dataSource={list}
          columns={columns()}
          total={total}
          page={page}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
          onChange={handleSorter}
        />
      </Card>
    </>
  );
}
