import React from 'react'
import PromotionComponent from '../Public/Promotion/index'
import { useState } from 'react'
import { useEffect } from 'react'
import { getAllPlaylet, getPlayletPromotion, playletBackSetSave, playletPromotionAdd, playletPromotionStatusEdit } from '../../../api/playlet'
import { message } from 'antd'

export default function Promotion() {
  const [show, setShow] = useState(false)
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [backShow, setBackShow] = useState(false)
  const [playlets, setPlaylets] = useState([])
  const [statusLoading, setStatusLoading] = useState(0)

  useEffect(() => {
    getList()
    getPlaylet()
  }, [])

  // 获取列表数据
  const getList = async (params = {}) => {
    const res = await getPlayletPromotion({ ...params, app_type: 1007 })
    const { data } = res
    if (res?.code === 200) {
      let list = data?.lists
      let newList = list.map((item) => {
        item.id += '@@##' + item?.promotion_id
        return item
      })
      setList(newList)
      setTotal(data?.total)
      setLoading(false)
    }
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPageSize(size)
    getList({ page, pageSize: size })
  }

  // 添加弹窗
  const addPromotion = () => {
    setShow(true)
  }

  // 关闭弹窗
  const onClose = () => {
    setShow(false)
    setConfirmLoading(false)
  }

  // 打开卡回传弹窗
  const backSet = async record => {
    setBackShow(true)
  }

  // 关闭卡回传弹窗
  const onSetClose = () => {
    setBackShow(false)
    setConfirmLoading(false)
  }

  // 添加推广链接
  const handleAddPromotion = async (params) => {
    setConfirmLoading(true)
    const res = await playletPromotionAdd({...params, app_type: 1007})
    if (res?.code === 200) {
      message.success('推广链接添加成功', 2)
      setTimeout(() => {
        onClose()
        getList()
      }, 1000);
    } else {
      setConfirmLoading(false)
    }
  }

  // 获取自研短剧-所有剧场
  const getPlaylet = async () => {
    const res = await getAllPlaylet({app_type: 1007})
    if (res?.code === 200) {
      const {data} = res
      const lists = data.lists.map((item) => {
        return {label: item.aliasname, value: item.app_type}
      })
      setPlaylets(lists)
    }
  }

  // 添加卡回传
  const handleBackSet = async (params) => {
    setConfirmLoading(true)
    const res = await playletBackSetSave({...params, app_type: 1007})
    if (res?.code === 200) {
      message.success('卡回传设置成功', 2)
      setTimeout(() => {
        onSetClose()
        // getList()
      }, 1000);
    } else {
      setConfirmLoading(false)
    }
  }

  // 条件筛选查询
  const onSearch = (params) => {
    getList(params)
  }

  // 删除推广链接
  const handleDeletePromotion = async () => {
    // 删除成功后重新获取列表记录
    getList()
  }

  // 状态修改
  const promotionStatusEdit = async ({id, status}) => {
    setStatusLoading(id)
    const res = await playletPromotionStatusEdit({id, status, app_type: 1007})
    if (res?.code === 200) {
      message.success('状态更改成功')
      setStatusLoading(0)
    }
  }
  
  // 传到子组件的参数
  const propsData = {
    list, total, page, loading,
    pagesize, perPage:pageSize,
    addPromotion, onClose, show,
    handleAddPromotion, confirmLoading,
    backShow, onSetClose, backSet, handleBackSet,
    app_type: 1007, handleDeletePromotion, playlets,
    category: 4, onSearch, promotionStatusEdit,
    statusLoading
  }

  return (
    <div className='yingtan_promotion'>
        <PromotionComponent {...propsData} />
    </div>
  )
}
