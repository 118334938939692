import React from "react";

import { Modal, Form, Select, DatePicker } from "antd";

const ruleMapper = {
  // 用户
  adminId: [{ required: true, message: "请选择用户" }], // 时间段
  rangeDate: [{ required: true, message: "请选择时间段" }],
};

const LeaveModal = props => {
  const { visible, confirmLoading, onOk, onCancel, form, options, isEdit } =
    props;

  const title = isEdit ? "编辑请假" : "新增请假";

  return (
    <Modal
      open={visible}
      okButtonProps={{
        loading: confirmLoading,
      }}
      onOk={onOk}
      onCancel={onCancel}
      title={title}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="选择用户" name="adminId" rules={ruleMapper.adminId}>
          <Select
            allowClear
            showSearch
            options={options?.userList || []}
            placeholder="请选择用户"
            style={{ width: 150 }}
          />
        </Form.Item>
        <Form.Item label="请假时间段" name="rangeDate" rules={ruleMapper.rangeDate}>
          <DatePicker.RangePicker
            showTime={false}
            format="YYYY-MM-DD"
            placeholder={["开始时间", "结束时间"]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(LeaveModal);
