import React, { useEffect, useState } from "react";
import { Card } from "antd";
import EcpmPromotion from "./List/promotion";
import EcpmBook from "./List/book";
import { getIaaPlaylet } from "../../../api/statistics";
import { getFilterPitchers } from "../../../api/permission";

export default function Index() {
  const [activeTabKey, setActiveTabKey] = useState(1);
  const [bookOptions, setBookOptions] = useState([])
  const [adminOptions, setAdminOptions] = useState([])

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabList = [
    { key: 1, tab: "推广链接" },
    { key: 2, tab: "小说收益" },
  ];

  const contentList = {
    1: <EcpmPromotion books={bookOptions} pitchers={adminOptions} />,
    2: <EcpmBook books={bookOptions} pitchers={adminOptions} />,
  };

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  // 获取投手及小说的筛选数据
  const getData = () => {
    Promise.all([getPitchers(), getBooks()])
  }

  // 获取筛选的投手信息
  const getPitchers = async () => {
    const res = await getFilterPitchers({ type: "novel" });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setAdminOptions(newList);
    }
  };

  // 获取小说数据
  const getBooks = async () => {
    const res = await getIaaPlaylet()
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.book_name, value: item.book_id };
      });
      setBookOptions(newLists);
    }
  }

  return (
    <>
      <Card
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        size="small"
      >
        {contentList[activeTabKey]}
      </Card>
    </>
  );
}
