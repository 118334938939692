import { Card, Form, InputNumber, Space, Button } from "antd";

import styles from "./index.module.less";

// 出价计算
const BidCalc = () => {
  // 表单
  const [formData] = Form.useForm();

  // 校验
  const requiredRule = [{ required: true, message: "请填写" }];

  // 计算逻辑
  const calcValues = (values = {}) => {
    let { achievingRate, achievedAverage, nonAchieved, firstDay } = values;

    achievingRate = achievingRate / 100;
    nonAchieved = nonAchieved / 100;
    // 计算全量激活用户平均 arpu
    const fullDose =
      achievingRate * achievedAverage +
      achievedAverage * nonAchieved * (1 - achievingRate);

    // 计算激活成本
    const activationCost = fullDose / firstDay;
    // 计算转化成本
    const conversionCost = activationCost / achievingRate;

    return {
      fullDose,
      activationCost,
      conversionCost,
    };
  };

  //
  const onSubmit = values => {
    const calculated = calcValues(values);
    formData.setFieldsValue({
      ...values,
      ...calculated,
    });
  };

  return (
    <Card size="small" title="出价计算" className={styles["bid-calc-wrapper"]}>
      <Form
        onFinish={onSubmit}
        form={formData}
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 6 }}
      >
        <Form.Item
          label="关键行为达成率"
          name="achievingRate"
          rules={requiredRule}
        >
          <InputNumber min={0} max={100} suffix="%" precision={2} />
        </Form.Item>
        <Form.Item
          label="达成用户平均arpu"
          name="achievedAverage"
          rules={requiredRule}
        >
          <InputNumber min={0} precision={2} />
        </Form.Item>
        <Form.Item
          label="非达成用户平均arpu为达成用户的x%"
          name="nonAchieved"
          tooltip="除达成用户外，非达成用户也会贡献收益，但平均arpu比达成用户低，通常经验值在30%-35%"
          rules={requiredRule}
        >
          <InputNumber min={0} suffix="%" precision={2} />
        </Form.Item>

        <Form.Item label="全量激活用户平均arpu" name="fullDose">
          <InputNumber
            min={0}
            disabled
            style={{ color: "rgba(0, 0, 0, 0.88)" }}
            precision={2}
          />
        </Form.Item>

        <Form.Item
          label="考核首日ROI"
          name="firstDay"
          tooltip="根据业务情况设定考核"
          rules={requiredRule}
        >
          <InputNumber min={0} precision={2} />
        </Form.Item>

        <Form.Item
          label="激活成本"
          name="activationCost"
          tooltip="激活用户平均ARPU/首日ROI"
        >
          <InputNumber
            min={0}
            disabled
            style={{ color: "rgba(0, 0, 0, 0.88)" }}
            precision={2}
          />
        </Form.Item>
        <Form.Item
          label="转化成本"
          name="conversionCost"
          tooltip="出价参考，计算为：激活成本/达成率"
        >
          <InputNumber
            min={0}
            disabled
            style={{ color: "rgba(0, 0, 0, 0.88)" }}
            precision={2}
          />
        </Form.Item>

        <Form.Item label colon={false}>
          <Space>
            <Button type="primary" htmlType="submit">
              计算
            </Button>
            <Button type="default" htmlType="reset">
              重置
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default BidCalc;
