import { Card, DatePicker, Divider, Modal } from "antd";
import React from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";

const { RangePicker } = DatePicker;

export default function Index(props) {
  const { show, onClose, list, loading, onSearch } = props;

  // 日期范围筛选查询
  const dateChange = values => {
    let rangeDate
    if (values) {
      rangeDate = values.map((date) => {
        return date.format('YYYYMMDD')
      })
    }
    onSearch({range_date: rangeDate}, 'read')
  }

  return (
    <Modal
      title="用户阅读记录"
      open={show}
      onCancel={onClose}
      width={700}
      footer={null}
      maskClosable={false}
      style={{height: 650, overflowY: 'auto'}}
      destroyOnClose={true}
    >
      <Divider />
      <Card title={<RangePicker onChange={dateChange} />}>
        <Table
          dataSource={list}
          columns={columns()}
          loading={loading}
          pagination={false}
        />
      </Card>
    </Modal>
  );
}
