import { Card } from "antd";
import React from "react";

export default function Index() {
  return (
    <>
      <Card
        tabList={[{ key: 1, tab: "字节小程序" }]}
        activeTabKey={1}
      ></Card>
    </>
  );
}
