import { numMulti } from "../../../util/calculate";

const columns = () => [
  {
    title: "小说ID",
    width: 90,
    align: "center",
    dataIndex: "bid",
    key: "bid",
  },
  {
    title: "小说名称",
    width: 110,
    align: "center",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "首充(人数)",
    width: 110,
    align: "center",
    dataIndex: "first_pay",
    key: "first_pay",
    sorter: {},
  },
  {
    title: "二充(人数)",
    width: 110,
    align: "center",
    dataIndex: "second_pay",
    key: "second_pay",
  },
  {
    title: "二充率",
    width: 110,
    align: "center",
    dataIndex: "second_rate",
    key: "second_rate",
    sorter: {},
    render: value => (
      value ? numMulti(value, 100).toFixed(2) + '%' : 0
    )
  },
  {
    title: "三充及以上(人数)",
    width: 120,
    align: "center",
    dataIndex: "thirth_pay",
    key: "thirth_pay",
  },
  {
    title: "多充率",
    width: 120,
    align: "center",
    dataIndex: "thirth_rate",
    key: "thirth_rate",
    sorter: {},
    render: value => (
      value ? numMulti(value, 100).toFixed(2) + '%' : 0
    )
  },
  {
    title: "VIP充值(人数)",
    width: 120,
    align: "center",
    dataIndex: "vip_pay",
    key: "vip_pay",
  },
  {
    title: "VIP充值率",
    width: 120,
    align: "center",
    dataIndex: "vip_rate",
    key: "vip_rate",
    sorter: {},
    render: value => (
      value ? numMulti(value, 100).toFixed(2) + '%' : 0
    )
  },
];

export default columns
