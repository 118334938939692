/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getRoleOrgan } from "../api/permission";

const useTableList = (api, params) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    getList();
  }, [params]);

  // 获取列表记录
  const getList = async () => {
    setLoading(true);

    if (params.isGetRole) {
      const org = await getRoleOrgan();
      if (org?.code === 200) {
        const { data } = org;
        params.agentId = data?.agent_id === 0 ? 13 : data?.agent_id;
      }
      delete params.isGetRole;
    }

    try {
      const res = await api(params);
      if (res?.code === 200) {
        const { data = {} } = res;
        setData(data)
      }
    } catch (err) {
      console.log(err, "errerrerr");
    } finally {
      setLoading(false);
    };
  }; 

  return {
    data,
    loading,
  }
}

export default useTableList;
