import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  List,
  Select,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  advertiseBidLimitSet,
  getAccountList,
  getGuanList,
} from "../../../../api/advertise";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Index() {
  const [list, setList] = useState([]);
  const [guanOptions, setGuanOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [conditions, setConditions] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [isChecked, setIsChecked] = useState(false)
  const [isCheckAll, setIsCheckAll] = useState(false);

  useEffect(() => {
    getGuanData();
  }, []);

  const [form] = Form.useForm();

  // 获取管家列表
  const getGuanData = async (params = {}) => {
    const res = await getGuanList(params);
    if (res?.code === 200) {
      const { data } = res;
      let newData = data?.map((item) => {
        return { label: item?.account_name, value: item?.pid };
      });
      setGuanOptions(newData);
    }
  };

  const fetchData = async (params = {}) => {
    return await getAccountList({ ...params });
  };

  // 获取广告账户列表
  const getList = (params = {}) => {
    setListLoading(true);
    // 返回promise对象
    const p = fetchData(params);
    p.then((res) => {
      const { data } = res;
      if (res?.code === 200) {
        setList(data?.lists);
        setTotal(data?.total);
        setListLoading(false);
      }
    });
  };

  // 获取加载更多
  const loadMore = () => {
    let p1 = page + 1;
    const p = fetchData({ ...conditions, page: p1 });
    p.then((res) => {
      const { data } = res;
      if (res?.code === 200) {
        let newList = [...list, ...data?.lists]
        setList([...list, ...data?.lists]);
        if (isCheckAll) {
          let newSelected = newList.map((item) => {
            return item.account_id
          })
          setSelectedList(newSelected)
        }
      }
    });
    setPage(p1);
  };

  // 查询表单提交
  const onSubmit = (values) => {
    if (!values["pid"] && !values['account_name']) {
      message.error("请至少筛选管家或查询关键词");
      return false;
    }
    setIsCheckAll(false)
    setIsChecked(false)
    setConditions(values);
    setSelectedList([]);
    getList(values);
  };

  // 批量提交设置最高出价
  const batchSet = () => {
    form.validateFields().then(async () => {
      setListLoading(true);
      let values = form.getFieldsValue();
      if (selectedList.length === 0) {
        message.error("请选择批量设置的广告账户");
        return false;
      }
      if (conditions?.pid) {
        values.pid = conditions?.pid;
      }
      if (conditions?.account_name) {
        values.account_name = conditions?.account_name
      }
      values.account_ids = selectedList;
      values.is_checked_all = isCheckAll
      const res = await advertiseBidLimitSet(values);
      if (res?.code === 200) {
        form.resetFields();
        message.success("最高出价限制设置成功");
        let newList = list.map((item) => {
          if (selectedList.includes(item.account_id)) {
            item.bid_limit = values.bid_limit;
          }
          return item;
        });
        setSelectedList([]);
        setList(newList);
        setListLoading(false);
      }
    });
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 全选或全不选
  const selectChange = (e) => {
    let checked = e.target.checked;
    setIsChecked(checked)
    if (checked) {
      let accountIds = list.map((item) => {
        return item.account_id;
      });
      setSelectedList(accountIds);
    } else {
      setSelectedList([]);
    }
  };

  // 单条账户选中或不选
  const itemSelectChange = (e, accountId) => {
    let checked = e.target.checked;
    if (!checked) {
      let newList = selectedList.filter((value) => {
        return value !== accountId;
      });
      setSelectedList(newList);
    } else {
      setSelectedList([...selectedList, accountId]);
    }
  };

  // 所中所有
  const checkALL = () => {
    if (list.length === 0) {
      message.error("先获取账户列表");
      return false;
    }
    setIsCheckAll(true);
    let accountIds = list.map((item) => {
      return item.account_id;
    });
    setSelectedList(accountIds);
  };

  // 取消全选
  const cancelCheckAll = () => {
    setIsCheckAll(false);
    setIsChecked(false)
    setSelectedList([]);
  };

  return (
    <Card
      title={
        <Form layout="inline" onFinish={onSubmit}>
          <Form.Item label="管家账户" name="pid">
            <Select
              allowClear
              showSearch
              placeholder="请选择管家"
              options={guanOptions}
              style={{ width: 250 }}
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item label="关键词搜索" name="account_name">
            <Input allowClear />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      }
    >
      <div style={{ display: "flex", width: "100%" }}>
        <Space style={{marginLeft: 16}}>
          <Checkbox
            onChange={selectChange}
            disabled={list.length === 0}
            checked={isCheckAll || isChecked}
          />
          <>
            共<span style={{ fontWeight: "bold" }}>{total}</span>条记录, 已选中:
            <span style={{ color: "red", fontWeight: "bold", }}>
              {isCheckAll ? total : selectedList.length}
            </span>
            {isCheckAll ? (
              <Button type="link" onClick={cancelCheckAll}>
                取消全选
              </Button>
            ) : (
              <Button type="link" onClick={checkALL}>
                全选
              </Button>
            )}
          </>
        </Space>
        {selectedList.length > 0 ? (
          <div style={{marginLeft: 200}}>
            <Form layout="inline" form={form}>
              <Form.Item
                label="批量最高出价设置"
                name="bid_limit"
                rules={[{ required: true }]}
              >
                <Input size="small" />
              </Form.Item>
              <Form.Item>
                <Button onClick={batchSet} size="small">
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        id="scrollableDiv"
        style={{
          paddingTop: 10,
          height: 400,
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <InfiniteScroll
          dataLength={list.length}
          next={loadMore}
          hasMore={list.length > 10}
          // endMessage={<Divider plain>已全部加载完成 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={list}
            loading={listLoading}
            renderItem={(item) => (
              <List.Item key={item.account_id}>
                <div style={{ display: "flex", width: "90%" }}>
                  <div style={{ width: "60%" }}>
                    <Space>
                      <Checkbox
                        checked={selectedList.includes(item.account_id)}
                        onChange={(e) => itemSelectChange(e, item.account_id)}
                      />
                      <div>
                        {item.account_name} 【{item.account_id}】
                      </div>
                    </Space>
                  </div>
                  <div>
                    最高出价限制:{" "}
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      {item?.bid_limit}
                    </span>
                  </div>
                </div>
              </List.Item>
            )}
          ></List>
        </InfiniteScroll>
      </div>
    </Card>
  );
}
