import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  Table,
  Typography,
  Modal,
  Divider,
  InputNumber,
  Tag,
} from "antd";
import React from "react";
import { useState } from "react";
import playletColumns from "./playletColumns";
import novelColumns from "./novelColumns";
import adLogColumns from "./adLogColumns";
import { useEffect } from "react";
import {
  accountPlanSummary,
  adRemarkEdit,
  costPromotionTimeDivision,
  filterPitchers,
  recycleAccountPlan,
  getLogList,
} from "../../../../api/statistics";
import { useLocation, useNavigate } from "react-router-dom";
import {
  handleDeletePlan,
  handleUpdatePlanStatus,
  saveBidData,
  getBidUpdateNum,
} from "../../../../api/advertise";
import { connect } from "react-redux";
import { saveMenu } from "../../../../redux/actions/menu";
import ChartDualAxes from "../../../../components/ChartDualAxes";
import EditableCell from "../../../../components/Editable/EditableCell";

const { RangePicker } = DatePicker;
const { Text } = Typography;

function Index(props) {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [accountSummary, setAccountSummary] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [divisionCost, setDivisionCost] = useState([]);
  const [newBid, setNewBid] = useState(false); //新出价
  const [bidNum, setBidNum] = useState(0); //出价修改次数
  const [logList, setLogList] = useState([]); //日志
  const [logModalShow, setLogModalShow] = useState(false); //日志弹窗

  // 当前路径
  const pathKey = useLocation().pathname;

  const { state } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  let promotion_id = props?.promotionId || null
  let userId = props?.user_id || null
  if (promotion_id) {
    form.setFieldsValue({ promotion_id });
  }
  if (userId) {
    form.setFieldsValue({ admin_id: parseInt(userId) });
  }

  useEffect(() => {
    getPitcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getList();
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotion_id])

  
  // 获取列表信息
  const getList = async (params = {}) => {
    setLoading(true);
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      params.type = "playlet";
    }
    const res = await recycleAccountPlan({
      admin_id: props?.admin_id || props?.conditions?.admin_id || userId || null,
      advertiser:
        props?.conditions?.keyword || props?.conditions?.advertiser_id,
      advertiser_name: state?.advertise?.account_name,
      promotion_id,
      ...params
    });
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 处理编辑备注
  const handleSave = async (row) => {
    if (!row.remark) {
      return false;
    }
    setLoading(true);
    const newData = [...list];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    const res = await adRemarkEdit({ id: row.id, remark: row.remark });
    if (res?.code === 200) {
      setList(newData);
    }
    setLoading(false);
  };

  const components = {
    body: {
      // row: EditableRow,
      cell: EditableCell,
    },
  };

  const getColumns = ({
    updatePlanStatus,
    deletePlan,
    switchover,
    showTimeDivision,
  }) => {
    let columns;
    if (pathKey.split("/").includes("novel")) {
      columns = novelColumns({
        updatePlanStatus,
        deletePlan,
        switchover,
        showTimeDivision,
      });
    } else if (pathKey.split("/").includes("theater")) {
      let newColumns = playletColumns({
        updatePlanStatus,
        deletePlan,
        switchover,
        showTimeDivision,
        updateBidNum, //出价修改次数
        editBid, //修改出价界面
        saveBid, //提交出价
        showOperateLog, //操作日志
      });

      columns = newColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            field: "remark",
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      });
    }
    return columns;
  };

  // 获取列表数据汇总
  const getSummary = async (params = {}) => {
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      params.type = "playlet";
    }
    const res = await accountPlanSummary({
      admin_id: props?.admin_id || props?.conditions?.admin_id || props.user_id || null,
      advertiser:
        props?.conditions?.keyword || props?.conditions?.advertiser_id,
      advertiser_name: state?.advertise?.account_name,
      promotion_id,
      ...params,
    });
    if (res?.code === 200) {
      const {
        data: { summary },
      } = res;
      setAccountSummary(summary);
    }
  };

  // 获取筛选的投手信息
  const getPitcher = async (params = {}) => {
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      if (pathKey.split("/").includes("toutiao_agtplt")) {
        params.type = "explaylet";
      } else {
        params.type = "playlet";
      }
    }
    const res = await filterPitchers(params);
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(newList);
    }
  };

  // 分页
  const onShowChange = (page, size) => {
    setPage(page);
    setPerPage(size);
    let params = { ...conditions, page, pageSize: size };
    setConditions(params);
    getList(params);
  };

  // 条件筛选查询
  const onSearch = (values) => {
    let createdAt;
    let params = { ...values };
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
    }
    params.range_date = createdAt;
    params.advertiser = values.keyword;
    setConditions(params);
    props.onSearch(params);
    getList(params);
    getSummary(params);
    setPage(1);
    setPerPage(10);
  };

  // 重置
  const formReset = () => {
    form.resetFields();
    props.onSearch([]);
  };

  // 计划状态修改
  const updatePlanStatus = async (record, status) => {
    let params = {
      advertiser_id: record?.advertiser_id,
      ad_id: record?.ad_id,
      status,
    };
    const res = await handleUpdatePlanStatus(params);
    if (res?.code) {
      message.success("状态修改成功", 1);
      getList({ ...conditions });
    }
  };

  // 删除计划
  const deletePlan = async (record) => {
    let params = { advertiser_id: record?.advertier_id, ad_id: record?.ad_id };
    const res = await handleDeletePlan(params);
    if (res?.code) {
      message.success("头条计划已删除", 1);
      getList({ ...conditions });
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  const switchover = (record, tab) => {
    props.switchover(record, tab);
  };

  // 弹窗显示分时曲线
  const showTimeDivision = async (record) => {
    setConfirmLoading(true);
    setModalShow(true);
    const res = await costPromotionTimeDivision({
      advertiser_id: record?.advertiser_id,
      ...conditions,
      ad_id: record?.ad_id,
    });
    if (res?.code === 200) {
      const { data } = res;
      setDivisionCost(data);
      setConfirmLoading(false);
    }
  };

  const modalClose = () => {
    setModalShow(false);
  };

  // 消耗 、 转化数排序
  const onTableChange = (pagination, filter, sorter) => {
    let field = sorter.field;
    let orderType = sorter.order === "ascend" ? "asc" : "desc";
    let order = `${field}_${orderType}`;
    if (field !== undefined) {
      getList({ ...conditions, order });
    }
  };

  const updateBidNum = async (record) => {
    //验证当前次数
    const result = await getBidUpdateNum({ ad_id: record?.ad_id });
    let hasNum = 0;
    if (result?.code === 200) {
      hasNum = result?.data?.num;
    }
    setBidNum(hasNum);
  };
  //修改出价
  const editBid = (record) => {
    return (
      <>
        <card>
          <InputNumber min={1} max={500} onChange={changeBid} />
          <br />
          <Tag color="red">修改次数:{bidNum}</Tag>
        </card>
      </>
    );
  };
  //修改出价回调
  const changeBid = async (value) => {
    setNewBid(value);
  };
  //出价修改提交
  const saveBid = async (record) => {
    const res = await saveBidData({
      advertiser_id: record?.advertiser_id,
      ad_id: record?.ad_id,
      new_bid: newBid,
      id: record?.id,
    });
    if (res?.code === 200) {
      message.success("出价修改成功", 1);
      getList({ ...conditions });
    } else {
      message.error(res?.message ?? "出价修改失败");
    }
    return;
  };

  //广告操作日志
  const showOperateLog = async (record) => {
    setLogModalShow(true);
    setConfirmLoading(true);
    const res = await getLogList({ id: record.id });
    if (res?.code === 200) {
      setLogList(res?.data);
      setConfirmLoading(false);
    }
  };
  //关闭广告操作日志
  const logModalClose = () => {
    setLogModalShow(false);
  };

  return (
    <>
      <Card
        size="small"
        title={
          <Form
            onFinish={onSearch}
            form={form}
            initialValues={{
              admin_id: props?.admin_id || props?.conditions?.admin_id || null,
              keyword:
                props?.conditions?.keyword || props?.conditions?.advertiser_id,
            }}
          >
            <Row gutter={16}>
              <Col span={5}>
                <Form.Item label="筛选投手" name="admin_id">
                  <Select
                    allowClear
                    options={pitcherOptions}
                    placeholder="筛选投手"
                    showSearch
                    filterOption={filterOption}
                    size="small"
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="ad_name" label="广告名称">
                  <Input placeholder="输入广告名称" allowClear size="small" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="ad_id" label="广告ID">
                  <Input placeholder="输入广告ID" allowClear size="small" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="出价范围" style={{ marginBottom: -10 }}>
                  <Form.Item
                    name="min_money"
                    style={{ display: "inline-block" }}
                  >
                    <InputNumber
                      size="small"
                      placeholder="最小值"
                      style={{
                        width: "50%",
                      }}
                      min={0}
                    />
                  </Form.Item>
                  <div
                    style={{
                      display: "inline-block",
                      paddingTop: 3,
                      marginLeft: -86,
                    }}
                  >
                    -
                  </div>
                  <Form.Item
                    name="max_money"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <InputNumber
                      size="small"
                      placeholder="最大值"
                      style={{
                        width: "50%",
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={5}>
                <Form.Item label="广告状态" name="status">
                  <Select
                    allowClear
                    placeholder="选择广告状态"
                    options={[
                      { label: "投放中", value: "AD_STATUS_DELIVERY_OK" },
                      { label: "已暂停", value: "AD_STATUS_DISABLE" },
                      { label: "已被项目暂停", value: "PROJECT_DISABLED" },
                      { label: "已删除", value: "AD_STATUS_DELETE" },
                    ]}
                    size="small"
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="投放日期" name="range_date">
                  <RangePicker size="small" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="广告账户" name="keyword">
                  <Input
                    allowClear
                    placeholder="输入账号名称, ID搜索"
                    size="small"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="推广外链" name="promotion_id">
                  <Input placeholder="输入推广外链id" allowClear size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item style={{ textAlign: "right" }}>
              <Space>
                <Button type="primary" htmlType="submit" size="small">
                  确定
                </Button>
                <Button onClick={formReset} size="small">
                  重置
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => navigate(-1)}
                  ghost
                >
                  返回
                </Button>
              </Space>
            </Form.Item>
          </Form>
        }
        headStyle={{ paddingTop: 10 }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          components={components}
          size="small"
          sticky
          dataSource={list}
          columns={getColumns({
            updatePlanStatus,
            deletePlan,
            switchover,
            showTimeDivision,
            showOperateLog,
          })}
          loading={loading}
          bordered={true}
          pagination={{
            total: total,
            showSizeChanger: true,
            showQuickJumper: props.total > 50 ? true : false,
            showTotal: (total) => `共 ${total} 条`,
            pageSizeOptions: [10, 15, 20, 30],
            current: page || 1,
            onChange: onShowChange,
            pageSize: perPage,
          }}
          onChange={onTableChange}
          scroll={{ x: 500 }}
          summary={(pageData) => {
            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={5} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      <Text>-</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <Text>-</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>{accountSummary?.cost}</Text>
                    </Table.Summary.Cell>
                    {pathKey.split("/").includes("theater") ? (
                      <>
                        <Table.Summary.Cell index={8} align="center">
                          <Text>{accountSummary?.money}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={9} align="center">
                          <Text>{accountSummary?.total_rate}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={10} align="center">
                          <Text>{accountSummary?.real_convert_cost}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={11} align="center">
                          <Text>{accountSummary?.convert_cost}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={12} align="center">
                          <Text>{accountSummary?.convert}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={13} align="center">
                          <Text>{accountSummary?.convert_rate}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={14} align="center">
                          <Text>{accountSummary?.upnums}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={15} align="center">
                          <Text>{accountSummary?.active}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={16} align="center">
                          <Text>{accountSummary?.active_cost}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={17} align="center">
                          <Text>-</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={18} align="center">
                          <Text>-</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={19} align="center">
                          <Text>{accountSummary?.show}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={20} align="center">
                          <Text>{accountSummary?.click}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={21} align="center">
                          <Text>{accountSummary?.ctr}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={22} align="center">
                          <Text>{accountSummary?.avg_click_cost}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={23} align="center">
                          <Text>{accountSummary?.avg_show_cost}</Text>
                        </Table.Summary.Cell>
                      </>
                    ) : (
                      <>
                        <Table.Summary.Cell index={7} align="center">
                          <Text>{accountSummary?.real_convert_cost}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8} align="center">
                          <Text>{accountSummary?.convert_cost}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={9} align="center">
                          <Text>{accountSummary?.convert}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={10} align="center">
                          <Text>{accountSummary?.convert_rate}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={11} align="center">
                          <Text>-</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={12} align="center">
                          <Text>{accountSummary?.upnums}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={13} align="center">
                          <Text>-</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={14} align="center">
                          <Text>{accountSummary?.show}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={15} align="center">
                          <Text>{accountSummary?.click}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={16} align="center">
                          <Text>{accountSummary?.ctr}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={17} align="center">
                          <Text>{accountSummary?.avg_click_cost}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={18} align="center">
                          <Text>{accountSummary?.avg_show_cost}</Text>
                        </Table.Summary.Cell>
                      </>
                    )}
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </Card>
      {/* 弹窗显示分时曲线 */}
      <Modal
        open={modalShow}
        onCancel={modalClose}
        title="分时消耗数据"
        maskClosable={false}
        confirmLoading={confirmLoading}
        footer={null}
        width={1000}
      >
        <Divider />
        <ChartDualAxes
          data={[divisionCost, divisionCost]}
          xField="time_hour"
          yField={["cost", "convert"]}
          meta={{
            cost: {
              alias: "消耗",
            },
            convert: {
              alias: "转化",
            },
          }}
          geometryOptions={[
            {
              geometry: "line",
              color: "#5B8FF9",
            },
            {
              geometry: "line",
              color: "#FFD546",
            },
          ]}
          xAxis={{
            label: {
              offsetX: 10,
              offsetY: 5,
              style: {
                // fill: '#5A8DF6',
                fontSize: 10,
                margin: 10,
              },
            },
          }}
        />
      </Modal>
      {/* 操作日志 */}
      <Modal
        open={logModalShow}
        onCancel={logModalClose}
        title="操作日志"
        maskClosable={false}
        confirmLoading={confirmLoading}
        footer={null}
        width={1000}
      >
        <Table
          size="small"
          sticky
          dataSource={logList}
          columns={adLogColumns()}
          loading={loading}
          pagination={false}
          bordered={true}
          scroll={{ x: 500 }}
        />
        ;
      </Modal>
    </>
  );
}

export default connect((state) => ({ user: state.user, menu: state.menu }), {
  saveMenu,
})(Index);
