import { Badge } from "antd";

const columns = ({showDetail}) => [
  {
    title: "消耗详情",
    width: 280,
    dataIndex: "info",
    key: "info",
    ellipsis: true,
    render: (_, record) => {
      return (
        <div style={{cursor: 'pointer'}} onClick={() => showDetail(record?.id)}>
          <div style={{ display: "flex" }}>
            <div style={{marginRight: 5}}>
              {record?.is_read === 0 ? (
                <Badge status="error" size="small" />
              ) : (
                <Badge status="default" size="small" />
              )}
            </div>
            <div>
              <div>
                {record?.is_read === 0 ? (
                  <div style={{ fontWeight: "bold" }}>
                    【{record?.category}】{record?.title}
                  </div>
                ) : (
                  <div>
                    【{record?.category}】{record?.title}
                  </div>
                )}
              </div>
              <div style={{paddingLeft: 2}}>{record?.content}</div>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    title: "消耗分类",
    width: 80,
    dataIndex: "category",
    key: "category",
  },
  {
    title: "消耗时间",
    width: 120,
    dataIndex: "created_at",
    key: "created_at",
  },
];

export default columns;
