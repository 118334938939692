import { Form, Input, Modal, Radio, Select, Tree, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { getOrganizationTree, roleList } from '../../../api/permission';
import { treeToArray } from '../../../util';

const { Text } = Typography;
const { DirectoryTree } = Tree

export default function SaveModal(props) {
    const [list, setList] = useState([])
    const [selectModalShow, setSelectModalShow] = useState(false)
    const [checkedKeys, setCheckedKeys] = useState([])
    const [options, setOptions] = useState([])
    const [selectLoading, setSelectLoading] = useState(false)

    const { type, show, form, handleCancel, handleSubmit, loading } = props
    const layout = {
        labelCol: { sm: { span: 5 } },
        wrapperCol: { xl: { span: 16 } }
    }
    const getTreeList = async (param = {}) => {
        const res = await getOrganizationTree(param)
        if (res?.code === 200) {
            setList(res?.data?.lists)
        }
    }

    useEffect(() => {
        getRoleOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let roleOptions = []
    const getRoleOptions = async (params = {}) => {
        setSelectLoading(true)
        const res = await roleList({ ...params, pageSize: 0 })
        const { data } = res
        if (res?.code === 200) {
            data?.lists.forEach((item) => {
                roleOptions.push({
                    label: item.name,
                    value: item.id
                })
            })
            setOptions(roleOptions);
            setSelectLoading(false)
        }
    }

    // 关闭弹窗
    const onClose = () => {
        setCheckedKeys([])
        handleCancel()
    }

    // 选择上级组织架构
    const seletOrganization = () => {
        getTreeList()
        setSelectModalShow(true)
    }

    const onCheck = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue);
    };

    // 点击确定选择上级组织
    const onSure = () => {
        let newList = []
        let orgIds = []
        if (checkedKeys.checked !== undefined) {
            treeToArray(list).forEach((item) => {
                if (checkedKeys.checked.indexOf(item.id) !== -1) {
                    newList.push(item.title)
                    orgIds.push(item.id)
                }
            })
            form.setFieldsValue({ organizations: newList.join() })
            form.setFieldsValue({ orgIds })
            setSelectModalShow(false)
        }
    }

    // 搜索
    const filterOption = (inputValue, option) => {
        const { label } = option
        return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    }

    return (
        <>
            <Modal
                title={type === 'add' ? '添加人员' : '修改人员'}
                open={show}
                onCancel={onClose}
                width={600}
                maskClosable={false}
                onOk={handleSubmit}
                confirmLoading={loading}
            >
                <Form {...layout} form={form} initialValues={{ status: 1 }}>
                    <Form.Item label='登录名称' name='username' rules={[{ required: true, message: '登录名称不能为空' }]}>
                        <Input placeholder='用户登录账号' />
                    </Form.Item>
                    <Form.Item label='名称简写' name='email' rules={[{ required: true, message: '名称简写不能为空' }]}>
                        <Form.Item name='email' style={{ marginBottom: 0 }}>
                            <Input placeholder='输入名称简写' />
                        </Form.Item>
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            <InfoCircleOutlined /> (添加素材时，简写会拼到素材的标题，防止登录名称太长素材标题显示不全)
                        </Text>
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} name='orgIds'>
                        <Input />
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} name='id'>
                        <Input />
                    </Form.Item>
                    <Form.Item label='组织部门' name='organizations'>
                        <Input readOnly
                            onClick={seletOrganization}
                            placeholder='请选择'
                            style={{ backgroundColor: '#eee' }}
                        />
                    </Form.Item>
                    <Form.Item label='角色分组' name='role' rules={[{ required: true, message: '账号角色不能为空' }]}>
                        <Select
                            mode='multiple'
                            allowClear
                            placeholder='可多选'
                            loading={selectLoading}
                            // onFocus={getRoleOptions}
                            options={options}
                            showSearch
                            filterOption={filterOption}
                        />
                    </Form.Item>
                    <Form.Item label='登录密码'>
                        <Form.Item name='password' style={{ marginBottom: 0 }}>
                            <Input />
                        </Form.Item>
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            <InfoCircleOutlined /> {type === 'add' ? '可不填写，默认为123456' : '可不填写，默认为原密码'}
                        </Text>
                    </Form.Item>
                    <Form.Item label='人员名称' name='nickname' rules={[{required: true, message: '人员名称不能为空'}]}>
                        <Form.Item name='nickname' style={{ marginBottom: 0 }}>
                            <Input />
                        </Form.Item>
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            <InfoCircleOutlined /> 可不填写，默认为登录名称
                        </Text>
                    </Form.Item>
                    <Form.Item label='人员状态' name='status' rules={[{ required: true, message: '请选择人员状态' }]}>
                        <Radio.Group>
                            <Radio value={0}>禁用</Radio>
                            <Radio value={1}>启用</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            {/* 弹窗选择对应的上层架构名称 */}
            <Modal
                title='组织选择'
                open={selectModalShow}
                onCancel={() => { setSelectModalShow(false) }}
                width={320}
                style={{ top: '25%' }}
                onOk={onSure}
                maskClosable={false}
            >
                <Form>
                    <Form.Item labelCol={{ sm: { span: 4 } }} label colon={false}>
                        {
                            list.length > 0 ?
                                <DirectoryTree
                                    checkable
                                    treeData={list}
                                    showLine={true}
                                    defaultExpandAll={true}
                                    expandAction={true}
                                    checkStrictly={true}
                                    onCheck={onCheck}
                                    checkedKeys={checkedKeys}
                                    selectable={false}
                                /> : ''
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
