import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  materialList,
  handleUpdateLabel,
  handleUpdateRecommend,
} from "../../../../../api";
import Table from "../../../../../components/TableNoSelection";
import columns from "./columns";
import {
  getFilterSuCai,
  getFilterTouShou,
  getMaterialLabels,
} from "../../../../../api/advertise";
import EditableCell from "../../../../../components/Editable/EditableCell";

const { RangePicker } = DatePicker;

export default function Local() {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [conditions, setConditions] = useState([]);
  const [filterTouShouOptions, setFilterTouShouOptions] = useState([]);
  const [filterSucaiOptions, setFilterSucaiOptions] = useState([]);
  const [materialLabels, setMaterialLabels] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isSuper, setIsSuper] = useState(false);
  const [isOur, setIsOur] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    getList(conditions);
    getFilterPitcher();
    getFilterMaterialer();
    getLabels();
  }, []);

  // 获取视频素材列表
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await materialList({ ...params, video: 1, public_convert: 1 });
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setTotal(data?.total);
      setIsSuper(data?.is_super);
      setIsOur(data?.is_our);
      setLoading(false);
    }
  };

  // 获取列表的投手筛选数据
  const getFilterPitcher = async () => {
    const res = await getFilterTouShou();
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list?.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setFilterTouShouOptions(newList);
    }
  };

  // 获取列表的素材手筛选数据
  const getFilterMaterialer = async () => {
    const res = await getFilterSuCai();
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list?.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setFilterSucaiOptions(newList);
    }
  };

  const layout = {
    labelCol: { xl: { span: 7 } },
    wrapperCol: { xl: { span: 16 } },
  };

  // 表单筛选查询
  const handleSearch = async (values) => {
    let createdAt = [];
    if (values["create_time"]) {
      createdAt = values["create_time"].map((date) => {
        return date.format("YYYY-MM-DD");
      });
      values.create_time = createdAt;
    }
    setConditions(values);
    let submit = false;
    for (const key in values) {
      if (values.hasOwnProperty.call(values, key)) {
        const element = values[key];
        if (element) {
          submit = true;
        }
      }
    }
    // 只有有筛选参数时才获取总消耗
    getList(values);
    setPage(1);
    setPerPage(10);
  };

  // 获取素材标签
  const getLabels = async (params = {}) => {
    const res = await getMaterialLabels(params);
    const { data } = res;
    if (res?.code === 200) {
      let labels = data?.labels;
      let newLabels = labels.map((item) => {
        return { label: item, value: item };
      });
      setMaterialLabels(newLabels);
    }
  };

  // 每页显示条数切换回调
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    setConditions({ page, pageSize: size, ...conditions });
  };

  // 排序
  const tableOnChange = (pagination, filters, sorter) => {
    let field = sorter?.field;
    let orderType = sorter?.order;
    let params = {};
    if (orderType === "descend" && field) {
      params = { ...conditions, order: field + "_" + "desc" };
    } else if (orderType === "ascend" && field) {
      params = { ...conditions, order: field + "_" + "asc" };
    } else {
      params = conditions;
    }
    setConditions(params);
    getList({
      ...params,
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 处理label标签
  const handleSave = async (row) => {
    if (!row.label) {
      return false;
    }
    setLoading(true);
    const newData = [...list];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    let label = row?.label.split(",");
    const res = await handleUpdateLabel({ id: row.id, label });
    if (res?.code === 200) {
      setList(newData);
    }
    setLoading(false);
  };

  const components = {
    body: {
      // row: EditableRow,
      cell: EditableCell,
    },
  };

  // 重新获取表格列字段
  const getColumns = ({ isSuper, isOur, onRecommendChange }) => {
    let newColumns = columns({
      isSuper,
      isOur,
      onRecommendChange,
    });
    return newColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          field: "label",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
  };

  // 是否为重点素材
  const onRecommendChange = (record, value) => {
    handleUpdateRecommend({ id: record?.id, recommend: value });
  };

  return (
    <div className="local">
      <Card
        style={{ marginBottom: 10 }}
        bodyStyle={{ padding: 0, paddingTop: 5 }}
      >
        <Form {...layout} form={form} onFinish={handleSearch}>
          <Row gutter={12}>
            <Col xl={{ span: 8 }}>
              <Form.Item name="title" label="素材名称">
                <Input placeholder="素材名称" allowClear />
              </Form.Item>
            </Col>
            <Col xl={{ span: 8 }}>
              <Form.Item name="id" label="素材ID">
                <Input placeholder="素材ID" allowClear />
              </Form.Item>
            </Col>
            <Col xl={{ span: 8 }}>
              <Form.Item name="toushou_id" label="投手筛选">
                <Select
                  allowClear
                  showSearch
                  placeholder="请选择投手"
                  options={filterTouShouOptions}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xl={{ span: 8 }}>
              <Form.Item name="uploader" label="上传用户筛选">
                <Select
                  showSearch
                  allowClear
                  placeholder="请选择上传用户"
                  options={filterSucaiOptions}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="素材归属"
                name="show_type"
                labelCol={{ xl: { span: 9 } }}
              >
                <Select
                  allowClear
                  placeholder="选择素材属性"
                  options={[
                    { label: "公有", value: 1 },
                    { label: "私有", value: 2 },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="是否转化"
                name="convert"
                labelCol={{ xl: { span: 9 } }}
              >
                <Select
                  allowClear
                  placeholder="筛选是否转化素材"
                  options={[
                    { label: "转化", value: 1 },
                    { label: "非转化", value: 0 },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xl={{ span: 8 }}>
              <Form.Item name="create_time" label="上传日期">
                <RangePicker
                  style={{ width: "100%", textAlign: "center" }}
                  size="middle"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={2}>
            <Col xl={{ span: 18 }}>
              <Form.Item
                name="label"
                label="素材标签"
                labelCol={{ xl: { span: 3 } }}
              >
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="选择素材标签"
                  options={materialLabels}
                  filterOption={filterOption}
                  style={{ width: "115%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="头条素材id"
                name="creative_id"
                style={{ marginLeft: -78 }}
              >
                <Input allowClear placeholder="输入头条素材id" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label colon={false} labelCol={{xs: {span: 19}}}>
            <Space>
              <Button type="primary" htmlType="submit" size="small">
                查询
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
                size="small"
              >
                重置
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Card>
        <Table
          size="small"
          components={components}
          dataSource={list}
          columns={getColumns({
            isSuper,
            isOur,
            onRecommendChange,
          })}
          loading={loading}
          pagesize={pagesize}
          page={page}
          perPage={perPage}
          total={total}
          onChange={tableOnChange}
        />
      </Card>
    </div>
  );
}
