import { Button, Card, Form, Input, message, Modal, Select, Space } from 'antd'
import React, { useState, useEffect } from 'react'
import { SwapOutlined } from '@ant-design/icons';
import SaveModal from './saveModal'
import { addOrganization, deleteOrganization, editOrganization, getOrganizationList } from '../../../api/permission'
import columns from './columns'
import { treeToArray } from '../../../util'
import Table from '../../../components/TableTree'

const { confirm } = Modal
export default function Organization() {
    const [modalShow, setModalShow] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [listLoading, setListLoading] = useState(true)
    const [isExpand, setIsExpand] = useState(true)
    const [expandKeys, setExpandKeys] = useState([])
    const [pname, setPname] = useState('-')
    const [pid, setPid] = useState(0)

    useEffect(() => {
        getList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [form] = Form.useForm()
    const [filterForm] = Form.useForm()

    // 获取列表数据
    const getList = async (params = []) => {
        const res = await getOrganizationList(params)
        const { data } = res
        if (res?.code === 200) {
            const Keys = treeToArray(data?.lists).map((item) => {
                return item.key
            })
            const lists = data?.lists
            setExpandKeys(Keys)
            setList(lists)
            if (lists.length > 0) {
                setPname(lists[0]?.name)
                setPid(lists[0]?.id)
            }
            setListLoading(false)
        }
    }

    // 新增弹窗
    const orgAdd = () => {
        setModalShow(true)
        form.setFieldsValue({ pname: pname })
        setModalTitle('add')
    }

    // 编辑弹窗
    const handleEdit = record => {
        const arr = treeToArray(list)
        const res = arr.filter((item) => {
            return item.id === record.pid
        })
        form.setFieldsValue(record)
        form.setFieldsValue({ pname: res.length > 0 ? res[0]?.name : '-' })
        setModalShow(true)
        setModalTitle('edit')
    }
    // 关闭弹窗
    const handleClose = () => {
        form.resetFields()
        setModalShow(false)
        setLoading(false)
    }
    // 处理表单提交
    const handleSubmit = async values => {
        setLoading(true)
        if (modalTitle === 'add') {
            organizationAdd(values)
        } else {
            organizationEdit(values)
        }
    }

    // 处理表单添加
    const organizationAdd = async data => {
        const res = await addOrganization(data)
        if (res?.code === 200) {
            message.success('添加成功', 1)
            setTimeout(() => {
                setLoading(false)
                handleClose()
                getList()
            }, 1000);
        } else {
            setLoading(false)
        }
    }

    // 处理表单修改
    const organizationEdit = async data => {
        const res = await editOrganization(data)
        if (res?.code === 200) {
            message.success('编辑成功', 1)
            setTimeout(() => {
                setLoading(false)
                handleClose()
                getList()
            }, 1000);
        } else {
            setLoading(false)
        }
    }

    // 删除
    const handleDelete = record => {
        confirm({
            title: `确定删除组织架构--${record.name} ？`,
            onOk: async () => {
                const res = await deleteOrganization({ id: record.id })
                if (res?.code === 200) {
                    message.success('删除成功', 1)
                    setTimeout(() => {
                        getList()
                    }, 1000);
                }
            }
        })
    }

    // 表单筛选查询
    const handleSelect = values => {
        getList(values)
    }

    // 处理展开|折叠
    const onFold = () => {
        let expanded = !isExpand
        if (!expanded) {
            setExpandKeys([])
        } else {
            const Keys = treeToArray(list).map((item) => {
                return item.key
            })
            setExpandKeys(Keys)
        }
        setIsExpand(!isExpand)
    }

    return (
        <div className='organization'>
            <Card
                className='org-search'
                title={
                    <Form layout="inline" form={filterForm} onFinish={handleSelect}>
                        <Form.Item label='组织名称' name='name'>
                            <Input />
                        </Form.Item>
                        <Form.Item label='组织状态' name='status'>
                            <Select allowClear placeholder='请选择状态' style={{ width: 120 }}>
                                <Select.Option value={1}>正常</Select.Option>
                                <Select.Option value={0}>停用</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button type='primary' htmlType='submit'>搜索</Button>
                                <Button onClick={() => { filterForm.resetFields() }}>重置</Button>
                            </Space>
                        </Form.Item>
                    </Form>
                }
                bodyStyle={{ display: 'none' }}
                style={{ marginBottom: 10 }}
            />
            <Card
                className='org-content'
                title={
                    <Space>
                        <Button type='primary' onClick={orgAdd}>新增</Button>
                        <Button type='primary' icon={<SwapOutlined />} onClick={onFold}>展开/折叠</Button>
                    </Space>
                }>
                {
                    list?.length && list.length > 0 ?
                        <Table
                            dataSource={list}
                            columns={columns({ handleEdit, handleDelete })}
                            expandedRowKeys={expandKeys}
                            isExpandAll={true}
                            loading={listLoading}
                        /> : ''
                }
            </Card>

            {/* 新增|修改弹窗 */}
            <SaveModal
                show={modalShow}
                title={modalTitle}
                close={handleClose}
                form={form}
                submit={handleSubmit}
                loading={loading}
                pname={pname}
                pid={pid}
            />
        </div>
    )
}
