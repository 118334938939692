import React from 'react'
import PromotionComponent from '../Public/Promotion/index'
import { useState } from 'react'
import { useEffect } from 'react'
import { getPlayletPromotion, playletBackSetSave, playletPromotionAdd } from '../../../api/playlet'
import { message } from 'antd'

export default function Promotion() {
  const [show, setShow] = useState(false)
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [backShow, setBackShow] = useState(false)

  useEffect(() => {
    getList()
  }, [])

  const appType = 2007

  // 获取列表数据
  const getList = async (params = {}) => {
    const res = await getPlayletPromotion({ ...params, app_type: appType })
    const { data } = res
    if (res?.code === 200) {
      let list = data?.lists
      let newList = list.map((item) => {
        item.id += '@@##' + item?.promotion_id
        return item
      })
      setList(newList)
      setTotal(data?.total)
      setLoading(false)
    }
  }

  // 推广链接名称搜索
  const onTitleSearch = value => {
    setPage(1)
    getList({title: value})
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPageSize(size)
    getList({ page, pageSize: size })
  }

  // 添加弹窗
  const addPromotion = () => {
    setShow(true)
  }

  // 关闭弹窗
  const onClose = () => {
    setShow(false)
    setConfirmLoading(false)
  }

  // 打开卡回传弹窗
  const backSet = async record => {
    setBackShow(true)
  }

  // 关闭卡回传弹窗
  const onSetClose = () => {
    setBackShow(false)
    setConfirmLoading(false)
  }

  // 添加推广链接
  const handleAddPromotion = async (params) => {
    setConfirmLoading(true)
    const res = await playletPromotionAdd({...params, app_type: appType})
    if (res?.code === 200) {
      message.success('推广链接添加成功', 2)
      setTimeout(() => {
        onClose()
        getList()
      }, 1000);
    } else {
      setConfirmLoading(false)
    }
  }

  // 添加卡回传
  const handleBackSet = async (params) => {
    setConfirmLoading(true)
    const res = await playletBackSetSave({...params, app_type: appType})
    if (res?.code === 200) {
      message.success('卡回传设置成功', 2)
      setTimeout(() => {
        onSetClose()
        // getList()
      }, 1000);
    } else {
      setConfirmLoading(false)
    }
  }
  
  // 传到子组件的参数
  const propsData = {
    list, total, page, loading,
    pagesize, perPage:pageSize,
    addPromotion, onClose, show,
    handleAddPromotion, confirmLoading,
    backShow, onSetClose, backSet, handleBackSet,
    app_type: appType, onTitleSearch
  }

  return (
    <div className='yingtan_promotion'>
        <PromotionComponent {...propsData} />
    </div>
  )
}
