import { Button, Space } from "antd";

const columns = ({ showLink, onShare }) => [
  {
    title: "小程序名称",
    width: 100,
    align: "center",
    dataIndex: "applet",
    key: "applet",
    render: (_, record) => (
      <>
        <div>{record?.app_name}</div>
        <div>{record?.app_id}</div>
      </>
    ),
  },
  {
    title: "备注",
    width: 100,
    align: "center",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "资产ID",
    width: 100,
    align: "center",
    dataIndex: "asset_id",
    key: "asset_id",
  },
  {
    title: "资产类型",
    width: 80,
    align: "center",
    dataIndex: "asset_type",
    key: "asset_type",
    render: () => "小程序",
  },
  {
    title: "审核状态",
    width: 100,
    align: "center",
    dataIndex: "audit_status",
    key: "audit_status",
    render: (value) =>
      value === "AUDITING"
        ? "审核中"
        : value === "AUDIT_ACCEPTED"
        ? "审核通过"
        : "审核拒绝",
  },
  {
    title: "所属账户ID",
    width: 120,
    align: "center",
    dataIndex: "advertiser_id",
    key: "advertiser_id",
    render: (value, record) => (
      <>
      <div>{record?.advertise?.account_name}</div>
      <div><span style={{fontWeight: 'bold'}}>ID:</span> {value}</div>
      </>
    )
  },
  {
    title: "创建时间",
    width: 120,
    align: "center",
    dataIndex: "create_time",
    key: "create_time",
    render: (value, record) => (
      value || record?.created_at
    )
  },
  {
    title: "操作",
    width: 80,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (_, record) => (
      <Space size="small">
        {/* <Button type="link" size="small" onClick={showLink}>
          管理链接
        </Button> */}
        {record?.create_type === 1 && record?.audit_status === 'AUDIT_ACCEPTED' ? (
          <Button type="link" size="small" onClick={() => onShare(record?.advertiser_id, record?.asset_id)}>
            共享
          </Button>
        ) : (
          ""
        )}
      </Space>
    ),
  },
];

export default columns;
