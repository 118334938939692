import React, { useEffect, useState } from "react";
import { Card } from "antd";
import EcpmPromotion from "./List/ecpm_promotion";
import EcpmBook from "./List/ecpm_book";

export default function Index(props) {
  const defaultTabKey = localStorage.getItem('system_from_name_ID') ? 2 : 1;
  const [activeTabKey, setActiveTabKey] = useState(defaultTabKey);
  const [curName, setCurName] = useState('')
  
  useEffect(()=> {
    // if(localStorage.getItem('system_from_name_ID')){
    //   setActiveTabKey(2)
    // }
    return () => {
      localStorage.removeItem('system_from_name_ID')
      localStorage.removeItem('ty_link_three_obj')
    }
  }, [])

  const tabList = [
    { key: 1, tab: "短剧收益" },
    { key: 2, tab: "投手短剧收益" },
    { key: 3, tab: "推广链接" },
  ];
  
  const handleTabChange = (val) => {
    localStorage.removeItem('system_from_name_ID')
    localStorage.removeItem('ty_link_three_obj')
    setCurName(val)
    setActiveTabKey(3)
  }

  const toThreeTab = obj => {
    const obj1  = {
      bookName: obj.bookName,
      adminId:obj.adminId
    }
    localStorage.setItem('ty_link_three_obj',JSON.stringify(obj1))
    setActiveTabKey(3);
  }

  const contentList = {
    1: <EcpmBook curKey={activeTabKey} key={activeTabKey} changeTab={handleTabChange}/>,
    2: <EcpmBook curKey={activeTabKey} key={activeTabKey} changeThreeTab={toThreeTab}/>,
    3: <EcpmPromotion curName={curName}/>,
  };

  const onTabChange = (key) => {
    localStorage.removeItem('system_from_name_ID')
    localStorage.removeItem('ty_link_three_obj')
    setCurName('')
    setActiveTabKey(key);
  };

  return (
    <>
      <Card
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        size="small"
      >
        {contentList[activeTabKey]}
      </Card>
    </>
  );
}
