import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { RightOutlined, DownOutlined } from '@ant-design/icons';

export default function TableTree(props) {
    const [expandKeys, setExpandKeys] = useState([])
    const { dataSource, columns, expandKey, isExpandAll, expandedRowKeys } = props
    
    useEffect(() => {
        setExpandKeys(expandedRowKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedRowKeys])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            rowClassName={(_, index) => (index % 2 === 1 ? "tableBac" : "")}
            pagination={false}
            expandable={{
                defaultExpandAllRows: isExpandAll,
                expandedRowKeys: expandKeys,
                expandKey: [expandKey],
                loading: true,
                bordered: true,
                indentSize: 50,
                expandIcon: ({ expanded, onExpand, record }) => {
                    return Reflect.has(record, 'children') ? (
                        !expanded ? (
                            <RightOutlined onClick={e => onExpand(record, e)} style={{ marginRight: 5 }} />
                        ) : (
                            <DownOutlined onClick={e => onExpand(record, e)} style={{ marginRight: 5 }} />
                        )
                    ) : ''
                },
                onExpand: (expanded, record) => {
                    setExpandKeys()
                }
            }}
            rowKey='id'
            scroll={{ x: 500 }}
            loading={props.loading}
        />
    )
}
