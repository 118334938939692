import { Button, DatePicker, Form, Select, Table, Typography } from "antd";
import React from "react";
import { useState, useEffect } from "react";
import tableShowColumns from "./tableShowColumns";
import { getFilterSuCaiUsers, getMaterialGroup, materialUserCost } from "../../../api/advertise";
import { useLocation } from "react-router-dom";

const { Text } = Typography;
const { RangePicker } = DatePicker;

export default function TableShow() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVip, setIsVip] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [adminOptions, setAdminOptions] = useState([]);

  // 当前的路径
  const pathKey = useLocation().pathname;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey]);

  // 获取列表页面的数据
  const getData = () => {
    Promise.all([getList(), getSuCaiShou(), getGroups()]);
  };

  // 获取筛选的素材手数据
  const getSuCaiShou = async () => {
    const res = await getFilterSuCaiUsers()
    if (res?.code === 200) {
      const {data} = res
      let list = data?.lists
      let newList = list?.map((item) => {
        return {label: item?.nickname, value: item.id}
      })
      setAdminOptions(newList)
    }
  };

  // 获取素材手分组信息
  const getGroups = async () => {
    const res = await getMaterialGroup({ tag: "material" });
    if (res?.code === 200) {
      const { data } = res;
      const lists = data?.lists;
      const newLists = lists.map((item) => {
        return { label: item?.name, value: item?.org_id };
      });
      setGroupOptions(newLists);
    }
  };

  // 列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else {
      params.type = "playlet";
    }
    const res = await materialUserCost(params);
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list.map((item) => {
        item.key = item.id;
        return item;
      });
      setList(newList);
      setIsVip(data?.is_vip);
      setLoading(false);
    }
  };

  // 日期筛选
  const onchange = (values) => {
    let createdAt;
    if (values["date"]) {
      createdAt = values["date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.date;
    }
    setConditions({ range_date: createdAt, ...values });
    getList({ range_date: createdAt, ...values });
  };

  // 排序
  const onChange = (pagination, filter, sorter) => {
    console.log(sorter);
    let field = sorter.field;
    let orderType = sorter.order === "ascend" ? "asc" : "desc";
    let order = `${field}_${orderType}`;
    if (field !== undefined) {
      getList({ ...conditions, order });
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <Form layout="inline" onFinish={onchange}>
          <Form.Item label="日期" name="date">
            <RangePicker size="small" />
          </Form.Item>
          <Form.Item label="素材手" name="sucaishou_id">
            <Select
              allowClear
              placeholder="选择素材手"
              options={adminOptions}
              style={{ width: 130 }}
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
          {isVip ? (
            <>
              {/* <Form.Item name="agent" label="代理商">
                <Select
                  placeholder="选择代理商"
                  options={[
                    { label: "台州焦点", value: 1 },
                    { label: "成都阅亿", value: 2 },
                    { label: "巨盈科技", value: 3 },
                  ]}
                  size="small"
                  style={{ width: 120 }}
                  allowClear
                  onChange={onAgentChange}
                />
              </Form.Item> */}
              <Form.Item name="org_id" label="素材手分组">
                <Select
                  allowClear
                  placeholder="请选择分组"
                  options={groupOptions}
                  style={{ width: 150 }}
                />
              </Form.Item>
            </>
          ) : (
            ""
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" size="small">
              确定
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        sticky
        dataSource={list}
        columns={tableShowColumns()}
        pagination={false}
        bordered={true}
        loading={loading}
        onChange={onChange}
        scroll={{ x: 500 }}
        summary={(pageData) => {
          let totalCost = 0;
          let totalMoneyCount = 0;
          let totalKuaiShouCost = 0;
          let totalOppoCost = 0;
          let totalVivoCost = 0;
          let totalAdqCost = 0;
          let totalClaim = 0;
          let totalAllCost = 0;
          pageData.forEach(
            ({
              cost,
              total_cost,
              total_kuaishou_cost,
              total_oppo_cost,
              total_vivo_cost,
              total_adq_cost,
              total_claim,
              total_all_cost,
            }) => {
              totalCost += parseFloat(cost);
              totalMoneyCount += parseFloat(total_cost);
              totalKuaiShouCost += parseFloat(total_kuaishou_cost);
              totalOppoCost += parseFloat(total_oppo_cost);
              totalVivoCost += parseFloat(total_vivo_cost);
              totalAdqCost += parseFloat(total_adq_cost);
              totalClaim += parseFloat(total_claim);
              totalAllCost += parseFloat(total_all_cost);
            }
          );
          return (
            <>
              {pageData && pageData.length > 0 ? (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2} align="center">
                    <Text type="danger" strong={true}>
                      汇总
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <Text>{totalCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <Text>{totalMoneyCount.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <Text>{totalKuaiShouCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    <Text>{totalOppoCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    <Text>{totalVivoCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    <Text>{totalAdqCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center">
                    <Text>{totalClaim.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center">
                    <Text>{totalAllCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              ) : (
                ""
              )}
            </>
          );
        }}
      />
    </>
  );
}
