import { postRequest } from "./request"

// 获取充值商品
export const getRechargeGoods = data => postRequest('/admin/recharge_panel/get_goods', data)

// 添加充值面板
export const handleAddPanel = data => postRequest('/admin/recharge_panel/add', data)

// 根据推广链接获取对应的充值面板
export const getPanels = data => postRequest('/admin/recharge_panel/get_list', data)

// 修改面板排序
export const updatePanelsSort = data => postRequest('/admin/recharge_panel/update_sort', data)

// 获取应用包名
export const getPackage = data => postRequest('/admin/package', data)

// 付费趋势列表
export const getPayQuList = data => postRequest('/admin/pay_qu', data)

// 付费趋势列表汇总
export const getPayQuSummer = data => postRequest('/admin/pay_qu/summary', data)

// 推广链接付费趋势成本或赔付字段修改
export const payQuUpdate = data => postRequest('/admin/pay_qu/fields_update', data)

// 短剧回收统计付费趋势成本或赔付字段修改
export const payQuPlayletUpdate = data => postRequest('/admin/pay_qu/playlet_fields_update', data)

// 根据渠道选择获取默认的收费信息
export const getDefaultQianZi = data => postRequest('/admin/package/default_charge', data)

// 充值获得的书币书券额度
export const getResourceScore = data => postRequest('/admin/package/score', data)