import { Button, Card, DatePicker, Form, Table, Typography } from "antd";
import React from "react";
import columns from "./columns";
import { useState } from "react";
import { getRecycleTrend } from "../../../../api/statistics";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { numDiv } from "../../../../util/calculate";

const { RangePicker } = DatePicker;
const { Text } = Typography;

// 付费趋势
export default function Index() {
  const [list, setList] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    // 默认获取type为1（腾阅）的数据
    getList({ type: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabList = [
    { tab: "腾阅", key: 1 },
    { tab: "天鹅", key: 2 },
    { tab: "文鼎", key: 3 },
    { tab: "阅文", key: 4 },
    { tab: "腾文", key: 5 },
    { tab: "常读", key: 6 },
    { tab: "文鼎进步小说", key: 7 },
    { tab: "追书云天天看书", key: 8 },
    { tab: "腾阅公众号", key: 9 },
    { tab: "触摸短剧", key: 10 },
    { tab: "网易羚羊短剧", key: 11 },
    { tab: "影潭短剧", key: 12 },
    { tab: "花生短剧", key: 13 },
  ];

  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getRecycleTrend({ ...params, id: state.id });
    const { data } = res;
    if (res?.code === 200) {
      let lists = data?.lists;
      let newList = lists?.map((item) => {
        item.key = item.id;
        return item;
      });
      console.log(data);
      setList(newList);
      setLoading(false);
    }
  };

  const get60Days = () => {
    let days = [];
    for (let i = 0; i <= 59; i++) {
      days.push(i);
    }
    return days;
  };

  // tab切换获取不同的列表数据
  const onChange = (value) => {
    let params = { ...conditions, type: value };
    setConditions(params);
    getList(params);
  };

  // 根据日期筛选
  const onSearch = (values) => {
    let createdAt = [];
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
    }
    let params = { ...conditions, range_date: createdAt };
    getList(params);
  };

  return (
    <Card
      tabList={tabList}
      tabProps={{ type: "card", size: "small" }}
      onTabChange={onChange}
    >
      <Card
        type="inner"
        title={
          <Form layout="inline" onFinish={onSearch}>
            <Form.Item name="range_date">
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
        }
        extra={
          <Button
            size="small"
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
        }
      >
        <Table
          sticky
          dataSource={list}
          columns={columns()}
          pagination={false}
          loading={loading}
          bordered={true}
          scroll={{ x: 500 }}
          summary={(pageData) => {
            let totalNewUsers = 0;
            let totalMoney = 0;
            let totalCost = 0;
            let totalHui30 = 0;
            let totalPercent = 0;
            let totalAfterPercent = 0;
            let totalFirstPayUser = 0;
            let totalFirstDayFirstPay = 0;
            let totalFirstDayPay = 0;
            let totalFirstPer = 0;
            let totalPer = 0;
            pageData.forEach(
              ({
                new_user,
                money,
                cost,
                rangeData,
                first_day_first_pay,
              }) => {
                totalNewUsers += parseInt(new_user);
                totalMoney += parseFloat(money);
                totalCost += parseFloat(cost);
                totalHui30 += parseFloat(rangeData?.day30Money?.day30Money);
                totalPercent += parseFloat(rangeData?.day30Money?.percent);
                totalAfterPercent += parseFloat(
                  rangeData?.day30Money?.afterPercent
                );
                totalFirstPayUser += parseFloat(rangeData?.firstPayUsers);
                totalFirstDayFirstPay += parseFloat(first_day_first_pay);
                totalFirstDayPay += parseFloat(rangeData?.firstDayPay);
                totalFirstPer += rangeData?.perSale?.firstPer
                  ? parseFloat(rangeData?.perSale?.firstPer)
                  : 0;
                totalPer +=
                  rangeData?.perSale?.per === "-"
                    ? 0
                    : parseFloat(rangeData?.perSale?.firstPer);
              }
            );

            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      <Text>{totalNewUsers}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                      <Text>{totalMoney.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      <Text>{totalCost.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      <Text>
                        {parseInt(totalCost) > 0
                          ? ((totalMoney / totalCost) * 100).toFixed(2) + "%"
                          : "-"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      <Text>{totalHui30.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <div>
                        <Text>回本率：{totalPercent}</Text>
                      </div>
                      <div>
                        <Text>分成后：{totalAfterPercent}</Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>{totalFirstPayUser}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="center">
                      <Text>
                        {totalFirstDayFirstPay.toFixed(2)} |{" "}
                        {totalFirstDayPay.toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="center">
                      <Text>
                        {totalFirstPer.toFixed(2)} | {totalPer.toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                    {get60Days().map((i) => {
                      let index = parseInt(i) + 9;
                      let totalRecharge = 0;
                      pageData.forEach(({ extendList }) => {
                        let money = extendList[i]?.money || 0;
                        totalRecharge += parseFloat(money);
                      });
                      let returnCost = parseInt(totalCost)
                        ? numDiv(totalRecharge, totalCost).toFixed(2) + "%"
                        : "-";
                      return (
                        <Table.Summary.Cell
                          index={index}
                          align="center"
                          key={index}
                        >
                          <div style={{ textAlign: "left" }}>
                            充值：{totalRecharge.toFixed(2)}
                          </div>
                          <div style={{ textAlign: "left" }}>
                            回本率：{returnCost}
                          </div>
                          {/* <div style={{ textAlign: 'left' }}>倍数：{rangeTotalData[key]?.total_beishu}</div> */}
                        </Table.Summary.Cell>
                      );
                    })}
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </Card>
    </Card>
  );
}
