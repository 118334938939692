import { Button, Card } from 'antd'
import React from 'react'
import Table from '../../../components/TableNoSelection'
import { useState } from 'react'
import columns from './columns'
import { materialAsyncList } from '../../../api/advertise'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function SyncPlan() {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getList()
  }, [])

  // 获取列表
  const getList = async (params={}) => {
    const res = await materialAsyncList(params)
    const {data} = res
    if (res?.code === 200) {
      setList(data?.lists)
      setTotal(data?.total)
      setLoading(false)
    }
  }

  // 分页
  const pagesize = (page, sise) => {
    setPage(page)
    setPageSize(sise)
    getList({page, pageSize: sise})
  }

  return (
    <div className='async'>
      <Card title={
        <Link to="/admin/material/async/add_async">
          <Button type='primary' size='small'>添加任务</Button>
        </Link>
        
      }>

        <Table 
          dataSource={list}
          columns={columns()}
          total={total}
          page={page}
          perPage={pageSize}
          loading={loading}
          pagesize={pagesize}
        />

      </Card>
    </div>
  )
}
