import { Button, Card, Col, Descriptions, Empty, Form, Radio, Row, Select, Space } from 'antd'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getFilteredChangeList } from '../../../../api/advertise';

export default function Index(props) {
    const [list, setList] = useState([])
    const [selectOptions, setSelectOptions] = useState([])
    const [changeOptions, setChangeOptions] = useState([])
    const [projectList, setProjectList] = useState([])

    const [form] = Form.useForm()

    useEffect(() => {
        getList()
    }, [])

    // 初始化列表数据
    const getList = async (params = {}) => {
        const res = await getFilteredChangeList(params)
        if (res?.code === 200) {
            const {data} = res
            const lists = data?.lists
            setList(lists)
            let newLists = lists.map((item) => {
                return {label: item.name, value: item.id}
            })
            setChangeOptions(newLists)
        }
    }

    const changeSelect = value => {
        let filtered = list.filter((item) => {
            return item.id === value
        })
        let project = filtered[0]?.project || []
        setProjectList(project)

    }

    // 切换选中项目
    const onOptionChange = e => {
        let value = e.target.value
        let filtered = projectList.filter((item) => {
            return item.id === value
        })
        form.resetFields()
        setSelectOptions(filtered)
    }

    // 搜索
    const filterOption = (inputValue, option) => {
        const { label } = option
        return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    }

    // 点击下一步
    const createNext = () => {
        props.createNext(selectOptions)
    }

    return (
        <>
            <Form layout='vertical' wrapperCol={{ sm: { span: 20 } }} form={form}>
                <Form.Item label='已有转化'>
                    <Select 
                        placeholder='选择转化'
                        allowClear
                        options={changeOptions}
                        showSearch
                        filterOption={filterOption}
                        onChange={changeSelect}
                        style={{width: '95%'}}
                    />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12} style={{ marginRight: 8 }}>
                        <Form.Item>
                            <Card
                                title='项目列表'
                                headStyle={{ backgroundColor: '#F8F8F9' }}
                                style={{ height: 250, width: 575, overflowY: 'auto' }}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        {
                                            projectList.length > 0 && projectList.map((item) => {
                                                return <Radio value={item.id} key={item.id} onChange={onOptionChange}>
                                                    {item.project_name}
                                                </Radio>
                                            })
                                        }
                                    </Space>
                                </Radio.Group>
                            </Card>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Card
                            title='已选择项目'
                            headStyle={{ backgroundColor: '#F8F8F9' }}
                            style={{ height: 250, overflowY: 'auto' }}
                        >
                            {
                                selectOptions && selectOptions.length > 0 ?

                                    <Descriptions layout="vertical">
                                        <Descriptions.Item label="投放模式" span={3}>
                                            {
                                                selectOptions[0]?.delivery_mode === 'MANUAL' ? '手动投放' : '自动投放'
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="推广目的" span={3}>
                                            {
                                                selectOptions[0]?.landing_type === 'APP' ? '应用' :
                                                    (selectOptions[0]?.landing_type === 'QUICK_APP' ? '快应用一跳' : (selectOptions[0]?.landing_type === 'Link' ? '线索（二跳）' : '小程序'))
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="营销场景" span={3}>
                                            {
                                                selectOptions[0]?.marketing_goal === 'VIDEO_AND_IMAGE' ? '短视频 + 图片' : 'LIVE直播'
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="广告类型" span={3}>
                                            {
                                                selectOptions[0]?.inventory_catalog === 'MANUAL' ? '首选媒体' : '通投智选'
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="投放类型" span={3}>常规投放</Descriptions.Item>
                                    </Descriptions> :
                                    <Empty description='请选择项目' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }

                        </Card>
                    </Col>
                </Row>
                <Form.Item label colon={false}
                    style={{
                        textAlign: 'right',
                        marginTop: -30,
                        marginRight: 50
                    }}
                >
                    <Space>
                        <Button>取消</Button>
                        <Button type='primary' onClick={createNext}>下一步</Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    )
}
