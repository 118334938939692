import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import React from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";
import { useState } from "react";
import { useEffect } from "react";
import { creativeAdList, filterPitchers } from "../../../../api/statistics";
import { useLocation } from "react-router-dom";

export default function Index(props) {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [conditions, setConditions] = useState([]);

  const [form] = Form.useForm();

  // 当前路径
  const pathKey = useLocation().pathname;

  useEffect(() => {
    getList();
    getFilterPitchers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey]);

  // 列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      params.type = "playlet";
    }
    const res = await creativeAdList({
      admin_id: props?.admin_id || props?.conditions?.admin_id || null,
      advertiser:
        props?.conditions?.keyword || props?.conditions?.advertiser_id,
      ad_id: props?.conditions?.ad_id || null,
      ...params,
    });
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      setLoading(false);
      setList(list);
      setTotal(data?.total);
      // setConditions({...conditions, ad_id: list[0]?.ad_id})
    }
  };

  // 投手数据
  const getFilterPitchers = async () => {
    let params = {};
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      if (pathKey.split("/").includes("toutiao_agtplt")) {
        params.type = "explaylet";
      } else {
        params.type = "playlet";
      }
    }
    const res = await filterPitchers(params);
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list?.map((item) => {
        return { label: item?.nickname, value: item.id };
      });
      setPitcherOptions(newList);
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 表单搜索查询
  const onSubmit = (values) => {
    let date;
    if (values["date"]) {
      date = values["date"].format("YYYYMMDD");
    }
    values.date = date;
    values.advertiser = values.keyword;
    setConditions({ ...conditions, ...values });
    props.onSearch({ ...conditions, ...values });
    getList({ ...conditions, ...values });
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    let params = { ...conditions, page, pageSize: size };
    setConditions(params);
    getList(params);
  };

  // 排序
  const sortOnChange = (pagination, filters, sorter) => {
    let field = sorter?.field;
    let orderType = sorter?.order;
    let params = {};
    if (orderType === "descend" && field) {
      params = { ...conditions, order: field + "_" + "desc" };
    } else if (orderType === "ascend" && field) {
      params = { ...conditions, order: field + "_" + "asc" };
    } else {
      params = conditions;
    }
    getList(params);
  };

  const layout = {
    labelCol: { sm: { span: 10 } },
    wrapperCol: { xl: { span: 14 } },
  };

  // 表单重置
  const formReset = () => {
    form.resetFields();
    props.onSearch([]);
  };

  return (
    <>
      <Card
        title={
          <Form
            {...layout}
            onFinish={onSubmit}
            form={form}
            initialValues={{
              keyword:
                props?.conditions?.keyword || props?.conditions?.advertiser_id,
              admin_id: props?.admin_id || props?.conditions?.admin_id || null,
              ad_id: props?.conditions?.ad_id || null,
            }}
          >
            <Row gutter={24} style={{ marginTop: 10 }}>
              <Col span={8}>
                <Form.Item label="筛选投手" name="admin_id">
                  <Select
                    allowClear
                    placeholder="筛选投手"
                    options={pitcherOptions}
                    showSearch
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="广告账户" name="keyword">
                  <Input allowClear placeholder="输入账号名称, ID搜索" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="日期筛选" name="date">
                  <DatePicker style={{ width: 200 }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="所属广告/计划ID" name="ad_id">
                  <Input allowClear placeholder="输入广告/计划ID" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    确定
                  </Button>
                  <Button onClick={formReset}>重置</Button>
                </Space>
              </Col>
            </Row>
          </Form>
        }
        bodyStyle={{ display: "none" }}
      />
      <Table
        dataSource={list}
        columns={columns()}
        page={page}
        total={total}
        perPage={perPage}
        loading={loading}
        bordered={true}
        pagesize={pagesize}
        onChange={sortOnChange}
      />
    </>
  );
}
