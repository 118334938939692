import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Descriptions,
  message,
  Row,
  Select,
  Space,
  Empty,
  Spin
} from "antd";
import React, { useEffect, useState } from "react";
import { getPlayletPackage } from "../../../../api/statistics";
import { getRoleOrgan } from "../../../../api/permission";
import { getDeliveryAvgArpu } from "../../../../api/delivery";
import { arpuDiv } from "../../../../util/calculate";
const { RangePicker } = DatePicker;
export default function AverageArpu() {
  const [list, setList] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [playletOptions, setPlayletOptions] = useState([]);
  const [agentId, setAgentId] = useState(0);
  const [rangeDate, setRangeDate] = useState([]);
  const [playletId, setPlayletId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [valueId, setvalueId] = useState(1);
  const [form] = Form.useForm();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = () => {
    Promise.all([getPlaylet(), getAgent()]);
  };
  // 获取短剧列表
  const getPlaylet = async () => {
    const res = await getPlayletPackage();
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.book_name, value: item.book_id };
      });
      form.setFieldsValue({ playletld: newLists[0]?.value });
      setPlayletOptions(newLists);
    }
  };
  // 表单查询提交验证
  const validator = (values) => {
    let rangeDate = values?.range_date;
    let playletId = values?.playletId;
    let getvalueId = values?.valueId;
    let inputIPU = values?.ipu;
    let inputARPU = values?.arpu;
    let inputECPM = values?.ecpm;
    if (!rangeDate || !playletId || !inputIPU) {
      message.error("筛选查询所有参数必选");
      return false;
    }
    if (inputIPU < 2 || inputIPU > 15) {
      message.error("ipu取值范围为2-15");
      return false;
    }
    if (
      getvalueId===1&&!inputARPU &&
      (inputECPM === null || inputECPM === undefined || inputECPM === "")
    ) {
      message.error("arpu取值必填");
      return false;
    }
    if (
      getvalueId===2&&!inputECPM &&
      (inputARPU === null || inputARPU === undefined || inputARPU === "")
    ) {
      message.error("ecpm取值必填");
      return false;
    }
    return true;
  };
  // 获取组织代理商id
  const getAgent = async () => {
    const res = await getRoleOrgan();
    if (res?.code === 200) {
      const { data } = res;
      setAgentId(data?.agent_id);
    }
  };
  // form排版
  const layout = {
    labelCol: { sm: { span: 6 } },
    wrapperCol: { xl: { span: 16 } },
  };
  // 日期筛选切换时调用档位接口
  const onDatesChange = (values) => {
    let dates;
    if (values) {
      dates = values.map((date) => {
        return date.format("YYYY-MM-DD");
      });
      setRangeDate(dates);
    } else {
      setRangeDate([]);
    }
  };
  const getYearMonth = (date) => date.year() * 12 + date.month();
  const disabled2DaysDate = (current, { from, type }) => {
    if (from) {
      const minDate = from.add(0, "days");
      const maxDate = from.add(2, "days");
      switch (type) {
        case "year":
          return (
            current.year() < minDate.year() || current.year() > maxDate.year()
          );
        case "month":
          return (
            getYearMonth(current) < getYearMonth(minDate) ||
            getYearMonth(current) > getYearMonth(maxDate)
          );
        default:
          return Math.abs(current.diff(from, "days")) >= 1;
      }
    }
    return false;
  };
  // 表单条件筛选
  const onSubmit = (values) => {
    if (!validator(values)) {
      return false;
    }
    let rangeDate;
    if (values?.range_date) {
      rangeDate = values.range_date.map((date) => {
        return date.format("YYYY-MM-DD");
      });
      values.startDate = rangeDate[0];
      values.endDate = rangeDate[1];
      delete values.range_date;
    }
    getList(values);
  };
  // 获取列表记录
  const getList = async (params = {}) => {
    // setLoading(true);
    // console.log(params,'params看看获取列表记录')
    params.agentId = agentId === 0 ? 13 : agentId;
    setisLoading(true);
    let res = await getDeliveryAvgArpu(params);
    //  console.log(res,'看看获取列表记录')
    if (res?.code === 200) {
      const { data } = res;
      const items = [
        {
          key: "1",
          label: "指标",
          children: "达成用户平均arpu",
        },
        {
          key: "2",
          label: "数值",
          children: data.avgArpu,
        },
        {
          key: "3",
          label: "全量激活用户平均arpu",
          children: "非达成用户arpu为达成用户的x%",
        },
        {
          key: "4",
          label: data.allAvgArpu,
          children: arpuDiv(data.avgArpuPercent),
        },
      ];
      setList(items);
      setisLoading(false);
      // setLoading(false);
    }
    setisLoading(false);
    // setLoading(false);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };
  // 搜索
  const valueOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };
  // 短剧切换时调用档位接口
  const onPlayletChange = (value) => {
    if (rangeDate.length > 1 && value) {
      // 请求档位接口
      setPlayletId(value);
    }
  };
  const onvalueChange = (value) => {
    if (value) {
      //console.log(value,'看看')
      setvalueId(value);
      if (value === 1) {
        form.resetFields(["arpu"]);
      } else {
        form.resetFields(["ecpm"]);
      }
    }
  };
  // ipu最值小切换
  const onIpuChange = (value) => {
    if (value) {
      if (!getLevelCheck()) {
        return false;
      }
      let ipuVal = parseInt(value);
      form.setFieldsValue({ ipu: ipuVal > 15 ? 15 : ipuVal });
    }
  };
  // 在输入取值范围时，检测是否已有请求档位
  const getLevelCheck = () => {
    if (rangeDate.length === 0 || !playletId) {
      message.error("设置取值范围时，请先筛选日期及选择对应的短剧");
      return false;
    }
    return true;
  };

  // arpu最小值切换
  const onArpuChange = (value) => {
    if (value) {
      if (!getLevelCheck()) {
        return false;
      }
      let arpuVal = parseFloat(value);
      form.setFieldsValue({ arpu: arpuVal });
    }
  };
  //Ecpm最小值切换
  const onEcpmChange = (value) => {
    if (value) {
      if (!getLevelCheck()) {
        return false;
      }
      let ecpmVal = parseFloat(value);
      form.setFieldsValue({ ecpm: ecpmVal > 600 ? 600 : ecpmVal });
    }
  };
  return (
    <>
      <Card
        title={
          <Form
            onFinish={onSubmit}
            form={form}
            {...layout}
            initialValues={{
              valueId: 1,
            }}
          >
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item label="日期" name="range_date">
                  <RangePicker
                    disabledDate={disabled2DaysDate}
                    onChange={onDatesChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="短剧" name="playletId">
                  <Select
                    allowClear
                    placeholder="选择短剧"
                    showSearch
                    filterOption={filterOption}
                    options={playletOptions}
                    onChange={onPlayletChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="类型" name="valueId">
                  <Select
                    allowClear
                    placeholder="选择类型"
                    showSearch
                    filterOption={valueOption}
                    options={[
                      {
                        value: 1,
                        label: "ARPU",
                      },
                      {
                        value: 2,
                        label: "ECPM",
                      },
                    ]}
                    onChange={onvalueChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={9}>
                <Form.Item label="IPU值">
                  <Form.Item name="ipu" style={{ display: "inline-block" }}>
                    <InputNumber
                      placeholder="ipu值(2-15)"
                      style={{
                        width: 100,
                      }}
                      min={2}
                      max={15}
                      onChange={onIpuChange}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
              {valueId === 1 ? (
                <Col span={6}>
                  <Form.Item label="ARPU值">
                    <Form.Item name="arpu" style={{ display: "inline-block" }}>
                      <InputNumber
                        // placeholder="ARPU(0.2-5.0)"
                        style={{
                          width: 100,
                        }}
                        min={0.2}
                        step={0.1}
                        onChange={onArpuChange}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              ) : (
                <Col span={6}>
                  <Form.Item label="ECPM值">
                    <Form.Item name="ecpm" style={{ display: "inline-block" }}>
                      <InputNumber
                        placeholder="ecpm值(60-600)"
                        style={{
                          width: 100,
                        }}
                        min={60}
                        max={600}
                        step={10}
                        onChange={onEcpmChange}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              )}
              <Col span={8}>
                <Form.Item label colon={false}>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      查询
                    </Button>
                    <Button onClick={() => form.resetFields()}>重置</Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      >
        {list.length >= 1 ? (
           <>
          {isLoading?(<Spin><div style={{padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,}}></div></Spin>):( 
           <Descriptions
             title=""
             column={2}
             size="middle"
             layout="vertical"
             bordered
             items={list}
           />
          )}
           </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Card>
    </>
  );
}
