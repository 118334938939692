import {
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  Radio,
  Checkbox,
  Flex,
  Space,
  Row,
  Col,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { backConfigHandle, backConfigDetail } from "@/api/delivery";
import styles from "./index.module.less";
import { backModes, backWindows, keyBehaviors, backTypes } from "./constant";

const { Option } = Select;

export default function AddOrEditModel(props) {
  const { open, id, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    id && getDetail();
  }, []);

  // 获取策略详情
  const getDetail = async () => {
    const res = await backConfigDetail({ id });
    console.log(res, "resssss");
    if (res?.code === 200) {
      setData(res?.data || {});
      form.setFieldsValue(res?.data || {});
    }
  };

  const onOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    const params = {
      ...values,
      id: id ?? undefined,
      minIpu: values.minIpu ? String(values.minIpu) : undefined,
      maxIpu: values.maxIpu ? String(values.maxIpu) : undefined,
      minArpu: values.minArpu ? String(values.minArpu) : undefined,
      maxArpu: values.maxArpu ? String(values.maxArpu) : undefined,
      backNum: values.backNum ? String(values.backNum) : undefined,
      cutBackNum: values.cutBackNum ? String(values.cutBackNum) : undefined,
    };
    console.log(params, "paramsparams");

    try {
      const res = await backConfigHandle(params);
      if (res?.code === 200) {
        message.success("操作成功");
        onClose(true);
      }
    } catch (err) {
      console.log(err, "errerrerr");
    } finally {
      setLoading(false);
    }
  };

  const renderROI = (
    <>
      <div className={styles.ROItip}>温馨提示</div>
      <div>
        ROI变现模式仅回传「激活」行为,请勿使用该模式投放「关键行为模式」,否则会造成计划空跑or资损现象
      </div>
    </>
  );

  const renderKey = (
    <>
      <Form.Item name="backWindow" label="时间窗口">
        <Radio.Group options={backWindows} />
      </Form.Item>

      <Row>
        <Col span={10} className={styles.formTitle}>
          关键行为配置
        </Col>
      </Row>

      <Form.Item
        name="keyBehaviors"
        label="关键行为指标"
        rules={[
          {
            required: true,
            message: "至少选择一条",
          },
        ]}
      >
        <Checkbox.Group options={keyBehaviors} />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.keyBehaviors?.length !== curValues.keyBehaviors?.length
        }
      >
        {({ getFieldValue }) => {
          const _keyBehaviors = getFieldValue("keyBehaviors");
          const formItem =
            _keyBehaviors.length > 0
              ? _keyBehaviors.map((key) => {
                  const curItem = keyBehaviors.find(
                    (item) => item.value === key
                  );
                  return (
                    <React.Fragment key={key}>
                      <Row>
                        <Col span={10} className={styles.formTitle}>
                          {curItem.title}
                        </Col>
                      </Row>
                      <Form.Item
                        label="最小值"
                        name={curItem.min}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="最大值"
                        name={curItem.max}
                        dependencies={[curItem.min]}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value && getFieldValue(curItem.min) > value) {
                                return Promise.reject(
                                  new Error("最小值必须小于等于最大值")
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </React.Fragment>
                  );
                })
              : null;

          return formItem;
        }}
      </Form.Item>

      <Row>
        <Col span={10} className={styles.formTitle}>
          扣量设置
        </Col>
      </Row>

      <Form.Item name="backType" label="回传方式">
        <Radio.Group options={backTypes} />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.backType !== curValues.backType
        }
      >
        {({ getFieldValue }) => {
          return getFieldValue("backType") === 2 ? (
            <>
              <Form.Item
                name="backNum"
                label="回传数"
                rules={[{ required: true }]}
              >
                <InputNumber placeholder="请输入" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="cutBackNum"
                label="扣除数"
                rules={[{ required: true }]}
              >
                <InputNumber placeholder="请输入" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item name="startBackLimit" label="前几条不扣除">
                <InputNumber placeholder="请输入" style={{ width: "100%" }} />
              </Form.Item>
            </>
          ) : null;
        }}
      </Form.Item>
    </>
  );

  const renderMode = () => {
    const curItem = backModes.find(item => item.value === data.backMode);
    return curItem?.label || '';
  }

  return (
    <Drawer
      title={id ? "编辑规则" : "新建规则"}
      width={580}
      onClose={() => onClose(false)}
      open={open}
      classNames={{
        body: styles.drawerBodyWrapper,
      }}
      footer={
        <Flex justify="flex-end">
          <Space size={16}>
            <Button onClick={() => onClose(false)}>取消</Button>
            <Button type="primary" loading={loading} onClick={onOk}>
              确定
            </Button>
          </Space>
        </Flex>
      }
    >
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          backMode: 1,
          backWindow: 1,
          keyBehaviors: [1, 2],
          backType: 2,
        }}
      >
        <Row>
          <Col span={10} className={styles.formTitle}>
            规则信息
          </Col>
        </Row>

        <Form.Item
          name="name"
          label="规则名称"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="请输入规则名称" />
        </Form.Item>

        <Form.Item name="backMode" label="回传模式">
          {id ? renderMode() : <Radio.Group options={backModes} /> }
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.backMode !== curValues.backMode
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue("backMode") === 1 ? renderKey : renderROI;
          }}
        </Form.Item>
      </Form>
    </Drawer>
  );
}
