import { Button, Card, Space, Typography } from "antd";
import React from "react";
import Table from "../../../../components/TableNoSelection";
import { useState } from "react";
import columns from "./columns";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getRecycleDetail } from "../../../../api/statistics";

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = async () => {
    const res = await getRecycleDetail({ id: state.id });
    const { data } = res;
    if (res?.code === 200) {
      setList(data);
      setLoading(false);
    }
  };

  return (
    <Card
      title={
        <Space size="middle">
          <Typography.Text>投手在各个主体的计划数</Typography.Text>
          <Button
            size="small"
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
        </Space>
      }
    >
      <Table dataSource={list} columns={columns()} loading={loading} />
    </Card>
  );
}
