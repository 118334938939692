import { Button, Form, Input, Popover, Space, Tooltip, Typography, message } from "antd"
import dayjs from "dayjs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    MoreOutlined,
  } from '@ant-design/icons';
import { Link } from "react-router-dom";

const {Text} = Typography

const columns = ({onChange, handleUpdate, backReset}) => [
    {
        title: "备注",
        width: 150,
        align: "center",
        dataIndex: "remark",
        key: "remark",
        ellipsis: true,
        fixed: 'left',
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'><Input onKeyDown={(e) => { onChange(e, record) }} /></Form.Item>
                    </Form> 
                } 
                title="修改备注" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
    {
        title: "推广链接",
        width: 265,
        align: "center",
        dataIndex: "url",
        key: "url",
        ellipsis: true,
        render: (value, record) => {
            let jianceUrl = `https://jiance.shuyueyun.cn/index.php/admin/advertiseone/index?ip=__IP__&promotion_id=__PROMOTION_ID__&ua=__UA__&imei=__IMEI__&mac=__MAC__&oaid=__OAID__&androidid=__ANDROIDID__&os=__OS__&TIMESTAMP=__TS__&callback=__CALLBACK_PARAM__&uid=${record.uid}&tuisid=${record.id}&advertiser_id=__ADVERTISER_ID__`
            return <>
                <Tooltip title={value} placement="rightTop">{value}</Tooltip>
                <div>
                    <CopyToClipboard text={value} onCopy={() => {
                            message.success('复制成功')
                        }}>
                        <Button>复制推广链接</Button>
                    </CopyToClipboard>

                    <CopyToClipboard style={{marginLeft: 5}} text={jianceUrl} onCopy={() => {
                            message.success('复制成功')
                        }}>
                        <Button>复制监测链接</Button>
                    </CopyToClipboard>
                </div>
            </>
        }
    },
    {
        title: "入口章节",
        width: 200,
        align: "center",
        dataIndex: "rukou",
        key: "rukou",
        ellipsis: true,
        render: (_, record) => {
            return <div>
                小说: {record?.book?.title}
                <div>章节: {record?.chapter?.title}</div>
            </div>
            
        }
    },
    {
        title: "用户数",
        width: 100,
        align: "center",
        dataIndex: "admin_user_count",
        key: "admin_user_count",
    },
    {
        title: "加桌数",
        width: 100,
        align: "center",
        dataIndex: "admin_user_table_count",
        key: "admin_user_table_count",
    },
    {
        title: "加桌率",
        width: 100,
        align: "center",
        dataIndex: "rate",
        key: "rate",
    },
    {
        title: "累计充值",
        width: 120,
        align: "center",
        dataIndex: "pay_log_sum_money",
        key: "pay_log_sum_money",
    },
    {
        title: "点击次数",
        width: 120,
        align: "center",
        dataIndex: "click",
        key: "click",
    },
    {
        title: "创建时间",
        width: 180,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "操作",
        width: 330,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        fixed: 'right',
        render: (_, record) => (
            <Space size='small'>
                <Button type="primary" ghost onClick={() => {handleUpdate(record)}}>
                    <Link
                        to={`/admin/batch_plan/promotion/pay_trend`}
                        state={{ ...record }}
                    >
                        付费趋势
                    </Link>
                </Button>
                <Button type="primary" ghost onClick={() => {handleUpdate(record)}}>
                    <Link
                        to={`/admin/batch_plan/promotion/panel`}
                        state={{ ...record }}
                    >
                        充值面板
                    </Link>
                </Button>
                <Button block type='primary' ghost onClick={() => backReset(record)}>回传设置</Button>
                <Popover
                    placement="top"
                    content={
                        <Button type="primary" ghost onClick={() => {handleUpdate(record)}}>修改推广链接</Button>
                    }
                >
                    <MoreOutlined />
                </Popover>
            </Space>
        )
    },
]

export default columns