import React, { useEffect, useState } from "react";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import { Button, Card, DatePicker, Form, Select, Space } from "antd";
import { addPitcherPlayletDay, filterPitchers, playletActiveForDate } from "../../../api/statistics";
import EditableCell from "../../../components/Editable/EditableCell";

const { RangePicker } = DatePicker;

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pitchers, setPitchers] = useState([]);
  const [dates, setDates] = useState(null);
  const [dateValue, setDateValue] = useState(null);

  useEffect(() => {
    getPitchers();
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form] = Form.useForm()

  // 获取列表
  const getList = async (params = {}) => {
    setLoading(true)
    const res = await playletActiveForDate(params)
    if (res?.code === 200) {
      const {data} = res
      setList(data?.lists)
      setLoading(false)
    }
  };

  // 获取筛选的投手信息
  const getPitchers = async () => {
    const res = await filterPitchers({ type: "playlet" });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitchers(newList);
      if (!data?.is_super) {
        form.setFieldsValue({admin_id: data?.current_admin_id})
      }
    }
  };

  // 筛选表单提交
  const onSubmit = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((value) => {
        return value.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    getList(values);
  };

  // 限制可选时间范围
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") >= 7;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= 7;
    return !!tooEarly || !!tooLate;
  };

  // 弹出日历和关闭日历的回调
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  // 获取所显示的字段数据
  const getColumns = () => {
    let newColumns = columns()
    return newColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          field: "old_playlet",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
  }

   // 处理添加老剧
   const handleSave = async (row) => {
    if (!row.old_playlet) {
      return false;
    }
    setLoading(true);
    const newData = [...list];
    const index = newData.findIndex((item) => row.day === item.day);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    let adminId = form.getFieldValue('admin_id')
    const res = await addPitcherPlayletDay({admin_id: adminId, playlet_name: row.old_playlet, day: row.day})
    if (res?.code === 200) {
      setList(newData);
    }
    setLoading(false);
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  return (
    <Card
      title={
        <Form layout="inline" onFinish={onSubmit} form={form}>
          <Form.Item label="选择投手" name="admin_id">
            <Select
              allowClear
              placeholder="请选择"
              options={pitchers}
              style={{ width: 150 }}
            />
          </Form.Item>
          
          <Form.Item label="日期选择" name="range_date">
            <RangePicker
              value={dates || dateValue}
              disabledDate={disabledDate}
              onCalendarChange={(val) => {
                setDates(val);
              }}
              onChange={(val) => {
                setDateValue(val);
              }}
              onOpenChange={onOpenChange}
              changeOnBlur
            />
          </Form.Item>
          <Form.Item label colon={false}>
            <Space>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
              <Button>重置</Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Table
        dataSource={list}
        columns={getColumns()}
        loading={loading}
        pagination={false}
        components={components}
      />
    </Card>
  );
}
