import { Form, Input, Upload, message } from 'antd'
import React from 'react'
// import ImageUpload from '../../../components/ImageUpload';
import {
    PlusOutlined,
} from '@ant-design/icons'
import { useState } from 'react'
import { BASE_URL } from '../../../config';

const { Item } = Form

/** 修改密码弹窗表单 */
export default function ResetPasswdForm(props) {
    const [imageFile, setImageFile] = useState(localStorage.getItem('avatar'))
    const { form } = props
    const layout = {
        labelCol: { sm: { span: 5 } },
        wrapperCol: { xl: { span: 14 } },
    };

    // 图片上传验证
    const beforeUpload = (file) => {
        // 限制只能上传 jpeg, jpg, png格式的图片
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('上传图片格式不正确，只支持jpeg,jpg,png格式的图片');
        }
        // 限制最大2M的图片
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('头像图片最大2M');
        }
        return (isJpgOrPng && isLt2M) || Upload.LIST_IGNORE;
    };

    // 选择图片上传
    const handleChange = async ({ fileList: newFileList }) => {
        const {response} = newFileList[0]
        let imgUrl = response?.data?.attach?.oss_url
        form.setFieldsValue({avatar: imgUrl})
        setImageFile(imgUrl);
    }

    // 上传图片按钮
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
            </div>
        </div>
    );

    return (
        <Form {...layout} form={form} initialValues={{avatar: imageFile}}>
            <Item
                name='username'
                label='用户名'
                rules={[{ required: true, message: '请输入用户名' }]}
            >
                <Input />
            </Item>
            <Item
                name='nickname'
                label='昵称'
                rules={[{ required: true, message: '请输入用户昵称' }]}
            >
                <Input />
            </Item>
            <Item
                name='email'
                label='邮箱'
            >
                <Input />
            </Item>
            <Item
                name='mobile'
                label='手机号'
            >
                <Input />
            </Item>
            <Item
                name='password'
                label='密码'
                rules={[{ required: true, message: '请输入密码' }]}
            >
                <Input value={imageFile} />
            </Item>
            <Item hidden name='avatar'>
                <Input />
            </Item>
            <Item
                label='头像'
            >
                <Upload
                    action={`${BASE_URL}/upload/image`}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    listType='picture-card'
                    maxCount={1}
                    accept='.jpg,jpeg,.png'
                    name={props.name}
                    showUploadList={false}
                    headers={{
                        Authorization: 'Bearer ' + localStorage.getItem('admin_token')
                    }}
                >
                    {
                        imageFile ? (
                            <img
                                src={imageFile}
                                alt="用户头像"
                                style={{ width: "100%" }}
                            />
                        ) :
                            uploadButton
                    }
                </Upload>
            </Item>
        </Form>
    )
}
