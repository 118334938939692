import { Button, Card, Input, Modal, message } from 'antd'
import React, { useState, useEffect } from 'react'
import Table from '../../../components/TableNoSelection'
import columns from './columns'
import { useNavigate } from 'react-router-dom'
import { getPromotionList, promotionDelete, retryPromotionPlan } from '../../../api/advertise'
import {checkPlayletPitcher} from '../../../api/statistics' 

const { Search } = Input
const {confirm} = Modal

export default function AdvertiseNew() {
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [conditions, setConditions] = useState([])
  const [isPitcher, setIsPitcher] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    getList()
    isPlayletPitcher()
  }, [])

  const getList = async (params = {}) => {
    const res = await getPromotionList(params)
    const {data} = res
    if (res?.code === 200) {
      setList(data?.lists)
      setTotal(data.total)
      setLoading(false)
    }
  }

  // 判断是否为自己平台的短剧投手
  const isPlayletPitcher = async () => {
    const res = await checkPlayletPitcher()
    if (res?.code === 200) {
      const {data} = res
      setIsPitcher(data?.is_pitcher)
    }
  }
  const onSearch = value => {
    setConditions({name: value})
    getList({name: value})
  }

  // 每页显示条数切换回调
  const pagesize = (page, size) => {
    setPage(page)
    setConditions({...conditions, pageSize: size, page})
    getList({...conditions, pageSize: size, page})
  }

  const deleteRecord = record => {
    confirm({
      title: `确定删除广告-${record?.name}?`,
      confirmLoading: {confirmLoading},
      onOk: async () => {
        setConfirmLoading(true)
        const res = await promotionDelete({id: record?.id})
        if (res?.code === 200) {
          message.success('删除成功', 1)
          setTimeout(() => {
            getList()
            setConfirmLoading(false)
          }, 1000);
        }
      }
    })
  }

  // 重新执行失败计划
  const retryErrorTask = async record => {
    const res = await retryPromotionPlan({promotion_id: record.id})
    if (res?.code === 200) {
      message.success(`共有${record?.error_num}失败计划已重新执行`, 2)
      getList(conditions);
    } 
  }

  // 复制广告
  const advertiseCopy = record => {
    console.log(record);
  }

  return (
    <div className='advertise_new'>
      <Card
        title={
          <Button type='primary' onClick={() => { navigate('/admin/batch_plan/advertise_new/select_add') }}>添加广告</Button>
        }
        extra={
          <Search
            placeholder="广告名称"
            enterButton="搜索"
            onSearch={onSearch}
            allowClear
            style={{
              width: 200,
            }}
          />
        }>
        <Table
          dataSource={list}
          columns={columns({deleteRecord, retryErrorTask, advertiseCopy, isPitcher})}
          total={total}
          loading={loading}
          page={page}
          pagesize={pagesize}
        />
      </Card>
    </div>
  )
}
