import React, { useState } from "react";
import {
  Button,
  Card,
  Descriptions,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  message,
} from "antd";
import Table from "../../../components/TableHasTotal";
import { useEffect } from "react";
import {
  accountHandleBind,
  accountUnBind,
  checkIsBind,
  getPlayletAccountList,
  getRelateAccount,
} from "../../../api/playlet";

const { Search } = Input;
const { confirm } = Modal;

export default function AccountBinding() {
  const [accountList, setAccountList] = useState([]);
  const [show, setShow] = useState(false);
  const [searchType, setSearchType] = useState(1);
  const [relateAccount, setRelateAccount] = useState([]);
  const [isBind, setIsBind] = useState(false);
  const [selectKeys, setSelectKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const appType = 1007;

  useEffect(() => {
    checkBind({ app_type: appType });
  }, []);

  const checkBind = async (params = {}) => {
    const res = await checkIsBind(params);
    if (res?.code === 200) {
      const { data } = res;
      if (data?.is_bind) {
        setIsBind(true);
        const relateAccount = await getRelateAccount({ app_type: appType });
        if (relateAccount?.code === 200) {
          setRelateAccount(relateAccount?.data);
        }
      } else {
        setIsBind(false);
        const accountList = await getPlayletAccountList({ app_type: appType });
        if (accountList?.code === 200) {
          const newList = accountList?.data?.lists.map((item) => {
            item.key = item.id;
            return item;
          });
          setAccountList(newList);
        }
      }
    }
  };

  // 开启账号绑定弹窗
  const openAccountModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const onChange = (value) => {
    setSearchType(value);
  };

  const selectBefore = (
    <Select defaultValue={1} onChange={onChange}>
      <Select.Option value={1}>用户名</Select.Option>
      <Select.Option value={2}>用户ID</Select.Option>
    </Select>
  );

  // 关键词查询
  const onSearch = (value) => {
    if (searchType === 1) {
      // getList({ truename: value })
    } else {
      // getList({ id: value })
    }
  };

  // 全选或全不选
  const selectRows = (selectRows) => {
    setSelectKeys(selectRows);
  };

  const accountColumns = [
    {
      title: "ID",
      width: 80,
      align: "center",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "用户名",
      width: 100,
      align: "center",
      dataIndex: "truename",
      key: "truename",
      ellipsis: true,
    },
    {
      title: "角色",
      width: 100,
      align: "center",
      dataIndex: "role",
      key: "role",
      render: (_, record) => record?.role?.role_name,
    },
  ];

  // 确定绑定
  const onSubmit = async () => {
    setLoading(true);
    const res = await accountHandleBind({
      app_type: appType,
      account_id: selectKeys[0],
    });
    if (res?.code === 200) {
      message.success("账号绑定成功", 2);
      setTimeout(() => {
        checkBind({ app_type: appType });
        closeModal();
        setLoading(false);
      }, 1000);
    }
  };

  // 账号解绑
  const handleUnBind = () => {
    confirm({
      title: "确定绑定账号?",
      onOk: async () => {
        const res = await accountUnBind({ app_type: appType });
        if (res?.code === 200) {
          message.success("账号解绑成功", 2);
          setTimeout(() => {
            checkBind({ app_type: appType });
          }, 1000);
        }
      },
    });
  };

  return (
    <>
      {!isBind ? (
        <Empty
          description={
            <span>
              账号未绑定{" "}
              <Typography.Text type="success">
                （账号绑定后可以同步获取到短剧平台的统计数据）
              </Typography.Text>
            </span>
          }
        >
          <Button type="primary" onClick={openAccountModal}>
            账号绑定
          </Button>
        </Empty>
      ) : (
        <Card
          extra={
            <Button type="primary" onClick={handleUnBind}>
              账号解绑
            </Button>
          }
        >
          <div style={{ display: "flex" }}>
            <Descriptions
              title="投放系统账号"
              layout="vertical"
              style={{ width: "45%", marginRight: 30 }}
              bordered
            >
              <Descriptions.Item label="用户ID">
                {relateAccount?.current?.id}
              </Descriptions.Item>
              <Descriptions.Item label="用户名称">
                {relateAccount?.current?.nickname}
              </Descriptions.Item>
              <Descriptions.Item label="角色">
                {relateAccount?.current?.role_names}
              </Descriptions.Item>
              <Descriptions.Item label="所属组织架构">
                {relateAccount?.current?.org_names}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              title="短剧平台账号"
              layout="vertical"
              bordered
              style={{ width: "45%" }}
            >
              <Descriptions.Item label="用户ID">
                {relateAccount?.bind?.id}
              </Descriptions.Item>
              <Descriptions.Item label="用户名称">
                {relateAccount?.bind?.truename}
              </Descriptions.Item>
              <Descriptions.Item label="角色">
                {relateAccount?.bind?.role?.role_name}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Card>
      )}

      {/* 账号绑定modal */}
      <Modal
        open={show}
        onCancel={closeModal}
        okText="确认绑定"
        onOk={onSubmit}
        width={650}
        style={{ height: 550, overflowY: "auto" }}
        confirmLoading={loading}
        maskClosable={false}
      >
        <Card
          style={{ marginTop: 20 }}
          title={
            <Form layout="inline">
              <Form.Item>
                <Search
                  placeholder={searchType === 1 ? "输入用户姓名" : "输入用户id"}
                  allowClear
                  enterButton="搜索"
                  addonBefore={selectBefore}
                  onSearch={onSearch}
                />
              </Form.Item>
            </Form>
          }
        >
          <Table
            dataSource={accountList}
            columns={accountColumns}
            selectRows={selectRows}
            type="radio"
            pagination={false}
          />
        </Card>
      </Modal>
    </>
  );
}
