import { Card, Checkbox, Form, Input, Modal, Space, Tree, message } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { treeToArray } from '../../../util'
import { dispathMenu, getMenuTree } from '../../../api/permission'

const { DirectoryTree } = Tree

export default function DispatchModal(props) {
    const [menuTreeList, setMenuTreeList] = useState([])
    const [expandedKeys, setExpandKeys] = useState([])
    const [foldChecked, setFoldChecked] = useState(true)
    const [strictly, setStrictly] = useState(false)
    const [checkedKeys, setCheckedKeys] = useState([])
    const [checkStrictly, setCheckStrictly] = useState(true)
    const [loading, setLoading] = useState(false)
    const { dispatchModalShow, onClose, form, menuChecked } = props

    useEffect(() => {
        getTreeMenu()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuChecked])

    // 获取树形结构的菜单数据
    const getTreeMenu = async (params = {}) => {
        const res = await getMenuTree(params)
        const { data } = res
        if (res?.code === 200) {
            let lists = data?.lists
            let keys = getKeys(lists)
            let checkedMenu = menuChecked.data && menuChecked.data.map((item) => {
                if (keys.indexOf(item.id) !== -1) {
                    return item.id
                }
                return []
            })
            setCheckedKeys(checkedMenu)
            setExpandKeys(keys)
            setMenuTreeList(lists)

        }
    }
    // 控制点个节点的展开|折叠
    const onExpand = keys => {
        setExpandKeys(keys)
    }

    const getKeys = (lists = []) => {
        return treeToArray(lists).map((item) => {
            return item.id
        })
    }

    // 控制展开|折叠
    const onFold = e => {
        setFoldChecked(!foldChecked)
        let checked = e.target.checked
        if (checked) {
            let keys = getKeys(menuTreeList)
            setExpandKeys(keys)
        } else {
            setExpandKeys([])
        }
    }

    // 全选|全不选
    const onSelect = e => {
        let checked = e.target.checked
        if (checked) {
            let keys = getKeys(menuTreeList)
            setCheckedKeys(keys)
        } else {
            setCheckedKeys([])
        }

    }

    // 父子联动
    const onInteraction = e => {
        setStrictly(!strictly)
        let checked = e.target.checked
        setCheckStrictly(!checked)
    }

    const onCheck = checkedKeysValue => {
        let role = form.getFieldValue('name')
        setCheckedKeys(checkedKeysValue, role);
    };

    // 点击确定提交
    const handleSubmit = () => {
        const { checked } = checkedKeys
        // 已有菜单权限跟所以的菜单一样（就是未修改，保持原样时），不请求后端接口，直接关闭弹窗
        if (strictly && checkedKeys) {
            doSubmit(checkedKeys)
        } else {
            if (checked !== undefined) {
                doSubmit(checked)
            } else {
                handleClose()
            }
        }
    }

    // 处理菜单权限分配
    const doSubmit = async (checked) => {
        setLoading(true)
        const id = form.getFieldValue('id');
        const res = await dispathMenu({ id, menuIds: checked })
        if (res?.code === 200) {
            message.success('菜单权限已分配成功', 1)
            setTimeout(() => {
                setLoading(false)
                handleClose('confirm')
            }, 1000);
        } else {
            setLoading(false)
        }
    }

    // 关闭弹窗
    const handleClose = (type = 'cancel') => {
        setCheckedKeys(menuChecked.data)
        onClose(type)
        setLoading(false)
        setStrictly(false)
        setCheckStrictly(true)
    }

    const layout = {
        labelCol: { xl: { span: 5 } },
        wrapperCol: { xl: { span: 16 } }
    }
    return (
        <Modal
            open={dispatchModalShow}
            title='菜单授权'
            onCancel={handleClose}
            maskClosable={false}
            width={650}
            onOk={handleSubmit}
            confirmLoading={loading}
        >
            <Form {...layout} form={form}>
                <Form.Item label='角色名称' name='name'>
                    <Input readOnly style={{ backgroundColor: '#eee' }} />
                </Form.Item>
                <Form.Item label='菜单权限' name='menu'>
                    <Space>
                        <Checkbox onChange={onFold} checked={foldChecked}>展开/折叠</Checkbox>
                        <Checkbox onChange={onSelect}>全选/全不选</Checkbox>
                        <Checkbox onChange={onInteraction} checked={strictly}>父子联动</Checkbox>
                    </Space>
                </Form.Item>
                <Form.Item label colon={false}>
                    <Card title={null} bodyStyle={{ height: 400, overflowY: 'auto' }}>
                        {
                            menuTreeList.length > 0 ?
                                <DirectoryTree
                                    checkable
                                    treeData={menuTreeList}
                                    showLine={true}
                                    expandAction={true}
                                    checkStrictly={checkStrictly}
                                    expandedKeys={expandedKeys}
                                    onCheck={onCheck}
                                    checkedKeys={checkedKeys}
                                    selectable={false}
                                    showIcon={false}
                                    onExpand={onExpand}
                                /> : ''
                        }
                    </Card>
                </Form.Item>
            </Form>

        </Modal>
    )
}
