import { Card } from "antd";
import React, { useState } from "react";
import TableShow from "./tableShow";
import TableEdit from "./tableEdit";

export default function Index() {
  const [activeTabKey, setActiveTabKey] = useState("tab1");

  const tabList = [
    {
      key: "tab1",
      tab: "广告素材消耗统计",
    },
    {
      key: "tab2",
      tab: "手动填写每日素材总消耗",
    },
  ];

  const contentList = {
    tab1: <TableShow />,
    tab2: <TableEdit />,
  };

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <Card
      tabList={tabList}
      tabProps={{ type: "card" }}
      onTabChange={onTabChange}
    >
      {contentList[activeTabKey]}
    </Card>
  );
}
