import { Form, Input, Popover, Typography } from "antd"
const {Text} = Typography

const columns = ({onChange}) => [
    {
        title: "日期",
        width: 100,
        align: "center",
        dataIndex: "day",
        key: "day",
        fixed: 'left',
    },
    {
        title: "新增粉丝",
        width: 100,
        align: "center",
        dataIndex: "new_subscribe",
        key: "new_subscribe",
    },
    {
        title: "新增用户",
        width: 100,
        align: "center",
        dataIndex: "new_user",
        key: "new_user",
    },
    {
        title: "激活用户",
        width: 100,
        align: "center",
        dataIndex: "active_user",
        key: "active_user",
    },
    {
        title: "激活率",
        width: 100,
        align: "center",
        dataIndex: "activation_rate",
        key: "activation_rate",
    },
    {
        title: "累计充值",
        width: 100,
        align: "center",
        dataIndex: "total_charge",
        key: "total_charge",
    },
    {
        title: "成本",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'>
                            <Input onKeyDown={(e) => onChange(e, 'cost', record?.id)} />
                        </Form.Item>
                    </Form> 
                } 
                title="修改成本" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
    {
        title: "自动计算头条成本",
        width: 100,
        align: "center",
        dataIndex: "cal_cost",
        key: "cal_cost",
    },
    {
        title: "赔付",
        width: 100,
        align: "center",
        dataIndex: "claim",
        key: "claim",
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'>
                            <Input onKeyDown={(e) => onChange(e, 'claim', record?.id)} />
                        </Form.Item>
                    </Form> 
                } 
                title="修改赔付" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
    {
        title: "投放书",
        width: 120,
        align: "center",
        dataIndex: "book_title",
        key: "book_title",
        ellipsis: true,
    },
    {
        title: "回本率",
        width: 100,
        align: "center",
        dataIndex: "huibenlv",
        key: "huibenlv",
    },
    {
        title: "前30天充值总额",
        width: 100,
        align: "center",
        dataIndex: "huiben_30",
        key: "huiben_30",
    },
    {
        title: "前30天回本率",
        width: 100,
        align: "center",
        dataIndex: "hibenlv_30",
        key: "hibenlv_30",
    },
    {
        title: "首日充值人数",
        width: 100,
        align: "center",
        dataIndex: "pay_user",
        key: "pay_user",
    },
    {
        title: "首日首充|首日充值",
        width: 100,
        align: "center",
        dataIndex: "first_money",
        key: "first_money",
        render: (_, record) => {
            return <>{record.first_day_first_pay}|{record?.first_day_pay}</>
        }
    },
    {
        title: "首日首充客单价|首日客单价",
        width: 100,
        align: "center",
        dataIndex: "per_sale",
        key: "per_sale",
        render: (_, record) => (
            record.firstPer|record.per
        )
    },
    {
        title: "当天付费人数成本|总付费人数成本",
        width: 100,
        align: "center",
        dataIndex: "day_cost",
        key: "day_cost",
        render: (_, record) => (
            record.firstPercent|record.totalPercent
        )
    },
    {
        title: '加桌|加桌率',
        width: 100,
        align: "center",
        dataIndex: "addTableRate",
        key: "addTableRate",
        render: (_, record) => (
            record?.add_table | record?.add_table_percent
        )
    },
]

export default columns