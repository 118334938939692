const menuList = [
        {
            title: "首页",
            key: "index",
            icon: "icon-shujuzonglan",
            path: "index",
            children: [
                {
                    title: "概览",
                    key: "gailan",
                    icon: "icon-shujuzonglan",
                    path: "gailan",
                },
                {
                    title: "新剧设置",
                    key: "new_playlet",
                    icon: "icon-shujuzonglan",
                    path: "new_playlet",
                },
                {
                    title: "出价计算",
                    key: "bid_calc",
                    icon: "",
                    path: "bid_calc",
                },
                {
                    title: "用户请假",
                    key: "leave_manage",
                    icon: "",
                    path: "leave_manage",
                },
            ]
        },
        {
            title: "素材管理",
            key: "material",
            icon: "icon-sucai-outline",
            children: [
                {
                    title: "视频素材",
                    key: "video",
                    icon: 'icon-tupian',
                    path: "video",
                    href: '/admin/material/video',
                    children: [
                        {
                            title: "添加视频素材",
                            key: "add_video",
                            icon: 'icon-tupian',
                            path: "add_video",
                        },
                        {
                            title: "数据详情",
                            key: "video_data_detail",
                            icon: 'icon-tupian',
                            path: "video_data_detail",
                        },
                        {
                            title: "每日消耗统计",
                            key: "video_creative_cost",
                            icon: 'icon-tupian',
                            path: "video_creative_cost",
                        },
                        {
                            title: "公有转化素材",
                            key: "public",
                            icon: 'icon-tupian',
                            path: "public",
                        },
                    ]
                },
                {
                    title: "图片素材",
                    key: "image",
                    icon: 'icon-xiaohaopinjilu',
                    path: "image",
                    href: '/admin/material/image',
                    children: [
                        {
                            title: "添加图片素材",
                            key: "add_img",
                            icon: 'icon-tupian',
                            path: "add_img",
                        },
                        {
                            title: "数据详情",
                            key: "img_data_detail",
                            icon: 'icon-tupian',
                            path: "img_data_detail",
                        },
                        {
                            title: "每日消耗统计",
                            key: "img_creative_cost",
                            icon: 'icon-tupian',
                            path: "img_creative_cost",
                        },
                    ]
                },
                {
                    title: "标题素材",
                    key: "title",
                    icon: 'icon-iconfontzhizuobiaozhunbduan23',
                    path: "title"
                },
                {
                    title: "素材上传统计",
                    key: "up_statistics",
                    icon: 'icon-iconfontzhizuobiaozhunbduan23',
                    path: "up_statistics"
                },
                {
                    title: "同步素材到头条",
                    key: "async",
                    icon: 'icon-iconfontzhizuobiaozhunbduan23',
                    path: "async",
                    href: "/admin/material/async",
                    children: [
                        {
                            title: "添加任务",
                            key: "add_async",
                            icon: 'icon-iconfontzhizuobiaozhunbduan23',
                            path: "add_async",
                        }
                    ]
                },
                {
                    title: "开放指定素材",
                    key: "open",
                    icon: 'icon-iconfontzhizuobiaozhunbduan23',
                    path: "open",
                },
            ]
        },
        {
            title: "批量计划",
            key: "batch_plan",
            icon: "icon-jihuarenwu",
            children: [
                {
                    title: "广告主列表",
                    key: "advertiser_list",
                    icon: 'icon-guanggaozhu',
                    path: "advertiser_list"
                },
                {
                    title: "读者管理",
                    key: "duzhe",
                    icon: 'icon-guanggaozhu',
                    path: "duzhe"
                },
                {
                    title: "后台转化管理",
                    key: "transform",
                    icon: 'icon-chengguozhuanhua',
                    path: "transform",
                    href: '/admin/batch_plan/transform',
                    children: [
                        {
                            title: "添加转化",
                            key: "add_trans",
                            icon: 'icon-chengguozhuanhua',
                            path: "add_trans",
                        }
                    ]
                },
                {
                    title: "广告计划后台列表",
                    key: "ad_plan",
                    icon: 'icon-liebiao',
                    path: "ad_plan"
                },
                {
                    title: "计划审核结果",
                    key: "creativeaudit",
                    icon: 'icon-shenhe',
                    path: "creativeaudit"
                },
                {
                    title: "抖小简化批量广告",
                    key: "simplify_add",
                    icon: 'icon-zuixinzixun-03',
                    path: "simplify_add",
                },
                {
                    title: "2.0广告",
                    key: "advertise_new",
                    icon: 'icon-zuixinzixun-03',
                    path: "advertise_new",
                    href: '/admin/batch_plan/advertise_new',
                    children: [
                        {
                            title: "添加广告",
                            key: "add",
                            icon: 'icon-zuixinzixun-03',
                            path: "add",
                        }
                    ]
                },
                {
                    title: "推广链接",
                    key: "promotion",
                    icon: 'icon-zuixinzixun-03',
                    path: "promotion",
                    href: "/admin/batch_plan/promotion",
                    children: [
                        {
                            title: "付费趋势",
                            key: "pay_trend",
                            icon: 'icon-zuixinzixun-03',
                            path: "pay_trend",
                        },
                        {
                            title: "充值面板",
                            key: "panel",
                            icon: 'icon-zuixinzixun-03',
                            path: "panel",
                        },
                    ]
                },
                {
                    title: "小说列表",
                    key: "novel_list",
                    icon: 'icon-zuixinzixun-03',
                    path: "novel_list",
                    href: "/admin/batch_plan/novel_list",
                    children: [
                        {
                            title: "小说章节",
                            key: "chapter",
                            icon: 'icon-zuixinzixun-03',
                            path: "chapter",
                        },
                    ]
                },
                {
                    title: "自动化策略",
                    key: "auto_policy",
                    icon: "icon-shujuzonglan",
                    path: "auto_policy",
                    children: [
                        {
                            title: "自动关停",
                            key: "auto_strategy",
                            icon: 'icon-zuixinzixun-03',
                            path: "auto_strategy",
                        },
                        {
                            title: "自动补计划",
                            key: "auto_put",
                            icon: 'icon-zuixinzixun-03',
                            path: "auto_put",
                        },
                        {
                            title: "自动补计划表",
                            key: "auto_put_table",
                            icon: 'icon-zuixinzixun-03',
                            path: "auto_put_table",
                        },
                    ]
                },
                {
                    title: "回传管理",
                    key: "backhaul_management",
                    icon: 'icon-shenhe',
                    path: "backhaul_management",
                },
            ]
        },
        {
            title: "数据统计",
            key: "statistics",
            icon: "icon-dashuju",
            children: [
                {
                    title: "小说",
                    key: "novel",
                    icon: "icon-dashuju",
                    children: [
                        {
                            title: "订单明细",
                            key: "items",
                            icon: "icon-dingdan",
                            path: "items",
                        },
                        {
                            title: "小说多充统计",
                            key: "over_charge",
                            icon: "icon-dingdan",
                            path: "over_charge",
                        },
                        {
                            title: "小说章节阅读统计",
                            key: "read_record",
                            icon: "icon-dingdan",
                            path: "read_record",
                        },
                        {
                            title: "投放统计",
                            key: "push",
                            icon: "icon-dashuju",
                            children: [
                                {
                                    title: "回收统计",
                                    key: "recycle",
                                    icon: 'icon-toutiao1',
                                    path: "recycle",
                                    href: '/admin/statistics/novel/push/recycle',
                                    children: [
                                        {
                                            title: "数据详情",
                                            key: "detail",
                                            icon: 'icon-toutiao1',
                                            path: "detail",
                                        },
                                        {
                                            title: "付费趋势",
                                            key: "trend",
                                            icon: 'icon-toutiao1',
                                            path: "trend",
                                        },
                                    ],
                                },
                                {
                                    title: "按组回收统计",
                                    key: "recycle_group",
                                    icon: "icon-dashuju",
                                    href: "/admin/statistics/novel/recycle_group",
                                    children: [
                                        {
                                            title: "组付费趋势",
                                            key: "group_trend",
                                        }
                                    ]
                                },
                                {
                                    title: "书籍今日消耗",
                                    key: "daily_cost",
                                    icon: 'icon-toutiao1',
                                    path: "daily_cost"
                                },
                                {
                                    title: "三方今日消耗",
                                    key: "daily_playlet_cost",
                                    icon: 'icon-toutiao1',
                                    path: "daily_playlet_cost"
                                },
                                {
                                    title: "头条数据列表",
                                    key: "toutiao",
                                    icon: 'icon-toutiao1',
                                    path: "toutiao"
                                },
                                {
                                    title: "头条计划列表搜索",
                                    key: "search",
                                    icon: 'icon-wxbsousuotuiguang',
                                    path: "search"
                                },
                                {
                                    title: "adp广告数据",
                                    key: "adp_advertise",
                                    icon: 'icon-toutiao',
                                    path: "adp_advertise"
                                },
                                {
                                    title: "三方小说汇总",
                                    key: "third_summary",
                                    icon: 'icon-toutiao',
                                    path: "third_summary"
                                },
                                {
                                    title: "ARPU/ECPM占比",
                                    key: "arpu_ipu_rate",
                                    icon: 'icon-toutiao',
                                    path: "arpu_ipu_rate"
                                },
                            ]
                        },
                        {
                            title: "素材统计",
                            key: "sucaishou_count",
                            icon: "icon-dashuju",
                            children: [
                                {
                                    title: "每日素材消耗",
                                    key: "sucai_daily_cost",
                                    icon: 'icon-toutiao1',
                                    path: "sucai_daily_cost"
                                },
                                {
                                    title: "素材手消耗",
                                    key: "user_cost",
                                    icon: 'icon-toutiao1',
                                    path: "user_cost"
                                },
                            ]
                        },
                        {
                            title: "快应用回传记录",
                            key: "back_record_toutiao",
                            icon: "icon-dingdan",
                            path: "back_record_toutiao",
                        },
                    ]
                },
                {
                    title: "剧场",
                    key: "theater",
                    icon: "icon-dashuju",
                    children: [
                        {
                            title: "投放统计",
                            key: "push_plt",
                            icon: "icon-dashuju",
                            children: [
                                {
                                    title: "回收统计",
                                    key: "recycle_plt",
                                    icon: 'icon-toutiao1',
                                    path: "recycle_plt",
                                    href: '/admin/statistics/therter/push_plt/recycle_plt',
                                    children: [
                                        {
                                            title: "付费趋势",
                                            key: "playlet_trend",
                                            icon: 'icon-toutiao1',
                                            path: "playlet_trend",
                                        },
                                    ],
                                },
                                {
                                    title: "按组回收统计",
                                    key: "recycle_group_plt",
                                    icon: 'icon-toutiao1',
                                    path: "recycle_group_plt"
                                },
                                {
                                    title: "短剧今日消耗",
                                    key: "daily_cost_plt",
                                    icon: 'icon-toutiao1',
                                    path: "daily_cost_plt"
                                },
                                {
                                    title: "亿投今日消耗",
                                    key: "yitou_cost_plt",
                                    icon: 'icon-toutiao1',
                                    path: "yitou_cost_plt"
                                },
                                {
                                    title: "亿投短剧汇总",
                                    key: "yitou_summar",
                                    icon: 'icon-toutiao1',
                                    path: "yitou_summar"
                                },
                                {
                                    title: "头条数据列表",
                                    key: "toutiao_plt",
                                    icon: 'icon-toutiao1',
                                    path: "toutiao_plt",
                                    href: '/admin/statistics/theater/push_plt/toutiao_plt',
                                    children: [
                                        {
                                            title: "自动规则",
                                            key: "auto_rule",
                                            icon: 'icon-toutiao1',
                                            path: "auto_rule",
                                        }
                                    ]
                                },
                                {
                                    title: "头条数据列表",
                                    key: "toutiao_agtplt",
                                    icon: 'icon-toutiao1',
                                    path: "toutiao_agtplt"
                                },
                                {
                                    title: "头条计划列表搜索",
                                    key: "search_plt",
                                    icon: 'icon-wxbsousuotuiguang',
                                    path: "search_plt"
                                },
                                {
                                    title: "adp广告数据",
                                    key: "adp_advertise_plt",
                                    icon: 'icon-toutiao',
                                    path: "adp_advertise_plt"
                                },
                                {
                                    title: "每日盈亏统计",
                                    key: "profit_statistics",
                                    icon: 'icon-toutiao',
                                    path: "profit_statistics",
                                    children: [
                                        {
                                            title: "盈亏详情",
                                            key: "detail",
                                            icon: 'icon-toutiao1',
                                            path: "detail",
                                        }
                                    ]
                                },
                                {
                                    title: "按组盈亏统计",
                                    key: "group_profit_statistics",
                                    icon: 'icon-toutiao',
                                    path: "group_profit_statistics",
                                    // children: [
                                    //     {
                                    //         title: "盈亏详情",
                                    //         key: "detail",
                                    //         icon: 'icon-toutiao1',
                                    //         path: "detail",
                                    //     }
                                    // ]
                                },
                                {
                                    title: "投手绩效排行统计",
                                    key: "pitcher_performance_statistics",
                                    icon: 'icon-toutiao',
                                    path: "pitcher_performance_statistics",
                                },
                                {

                                    title: "短剧分时统计",
                                    key: "timer_statistics",
                                    icon: 'icon-toutiao',
                                    path: "timer_statistics"
                                },
                                {
                                    title: "短剧投放明细",
                                    key: "deliver_items",
                                    icon: 'icon-toutiao',
                                    path: "deliver_items"
                                },
                                {
                                    title: "账户头条广告",
                                    key: "account_toutiao_ads",
                                    icon: 'icon-toutiao',
                                    path: "account_toutiao_ads"
                                },
                                {
                                    title: "短剧每日榜单",
                                    key: "list_rank",
                                    icon: 'icon-toutiao',
                                    path: "list_rank"
                                },
                                {
                                    title: "ARPU/ECPM占比",
                                    key: "ipu_user_rate",
                                    icon: 'icon-toutiao',
                                    path: "ipu_user_rate"
                                },
                                {
                                    title: "IAA回收统计",
                                    key: "recycle_iaa_plt",
                                    icon: 'icon-toutiao',
                                    path: "recycle_iaa_plt"
                                },
                                {
                                    title: "每日短剧广告",
                                    key: "daily_playlet_ad",
                                    icon: 'icon-toutiao',
                                    path: "daily_playlet_ad"
                                },
                            ]
                        },
                        {
                            title: "素材统计",
                            key: "sucaishou_count_plt",
                            icon: "icon-dashuju",
                            children: [
                                {
                                    title: "每日素材消耗",
                                    key: "sucai_daily_cost_plt",
                                    icon: 'icon-toutiao1',
                                    path: "sucai_daily_cost"
                                },
                                {
                                    title: "素材手历史消耗",
                                    key: "user_cost_plt",
                                    icon: 'icon-toutiao1',
                                    path: "user_cost_plt"
                                },
                                {
                                    title: "素材手今日消耗",
                                    key: "user_cost_real_plt",
                                    icon: 'icon-toutiao1',
                                    path: "user_cost_real_plt"
                                },
                            ]
                        },
                        {
                            title: "ECPM列表",
                            key: "ecpm_list",
                            icon: "icon-ecpm_list",
                        },
                    ]
                }
            ]

        },
        {
            title: "权限管理",
            key: "permission",
            icon: "icon-dashuju",
            children: [
                {
                    title: "人员管理",
                    key: "member",
                    icon: 'icon-toutiao1',
                    path: "member"
                },
                {
                    title: "角色管理",
                    key: "role",
                    icon: 'icon-wxbsousuotuiguang',
                    path: "role",
                    href: '/admin/permission/role',
                    children: [
                        {

                            title: "分配用户",
                            key: "user",
                            icon: 'icon-wxbsousuotuiguang',
                            path: "user",
                        },
                    ],
                },
                {
                    title: "组织架构",
                    key: "organization",
                    icon: 'icon-toutiao',
                    path: "organization"
                },
                {
                    title: "菜单管理",
                    key: "menu",
                    icon: 'icon-toutiao',
                    path: "menu"
                },
            ]
        },
        {
            title: "三方剧场",
            key: "playlet",
            icon: "icon-dashuju",
            children: [
                {
                    title: "影潭短剧",
                    key: "yingtan",
                    icon: 'icon-toutiao1',
                    path: "yingtan",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_yt",
                            icon: 'icon-toutiao1',
                            path: "promotion_yt",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_yt",
                            icon: 'icon-toutiao1',
                            path: "back_result_yt",
                        },
                        {
                            title: "订单管理",
                            key: "order_yt",
                            icon: 'icon-toutiao1',
                            path: "order_yt",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_yt",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_yt",
                        },
                        {
                            title: "复充率",
                            key: "payagain_yt",
                            icon: 'icon-toutiao1',
                            path: "payagain_yt",
                        },
                    ]
                },
                {
                    title: "羚羊短剧",
                    key: "lingyang",
                    icon: 'icon-toutiao1',
                    path: "lingyang",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_ly",
                            icon: 'icon-toutiao1',
                            path: "promotion_ly",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_ly",
                            icon: 'icon-toutiao1',
                            path: "back_result_ly",
                        },
                        {
                            title: "订单管理",
                            key: "order_ly",
                            icon: 'icon-toutiao1',
                            path: "order_ly",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_ly",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_ly",
                        },
                        {
                            title: "复充率",
                            key: "payagain_ly",
                            icon: 'icon-toutiao1',
                            path: "payagain_ly",
                        },
                    ]
                },
                {
                    title: "花生短剧",
                    key: "huasheng",
                    icon: 'icon-toutiao1',
                    path: "huasheng",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_hs",
                            icon: 'icon-toutiao1',
                            path: "promotion_hs",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_hs",
                            icon: 'icon-toutiao1',
                            path: "back_result_hs",
                        },
                        {
                            title: "订单管理",
                            key: "order_hs",
                            icon: 'icon-toutiao1',
                            path: "order_hs",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_hs",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_hs",
                        },
                        {
                            title: "复充率",
                            key: "payagain_hs",
                            icon: 'icon-toutiao1',
                            path: "payagain_hs",
                        },
                    ]
                },
                {
                    title: "触摸短剧",
                    key: "chumo",
                    icon: 'icon-toutiao1',
                    path: "chumo",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_cm",
                            icon: 'icon-toutiao1',
                            path: "promotion_cm",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_cm",
                            icon: 'icon-toutiao1',
                            path: "back_result_cm",
                        },
                        {
                            title: "订单管理",
                            key: "order_cm",
                            icon: 'icon-toutiao1',
                            path: "order_cm",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_cm",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_cm",
                        },
                        {
                            title: "复充率",
                            key: "payagain_cm",
                            icon: 'icon-toutiao1',
                            path: "payagain_cm",
                        },
                    ]
                },
                {
                    title: "友和短剧",
                    key: "youhe",
                    icon: 'icon-toutiao1',
                    path: "youhe",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_yh",
                            icon: 'icon-toutiao1',
                            path: "promotion_yh",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_yh",
                            icon: 'icon-toutiao1',
                            path: "back_result_yh",
                        },
                        {
                            title: "订单管理",
                            key: "order_yh",
                            icon: 'icon-toutiao1',
                            path: "order_yh",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_yh",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_yh",
                        },
                        {
                            title: "复充率",
                            key: "payagain_yh",
                            icon: 'icon-toutiao1',
                            path: "payagain_yh",
                        },
                    ]
                },
                {
                    title: "点众短剧",
                    key: "dianzhong",
                    icon: 'icon-toutiao1',
                    path: "dianzhong",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_dz",
                            icon: 'icon-toutiao1',
                            path: "promotion_dz",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_dz",
                            icon: 'icon-toutiao1',
                            path: "back_result_dz",
                        },
                        {
                            title: "订单管理",
                            key: "order_dz",
                            icon: 'icon-toutiao1',
                            path: "order_dz",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_dz",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_dz",
                        },
                        {
                            title: "复充率",
                            key: "payagain_dz",
                            icon: 'icon-toutiao1',
                            path: "payagain_dz",
                        },
                    ]
                },
                {
                    title: "山海短剧",
                    key: "shanhai",
                    icon: 'icon-toutiao1',
                    path: "shanhai",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_sh",
                            icon: 'icon-toutiao1',
                            path: "promotion_sh",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_sh",
                            icon: 'icon-toutiao1',
                            path: "back_result_sh",
                        },
                        {
                            title: "订单管理",
                            key: "order_sh",
                            icon: 'icon-toutiao1',
                            path: "order_sh",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_sh",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_sh",
                        },
                        {
                            title: "复充率",
                            key: "payagain_sh",
                            icon: 'icon-toutiao1',
                            path: "payagain_sh",
                        },
                    ]
                },
                {
                    title: "掌阅短剧",
                    key: "zhangyue",
                    icon: 'icon-toutiao1',
                    path: "zhangyue",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_zy",
                            icon: 'icon-toutiao1',
                            path: "promotion_zy",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_zy",
                            icon: 'icon-toutiao1',
                            path: "back_result_zy",
                        },
                        {
                            title: "订单管理",
                            key: "order_zy",
                            icon: 'icon-toutiao1',
                            path: "order_zy",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_zy",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_zy",
                        },
                        {
                            title: "复充率",
                            key: "payagain_zy",
                            icon: 'icon-toutiao1',
                            path: "payagain_zy",
                        },
                    ]
                },
                {
                    title: "天桥短剧",
                    key: "tianqiao",
                    icon: 'icon-toutiao1',
                    path: "tianqiao",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_sy",
                            icon: 'icon-toutiao1',
                            path: "promotion_sy",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_sy",
                            icon: 'icon-toutiao1',
                            path: "back_result_sy",
                        },
                        {
                            title: "订单管理",
                            key: "order_sy",
                            icon: 'icon-toutiao1',
                            path: "order_sy",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_sy",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_sy",
                        },
                        {
                            title: "复充率",
                            key: "payagain_sy",
                            icon: 'icon-toutiao1',
                            path: "payagain_sy",
                        },
                    ]
                },
                {
                    title: "番茄短剧",
                    key: "fanqie",
                    icon: 'icon-toutiao1',
                    path: "fanqie",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_fq",
                            icon: 'icon-toutiao1',
                            path: "promotion_fq",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_fq",
                            icon: 'icon-toutiao1',
                            path: "back_result_fq",
                        },
                        {
                            title: "iaa 广告回传",
                            key: "back_result_fq_iaa",
                            icon: 'icon-toutiao1',
                            path: "back_result_fq_iaa",
                        },
                        {
                            title: "订单管理",
                            key: "order_fq",
                            icon: 'icon-toutiao1',
                            path: "order_fq",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_fq",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_fq",
                        },
                        {
                            title: "复充率",
                            key: "payagain_fq",
                            icon: 'icon-toutiao1',
                            path: "payagain_fq",
                        },
                    ]
                },
                {
                    title: "节奏短剧",
                    key: "jiezou",
                    icon: 'icon-toutiao1',
                    path: "jiezou",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_jz",
                            icon: 'icon-toutiao1',
                            path: "promotion_jz",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_jz",
                            icon: 'icon-toutiao1',
                            path: "back_result_jz",
                        },
                        {
                            title: "订单管理",
                            key: "order_jz",
                            icon: 'icon-toutiao1',
                            path: "order_jz",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_jz",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_jz",
                        },
                        {
                            title: "复充率",
                            key: "payagain_jz",
                            icon: 'icon-toutiao1',
                            path: "payagain_jz",
                        },
                    ]
                },
                {
                    title: "咪咕短剧",
                    key: "migu",
                    icon: 'icon-toutiao1',
                    path: "migu",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_mg",
                            icon: 'icon-toutiao1',
                            path: "promotion_mg",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_mg",
                            icon: 'icon-toutiao1',
                            path: "back_result_mg",
                        },
                        {
                            title: "订单管理",
                            key: "order_mg",
                            icon: 'icon-toutiao1',
                            path: "order_mg",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_mg",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_mg",
                        },
                        {
                            title: "复充率",
                            key: "payagain_mg",
                            icon: 'icon-toutiao1',
                            path: "payagain_mg",
                        },
                    ]
                },
                {
                    title: "漫深短剧",
                    key: "mansen",
                    icon: 'icon-toutiao1',
                    path: "mansen",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_ms",
                            icon: 'icon-toutiao1',
                            path: "promotion_ms",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_ms",
                            icon: 'icon-toutiao1',
                            path: "back_result_ms",
                        },
                        {
                            title: "订单管理",
                            key: "order_ms",
                            icon: 'icon-toutiao1',
                            path: "order_ms",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_ms",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_ms",
                        },
                        {
                            title: "复充率",
                            key: "payagain_ms",
                            icon: 'icon-toutiao1',
                            path: "payagain_ms",
                        },
                    ]
                },
                {
                    title: "新剧",
                    key: "new_play",
                    icon: 'icon-toutiao1',
                    path: "new_play",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_tq",
                            icon: 'icon-toutiao1',
                            path: "promotion_tq",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_tq",
                            icon: 'icon-toutiao1',
                            path: "back_result_tq",
                        },
                        {
                            title: "订单管理",
                            key: "order_tq",
                            icon: 'icon-toutiao1',
                            path: "order_tq",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_tq",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_tq",
                        },
                        {
                            title: "复充率",
                            key: "payagain_tq",
                            icon: 'icon-toutiao1',
                            path: "payagain_tq",
                        },
                    ]
                },
                {
                    title: "自研短剧",
                    key: "platform",
                    icon: 'icon-toutiao1',
                    path: "platform",
                    children: [
                        {
                            title: "账号绑定",
                            key: "account_binding",
                            icon: 'icon-toutiao1',
                            path: "account_binding",
                        },
                        {
                            title: "推广链接管理",
                            key: "promotion_pm",
                            icon: 'icon-toutiao1',
                            path: "promotion_pm",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_pm",
                            icon: 'icon-toutiao1',
                            path: "back_result_pm",
                        },
                        {
                            title: "订单管理",
                            key: "order_pm",
                            icon: 'icon-toutiao1',
                            path: "order_pm",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_pm",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_pm",
                        },
                        {
                            title: "复充率",
                            key: "payagain_pm",
                            icon: 'icon-toutiao1',
                            path: "payagain_pm",
                        },
                    ]
                },
            ]
        },
        {
            title: "规则配置说明",
            key: "configuration_rule",
            icon: "icon-dashuju",
        },
        {
            title: "三方小说",
            key: "third_book",
            icon: "icon-dashuju",
            children: [
                {
                    title: "天鹅小说",
                    key: "swan",
                    icon: 'icon-toutiao1',
                    path: "swan",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_ths",
                            icon: 'icon-toutiao1',
                            path: "promotion_ths",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_ths",
                            icon: 'icon-toutiao1',
                            path: "back_result_ths",
                        },
                        {
                            title: "订单管理",
                            key: "order_ths",
                            icon: 'icon-toutiao1',
                            path: "order_ths",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_ths",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_ths",
                        },
                        {
                            title: "复充率",
                            key: "payagain_ths",
                            icon: 'icon-toutiao1',
                            path: "payagain_ths",
                        },
                    ]
                },
                {
                    title: "晴空小说",
                    key: "qing_kong",
                    icon: 'icon-toutiao1',
                    path: "qing_kong",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_thk",
                            icon: 'icon-toutiao1',
                            path: "promotion_thk",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_thk",
                            icon: 'icon-toutiao1',
                            path: "back_result_thk",
                        },
                        {
                            title: "订单管理",
                            key: "order_thk",
                            icon: 'icon-toutiao1',
                            path: "order_thk",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_thk",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_thk",
                        },
                        {
                            title: "复充率",
                            key: "payagain_thk",
                            icon: 'icon-toutiao1',
                            path: "payagain_thk",
                        },
                    ]
                },
                {
                    title: "常读小说",
                    key: "chang_du",
                    icon: 'icon-toutiao1',
                    path: "chang_du",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_thc",
                            icon: 'icon-toutiao1',
                            path: "promotion_thc",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_thc",
                            icon: 'icon-toutiao1',
                            path: "back_result_thc",
                        },
                        {
                            title: "订单管理",
                            key: "order_thc",
                            icon: 'icon-toutiao1',
                            path: "order_thc",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_thc",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_thc",
                        },
                        {
                            title: "复充率",
                            key: "payagain_thc",
                            icon: 'icon-toutiao1',
                            path: "payagain_thc",
                        },
                    ]
                },
                {
                    title: "进步小说",
                    key: "progress",
                    icon: 'icon-toutiao1',
                    path: "progress",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_thp",
                            icon: 'icon-toutiao1',
                            path: "promotion_thp",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_thp",
                            icon: 'icon-toutiao1',
                            path: "back_result_thp",
                        },
                        {
                            title: "订单管理",
                            key: "order_thp",
                            icon: 'icon-toutiao1',
                            path: "order_thp",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_thp",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_thp",
                        },
                        {
                            title: "复充率",
                            key: "payagain_thp",
                            icon: 'icon-toutiao1',
                            path: "payagain_thp",
                        },
                    ]
                },
                {
                    title: "最看小说",
                    key: "zui_kan",
                    icon: 'icon-toutiao1',
                    path: "zui_kan",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_thz",
                            icon: 'icon-toutiao1',
                            path: "promotion_thz",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_thz",
                            icon: 'icon-toutiao1',
                            path: "back_result_thz",
                        },
                        {
                            title: "订单管理",
                            key: "order_thz",
                            icon: 'icon-toutiao1',
                            path: "order_thz",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_thz",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_thz",
                        },
                        {
                            title: "复充率",
                            key: "payagain_thz",
                            icon: 'icon-toutiao1',
                            path: "payagain_thz",
                        },
                    ]
                },
                {
                    title: "贝壳小说",
                    key: "conch",
                    icon: 'icon-toutiao1',
                    path: "conch",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_thb",
                            icon: 'icon-toutiao1',
                            path: "promotion_thb",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_thb",
                            icon: 'icon-toutiao1',
                            path: "back_result_thb",
                        },
                        {
                            title: "订单管理",
                            key: "order_thb",
                            icon: 'icon-toutiao1',
                            path: "order_thb",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_thb",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_thb",
                        },
                        {
                            title: "复充率",
                            key: "payagain_thb",
                            icon: 'icon-toutiao1',
                            path: "payagain_thb",
                        },
                    ]
                },
                {
                    title: "自研小说",
                    key: "platform_novel",
                    icon: 'icon-toutiao1',
                    path: "platform_novel",
                    children: [
                        {
                            title: "账号绑定",
                            key: "novel_account_binding",
                            icon: 'icon-toutiao1',
                            path: "novel_account_binding",
                        },
                    ]
                },
                {
                    title: "黑岩小说",
                    key: "black_rock",
                    icon: 'icon-toutiao1',
                    path: "black_rock",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_br",
                            icon: 'icon-toutiao1',
                            path: "promotion_br",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_br",
                            icon: 'icon-toutiao1',
                            path: "back_result_br",
                        },
                        {
                            title: "订单管理",
                            key: "order_br",
                            icon: 'icon-toutiao1',
                            path: "order_br",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_br",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_br",
                        },
                        {
                            title: "复充率",
                            key: "payagain_br",
                            icon: 'icon-toutiao1',
                            path: "payagain_br",
                        },
                    ]
                },
                {
                    title: "网易小说",
                    key: "net_easy",
                    icon: 'icon-toutiao1',
                    path: "net_easy",
                    children: [
                        {
                            title: "推广链接管理",
                            key: "promotion_ns",
                            icon: 'icon-toutiao1',
                            path: "promotion_ns",
                        },
                        {
                            title: "回传结果",
                            key: "back_result_ns",
                            icon: 'icon-toutiao1',
                            path: "back_result_ns",
                        },
                        {
                            title: "订单管理",
                            key: "order_ns",
                            icon: 'icon-toutiao1',
                            path: "order_ns",
                        },
                        {
                            title: "付费趋势",
                            key: "pay_qu_ns",
                            icon: 'icon-toutiao1',
                            path: "pay_qu_ns",
                        },
                        {
                            title: "复充率",
                            key: "payagain_ns",
                            icon: 'icon-toutiao1',
                            path: "payagain_ns",
                        },
                    ]
                },
                {
                    title: "虚拟回传",
                    key: "iaa_VBackpass",
                    icon: 'icon-shujuzonglan',
                    path: "iaa_VBackpass",
                }
            ]
        },
        {
            title: "资产",
            key: "asset",
            icon: "icon-shujuzonglan",
            path: "asset",
            children: [
                {
                    title: "小程序",
                    key: "applets",
                    icon: "icon-shujuzonglan",
                    path: "applets",
                },
                {
                    title: "资产事件",
                    key: "event",
                    icon: "icon-shujuzonglan",
                    path: "event",
                },
                {
                    title: "行业产品中心",
                    key: "product_library",
                    icon: "icon-shujuzonglan",
                    path: "product_library",
                },
                {
                    title: "任务列表",
                    key: "task",
                    icon: "icon-shujuzonglan",
                    path: "task",
                },
                {
                    title: "落地页",
                    key: "landing",
                    icon: "icon-shujuzonglan",
                    path: "landing",
                },
                {
                    title: "一键开户",
                    key: "account_generate",
                    icon: "icon-shujuzonglan",
                    path: "account_generate",
                },
            ]
        }
    ]

  export default menuList
