import { Form, Input, Popover, Typography } from "antd"

const {Text} = Typography

const tableEditColumns = ({onCostChange}) => [
    {
        title: "日期",
        width: 120,
        align: "center",
        dataIndex: "day",
        key: "day",
        fixed: 'left',
    },
    {
        title: "素材手",
        width: 110,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        ellipsis: true,
        render: (_, record) => (
            record?.user?.nickname
        )
    },
    {
        title: "头条手动消耗",
        width: 120,
        align: "center",
        dataIndex: "total_cost",
        key: "total_cost",
        editable: true,
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'><Input onKeyDown={(e) => { onCostChange(e, record, 'total_cost') }} /></Form.Item>
                    </Form> 
                } 
                title="修改头条手动消耗" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
    {
        title: "快手消耗",
        width: 100,
        align: "center",
        dataIndex: "total_kuaishou_cost",
        key: "total_kuaishou_cost",
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'><Input onKeyDown={(e) => { onCostChange(e, record, 'total_kuaishou_cost') }} /></Form.Item>
                    </Form> 
                } 
                title="修改快手消耗" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
    {
        title: "OPPO消耗",
        width: 100,
        align: "center",
        dataIndex: "total_oppo_cost",
        key: "total_oppo_cost",
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'><Input onKeyDown={(e) => { onCostChange(e, record, 'total_oppo_cost') }} /></Form.Item>
                    </Form> 
                } 
                title="修改OPPO消耗" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
    {
        title: "VIVO消耗",
        width: 100,
        align: "center",
        dataIndex: "total_vivo_cost",
        key: "total_vivo_cost",
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'><Input onKeyDown={(e) => { onCostChange(e, record, 'total_vivo_cost') }} /></Form.Item>
                    </Form> 
                } 
                title="修改VIVO消耗" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
    {
        title: "adq消耗",
        width: 120,
        align: "center",
        dataIndex: "total_adq_cost",
        key: "total_adq_cost",
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'><Input onKeyDown={(e) => { onCostChange(e, record, 'total_adq_cost') }} /></Form.Item>
                    </Form> 
                } 
                title="修改adq消耗" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
    {
        title: "头条赔付金额",
        width: 120,
        align: "center",
        dataIndex: "total_claim",
        key: "total_claim",
        render: (value, record) => {
            return <Popover 
                content={
                    <Form initialValues={{title: value}}>
                        <Form.Item name='title'><Input onKeyDown={(e) => { onCostChange(e, record, 'total_claim') }} /></Form.Item>
                    </Form> 
                } 
                title="修改头条赔付金额" 
                trigger="click">
                    <Text
                        style={{width: 120, cursor: 'pointer', color: '#1492FF'}}
                        ellipsis={{tooltip: value}}
                    >
                        {value ? value : '--'}
                    </Text>
            </Popover>
        }
    },
]

export default tableEditColumns