import {
  Button,
  Card,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import Landing from "./Landing";
import Async from "./Asyn";
import { CloseOutlined } from "@ant-design/icons";
import {
  getAdvertiserList,
  getAssetShareAccount,
} from "../../../api/advertise";
import { getPromotionPlayletList } from "../../../api";
import {
  appletAsyncPull,
  assetCreate,
  assetShare,
  distributorAssocAdmin,
  getAppletsPlat,
  getAssetList,
  getLandingImages,
  getPromotionList,
} from "../../../api/asset";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { getBackRuleList } from "@/api/backRule";
import BindAccount from "./BindAccount";

const { Search } = Input;
const { confirm } = Modal;

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [playletOptions, setPlayletOptions] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [landingImgOptions, setLandingImgOptions] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [shareAdvertiserId, setShareAdvertiserId] = useState("");
  const [shareAssetId, setShareAssetId] = useState("");
  const [shareModalShow, setShareModalShow] = useState(false);
  const [asyncModalShow, setAsyncModalShow] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [asyncConfirmLoading, setAsyncConfirmLoading] = useState(false);
  const [landingImageLoading, setLandingImageLoading] = useState(false);
  const [shareAccounts, setShareAccounts] = useState([]);
  const [selectShareAccount, setSelectShareAccount] = useState([]);
  const [selectedShareItems, setSelectedShareItems] = useState([]);
  const [selectAsyncAccount, setSelectAsyncAccount] = useState([]);
  const [promotionList, setPromotionList] = useState([]);
  const [backRuleList, setBackRuleList] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  const [bindAccountShow, setBindAccountShow] = useState(false);

  const [landingForm] = Form.useForm();
  const [bindForm] = Form.useForm()
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search.substring(1));
  let taskNo = searchParams.get("taskNo");

  useEffect(() => {
    getData(taskNo);
  }, []);

  // 异步获取所有数据
  const getData = () => {
    Promise.all([
      getList({ task_no: taskNo }),
      getAccounts(),
      getPlaylets(),
      getLandingImg(),
      getPackages(),
      getBackRules(),
    ]);
  };

  // 获取资产列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getAssetList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setTotal(data?.total);
    }
    setLoading(false);
  };

  // 切换查看创建的资产或共享的资产
  const onShareChange = e => {
    let value = e.target.value;
    setConditions({ ...conditions, is_share: value });
    getList({ ...conditions, is_share: value });
  };

  // 获取账户信息
  const getAccounts = async () => {
    const res = await getAdvertiserList();
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map(item => {
        return { label: item?.account_name, value: item?.account_id, pid: item?.pid };
      });
      setAdvertiserOptions(newList);
    }
  };

  // 获取筛选的短剧数据
  const getPlaylets = async () => {
    const res = await getPromotionPlayletList();
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map(item => {
        return { label: item?.book_name, value: item.book_id };
      });
      setPlayletOptions(newList);
    }
  };

  const savePlaylets = data => {
    setPlayletOptions([data, ...playletOptions]);
  };

  // 获取小程序列表选择数据
  const getPackages = async () => {
    const res = await getAppletsPlat();
    if (res?.code === 200) {
      const { data } = res;
      let newLists = data?.map(item => {
        return {
          label: item?.name,
          value: item.app_id,
          app_type: item.app_type,
          start_page: item.start_page,
        };
      });
      setPackageList(newLists);
    }
  };

  // 获取回传规则列表
  const getBackRules = async () => {
    let adminId = localStorage.getItem("nicknameId");
    const res = await getBackRuleList({ adminId });
    if (res?.code === 200) {
      const { data } = res;
      let newList = data?.map(item => {
        return { label: item?.name, value: item?.id };
      });
      setBackRuleList(newList);
    }
  };

  // 获取落地页的图片库
  const getLandingImg = async () => {
    setLandingImageLoading(true);
    const res = await getLandingImages();
    if (res?.code === 200) {
      const { data } = res;
      setLandingImgOptions(data?.lists);
    }
    setLandingImageLoading(false);
  };

  // 搜索短剧获取推广链接
  const handleSearch = async keyword => {
    const res = await getPromotionList({ keyword });
    if (res?.code === 200) {
      const { data } = res;
      setPromotionList(data?.lists);
    }
  };

  // 小程序名称或备注输入查询
  const onKeywordSelect = value => {
    let params = { ...conditions, keyword: value };
    setConditions(params);
    getList(params);
  };

  // 审核状态切换
  const onStatusSelect = value => {
    let params = { ...conditions, audit_status: value };
    setConditions(params);
    getList(params);
  };

  // 账户切换
  const onAccountSelect = value => {
    let params = { ...conditions, advertiser_id: value };
    setConditions(params);
    getList(params);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 一键开号弹窗
  const add = () => {
    setModalShow(true);
  };

  // 关闭一键开号弹窗
  const onModalClose = () => {
    landingForm.resetFields();
    setModalShow(false);
  };

  const showAsync = () => {
    setAsyncModalShow(true);
  };

  // 下一步到落地页提交
  const onSubmit = async () => {
    getLandingFormData();
    return false;
  };

  // 检查资产的账户是否正常授权
  const checkAdvertise = (msg = "") => {
    setErrMessage(msg);
  };

  // 处理表单提交
  const handleSubmit = async values => {
    if (errMessage) {
      message.error(errMessage);
      return false;
    }
    if (
      values.app_type === 1011 ||
      values.app_type === 5005 ||
      values.app_type === 5004
    ) {
      values.remark = values.promotion_title;
      values.link_remark = values.promotion_title;
    }
    setSubmitButtonLoading(true);
    const res = await assetCreate(values);
    if (res?.code === 200) {
      message.success("一键开号操作成功");
      setTimeout(() => {
        onModalClose();
        navigate("/admin/asset/task");
      }, 1000);
    }
    setSubmitButtonLoading(false);
  };

  // 收集落地页表单数据
  const getLandingFormData = () => {
    landingForm
      .validateFields()
      .then(async () => {
        const values = landingForm.getFieldValue();
        let startParam = values?.start_param;
        if (startParam?.includes && !startParam.includes("&")) {
          message.error("启动参数填写有误，请确认");
          return false;
        }
        if (values.promotion_type === 1) {
          let findItem = promotionList.find(item => {
            return item.promotion_id === values.promotion_id;
          });
          let appName = findItem?.app_name;
          values.app_type = findItem?.app_type;
          values.app_name = appName;
          values.external_action = findItem?.external_action;
          let findPackageItem = packageList.find(item => {
            return item.label.includes(appName);
          });
          values.promotion_title = findItem?.title;
          values.book_name = findItem?.book_name;
          values.book_id = findItem?.book_id;
          values.app_id = findPackageItem?.value;
          values.start_page = findPackageItem?.start_page;
          values.start_param = findItem?.start_param;
        } else {
          let appId = values.app_id;
          let findItem = packageList.find(item => {
            return item.value === appId;
          });
          values.app_type = findItem?.app_type;
          values.app_name = findItem?.label;
          values.start_page = findItem?.start_page;
          if (!values?.book_name) {
            let playletFilterItem = playletOptions.find(item => {
              return item.value === values.book_id;
            });
            values.book_name = playletFilterItem?.label;
          }
        }
        values.mini_type = 2;
        if (!values?.image_url) {
          message.error("请设置落地页图片");
          return false;
        }
        handleSubmit(values);
      })
      .catch(e => {
        console.log(e);
        return;
      });
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ ...conditions, page, pageSize: size });
  };

  const fetchData = async (params = {}) => {
    return await getAssetShareAccount(params);
  };

  // 获取共享时的同组织下的账户列表
  const getShareAccountList = async (params = {}) => {
    const res = await getAssetShareAccount(params);
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      setShareAccounts(list);
    }
    setShareLoading(false);
  };

  // 小程序资产管理链接
  const showLink = () => {};

  // 共享
  const onShare = async (advertiserId, assetId) => {
    setShareModalShow(true);
    setShareLoading(true);
    setShareAdvertiserId(advertiserId);
    setShareAssetId(assetId);
    getShareAccountList({ advertiserId: advertiserId });
  };

  // 关闭共享弹窗
  const shareModalClose = () => {
    setSelectShareAccount([]);
    setSelectedShareItems([]);
    setShareModalShow(false);
  };

  // 关闭同步弹窗
  const asyncModalClose = () => {
    setSelectAsyncAccount([]);
    setAsyncConfirmLoading(false);
    setAsyncModalShow(false);
  };

  // 根据账户名称或id筛选
  const onShareAccountSearch = value => {
    setShareLoading(true);
    getShareAccountList({ advertiserId: shareAdvertiserId, keyword: value });
  };

  // 复选框是否选中切换
  const onCheckChange = (e, accountId) => {
    let checked = e.target.checked;
    if (checked) {
      setSelectShareAccount([...selectShareAccount, accountId]);
      let fitlerItem = shareAccounts.filter(item => {
        return item.account_id === accountId;
      });
      setSelectedShareItems([...selectedShareItems, ...fitlerItem]);
    } else {
      let filterAccount = selectShareAccount.filter(value => {
        return value !== accountId;
      });
      setSelectShareAccount(filterAccount);
      let newSelectItems = selectedShareItems.filter(item => {
        return item.account_id !== accountId;
      });
      setSelectedShareItems(newSelectItems);
    }
  };

  // 移除已选中的户
  const selectedAccountRemove = item => {
    let accountId = item?.account_id;
    let filterAccount = selectShareAccount.filter(value => {
      return accountId !== value;
    });
    setSelectShareAccount(filterAccount);

    let newSelectedItems = selectedShareItems.filter(item => {
      return item.account_id !== accountId;
    });
    setSelectedShareItems(newSelectedItems);
  };

  // 清空已选
  const clearAll = () => {
    setSelectShareAccount([]);
    setSelectedShareItems([]);
  };

  // 提交处理共享
  const handleShare = async () => {
    if (selectShareAccount.length === 0) {
      return false;
    }
    setConfirmLoading(true);
    const res = await assetShare({
      share_advertiserId: shareAdvertiserId,
      advertiser_ids: selectShareAccount,
      asset_id: shareAssetId,
      share_orange: 1,
    });
    if (res?.code === 200) {
      message.success("小程序资产共享成功");
      shareModalClose();
    }
    setConfirmLoading(false);
  };

  // 获取加载更多
  const loadMore = () => {
    let p1 = page + 1;
    const p = fetchData({ advertiserId: shareAdvertiserId, page: p1 });
    p.then(res => {
      const { data } = res;
      if (res?.code === 200) {
        let existsIds = shareAccounts.map(item => item.id);
        let lists = data?.lists;
        let newList = lists.filter(item => {
          return !existsIds.includes(item.id);
        });
        setShareAccounts([...shareAccounts, ...newList]);
      }
    });
    setPage(p1);
  };

  // 同步小程序资产选中账户切换
  const onAsnycCheckChange = (accountId, checked) => {
    if (checked) {
      setSelectAsyncAccount([...selectAsyncAccount, accountId]);
    } else {
      let filterAccount = selectAsyncAccount.filter(value => {
        return value !== accountId;
      });
      setSelectAsyncAccount(filterAccount);
    }
  };

  // 移除已选择的同步败
  const selectedAsyncAccountRemove = item => {
    let accountId = item?.account_id;
    let filterAccount = selectAsyncAccount.filter(value => {
      return accountId !== value;
    });
    setSelectAsyncAccount(filterAccount);
  };

  // 清空已选择同步的账户
  const asyncSelectAccoutClear = () => {
    setSelectAsyncAccount([]);
  };

  // 提交处理同步资产
  const handleAsync = async () => {
    if (selectAsyncAccount.length === 0) {
      message.error("请选择要同步的账户");
      return false;
    }
    setAsyncConfirmLoading(true);
    const res = await appletAsyncPull({ advertiser_ids: selectAsyncAccount });
    if (res?.code === 200) {
      message.success("操作成功，稍后刷新页面查看同步的小程序资产");
      setTimeout(() => {
        asyncModalClose();
      }, 1000);
    }
    setAsyncConfirmLoading(true);
  };

  const showBindAccount = () => {
    confirm({
      title: "确保跟番茄平台的账号昵称保持一致",
      content: (
        <Form layout="inline" form={bindForm}>
          <Form.Item label="选择剧场" name="app_id">
            <Radio.Group
              options={packageList.filter(item => {
                return item.app_type === 1011;
              })}
            />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        let appId = bindForm.getFieldValue('app_id')
        if (!appId) {
          return new Promise((_, reject) => {
            reject('请选择你要绑定的番茄平台账号')
          }).catch((msg) => {
            message.error(msg)
            return new Promise((_, reject) => {
              reject()
            })
          })
        }
        const res = await distributorAssocAdmin({app_id: appId});
        if (res?.code === 200) {
          message.success('操作账户绑定成功')
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve()
            }, 1000);
          })
        } else {
          return new Promise((_, reject) => {
            reject()
          })
        }
      }
    })
    // setBindAccountShow(true)
  };

  const bindAccountClose = () => {
    setBindAccountShow(false);
  };

  return (
    <>
      <Card
        tabList={[{ key: 1, tab: "字节小程序" }]}
        activeTabKey={1}
        size="small"
      >
        {/* 筛选条件 */}
        <Card
          type="inner"
          title={
            <Form
              layout="inline"
              initialValues={{
                audit_status: "",
              }}
            >
              <Form.Item label="小程序名称|备注" name="keyword">
                <Search
                  placeholder="输入小程序名称或备注"
                  enterButton
                  allowClear
                  onSearch={onKeywordSelect}
                />
              </Form.Item>
              <Form.Item label="审核状态" name="audit_status">
                <Select
                  placeholder="选择"
                  style={{ width: 100 }}
                  options={[
                    { label: "全部", value: "" },
                    { label: "审核中", value: "AUDITING" },
                    { label: "审核通过", value: "AUDIT_ACCEPTED" },
                    { label: "审核拒绝", value: "AUDIT_REJECTED" },
                  ]}
                  onSelect={onStatusSelect}
                />
              </Form.Item>
              <Form.Item label="账户" name="advertiser_id">
                <Select
                  placeholder="选择账户"
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  style={{ width: 250 }}
                  options={advertiserOptions}
                  onSelect={onAccountSelect}
                />
              </Form.Item>
            </Form>
          }
          styles={{ body: { display: "none" } }}
        />
        {/* 列表数据 */}
        <Card
          type="inner"
          style={{ marginTop: 10 }}
          title={
            <Space>
              <Button type="primary" onClick={add}>
                一键开户
              </Button>
              <Button onClick={showAsync}>同步字节小程序</Button>
              <Button onClick={showBindAccount}>绑定番茄账户</Button>
            </Space>
          }
          extra={
            <Radio.Group
              options={[
                { label: "我创建的资产", value: 0 },
                { label: "被共享的资产", value: 1 },
              ]}
              defaultValue={0}
              optionType="button"
              onChange={onShareChange}
            />
          }
        >
          <Table
            dataSource={list}
            columns={columns({ showLink, onShare })}
            total={total}
            page={page}
            perPage={perPage}
            loading={loading}
            pagesize={pagesize}
            pageOptions={[20, 25, 30]}
          />
        </Card>
      </Card>

      {/* 一键开号弹窗 */}
      <Drawer
        title={
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: -10, marginRight: 20 }}>
              <Button
                icon={<CloseOutlined />}
                type="primary"
                size="small"
                onClick={onModalClose}
              />
            </div>
            <div style={{ fontSize: 14 }}>
              一键开户(更新账户名称,创建小程序,落地页)
            </div>
          </div>
        }
        open={modalShow}
        onClose={onModalClose}
        maskClosable={false}
        width={650}
        closeIcon={false}
        // loading={playletModalLoading}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={onSubmit}
              loading={submitButtonLoading}
            >
              提交
            </Button>
          </div>
        }
      >
        <div style={{ marginTop: -20 }}>
          <Landing
            form={landingForm}
            landingImgs={landingImgOptions}
            accounts={advertiserOptions}
            packageList={packageList}
            playlets={playletOptions}
            getImages={getLandingImg}
            handleSearch={handleSearch}
            promotionList={promotionList}
            loading={landingImageLoading}
            savePlaylets={savePlaylets}
            backRuleOptions={backRuleList}
            checkAdvertise={checkAdvertise}
          />
        </div>
      </Drawer>

      {/* 共享 */}
      <Modal
        title="选择组织内账户"
        open={shareModalShow}
        onCancel={shareModalClose}
        width={750}
        onOk={handleShare}
        confirmLoading={confirmLoading}
      >
        <div style={{ display: "flex" }}>
          <ul
            style={{
              border: "1px solid #F0F0F0",
              padding: 10,
              width: "60%",
              overflow: "auto",
            }}
          >
            <li style={{ marginBottom: 20 }}>
              <Search
                placeholder="输入账户名称、ID搜索"
                onSearch={onShareAccountSearch}
              />
            </li>
            {shareLoading ? (
              <Spin />
            ) : (
              <div
                id="scrollableDiv"
                style={{
                  height: 350,
                  overflow: "auto",
                }}
              >
                <InfiniteScroll
                  dataLength={shareAccounts.length + 1}
                  next={loadMore}
                  hasMore={shareAccounts.length > 9}
                  scrollableTarget="scrollableDiv"
                >
                  {shareAccounts.length > 0 ? (
                    shareAccounts.map(item => {
                      return (
                        <li style={{ marginBottom: 15 }} key={item.id}>
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: 30,
                                marginRight: 10,
                              }}
                            >
                              <Checkbox
                                checked={selectShareAccount.includes(
                                  item.account_id
                                )}
                                onChange={e =>
                                  onCheckChange(e, item.account_id)
                                }
                              />
                            </div>
                            <div>
                              <div>{item.account_name}</div>
                              <div>ID: {item.account_id}</div>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <Empty />
                  )}
                </InfiniteScroll>
              </div>
            )}
          </ul>
          <ul
            style={{
              height: 425,
              border: "1px solid #F0F0F0",
              padding: 10,
              width: "39%",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "80%" }}>
                已选({selectShareAccount.length}/100)
              </div>
              <div>
                <Typography.Text
                  type="success"
                  disabled={selectShareAccount.length === 0}
                  style={{ cursor: "pointer" }}
                  onClick={clearAll}
                >
                  清空
                </Typography.Text>
              </div>
            </div>
            {selectShareAccount.length > 0
              ? selectedShareItems.map(item => {
                  return (
                    <li
                      key={item.id}
                      style={{
                        marginTop: 10,
                        backgroundColor: "#EBF1F4",
                        padding: 5,
                        display: "flex",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <Typography.Text
                          ellipsis={{ tooltip: item.account_name }}
                        >
                          {item.account_name}
                        </Typography.Text>
                        <div>ID: {item.account_id}</div>
                      </div>
                      <CloseOutlined
                        style={{ cursor: "pointer", marginLeft: 10 }}
                        onClick={() => {
                          selectedAccountRemove(item);
                        }}
                      />
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
      </Modal>

      {/* 同步小程序资产 */}
      <Modal
        title="同步选中账户下的小程序资产"
        open={asyncModalShow}
        onCancel={asyncModalClose}
        width={750}
        onOk={handleAsync}
        confirmLoading={asyncConfirmLoading}
      >
        <Async
          onCheckChange={onAsnycCheckChange}
          selectAsyncAccount={selectAsyncAccount}
          selectedRemove={selectedAsyncAccountRemove}
          clearAll={asyncSelectAccoutClear}
        />
      </Modal>

      {/* 绑定番茄平台账号 */}
      <BindAccount
        show={bindAccountShow}
        onClose={bindAccountClose}
        packageList={packageList}
      />
    </>
  );
}
