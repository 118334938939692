import { Button, Card, Space, Upload, message } from 'antd'
import React from 'react'
import { InboxOutlined } from '@ant-design/icons';
import { useState } from 'react';
import {BASE_URL} from '../../config'

const { Dragger } = Upload
export default function Index(props) {
    const [successFileList, setSuccessFileList] = useState([])
    const [errorFileList, setErrorFileList] = useState([])

    // 上传组件的属性
    const uploadProps = {
        name: 'file',
        multiple: true,
        accept: 'image/*',
        listType: "picture",
        action: `${BASE_URL}/upload/image`,
        data: {
            source: 'material_images'
        },
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('admin_token')
        },
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} 上传成功.`, 2);
                const lists = info.fileList?.map((item) => {
                    const {response} = item
                    return {attachId: response?.data?.attach?.id, oss_url: response?.data?.attach?.oss_url}
                })
                setSuccessFileList(lists)
            } else if (status === 'error') {
                message.error(`${info.file.name} 上传失败.`, 2);
                let lists = errorFileList
                lists.push({
                    name: info.file.name
                })
                setErrorFileList(lists)
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    }

    // 确定提交
    const handleSubmit = () => {
        if (errorFileList.length > 0) {
            message.error('你有上传失败的图片，请先移除')
            return false
        }
        props.getFileList(successFileList)
    }

    return (
        <Card title='上传图片素材'>
            <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">单击或拖动文件到此区域进行上传</p>
                <p className="ant-upload-hint">支持单个或批量上传</p>
            </Dragger>
            <Card 
            type="inner" 
            bodyStyle={{display: 'none'}}
            style={{marginTop: 10}}
            extra={
                <Space>
                    <Button onClick={props.closeModal}>取消</Button>
                    <Button type='primary' onClick={handleSubmit}>确定</Button>
                </Space>
            }>

            </Card>
        </Card>
    )
}
