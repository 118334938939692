import { Button, Card, DatePicker, Form, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../components/TableNoSelection";
import columns from "./columns";
import { operateExportExcel, operateStatistics } from "../../api/operate";

const { RangePicker } = DatePicker;

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [conditions, setConditions] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getList();
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await operateStatistics(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setLoading(false);
    }
  };

  // 表单筛选提交
  const onsubmit = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    setConditions(values);
    getList(values);
  };

  // 数据导出
  const excelExport = async () => {
    setExportLoading(true)
    const res = await operateExportExcel(conditions);
    if (res?.code === 200) {
      setExportLoading(false)
      const {data} = res
      let url = `https://ad.suyueyun.com/proxy/uploads/excel/${data?.filename}`
      const w = window.open('about:blank')
      w.location.href = url
    } else {
      setExportLoading(false)
    }
  };

  return (
    <>
      <Card
        title={
          <Form layout="inline" onFinish={onsubmit} form={form}>
            <Form.Item label="日期" name="range_date">
              <RangePicker style={{ width: 220 }} size="small" />
            </Form.Item>
            <Form.Item label="投手" name="nickname">
              <Input
                allowClear
                placeholder="输入投手名称"
                style={{ width: 140 }}
                size="small"
              />
            </Form.Item>
            <Form.Item label="短剧" name="playlet_name">
              <Input
                allowClear
                placeholder="输入短剧名称"
                style={{ width: 140 }}
                size="small"
              />
            </Form.Item>
            <Form.Item label="剧场" name="theater">
              <Input
                allowClear
                placeholder="输入剧场名称"
                style={{ width: 140 }}
                size="small"
              />
            </Form.Item>
            <Form.Item label colon={false}>
              <Space>
                <Button type="primary" htmlType="submit" size="small">
                  确定
                </Button>
                <Button size="small" onClick={() => form.resetFields()}>
                  重置
                </Button>
              </Space>
            </Form.Item>
          </Form>
        }
        bodyStyle={{ padding: 10 }}
      >
        <Card
          type="inner"
          size="small"
          bodyStyle={{ padding: 5 }}
          title={
            <Button type="primary" size="small" onClick={excelExport} loading={exportLoading}>
              导出
            </Button>
          }
        >
          <Table
            dataSource={list}
            loading={loading}
            columns={columns()}
            bordered={true}
            pagination={false}
          />
        </Card>
      </Card>
    </>
  );
}
