const columns =  () => [
    {
        title: "广告主ID",
        width: 160,
        align: "center",
        dataIndex: "account_id",
        key: "account_id",
        fixed: 'left',
    },
    {
        title: "广告主名称",
        width: 180,
        align: "center",
        dataIndex: "account_name",
        ellipsis: true,
        key: "account_name",
        fixed: 'left',
    },
    {
        title: "被拒计划数",
        width: 120,
        align: "center",
        dataIndex: "deny_adid",
        key: "deny_adid",
    },
    {
        title: "可用余额",
        width: 120,
        align: "center",
        dataIndex: "valid_balance",
        key: "valid_balance",
    },
    {
        title: "消耗",
        width: 120,
        align: "center",
        dataIndex: "cost",
        key: "cost",
        render: (_, record) => {
            let data = record?.account_data
            if (data && data.length > 0) {
                return data?.cost
            }
            return '0.00'
        }
    },
    {
        title: "展示",
        width: 120,
        align: "center",
        dataIndex: "show",
        key: "show",
        render: (_, record) => {
            let data = record?.account_data
            if (data && data.length > 0) {
                return data?.show
            }
            return 0
        }
    },
    {
        title: "平均展示消耗",
        width: 120,
        align: "center",
        dataIndex: "avg_show_cost",
        key: "avg_show_cost",
        render: (_, record) => {
            let data = record?.account_data
            if (data && data.length > 0) {
                return data?.avg_show_cost
            }
            return '0.00'
        }
    },
    {
        title: "点击",
        width: 120,
        align: "center",
        dataIndex: "click",
        key: "click",
        render: (_, record) => {
            let data = record?.account_data
            if (data && data.length > 0) {
                return data?.click
            }
            return 0
        }
    },
    {
        title: "平均点击消耗",
        width: 120,
        align: "center",
        dataIndex: "avg_click_cost",
        key: "avg_click_cost",
        render: (_, record) => {
            let data = record?.account_data
            if (data && data.length > 0) {
                return data?.avg_click_cost
            }
            return '0.00'
        }
    },
    {
        title: "转化数",
        width: 120,
        align: "center",
        dataIndex: "convert",
        key: "convert",
        render: (_, record) => {
            let data = record?.account_data
            if (data && data.length > 0) {
                return data?.convert
            }
            return 0
        }
    },
    {
        title: "平均转化成本",
        width: 120,
        align: "center",
        dataIndex: "convert_cost",
        key: "convert_cost",
        render: (_, record) => {
            let data = record?.account_data
            if (data && data.length > 0) {
                return data?.convert_cost
            }
            return '0.00'
        }
    },
    {
        title: "转化率",
        width: 120,
        align: "center",
        dataIndex: "convert_rate",
        key: "convert_rate",
        render: (_, record) => {
            let data = record?.account_data
            if (data && data.length > 0) {
                return data?.convert_rate
            }
            return '0.00'
        }
    },
    // {
    //     title: "操作",
    //     width: 120,
    //     align: "center",
    //     fixed: "right",
    //     dataIndex: "operation",
    //     ellipsis: true,
    //     key: "operation",
    //     render: (_, record) => (
    //         <Button type="link"
    //             onClick={() => {
    //                 // navigate(`/admin/index/manager/${record.id}`, { state: { id: record.id } })
    //                 console.log(record);
    //             }} 
    //         >
    //             查看
    //         </Button>
    //     ),
    // },
]

export default columns