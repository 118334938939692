import { Button, Space, Switch } from "antd";
import dayjs from "dayjs";

const columns = ({ onStatusChange, statusLoading, handleEdit, handleDelete }) => [
  {
    title: "用户ID",
    width: 120,
    align: "center",
    dataIndex: "id",
    key: "id",
    ellipsis: true,
  },
  {
    title: "登录名称",
    width: 100,
    align: "center",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "用户名称",
    width: 120,
    align: "center",
    dataIndex: "nickname",
    key: "nickname",
  },
  {
    title: "角色",
    width: 180,
    align: "center",
    dataIndex: "roles",
    key: "roles",
    ellipsis: true,
    render: (value) => {
      const res =
        Array.isArray(value) &&
        value.map((item) => {
          return item.name;
        });
      return res && res.join();
    },
  },
  {
    title: "组织架构",
    width: 180,
    align: "center",
    dataIndex: "organizations",
    key: "organizations",
    ellipsis: true,
    render: (value) => {
      const res =
        Array.isArray(value) &&
        value.map((item) => {
          return item.name;
        });
      return res && res.join();
    },
  },
  {
    title: "状态",
    width: 100,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (status, record) => (
      <Switch
        checkedChildren="启用"
        unCheckedChildren="禁用"
        defaultChecked={status}
        onChange={() => onStatusChange(record?.id, status)}
        loading={statusLoading === record?.id}
      />
    ),
  },
  {
    title: "创建时间",
    width: 150,
    align: "center",
    dataIndex: "create_time",
    key: "create_time",
    render: (text) => dayjs.unix(text).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "操作",
    width: 180,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    fixed: "right",
    render: (_, record) => (
      <Space>
        <Button
          type="primary"
          onClick={() => {
            handleEdit(record);
          }}
        >
          编辑
        </Button>
        <Button
          type="primary"
          danger
          onClick={() => {
            handleDelete(record);
          }}
        >
          删除
        </Button>
      </Space>
    ),
  },
];

export default columns;
