// import { Space, Tag } from "antd"
import dayjs from "dayjs"

const columns = () => [
    {
        title: "ID",
        width: 150,
        align: "center",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "消费内容",
        width: 300,
        align: "center",
        dataIndex: "zid",
        key: "zid",
        render: (_, record) => (
            <>
                <div>{record?.book?.title}--({record?.chapter?.title})</div>
                {/* <div></div> */}
            </>
        )
    },
    {
        title: "消费书币",
        width: 150,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "消费书券",
        width: 120,
        align: "center",
        dataIndex: "coupon_score",
        key: "coupon_score",
    },
    {
        title: "消费时间",
        // width: 120,
        align: "center",
        dataIndex: "addtime",
        key: "addtime",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
]

export default columns