import { Col, Popover, Row } from "antd"

const columns = () => [
    {
        title: "创意ID",
        width: 160,
        align: "center",
        dataIndex: "creative_id",
        key: "creative_id",
        ellipsis: true,
        fixed: 'left',
    },
    {
        title: "素材",
        width: 200,
        align: "center",
        dataIndex: "sucai",
        key: "sucai",
        ellipsis: true,
        render: (_, record) => {
            return <Row justify="start">
                <Col span={6}>
                    <Popover placement="right" content={
                        record?.attach_type && record?.attach_type === '视频' ?
                        <video 
                            alt="" 
                            controls
                            src={record?.oss_url} 
                            controlslist="nodownload"
                            preload="none"
                            width={350}
                            poster={`${record?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                        /> : <img 
                                alt="" 
                                src={record?.oss_url} 
                                width={350}
                            />
                    }>
                        {
                            record?.attach_type && record?.attach_type === '视频' ? 
                            <video 
                                alt="" 
                                src={record?.oss_url} 
                                width={20} 
                                poster={`${record?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                            /> : <img 
                                    alt="" 
                                    src={record?.oss_url} 
                                    width={50} 
                                    style={{marginTop: 5, marginRight: 10}}
                                />
                        }
                        
                    </Popover>
                </Col>
                <Col span={4}>
                    <div>
                        {record?.attach_type}
                        <div>ID:{record?.attach_id}</div>
                    </div>
                </Col>
            </Row>
        }
    },
    {
        title: "消耗",
        width: 90,
        align: "center",
        dataIndex: "cost",
        key: "cost",
        sorter: {}
    },
    {
        title: "转化数",
        width: 100,
        align: "center",
        dataIndex: "convert",
        key: "convert",
        sorter: {}
    },
    {
        title: "转化成本",
        width: 100,
        align: "center",
        dataIndex: "convert_cost",
        key: "convert_cost",
        sorter: {}
    },
    {
        title: "展示数",
        width: 100,
        align: "center",
        dataIndex: "show",
        key: "show",
        sorter: {}
    },
    {
        title: "点击数",
        width: 100,
        align: "center",
        dataIndex: "click",
        key: "click",
        sorter: {}
    },
    {
        title: "点击率",
        width: 100,
        align: "center",
        dataIndex: "ctr",
        key: "ctr",
        sorter: {}
    },
    {
        title: "平均点击单价",
        width: 120,
        align: "center",
        dataIndex: "avg_click_costs",
        key: "avg_click_costs",
        sorter: {}
    },
    {
        title: "平均千次展示费用",
        width: 150,
        align: "center",
        dataIndex: "avg_show_cost",
        key: "avg_show_cost",
        sorter: {}
    },
    {
        title: "转化率(%)",
        width: 120,
        align: "center",
        dataIndex: "convert_rate",
        key: "convert_rate",
        sorter: {}
    },
    {
        title: "深度转化数",
        width: 125,
        align: "center",
        dataIndex: "deep_convert",
        key: "deep_convert",
        sorter: {}
    },
    {
        title: "深度转化成本",
        width: 130,
        align: "center",
        dataIndex: "deep_convert_cost",
        key: "deep_convert_cost",
        sorter: {}
    },
    {
        title: "深度转化率",
        width: 125,
        align: "center",
        dataIndex: "deep_convert_rate",
        key: "deep_convert_rate",
        sorter: {}
    },
    {
        title: "所属计划ID",
        width: 125,
        align: "center",
        dataIndex: "ad_id",
        key: "ad_id",
    },
    {
        title: "所属计划",
        width: 125,
        align: "center",
        dataIndex: "ad_name",
        key: "ad_name",
    },
    {
        title: "所属广告组ID",
        width: 125,
        align: "center",
        dataIndex: "campaign_id",
        key: "campaign_id",
    },
    {
        title: "所属账户ID",
        width: 125,
        align: "center",
        dataIndex: "advertiser_id",
        key: "advertiser_id",
    },
    {
        title: "所属账户",
        width: 125,
        align: "center",
        dataIndex: "advertiser_name",
        key: "advertiser_name",
    },
]

export default columns