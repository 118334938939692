import { Card } from "antd";
import React from "react";
import { useState } from "react";
import SelectProject from "./SelectedProject";
import AddProject from "./add";
import { landingCheck } from "../../../api/advertise";
import { useLocation } from "react-router-dom";
import AdvertiseNewCopy from "../AdvertiseNew/AddPlan/copyAddPlan"

export default function SelectAdd() {
  const [activeTabKey, setActiveTabKey] = useState(1);
  const [selectOptions, setSelectOptions] = useState([]);
  const [externalUrlList, setExternalUrlList] = useState([]);

  const { state } = useLocation();

  const tabList = [
    { key: 1, tab: "选择已有项目" },
    { key: 2, tab: "新建项目" },
  ];

  const createNext = async (options) => {
    setActiveTabKey(2);
    setSelectOptions(options);
    // 批量小程序广告时，广告账户落地页是否已设置
    if (options[0]?.landing_type && options[0]?.landing_type === "MICRO_GAME") {
      const res = await landingCheck({ change_id: options[0].change_id });
      if (res?.code === 200) {
        const { data } = res;
        setExternalUrlList(data);
      } else {
        return false;
      }
    }
  };

  const contentList = {
    1: <SelectProject createNext={createNext} />,
    2: (
      <AddProject
        projectFinish={selectOptions[0]?.id ? true : false}
        projectId={selectOptions[0]?.id}
        changeId={selectOptions[0]?.change_id}
        landingType={selectOptions[0]?.landing_type}
        externalUrlList={externalUrlList}
        bid_type={selectOptions[0]?.bid_type}
        bidType={selectOptions[0]?.bid_type}
        budgetSwitch={selectOptions[0]?.budget_optimize_switch}
        deliveryMode={selectOptions[0]?.delivery_mode}
        microPromotionType={selectOptions[0]?.micro_promotion_type}
        deepBidType={selectOptions[0]?.deep_bid_type}
        isCopy={state?.operate && state?.operate === "copy"}
        advertiserIds={selectOptions[0]?.advertiser_ids}
        externalAction={selectOptions[0]?.external_action}
      />
    ),
  };

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  return state?.operate && state?.operate === "copy" ? (
    <AdvertiseNewCopy {...state?.record}/>
  ) : (
    <Card
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
    >
      {contentList[activeTabKey]}
    </Card>
  );
}
