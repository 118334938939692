const options = [
    {
        title: "日期",
        width: 100,
        align: "center",
        dataIndex: "day",
        key: "day",
        fixed: 'left',
    },
    {
        title: "新用户",
        width: 80,
        align: "center",
        dataIndex: "new_user",
        key: "new_user",
    },
    {
        title: "总充值金额",
        width: 100,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "总消耗",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "回本率",
        width: 100,
        align: "center",
        dataIndex: "huiben_lv",
        key: "huiben_lv",
    },
    {
        title: "首日充值人数",
        width: 100,
        align: "center",
        dataIndex: "pay_user",
        key: "pay_user",
    },
    {
        title: "首日首充金额",
        width: 100,
        align: "center",
        dataIndex: "first_day_first_pay",
        key: "first_day_first_pay",
    },
    {
        title: "首日充值金额",
        width: 100,
        align: "center",
        dataIndex: "first_day_pay",
        key: "first_day_pay",
    },
    {
        title: "总充值人数",
        width: 100,
        align: "center",
        dataIndex: "total_pay_user",
        key: "total_pay_user",
    },
]

// 动态添加第1天到第60天的列
for(let i = 1; i <= 60; i++) {
    options.push({
        title: `第${i}天`,
        width: 130,
        align: "center",
        dataIndex: `day${i}`,
        key: `day${i}`,
        render: (_, record) => {
            let day = `day${i}`
            let recharge = record?.rangeData[day]?.recharge
            let cost = record?.cost
            let huiLv = cost ? (recharge / cost).toFixed(2) : 0
            return <>
            <div style={{textAlign: 'left'}}>充值：{recharge}</div>
            <div style={{textAlign: 'left'}}>回本率：{huiLv}</div>
            </>
        }
    })
}

const columns = () => options

export default columns