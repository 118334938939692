import {useCallback, useEffect, useState} from 'react';
import {getAdvertiserList} from '@/api/advertise';
import {getAdvertiseRule} from '@/api/delivery';
import dayjs from 'dayjs';
import { FORMAT, DEFAULT_ADVERTISER_TYPE } from '../constants'

const useInit = ({ formData }) => {
  // 页面初始化的时候, loading
  const [initLoading, setInitLoading] = useState(false)
  // 账户列表
  const [accounts, setAccounts] = useState([]);
  // 原来的数据
  const [originData, setOriginData] = useState({});

  // 获取账户信息
  const getAccounts = async () => {
    const res = await getAdvertiserList();
    if (res?.['code'] === 200) {
      const { data } = res;
      let list = data?.['lists'];
      let newList = list.map((item) => {
        return { label: item?.account_name, value: item?.account_id };
      });
      setAccounts(newList);
    }
  };

  // 查询当前用户的规则信息
  const queryRule = useCallback(async () => {
    const res = await getAdvertiseRule();
    const { data, code } = res || {};
    if (code !== 200) {
      return;
    }

    const origin = data;
    const timeCondition = origin?.timeCondition || {};
    const ruleCondition = origin?.ruleCondition || {};

    setOriginData(origin);
    // 回显数据
    const advertiserList = (ruleCondition.advertiserList || [])?.map?.((e) => {
      return { label: e.advertiserName, value: e.advertiserId }
    })
    const time = dayjs(timeCondition?.time, FORMAT)
    formData.setFieldsValue({
      status: origin?.status === 'enable',
      // 广告账户设置
      advertiserSelectType: ruleCondition.advertiserSelectType || DEFAULT_ADVERTISER_TYPE,
      advertiserList,
      advertiserTotalCost: ruleCondition.advertiserTotalCost,
      advertiserTotalCostMax: ruleCondition.advertiserTotalCostMax,
      advertiserTopNum: ruleCondition.advertiserTopNum,
      // 广告设置
      advertCount: ruleCondition.advertCount,
      advertCost: ruleCondition.advertCost,
      advertTopNum: ruleCondition.advertTopNum,
      // 广告素材设置
      materialAdvertTopNum: ruleCondition.materialAdvertTopNum,
      // 执行时间
      time: time.isValid() ? time : undefined,
    })
  }, [formData])

  // 初始化的时候, 获取需要的数据
  useEffect(() => {
    setInitLoading(true);
    Promise.all([
      getAccounts(),
      queryRule(),
    ]).finally(() => {
      setInitLoading(false);
    });
  }, [queryRule]);

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().includes(inputValue.toLowerCase());
  };

  // 清空账户列表
  const onClearAccounts = () => {
    formData.setFieldsValue({ advertiserList: [] });
  }

  return {
    initLoading,
    accounts,
    originData,
    filterOption,
    onClearAccounts,
  }
}

export default useInit
