import { Card, DatePicker, Form, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import columns from "./columns";
import { profitTrendGroup } from "../../../../api/statistics";
import { numDiv, numMulti } from "../../../../util/calculate";

const {Text} = Typography

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await profitTrendGroup(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setLoading(false);
    }
  };

  // 日期切换
  const onDateChange = (value) => {
    if (value) {
      let day = value.format("YYYYMMDD");
      getList({ day });
    } else {
      getList();
    }
  };

  return (
    <Card
      title={
        <Form layout="inline">
          <Form.Item label="日期" name="day">
            <DatePicker onChange={onDateChange} />
          </Form.Item>
        </Form>
      }
    >
      <Table
        sticky
        dataSource={list}
        columns={columns()}
        loading={loading}
        pagination={false}
        size="middle"
        bordered={true}
        summary={(pageData) => {
          let totalCost = 0;
          let totalMoney = 0;
          let totalNewNoBidCost = 0;
          let totalNewNoBidMoney = 0;
          let totalProfit = 0;
          let totalGrantCost = 0;
          pageData.forEach(
            ({
              total_cost,
              total_money,
              new_noBid_cost,
              new_noBid_money,
              grant_cost,
              profit,
            }) => {
              totalCost += parseFloat(total_cost);
              totalMoney += parseFloat(total_money);
              totalNewNoBidCost += parseFloat(new_noBid_cost);
              totalNewNoBidMoney += parseFloat(new_noBid_money);
              totalGrantCost += parseFloat(grant_cost);
              totalProfit += parseFloat(profit);
            }
          );
          return (
            <>
              {pageData && pageData.length > 0 ? (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2} align="center">
                    <Text type="danger" strong={true}>
                      汇总
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <Text style={{ fontSize: 12 }}>
                      {totalMoney.toFixed(2)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <Text style={{ fontSize: 12 }}>{totalCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <Text style={{ fontSize: 12 }}>
                      {totalNewNoBidMoney.toFixed(2)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    <Text style={{ fontSize: 12 }}>
                      {totalNewNoBidCost.toFixed(2)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    <Text style={{ fontSize: 12 }}>
                      {totalGrantCost.toFixed(2)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    <Text style={{ fontSize: 12 }}>
                      {parseFloat(totalCost) && parseFloat(totalMoney)
                        ? numMulti(
                            numDiv(totalMoney * 1, totalCost * 1),
                            100
                          ).toFixed(2) + "%"
                        : 0}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center">
                    <Text style={{ fontSize: 12 }}>
                      {totalProfit.toFixed(2)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              ) : (
                ""
              )}
            </>
          );
        }}
      />
    </Card>
  );
}
