import { Button, Typography } from "antd";
import dayjs from "dayjs";

const columns = ({handleReUp}) => [
    {
        title: "2.0广告ID",
        width: 150,
        align: "center",
        dataIndex: "ad_id",
        key: "ad_id",
        ellipsis: true,
    },
    {
        title: "推广外链ID",
        width: 120,
        align: "center",
        dataIndex: "promotion_id",
        key: "promotion_id",
    },
    {
        title: "订单ID",
        width: 100,
        align: "center",
        dataIndex: "order_id",
        key: "order_id",
      },
      {
        title: "订单号",
        width: 100,
        align: "center",
        dataIndex: "order_num",
        key: "order_num",
      },
      {
        title: "回传金额",
        width: 110,
        align: "center",
        dataIndex: "money",
        key: "money",
      },
    {
        title: "备注",
        width: 120,
        align: "center",
        dataIndex: "remark",
        key: "remark",
        ellipsis: true,
    },
    {
        title: "OPENID",
        width: 100,
        align: "center",
        dataIndex: "user_id",
        key: "user_id",
    },
    {
        title: "回传结果",
        width: 250,
        align: "center",
        dataIndex: "result",
        key: "result",
        ellipsis: true,
    },
    {
        title: "是否回传",
        width: 120,
        align: "center",
        dataIndex: "is_up",
        key: "is_up",
        render: (value) => {
            let val = parseInt(value)
            if (val === 1) {
              return <div>正常回传</div>
            } else if (val === 2) {
              return <div>金额卡掉</div>
            } else if (val === 0) {
              return <div>卡回传策略过滤</div>
            } else {
              return <Typography.Text type="danger">未匹配上</Typography.Text>
            }
          }
    },
    {
        title: "是否卡掉重新回传",
        width: 100,
        align: "center",
        dataIndex: "is_retry",
        key: "is_retry",
        render: value => (
            value === 1 ? '是' : '否'
        )
    },
    {
        title: "回传时间",
        width: 160,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "操作",
        width: 160,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        render: (_, record) => (
            record?.is_real_up === 0 ? <Button type="primary" onClick={() => handleReUp(record)}>重新回传</Button> : ''
        )
    },
]

export default columns