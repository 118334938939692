import { numDiv, numMulti } from "../../../../util/calculate"

const options = [
    {
        title: "日期",
        width: 100,
        align: "center",
        dataIndex: "day",
        key: "day",
        fixed: 'left',
    },
    {
        title: "新增用户",
        width: 100,
        align: "center",
        dataIndex: "new_user",
        key: "new_user",
    },
    {
        title: "累计充值",
        width: 100,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "成本",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "回本率",
        width: 100,
        align: "center",
        dataIndex: "huiben_lv",
        key: "huiben_lv",
    },
    {
        title: "前30天充值",
        width: 110,
        align: "center",
        dataIndex: "huiben_30",
        key: "huiben_30",
        render: (_, record) => (
            record?.rangeData?.day30Money?.day30Money
        )
    },
    {
        title: "回本率30天",
        width: 150,
        align: "center",
        dataIndex: "huiben_lv30",
        key: "huiben_lv30",
        render: (_, record) => {
            let percent = record?.rangeData?.day30Money?.percent
            let afterPercent = record?.rangeData?.day30Money?.afterPercent
            return <>
                <div style={{textAlign: 'left'}}>回本率：{percent}</div>
                <div style={{textAlign: 'left'}}>分成后：{afterPercent}</div>
            </>
        }
    },
    {
        title: "首日充值人数",
        width: 100,
        align: "center",
        dataIndex: "first_pay_users",
        key: "first_pay_users",
        render: (_, record) => (
            record?.rangeData?.firstPayUsers
        )
    },
    {
        title: "首日首充|首日充值",
        width: 100,
        align: "center",
        dataIndex: "first_money",
        key: "first_money",
        render: (_, record) => {
            return `${record?.first_day_first_pay} | ${record?.rangeData?.firstDayPay}`
        }
    },
    {
        title: "首日首充客单价|首日客单价",
        width: 100,
        align: "center",
        dataIndex: "per_sale",
        key: "per_sale",
        render: (_, record) => {
            return `${record?.rangeData?.perSale?.firstPer} | ${record?.rangeData?.perSale?.per}`
        }
    },
]

// 动态添加第1天到第60天的列
for(let i = 0; i <= 59; i++) {
    options.push({
        title: `第${i+1}天`,
        width: 130,
        align: "center",
        dataIndex: `day${i}`,
        key: `day${i}`,
        render: (_, record) => {
            let extend = record?.extendList
            let bei = extend?.[i]?.beishu
            let recharge = extend?.[i]?.money
            let cost = record?.cost
            let return_cost = parseInt(cost) && recharge !== undefined ? numMulti(numDiv(recharge, cost), 100).toFixed(2) + '%' : 0
            return <>
            <div style={{textAlign: 'left'}}>充值：{recharge || 0}</div>
            <div style={{textAlign: 'left'}}>回本率：{return_cost}</div>
            <div style={{textAlign: 'left'}}>倍数：{bei || 0}</div>
            </>
        }
    })
}

const columns = () => options

export default columns