import { Button, Card, DatePicker, Form } from 'antd'
import React, { useState } from 'react'
import Table from '../../../components/TableNoSelection'
import columns from './accountColumns'
import { useEffect } from 'react'
import { getManagerAccount } from '../../../api'
import { useLocation } from 'react-router-dom'

const { RangePicker } = DatePicker
export default function Account() {
    const [list, setList] = useState([])
    const { state } = useLocation()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getList()
    }, [])

    const getList = async (params = {}) => {
        params.id = state.id
        const res = await getManagerAccount(params)
        const { data } = res
        if (res?.code === 200) {
            setList(data)
            setLoading(false)
        }
    }

    // 日期筛选查询
    const onSearch = values => {
        let createdAt
        if (values["range_date"]) {
            createdAt = values["range_date"].map((date) => {
                return date.format("YYYYMMDD")
            })
        }
        getList({range_date: createdAt})
    }

    return (
        <Card
            title={
                <Form layout='inline' onFinish={onSearch}>
                    <Form.Item name='range_date'>
                        <RangePicker style={{ width: '100%', textAlign: 'center' }} size='middle' />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType='submit'>确定</Button>
                    </Form.Item>
                </Form>
            }
        >
            <Table
                dataSource={list}
                columns={columns()}
                pagination={false}
                bordered={true}
                loading={loading}
            />
        </Card>
    )
}
