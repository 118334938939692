import React, { useState } from 'react'
import { LockOutlined, UserOutlined, SafetyOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Space } from 'antd';
import './login.less'
import siginImg from '../../assets/images/logo-signin.png'
import { connect } from 'react-redux';
import { saveUserInfo } from '../../redux/actions/login'
import { Navigate, useNavigate } from 'react-router-dom';
import { createCode, login } from '../../api';
import { useEffect } from 'react';

function Login(props) {
  const [loading, setLoading] = useState(false)
  const [verifyCode, setVerifyCode] = useState('')
  const [codeKey, setCodeKey] = useState('')
  const navigate = useNavigate()
  const { user } = props

  useEffect(() => {
    getCode()
  }, [])
  
  // 表单提交登录
  const onFinish = async (values) => {
    const {username, password, verify_code} = values
    const data = await login(username, password, verify_code, codeKey)
    let token = data?.data?.token
    setLoading(true)

    if (data?.code === 200) {
      let nickname = data?.data?.nickname
      let shortname = data?.data?.shortname
      let avatar = data?.data?.avatar
      let nicknameId = data?.data?.id
      let is_super = data?.data?.is_super
      props.saveUser({username: values.username, token, shortname, nickname, avatar, nicknameId, is_super})
      localStorage.setItem('admin_token', token)
      navigate('/admin/index/welcome')
    } else {
      setLoading(false)
      getCode()
    }

    
  }

  // 获取验证码
  const getCode = async () => {
    const {data} = await createCode()
    setVerifyCode(data?.code)
    setCodeKey(data?.key)
  }

  if (user.isLogin) {
    return <Navigate to='/admin/index/welcome' replace />
  } else {
    return (
      <div className='login'>
        <div className='login-main'>
          <div className='login-title'>
            <Space size='middle'>
              <img alt="图片" src={ siginImg } />
              <h3 className="login-title-text">
                投放后台管理系统
              </h3>
            </Space>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: '请输入账号!',
                },
              ]}
            >
              <Input 
                prefix={<UserOutlined className="site-form-item-icon" />} 
                placeholder="账号" 
                size='large'
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: '请输入密码!',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="密码"
                size='large'
              />
            </Form.Item>
            <Form.Item
              name="verify_code"
              rules={[
                {
                  required: true,
                  message: '请输入验证码!',
                },
              ]}
            >
              <Row gutter={24}>
                <Col xl={{span:14}}>
                  <Input
                    prefix={<SafetyOutlined /> }
                    placeholder="验证码"
                    size='large'
                    style={{width: '100%'}}
                />
                </Col>
                <Col xl={{span: 10}}>
                  <div className='login-code'>
                    <img src={verifyCode} alt="验证码" onClick={getCode} />
                  </div>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>记住我</Checkbox>
              </Form.Item>

            </Form.Item>

            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit" 
                className="login-form-button"
                size='large'
                loading={loading}
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
  
}

export default connect(
  state => ({user: state.user}),
  {
    saveUser: saveUserInfo
  }
)(Login)
