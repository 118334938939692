import React from 'react'
import PromotionComponent from '../Public/Promotion/index'
import { useState } from 'react'
import { useEffect } from 'react'
import { getPlayletPromotion, playletBackSetSave, playletConfigList, playletPromotionAdd, playletPromotionItem, playletPromotionStatusEdit, playletPromotionTitleEdit, promotionUpdate } from '../../../api/playlet'
import { message } from 'antd'
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
import { getPanelList } from '../../../api'

export default function Promotion() {
  const [show, setShow] = useState(false)
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [statusLoading, setStatusLoading] = useState(0)
  const [backShow, setBackShow] = useState(false)
  const [configDesc, setConfigDesc] = useState([])
  const [operate, setOperate] = useState('')
  const [promotionItem, setPromotionItem] = useState([])
  const [editor, setEditor] = useState(BraftEditor.createEditorState(null));
  const [editLoading, setEditLoading] = useState(false)
  const [conditions, setConditions] = useState([])
  const [panelList, setPanelList] = useState([])

  useEffect(() => {
    getList()
    getPanels()
  }, [])

  // 获取列表数据
  const getList = async (params = {}) => {
    const res = await getPlayletPromotion({ ...params, app_type: 1006 })
    const { data } = res
    if (res?.code === 200) {
      let list = data?.lists
      let newList = list.map((item) => {
        item.id += '@@##' + item?.promotion_id
        return item
      })
      setList(newList)
      setTotal(data?.total)
      setLoading(false)
    }
  }

  // 获取默认档位面板
  const getPanels = async () => {
    const res = await getPanelList({status: 1, order: 'id_asc'})
    if (res?.code === 200) {
      const {data} = res
      setPanelList(data?.lists)
    }
  }

  // 推广链接名称搜索
  const onTitleSearch = value => {
    setConditions({title: value})
    getList({title: value})
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPageSize(size)
    setConditions({...conditions, page, pageSize: size})
    getList({...conditions, page, pageSize: size})
  }

  // 添加弹窗
  const addPromotion = () => {
    setShow(true)
    setOperate('add')
    getConfigDesc()
  }

// 编辑弹窗
const updatePromotion = async (record) => {
  setOperate('update')
  getConfigDesc()
  setEditLoading(true)
  setShow(true)
  let id_promotionId = record?.id.split("@@##");
  const res = await playletPromotionItem({id: id_promotionId[0]})
  if (res?.code === 200) {
    const {data} = res
    let editorContent = data?.item?.content;
    setEditor(BraftEditor.createEditorState(editorContent))
    setPromotionItem(data?.item)
    setEditLoading(false)
  }
}

  // 关闭弹窗
  const onClose = () => {
    setShow(false)
    setOperate('')
    setConfirmLoading(false)
    setPromotionItem([])
    setEditor(ContentUtils.clear(editor));
  }

  // 打开卡回传弹窗
  const backSet = async record => {
    setBackShow(true)
  }

  // 关闭卡回传弹窗
  const onSetClose = () => {
    setBackShow(false)
    setConfirmLoading(false)
  }

  // 添加推广链接
  const handleAddPromotion = async (params, form) => {
    setConfirmLoading(true)
    const res = await playletPromotionAdd({...params, app_type: 1006})
    if (res?.code === 200) {
      message.success('推广链接添加成功', 2)
      setTimeout(() => {
        form.resetFields()
        onClose()
        getList()
      }, 1000);
    } else {
      setConfirmLoading(false)
    }
  }

  // 处理推广链接编辑
  const handleUpdatePromotion = async (params, form) => {
    setConfirmLoading(true)
    const res = await promotionUpdate({...params})
    if (res?.code === 200) {
      message.success('推广链接编辑成功', 2)
      setTimeout(() => {
        form.resetFields()
        onClose()
        getList()
      }, 1000);
    } 
    setConfirmLoading(false)
  }

  // 添加卡回传
  const handleBackSet = async (params) => {
    setConfirmLoading(true)
    const res = await playletBackSetSave({...params, app_type: 1006})
    if (res?.code === 200) {
      message.success('卡回传设置成功', 2)
      setTimeout(() => {
        onSetClose()
        // getList()
      }, 1000);
    } else {
      setConfirmLoading(false)
    }
  }

  // 删除推广链接
  const handleDeletePromotion = async () => {
    // 删除成功后重新获取列表记录
    getList()
  }

  // 修改备注(标题)
  const promotionTitleEdit = async ({ id, value }) => {
    const res = await playletPromotionTitleEdit({id, title: value, app_type: 1006})
    if (res?.code === 200) {
      message.success('标题修改成功')
      getList()
    }
  }

  // 状态修改
  const promotionStatusEdit = async ({id, status}) => {
    setStatusLoading(id)
    const res = await playletPromotionStatusEdit({id, status, app_type: 1002})
    if (res?.code === 200) {
      message.success('状态更改成功')
      setStatusLoading(0)
    }
  }

  // 获取配置说明内容
  const getConfigDesc = async () => {
    const res = await playletConfigList({app_type: 1006})
    if (res?.code === 200) {
      const {data} = res
      let lists = data?.lists
      if (lists) {
        let desc = lists[0]?.desc ? JSON.parse(lists[0]?.desc) : null;
        setConfigDesc(desc)
      }
    }
  }

  // 富文本编辑器输入
  const onEditorChange = value => {
    setEditor(value)
  }
  
  // 传到子组件的参数
  const propsData = {
    list, total, page, loading,
    pagesize, perPage:pageSize,
    addPromotion, onClose, show,
    handleAddPromotion, confirmLoading, handleUpdatePromotion,
    backShow, onSetClose, backSet, handleBackSet,
    app_type: 1006, handleDeletePromotion, promotionTitleEdit,
    promotionStatusEdit, statusLoading, configDesc, onEditorChange,
    updatePromotion, operate, promotionItem, editor, editLoading,
    onTitleSearch, panelList
  }

  return (
    <div className='yingtan_promotion'>
        <PromotionComponent {...propsData} />
    </div>
  )
}
