// 榜单类型枚举
export const RankTypeEnums = {
  RED: 0,
  BLACK: 1,
}

// 榜单类型
export const rankTypes = [
  // 黑榜还是红榜，空或者0是红榜，其余数字是黑榜
  { label: '红榜', value: RankTypeEnums.RED },
  { label: '黑榜', value: RankTypeEnums.BLACK },
]
