const tableShowColumns = () => [
    {
        title: "日期",
        width: 100,
        align: "center",
        dataIndex: "day",
        key: "day",
        fixed: 'left',
    },
    {
        title: "素材手",
        width: 100,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        ellipsis: true,
        // render: (_, record) => (
        //     record?.user?.nickname
        // )
    },
    {
        title: "广告消耗",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "头条消耗",
        width: 100,
        align: "center",
        dataIndex: "total_cost",
        key: "total_cost",
    },
    {
        title: "快手消耗",
        width: 100,
        align: "center",
        dataIndex: "total_kuaishou_cost",
        key: "total_kuaishou_cost",
    },
    {
        title: "OPPO消耗",
        width: 100,
        align: "center",
        dataIndex: "total_oppo_cost",
        key: "total_oppo_cost",
    },
    {
        title: "VIVO消耗",
        width: 100,
        align: "center",
        dataIndex: "total_vivo_cost",
        key: "total_vivo_cost",
    },
    {
        title: "adq消耗",
        width: 100,
        align: "center",
        dataIndex: "total_adq_cost",
        key: "total_adq_cost",
    },
    {
        title: "头条赔付金额",
        width: 100,
        align: "center",
        dataIndex: "total_claim",
        key: "total_claim",
    },
    {
        title: "总消耗",
        align: "center",
        width: 100,
        dataIndex: "total_all_cost",
        key: "total_all_cost",
        sorter: {},
    },
]

export default tableShowColumns