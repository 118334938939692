/* 代理商组织相关参数配置 */
import React, { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  InputNumber,
  Row,
  Space,
  Switch,
  message,
} from "antd";
import { useState } from "react";

import {
  getOrganConfig,
  getPanelList,
  getPlayletConfigData,
  getPlayletOpenData,
  panelBidLimitUpdate,
  panelStatusUpdate,
  saveOrganConfig,
  savePlayletOpenData,
  setPlayletConfigData,
} from "../../../api/index";
import { Navigate } from "react-router-dom";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import EditableCell from "../../../components/Editable/EditableCell";
import { getFastPlaylet } from "../../../api/playlet";

export default function Index() {
  const [formData] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [isAgent, setIsAgent] = useState(false);
  const [statusLoading, setStatusLoading] = useState(0);
  const [listLoading, setListLoading] = useState(0);
  const [playletModalShow, setPlayletModalShow] = useState(false);
  const [playletModalLoading, setPlayletModalLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [profitConfigData, setProfitConfigData] = useState([]);
  const [playletOpenShow, setPlayletOpenShow] = useState(false);
  const [playletOpenLoading, setPlayletOpenLoading] = useState(false);
  const [playletOpenData, setPlayletOpenData] = useState([]);

  useEffect(() => {
    getOrganConfigInfo();
    getPanels();
  }, []);

  //获得配置
  const getOrganConfigInfo = async () => {
    const res = await getOrganConfig();
    if (res?.code == 200) {
      const { data } = res;
      setIsAgent(data?.is_agent);
      formData.setFieldsValue({
        material_public_num: data?.num ?? 0,
        material_total_num: data?.total_num ?? 0,
        basic_profit_value: data?.basic_value ?? 0,
      });
    }
  };

  //提交配置
  const commitConfigData = async () => {
    setLoading(true);
    const res = await saveOrganConfig(formData.getFieldsValue());
    if (res?.code == 200) {
      message.success(res.message, 1);
      getOrganConfigInfo();
      setLoading(false);
    }
  };

  // 修改最高出价
  const handleSave = async (row) => {
    if (parseFloat(row.bid_limit) === 0) {
      return false;
    }
    setListLoading(true);
    const newData = [...list];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    const res = await panelBidLimitUpdate({
      id: row.id,
      bid_limit: row.bid_limit,
    });
    if (res?.code === 200) {
      setList(newData);
    }
    setListLoading(false);
  };

  const components = {
    body: {
      // row: EditableRow,
      cell: EditableCell,
    },
  };

  const getColumns = ({ onStatusChange, statusLoading }) => {
    let newColumns = columns({ onStatusChange, statusLoading });
    return newColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          field: "bid_limit",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
  };

  // 获取默认档位面板
  const getPanels = async () => {
    setListLoading(true);
    const res = await getPanelList();
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setListLoading(false);
    }
  };

  // 设置档位状态启停
  const onStatusChange = async (record, status) => {
    setStatusLoading(record?.id);
    let value = status === true ? 1 : 0;
    const res = await panelStatusUpdate({ status: value, id: record?.id });
    if (res?.code === 200) {
      setStatusLoading(0);
    }
  };

  // 打开剧场盈亏基础值配置弹窗
  const setPlayletProfit = async () => {
    if (!isAgent) {
      message.error("只有组织机构帐号才能操作");
      return false;
    }
    // 获取对应的三方剧场
    const res = await getFastPlaylet();
    if (res?.code === 200) {
      const { data } = res;
      setPackageList(data?.lists);
    }
    setPlayletModalShow(true);
    setPlayletModalLoading(true);
    // 获取三方剧场对应的盈亏值
    const configData = await getPlayletConfigData();
    if (configData?.code === 200) {
      const { data } = configData;
      setProfitConfigData(data);
      setPlayletModalLoading(false);
    }
  };

  // 关闭弹窗
  const playletModalClose = () => {
    setPlayletModalShow(false);
  };

  // 剧场盈亏基础值设置保存
  const profitFormSubmit = async (values) => {
    if (!isAgent) {
      message.error("只有组织机构帐号才能操作");
      return false;
    }
    let OjbVals = [];
    Object.values(values).forEach((value) => {
      OjbVals.push(value);
    });
    let filtered = OjbVals.filter((value) => value !== 0);
    if (filtered.length === 0) {
      message.error("请先设置剧场的基础盈亏值");
      return false;
    }
    let params = [];
    for (const key in values) {
      let keyArr = key.split("-");
      let item = {};
      if (keyArr.includes("mini")) {
        item = {
          app_type: keyArr[0],
          aliasname: keyArr[1],
          mini_value: values[key],
        };
      } else {
        item = {
          app_type: keyArr[0],
          aliasname: keyArr[1],
          byte_value: values[key],
        };
      }

      params.push(item);
    }
    setSubmitLoading(true);
    const res = await setPlayletConfigData({ config_data: params });
    if (res?.code === 200) {
      message.success("保存成功");
      setTimeout(() => {
        playletModalClose();
        setSubmitLoading(false);
      }, 500);
    }
  };

  // 三方剧场开放设置
  const playletOpenSet = async () => {
    if (!isAgent) {
      message.error("只有组织机构帐号才能操作");
      return false;
    }
    setPlayletOpenLoading(true);
    const res = await getPlayletOpenData();
    if (res.code === 200) {
      const { data } = res;
      setPlayletOpenData(data?.lists);
    }
    setPlayletOpenShow(true);
    setPlayletOpenLoading(false);
  };

  // 三方剧场开放保存设置
  const openChange = async (checked, menuKey, menuName) => {
    console.log(menuKey, menuName);
    const res = await savePlayletOpenData({
      is_open: checked,
      menu_key: menuKey,
      title: menuName,
    });
    if (res?.code === 200) {
      message.success("success");
    }
  };

  return (
    <>
      <Card title="基础参数配置" size="small">
        <Form onFinish={commitConfigData} form={formData} layout="inline">
          <Form.Item
            label="私有素材转公有需要非NOBID转化数"
            name="material_public_num"
          >
            <InputNumber allowclear="true" />
          </Form.Item>
          <Form.Item label="或者" colon={false} labelCol={{ xl: { span: 16 } }}>
            <div style={{ marginRight: 40 }}></div>
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="需要总转化数"
            name="material_total_num"
            style={{ marginLeft: -20 }}
          >
            <InputNumber allowclear="true" />
          </Form.Item>
          <Form.Item
            label="盈亏基础值"
            tooltip="用于统计投手每日的盈亏情况基值，设置为数值"
          >
            <Form.Item name="basic_profit_value">
              <InputNumber min={0} style={{ width: "60%" }} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" onClick={setPlayletProfit}>
                  添加剧场盈亏值
                </Button>
                <Button type="primary" onClick={playletOpenSet}>
                  三方剧场开放设置
                </Button>
              </Space>
            </Form.Item>
          </Form.Item>
          <Form.Item label colon={false}>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                提交
              </Button>
              <Button
                onClick={() => {
                  Navigate(-1);
                }}
              >
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>

      <Card style={{ marginTop: 20 }} title="充值档位面板" size="small">
        <Table
          components={components}
          dataSource={list}
          columns={getColumns({ onStatusChange, statusLoading })}
          loading={listLoading}
          pagination={false}
        />
      </Card>

      {/* 各剧场盈亏基础值配置缩 */}
      <Drawer
        title="剧场盈亏基础值设置"
        open={playletModalShow}
        onClose={playletModalClose}
        maskClosable={false}
        width={650}
        closeIcon={false}
        loading={playletModalLoading}
      >
        <Form labelCol={{ sm: { span: 9 } }} onFinish={profitFormSubmit}>
          <Row gutter={24}>
            {profitConfigData.length > 0
              ? profitConfigData.map((item) => {
                  return (
                    <Col span={12} key={item.app_type}>
                      <Form.Item
                        label={
                          <div style={{ fontWeight: "bold", fontSize: 13 }}>
                            {item.aliasname}
                          </div>
                        }
                      >
                        <Form.Item
                          label="微小"
                          name={`${item.app_type}-${item.aliasname}-mini`}
                          initialValue={item.mini_value}
                        >
                          <InputNumber style={{ width: "60%" }} />
                        </Form.Item>
                        <Form.Item
                          label="抖小"
                          name={`${item.app_type}-${item.aliasname}-byte`}
                          initialValue={item.byte_value}
                        >
                          <InputNumber style={{ width: "60%" }} />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  );
                })
              : packageList?.map((item) => {
                  return (
                    <Col span={12} key={item.app_type}>
                      <Form.Item
                        label={
                          <div style={{ fontWeight: "bold", fontSize: 13 }}>
                            {item.aliasname}
                          </div>
                        }
                      >
                        <Form.Item
                          label="微小"
                          name={`${item.app_type}-${item.aliasname}-mini`}
                          initialValue={0}
                        >
                          <InputNumber style={{ width: "60%" }} />
                        </Form.Item>
                        <Form.Item
                          label="抖小"
                          name={`${item.app_type}-${item.aliasname}-byte`}
                          initialValue={0}
                        >
                          <InputNumber style={{ width: "60%" }} />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  );
                })}
          </Row>
          <Form.Item label colon={false} labelCol={{ span: 16 }}>
            <Space>
              <Button onClick={playletModalClose}>取消</Button>
              <Button type="primary" htmlType="submit" loading={submitLoading}>
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>

      {/* 三方剧场开放设置 */}
      <Drawer
        title="三方剧场开放设置"
        open={playletOpenShow}
        onClose={() => setPlayletOpenShow(false)}
        maskClosable={false}
        width={700}
        loading={playletOpenLoading}
      >
        <Form labelCol={{ sm: { span: 16 } }}>
          <Row gutter={24}>
            {playletOpenData.map((item) => {
              return (
                <Col span={8} key={`${item.value}-${item.title}`}>
                  <Form.Item label={item.title}>
                    <Switch
                      defaultValue={item.is_open}
                      checkedChildren="开启"
                      unCheckedChildren="关闭"
                      onChange={(checked) =>
                        openChange(checked, item.value, item.title)
                      }
                    />
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
