import { Badge, notification } from "antd";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const useMessageNotify = data => {
  const { getChildrenMenus } = data || {};
  const navigate = useNavigate();

  const notifyId = useRef(1);
  // 已经通知过的消息
  const notifiedIds = useRef([]);

  const [api, contextHolder] = notification.useNotification({
    maxCount: 5,
    stack: true,
  });

  const closeNotify = event => {
    const target = event?.target;
    const element = target?.closest("[data-notify-id]");
    const notifyId = element?.getAttribute("data-notify-id");

    if (notifyId) {
      api.destroy(notifyId);
    }
  };

  // 显示 notify
  const showNotify = message => {
    const { category, title, created_at, content, id } = message || {};
    // 如果 id 存在并且已经通知过了，就不再通知
    if (id && notifiedIds.current.includes(id)) {
      console.log('fenge notify message 相同 id 不再通知', id);
      return;
    }

    notifiedIds.current.push(id);

    console.log('fenge notify message id 通知', id);

    api.open({
      message: (
        <div style={{ display: "flex" }}>
          <div>
            <Badge status="error" />
          </div>
          <div style={{ width: "75%" }}>
            【{category}】{title}
          </div>
          <div style={{ color: "#AAADB2" }}>{created_at}</div>
        </div>
      ),
      description: content,
      placement: "topRight",
      duration: null,
      onClick: event => {
        let url = `/admin/index/notification`;
        getChildrenMenus?.("index", false);
        navigate(url);

        closeNotify(event);
      },
      key: String(notifyId.current),
      props: {
        "data-notify-id": String(notifyId.current),
      },
    });

    // 通知 id 自增
    notifyId.current += 1;
  };

  const showNotifyList = messages => {
    console.log('fenge notify messages', messages);
    const list = Array.isArray(messages) ? messages : [];
    list.forEach(message => {
      showNotify(message);
    });
  };

  return {
    showNotify,
    showNotifyList,
    notifyContextHolder: contextHolder,
  };
};

export default useMessageNotify;
