import { postRequest } from "./request"

// 自动规则列表
export const getAutoRuleList = data => postRequest('/admin/auto_rule', data)

// 自动规则添加
export const createAutoRule = data => postRequest('/admin/auto_rule/create', data)

// 自动规则编辑
export const updateAutoRule = data => postRequest('/admin/auto_rule/update', data)

// 自动规则详情
export const getAutoRuleDetail = data => postRequest('/admin/auto_rule/detail', data)

// 自动规则状态修改
export const autoRuleStatusUpdate = data => postRequest('/admin/auto_rule/status_update', data)