import { useMemo } from "react";
import { Button, Space, Popconfirm } from "antd";

const useColumns = (data = {}) => {
  const { onEditLeave, onDeleteLeave, userList } = data;

  const columns = useMemo(() => {
    return [
      {
        title: "id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "用户名",
        dataIndex: "adminName",
        key: "adminName",
        render: (_, record) => {
          const user = userList?.find?.(e => {
            return e?.value === record?.adminId;
          });
          return user?.label || "";
        },
      },
      {
        title: "用户id",
        dataIndex: "adminId",
        key: "adminId",
      },
      {
        title: "请假开始时间",
        dataIndex: "leaveStartTime",
        key: "leaveStartTime",
      },
      {
        title: "请假结束时间",
        dataIndex: "leaveEndTime",
        key: "leaveEndTime",
      },
      {
        title: "操作",
        dataIndex: "operation",
        key: "operation",
        align: "center",
        render: (_, record) => {
          return (
            <Space>
              <Button type="link" onClick={() => onEditLeave(record)}>
                编辑
              </Button>
              <Popconfirm
                title="是否确认删除?"
                onConfirm={() => onDeleteLeave(record.id)}
                okText="确认"
                cancelText="取消"
                placement="topRight"
              >
                <Button type="link">
                  删除
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];
  }, [onEditLeave, onDeleteLeave, userList]);

  return {
    columns,
  };
};

export default useColumns;
