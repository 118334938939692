import React, { useState } from 'react'
import Table from '../../../components/TableNoSelection'
import columns from './planColumns'
import { Button, Card, DatePicker, Form } from 'antd'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { getManagerPlan } from '../../../api'

const { RangePicker } = DatePicker

export default function Plan() {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [conditions, setConditions] = useState([])
  const { state } = useLocation()


  useEffect(() => {
    getList()
  }, [])

  const getList = async (params = {}) => {
    params.id = state.id
    const res = await getManagerPlan(params)
    const { data } = res
    if (res?.code === 200) {
      setList(data?.lists)
      setTotal(data?.total)
      setLoading(false)
    }
  }

  // 日期筛选查询
  const onSearch = values => {
    let createdAt
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD")
      })
      setConditions({ ...conditions, range_date: createdAt })
    }
    getList({ range_date: createdAt })
  }

  const onchange = (pagination, filters, sorter, extra) => {
    let feild = sorter.columnKey
    let sortBy = sorter.order
    let order
    if (feild !== undefined) {
      if (sortBy === 'ascend') {
        order = feild + '_' + 'asc'
      } else {
        order = feild + '_' + 'desc'
      }
      getList({ order })
    }
  }

  // 每页显示条数切换回调
  const pagesize = (page, size) => {
    setPage(page)
    setConditions({ ...conditions, pageSize: size, page })
    getList({ pageSize: size, page })
  }

  return (
    <Card
      title={
        <Form layout='inline' onFinish={onSearch}>
          <Form.Item name='range_date'>
            <RangePicker style={{ width: '100%', textAlign: 'center' }} size='middle' />
          </Form.Item>
          <Form.Item>
            <Button htmlType='submit'>确定</Button>
          </Form.Item>
        </Form>
      }
    >
      <Table
        dataSource={list}
        columns={columns()}
        bordered={true}
        onChange={onchange}
        loading={loading}
        page={page}
        total={total}
        pagesize={pagesize}
      />
    </Card>
  )
}
