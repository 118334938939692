import React from "react";
import { Button, Card, Form, Input, message, Modal, Select, Space } from "antd";
import Table from "../../../components/TableTree";
import { useState } from "react";
import { useEffect } from "react";
import columns from "./columns";
import { SwapOutlined } from "@ant-design/icons";
import SaveModal from "./saveModal";
import AddMoal from "./addModal";
import { treeToArray } from "../../../util";
import { getMenuTree, menuDelete } from "../../../api/permission";

const {confirm} = Modal

export default function Menu() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [show, setShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  // 默认折叠
  const [expand, setExpand] = useState(false);
  const [expandKeys, setExpandKeys] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async (params = {}) => {
    const res = await getMenuTree({ ...params, order: "sort_asc" });
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setLoading(false);
    }
  };

  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();

  const getTreeList = () => {};

  const handleSelect = values => {
    getList(values);
  };

  // 点击编辑
  const handleEdit = record => {
    const arr = treeToArray(list);
    const res = arr.filter(item => {
      return item.id === record.pid;
    });
    setShow(true);
    form.setFieldsValue(record);
    form.setFieldsValue({ pname: res.length > 0 ? res[0]?.title : "主目录" });
  };

  const addMenu = () => {
    setAddModalShow(true);
  };

  // 关闭编辑弹窗
  const handleCancel = () => {
    setShow(false);
    getList();
  };

  // 关闭添加菜单弹窗
  const onClose = () => {
    setAddModalShow(false);
    getList();
  };

  const handleDelete = async record => {
    confirm({
      title: '确认删除该菜单?',
      onOk: async () => {
        const res = await menuDelete({id: record?.id})
        if (res?.code === 200) {
          message.success('菜单删除成功')
          setTimeout(() => {
            getList()
          }, 1000);
        }
      }
    })
    
  };

  // 展开折叠
  const handleExpand = () => {
    let expanded = !expand;
    if (!expanded) {
      setExpandKeys([]);
    } else {
      const keys = treeToArray(list).map(item => {
        return item.id;
      });
      setExpandKeys(keys);
    }
    setExpand(!expand);
  };

  return (
    <div className="menu">
      <Card
        className="menu-search"
        title={
          <Form layout="inline" form={filterForm} onFinish={handleSelect}>
            <Form.Item label="菜单名称" name="title">
              <Input allowClear placeholder="输入菜单名称" />
            </Form.Item>
            <Form.Item label="菜单状态" name="status">
              <Select
                allowClear
                placeholder="请选择状态"
                style={{ width: 120 }}
              >
                <Select.Option value={1}>正常</Select.Option>
                <Select.Option value={0}>停用</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
                <Button
                  onClick={() => {
                    filterForm.resetFields();
                  }}
                >
                  重置
                </Button>
              </Space>
            </Form.Item>
          </Form>
        }
        styles={{ body: { display: "none" } }}
        style={{ marginBottom: 10 }}
      />

      <Card
        className="menu-content"
        title={
          <Space>
            <Button
              type="primary"
              icon={<SwapOutlined />}
              onClick={handleExpand}
            >
              展开/折叠
            </Button>
            <Button type="primary" ghost onClick={addMenu}>
              添加菜单
            </Button>
          </Space>
        }
      >
        {list && list?.length > 0 ? (
          <Table
            dataSource={list}
            columns={columns({ handleEdit, handleDelete })}
            expandKey={1}
            expandedRowKeys={expandKeys}
            loading={loading}
          />
        ) : (
          ""
        )}
      </Card>

      {/* 添加菜单弹窗 */}
      <AddMoal
        show={addModalShow}
        onClose={onClose}
        getTreeList={getTreeList}
      />

      {/* 编辑弹窗 */}
      <SaveModal
        show={show}
        handleCancel={handleCancel}
        getTreeList={getTreeList}
        form={form}
      />
    </div>
  );
}
