import { Button, Space, Typography } from "antd";
import { numDiv, numMulti, numSub } from "../../../util/calculate";

const columns = ({
  updatePlanStatus,
  deletePlan,
  switchover,
  showBackResult,
}) => [
  {
    title: "广告名称",
    width: 120,
    align: "center",
    dataIndex: "ad_name",
    key: "ad_name",
  },
  {
    title: "广告ID",
    width: 100,
    align: "center",
    dataIndex: "ad_id",
    key: "ad_id",
  },
  {
    title: "所属账户",
    width: 130,
    align: "center",
    dataIndex: "advertiser_name",
    key: "advertiser_name",
  },
  {
    title: "广告状态",
    width: 120,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (_, record) => {
      return (
        <>
          <div style={{ marginBottom: 10 }}>{record?.statusTxt}</div>
          {record?.plan_ad_create?.status === "OK" ||
          record?.plan_ad_create?.status === "AD_STATUS_DELIVERY_OK" ? (
            <div>
              <Space>
                <Button
                  type="primary"
                  ghost
                  onClick={() => updatePlanStatus(record, "DISABLE")}
                  size="small"
                >
                  暂停
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => deletePlan(record)}
                  size="small"
                >
                  删除
                </Button>
              </Space>
            </div>
          ) : record?.plan_ad_create?.status === "DISABLED" ||
            record?.plan_ad_create?.status === "AD_STATUS_DISABLE" ? (
            <div>
              <Space>
                <Button
                  type="primary"
                  onClick={() => updatePlanStatus(record, "ENABLE")}
                  size="small"
                >
                  启动
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => deletePlan(record)}
                  size="small"
                >
                  删除
                </Button>
              </Space>
            </div>
          ) : (
            ""
          )}
        </>
      );
    },
  },
  {
    title: "广告预算",
    width: 90,
    align: "center",
    dataIndex: "budget",
    key: "budget",
    render: (_, record) => record?.plan_ad_create?.budget,
  },
  {
    title: "出价",
    width: 80,
    align: "center",
    dataIndex: "cpa_bid",
    key: "cpa_bid",
    render: (_, record) => record?.plan_ad_create?.cpa_bid,
  },
  {
    title: "消耗",
    width: 100,
    align: "center",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "回收",
    width: 90,
    align: "center",
    dataIndex: "money",
    key: "money",
    sorter: {},
  },
  {
    title: "回收率",
    width: 90,
    align: "center",
    dataIndex: "rate",
    key: "rate",
    render: (_, record) => {
      let cost = parseFloat(record?.cost)
      let rate = 0
      if (cost) {
        rate = numMulti(numDiv(record?.money, cost), 100).toFixed(2)
      }
      if (rate > 130) {
        return (
          <Typography.Text type="success" style={{ fontSize: 12 }}>
            {rate + "%"}
          </Typography.Text>
        );
      } else if (rate >= 0 && rate < 100) {
        return (
          <Typography.Text type="danger" style={{ fontSize: 12 }}>
            {rate + "%"}
          </Typography.Text>
        );
      } else if (rate >= 100 && rate <= 130) {
        return (
          <Typography.Text style={{ fontSize: 12 }}>
            {rate + "%"}
          </Typography.Text>
        );
      }
    },
  },
  {
    title: "实际转化成本",
    width: 100,
    align: "center",
    dataIndex: "real_convert_cost",
    key: "real_convert_cost",
  },
  {
    title: "转化成本",
    width: 100,
    align: "center",
    dataIndex: "convert_cost",
    key: "convert_cost",
  },
  {
    title: "转化数",
    width: 80,
    align: "center",
    dataIndex: "convert",
    key: "convert",
  },
  {
    title: "转化率(%)",
    width: 90,
    align: "center",
    dataIndex: "convert_rate",
    key: "convert_rate",
  },
  {
    title: "预估赔付",
    width: 100,
    align: "center",
    dataIndex: "claim",
    key: "claim",
    render: (_, record) => {
      let cpa_bid = record?.plan_ad_create?.cpa_bid;
      let convert_cost = record?.convert_cost;
      let convert = record?.convert;
      let claim = 0;
      if (cpa_bid > 0) {
        claim =
          numDiv(numSub(convert_cost, cpa_bid), cpa_bid) * 1 > 0.2
            ? numMulti(numSub(convert_cost, cpa_bid), convert) * 1
            : 0;
      }
      return claim === 0 ? 0 : claim.toFixed(2);
    },
  },
  {
    title: "卡回传",
    width: 100,
    align: "center",
    dataIndex: "upnums",
    key: "upnums",
  },
  {
    title: "回传数据",
    width: 120,
    align: "center",
    dataIndex: "back_data",
    key: "back_data",
    render: (_, record) =>
      record?.convert + record?.upnums > 0 ? (
        <Button type="link" onClick={() => showBackResult(record)}>
          查看
        </Button>
      ) : (
        "-"
      ),
  },
  {
    title: "展示数",
    width: 100,
    align: "center",
    dataIndex: "show",
    key: "show",
  },
  {
    title: "点击数",
    width: 100,
    align: "center",
    dataIndex: "click",
    key: "click",
  },
  {
    title: "点击率",
    width: 100,
    align: "center",
    dataIndex: "ctr",
    key: "ctr",
  },
  {
    title: "平均点击单价",
    width: 120,
    align: "center",
    dataIndex: "avg_click_costs",
    key: "avg_click_costs",
  },
  {
    title: "平均千次展示费用",
    width: 150,
    align: "center",
    dataIndex: "avg_show_cost",
    key: "avg_show_cost",
  },
  {
    title: "广告组(项目)ID",
    width: 130,
    align: "center",
    dataIndex: "campaign_id",
    key: "campaign_id",
  },
  {
    title: "广告组(项目)名称",
    width: 150,
    align: "center",
    dataIndex: "campaign_name",
    key: "campaign_name",
  },
  {
    title: "广告主ID",
    width: 150,
    align: "center",
    dataIndex: "advertiser_id",
    key: "advertiser_id",
    ellipsis: true,
  },
  {
    title: "操作",
    width: 140,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    render: (_, record) => (
      <Space>
        {/* <Button type="primary" ghost>回传数据</Button> */}
        <Button type="primary" ghost onClick={() => switchover(record, "tab3")}>
          创意/2.0素材
        </Button>
      </Space>
    ),
  },
];

export default columns;
