import { Button, Card } from 'antd';
import React from 'react'
import Account from './account';
import Plan from './plan';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Index() {
    const [activeTabKey, setActiveTabKey] = useState(1);
    const navigate = useNavigate()

    const tabList = [
        { key: 1, tab: '广告账号' },
        { key: 2, tab: '2.0 广告' },
    ]

    const contentList = {
        1: <Account />,
        2: <Plan />,
    }

    const onTabChange = key => {
        setActiveTabKey(key)
    }

    return (
        <div className='manager'>
            <Card
                style={{ width: '100%' }}
                tabList={tabList}
                title='管家列表'
                activeTabKey={activeTabKey}
                onTabChange={onTabChange}
                extra={
                    <Button onClick={() => {navigate(-1)}}>返回</Button>
                }
            >
                {contentList[activeTabKey]}
            </Card>
        </div>
    )
}
