import { Space, Tag } from "antd"
import dayjs from "dayjs"

const columns = () => [
    {
        title: "订单ID",
        width: 150,
        align: "center",
        dataIndex: "payid",
        key: "payid",
        ellipsis: true,
    },
    {
        title: "订单类型",
        width: 150,
        align: "center",
        dataIndex: "paytype",
        key: "paytype",
        render: value => (
            value === 1 ? <Tag color="success">VIP会员</Tag> : <Tag color="#70B9EB">普通充值</Tag>
        )
    },
    {
        title: "用户",
        width: 150,
        align: "center",
        dataIndex: "user",
        key: "user",
        render: (_, record) => {
            return <>
                <Space size='small'>
                    <img src={record?.avatar} width={30} />
                    <span>{record?.nickname}({record?.userid})</span>
                </Space>
            </>
        }
    },
    {
        title: "充值金额",
        width: 120,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "支付方式",
        width: 120,
        align: "center",
        dataIndex: "paytype",
        key: "paytype",
        render: (value) => {
            let type = {1: '公众号支付', 2: '第三方支付', 4: '通联支付', 3: 'H5支付', 15: '支付宝wap支付', 11: '支付宝app支付', 12: '微信app支付', 13: '微信H5支付', 20: '首信易支付'}
            if (type.hasOwnProperty(value)) {
                return type[value]
            }
            return '未知'
        }
    },
    {
        title: "订单状态",
        width: 110,
        align: "center",
        dataIndex: "status",
        key: "status",
        render: value => (
            value === 1 ? <Tag color="success">已支付</Tag> : <Tag color="error">未支付</Tag>
        )
    },
    {
        title: "添加时间",
        width: 180,
        align: "center",
        dataIndex: "addtime",
        key: "addtime",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    
]

export default columns