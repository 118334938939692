import { Avatar, Card, Typography } from 'antd'
import dayjs from 'dayjs';
import React from 'react'
import Table from '../../../../components/TableNoSelection'
import columns from './columns';

const { Meta } = Card;
const { Title } = Typography;

export default function Info(props) {
  let dataSource = props.user
  dataSource.recharge = props.recharge

  return (
    <>
    <Card
      style={{
        width: 500,
        marginTop: 16,
      }}
    >
      <Meta
        avatar={<Avatar src={props?.user?.avatar} />}
        title={`${props?.user?.username}(${props?.user?.id}) [${props?.user?.isguanzhu === 1 ? '关注' : '未关注'}]`}
        description={
          <>
            <div><Title level={5}>书币: {props?.user?.score}</Title></div>
            <div><Title level={5}>性别: {props?.user?.sex === 1 ? '男' : '女'}</Title></div>
            <div><Title level={5}>注册时间: {dayjs.unix(props?.user?.create_time).format('YYYY-MM-DD HH:mm:ss')}</Title></div>
            <div><Title level={5}>最后登录时间: {dayjs.unix(props?.user?.last_login_time).format('YYYY-MM-DD HH:mm:ss')}</Title></div>
          </>
        }
      />
    </Card>

    <Card style={{marginTop: 10}}>
        <Table 
          dataSource={[dataSource]}
          columns={columns()}
          pagination={false}
        />

    </Card>
    </>
  )
}
