import {
  Button,
  Card,
  DatePicker,
  Form,
  Space,
  Typography,
  message,
  Table
} from "antd";
import React, { useState } from "react";
import { ExportOutlined } from "@ant-design/icons";
import {numMulti, numDiv} from '../../../../util/calculate'

// import Table from "../../../../components/TableNoSelection";
import columns from "./columns";
import {
  getPayQuList,
  getPayQuSummer,
  payQuUpdate,
} from "../../../../api/promotion";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [day, setDay] = useState("");
  const [setIsShowTable] = useState(false);
  const [payQuSummary, setPayQuSummary] = useState([]);

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getList();
    getSummary();
  }, []);

  // 获取列表数据
  const getList = async (params = {}) => {
    const res = await getPayQuList({ ...params, promotion_id: state.id });
    const { data } = res;
    if (res?.code === 200) {
      let lists = data?.lists;
      setList(lists);
      setTotal(data?.total);
      setLoading(false);
      if (
        lists[0]?.hasOwnProperty("add_table") &&
        lists[0]?.hasOwnProperty("add_table_percent")
      ) {
        setIsShowTable(true);
      }
    }
  };

  // 数据汇总
  const getSummary = async (params = {}) => {
    const res = await getPayQuSummer({ ...params, promotion_id: state.id });
    if (res?.code === 200) {
      const {
        data: { summary },
      } = res;
      setPayQuSummary(summary);
    }
  };

  // 日期范围搜索
  const onSearch = (values) => {
    let createdAt;
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
    }
    setDay(createdAt);
    getList({ day: createdAt });
    getSummary({ day: createdAt });
  };

  // 分页
  const onShowChange = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ page, pageSize: size });
  };

  let addColumnsOptions = [];

  // 动态添加第1天到第60天的列
  for (let i = 1; i <= 60; i++) {
    addColumnsOptions.push({
      title: `第${i}天`,
      width: 120,
      align: "center",
      dataIndex: `day${i}`,
      key: `day${i}`,
      render: (_, record) => {
        let extend = record?.extend;
        let filterd = extend?.filter((item) => {
          return item.sort_day === i - 1;
        });
        let money = 0;
        if (filterd.length > 0) {
          money = filterd[0]?.money;
        }
        let return_cost = 0;
        if (parseInt(record.cost)) {
          let per = numMulti(numDiv(money, record.cost), 100);
          return_cost = per.toFixed(2) + '%';
        }
        return (
          <>
            <div style={{ textAlign: "left" }}>充值：{money}</div>
            <div style={{ textAlign: "left" }}>回本率：{return_cost}</div>
          </>
        );
      },
    });
  }

  // 修改成功或赔付字段
  const onChange = async (e, field, id) => {
    if (e.keyCode === 13) {
      let value = e.target.value;
      let msg = field === "cost" ? "成本" : "赔付";
      let params = { id, [field]: parseFloat(value) };
      const res = await payQuUpdate(params);
      if (res?.code === 200) {
        message.success(msg + "设置成功");
        getList();
      }
    }
  };

  let tableColumns = columns({ onChange });
  let newColumns = tableColumns.concat(addColumnsOptions);

  return (
    <Card
      title={
        <Space>
          <Button type="primary" icon={<ExportOutlined />}>
            导出
          </Button>
          <Form onFinish={onSearch} layout="inline">
            <Form.Item name="range_date">
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
        </Space>
      }
      extra={
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          返回
        </Button>
      }
    >
      <Table
        sticky
        dataSource={list}
        columns={newColumns}
        bordered={true}
        pagination={
          day
            ? false
            : {
                total: total,
                showSizeChanger: true,
                showQuickJumper: total > 50 ? true : false,
                showTotal: (total) => `共 ${total} 条`,
                pageSizeOptions: [10, 15, 20, 30],
                current: page || 1,
                onChange: onShowChange,
                pageSize: perPage,
              }
        }
        loading={loading}
        scroll={{ x: 500 }}
          summary={(pageData) => {
            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={1} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      <Text>{payQuSummary?.new_subscribe}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                      <Text>{payQuSummary?.new_user}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      <Text>{payQuSummary?.active_user}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      <Text>{payQuSummary?.activation_rate}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      <Text>{payQuSummary?.total_charge}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <Text>{payQuSummary?.cost}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>-</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="center">
                      <Text>{payQuSummary?.claim}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="center">
                      <Text>-</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="center">
                      <Text>{payQuSummary?.huibenlv}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="center">
                      <Text>{payQuSummary?.huiben_30}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="center">
                    <Text>{payQuSummary?.hibenlv_30}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="center">
                      <Text>{payQuSummary?.pay_user}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="center">
                      <Text>{payQuSummary?.first_day_first_pay}|{payQuSummary?.first_day_pay}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} align="center">
                      <Text>{payQuSummary?.firstPer}|{payQuSummary?.per}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} align="center">
                      <Text>{payQuSummary?.firstPercent}|{payQuSummary?.totalPercent}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} align="center">
                      <Text>{payQuSummary?.add_table}|{payQuSummary?.add_table_percent}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
      />
    </Card>
  );
}
