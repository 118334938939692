import { Button } from "antd"

const columns = ({unbindGuan}) => [
    {
        title: "管家名称",
        width: 150,
        align: "center",
        dataIndex: "account_name",
        key: "account_name",
    },
    {
        title: "管家ID",
        width: 130,
        align: "center",
        dataIndex: "pid",
        key: "pid",
    },
    {
        title: "操作",
        width: 130,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        render: (_, record) => (
            <Button type="primary" onClick={() => unbindGuan(record)}>解绑管家</Button>
        )
    },
]

export default columns