const columns = () => [
    {
        title: "日期",
        width: 100,
        align: "center",
        dataIndex: "day",
        key: "day",
    },
    {
        title: "投手",
        width: 90,
        align: "center",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        render: (_, record) => (
            record?.user?.nickname || '-'
        )
    },
    {
        title: "推广链接名称",
        width: 120,
        align: "center",
        dataIndex: "title",
        key: "title",
    },
    {
        title: "小说名称",
        width: 120,
        align: "center",
        dataIndex: "book_name",
        key: "book_name",
    },
    {
        title: "当日消耗",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
        sorter: (a, b) => a.cost - b.cost,
        sortDirections: ["descend"],
    },
    {
        title: "回收",
        width: 100,
        align: "center",
        dataIndex: "money",
        key: "money",
        sorter: (a, b) => a.money - b.money,
        sortDirections: ["descend"],
        render: value => (
            value || 0
        )
    },
    {
        title: "回收率",
        width: 110,
        align: "center",
        dataIndex: "huishoulv",
        key: "huishoulv",
        render: value => (
            value || 0
        )
    },
    {
        title: "支付人数(首充)",
        width: 120,
        align: "center",
        dataIndex: "count",
        key: "count",
        render: value => (
            value || 0
        )
    },
    {
        title: "付费成本",
        width: 100,
        align: "center",
        dataIndex: "paycosts",
        key: "paycosts",
        render: value => (
            value || 0
        )
    },
    {
        title: "首日客单价",
        width: 100,
        align: "center",
        dataIndex: "kedans",
        key: "kedans",
        render: value => (
            value || 0
        )
    },
]

export default columns