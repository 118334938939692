import { Button, Space, Typography } from "antd"

const columns = ({state, showDetail}) => [
    {
        title: "章节ID",
        width: 90,
        align: "center",
        dataIndex: "idx",
        key: "idx",
    },
    {
        title: "名称",
        width: 200,
        align: "center",
        dataIndex: "title",
        key: "title", 
        render: (value, record) => (
            <Space onClick={() => showDetail(record)}>
                <Typography.Link type="success">
                {value}
                </Typography.Link>
                {state.tjwn === record.idx ? <Button size="small" style={{backgroundColor: '#F3B760', color: 'white'}}>添加文案章节</Button> : (state.gzzj === record.idx ? <Button size="small" type="primary">添加关注章节</Button> : '')}
            </Space>
        )
    },
    {
        title: "字数",
        width: 90,
        align: "center",
        dataIndex: "words",
        key: "words",
    },
    {
        title: "收费",
        width: 90,
        align: "center",
        dataIndex: "moneys",
        key: "moneys",
    },
]

export default columns