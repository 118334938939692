import {useMemo} from 'react';
import { Form } from 'antd'

const useRulesMapper = ({ formData }) => {
  const advertiserTotalCost = Form.useWatch('advertiserTotalCost', formData)
  const advertiserTotalCostMax = Form.useWatch('advertiserTotalCostMax', formData)

  const rulesMapper = useMemo(() => {
    return {
      commonSelect: [{ required: true, message: '请选择' }],
      commonInput: [{ required: true, message: '请输入' }],
    }
  }, [])

  const advertiserTotalCostRule = useMemo(() => {
    return [
      ({ getFieldValue }) => {
        return {
          validator: () => {
            const min = getFieldValue('advertiserTotalCost')
            const max = getFieldValue('advertiserTotalCostMax')

            const minEmpty = [undefined, '', null].includes(min)
            const maxEmpty = [undefined, '', null].includes(max)
            if (minEmpty || maxEmpty) {
              return Promise.reject(new Error('请输入区间数值'))
            }

            if (min > max) {
              return Promise.reject(new Error('最小值不能大于最大值'))
            }

            return Promise.resolve()
          }
        }
      },
    ]
  }, [])

  return {
    rulesMapper,
    advertiserTotalCostRule,
  }
}

export default useRulesMapper
