import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Tree,
  message,
} from "antd";
import React from "react";
import {
  FormOutlined,
  DownOutlined,
  SyncOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useEffect } from "react";
import {
  createUser,
  deleteUser,
  getOrganizationTree,
  getUserList,
  statusUpdate,
  updateUser,
} from "../../../api/permission";
import Table from "../../../components/TableHasTotal";
import columns from "./columns";
import { useNavigate } from "react-router-dom";
import SaveModal from "./saveModal";
import { treeToArray } from "../../../util";

const { DirectoryTree } = Tree;
const { confirm } = Modal;

export default function Member() {
  const [orgTreeList, setOrgTreeList] = useState([]);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [conditions, setConditions] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);
  const [saveModalShow, setSaveModalShow] = useState(false);
  const [operateType, setOperateType] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [expandKeys, setExpandKeys] = useState([]);
  const [isExpand, setIsExpand] = useState(true);
  const [statusLoading, setStatusLoading] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getOrgTree();
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取树形组织架构
  const getOrgTree = async (params = {}) => {
    const res = await getOrganizationTree(params);
    if (res?.code === 200) {
      const lists = res?.data?.lists;
      const Keys = treeToArray(lists).map((item) => {
        return item.key;
      });
      setExpandKeys(Keys);
      setOrgTreeList(res?.data?.lists);
    }
  };

  // 刷新树形组织架构列表
  const onRefresh = () => {
    setIsExpand(true);
    getOrgTree();
  };

  // 展开|折叠
  const onFold = () => {
    let expanded = !isExpand;
    if (!expanded) {
      setExpandKeys([]);
    } else {
      const Keys = treeToArray(orgTreeList).map((item) => {
        return item.key;
      });
      setExpandKeys(Keys);
    }
    setIsExpand(!isExpand);
  };

  // 获取后台用户列表
  const getList = async (params = {}) => {
    const res = await getUserList(params);
    params.pageNum = params.pageNum || page;
    params.pageSize = params.pageSize || perPage;
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();

  // 表单查询筛选
  const selectFormSubmit = (values) => {
    let newConditions = { ...conditions, ...values };
    setConditions(newConditions);
    getList(newConditions);
  };

  const handleDelete = (record) => {
    confirm({
      title: `确认删除用户-${record.nickname}`,
      modalLoading: true,
      onOk: async () => {
        const res = await deleteUser({ id: record?.id });
        if (res?.code === 200) {
          message.success("用户删除成功", 1);
          setTimeout(() => {
            getList();
          }, 1000);
        }
      },
    });
  };

  // 选中树形结构
  const onSelect = (_, info) => {
    let currentId = info.node.id;
    let newConditions = { ...conditions, id: currentId };
    setConditions(newConditions);
    setLoading(true);
    getList(newConditions);
  };

  // 全选或全不选
  const selectRows = (selectRows) => {
    setSelectKeys(selectRows);
  };

  // 列表用户状态切换
  const onStatusChange = async (adminId, status) => {
    setStatusLoading(adminId);
    let value = !status
    const res = await statusUpdate({ status: value, id: adminId });
    if (res?.code === 200) {
      setStatusLoading(0);
    }
  };

  // 每页显示条数切换回调
  const pagesize = (page, size) => {
    console.log(page, size);
    setLoading(true);
    setPage(page);
    setPerPage(size);
    // setConditions({ ...conditions, pageSize: size, page })
    // getList({ ...conditions, pageSize: size, page })
  };

  // 点击添加弹窗
  const addUser = () => {
    setOperateType("add");
    setSaveModalShow(true);
  };

  // 修改弹窗
  const handleEdit = (record) => {
    record.account = record.username;
    let orgIds = [];
    let orgTitles = [];
    let roleIds = [];
    record.organizations.forEach((item) => {
      orgIds.push(item.id);
      orgTitles.push(item.name);
    });

    record.roles.forEach((role) => {
      roleIds.push(role.id);
    });
    record.orgIds = orgIds;
    record.role = roleIds;
    form.setFieldsValue(record);
    form.setFieldsValue({ organizations: orgTitles.join() });
    setOperateType("edit");
    setSaveModalShow(true);
  };

  // 关闭弹窗
  const handleCancel = () => {
    setSaveModalShow(false);
    form.resetFields();
    setModalLoading(false);
  };

  // 添加、编辑表单提交
  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const values = form.getFieldValue();
        setModalLoading(true);
        if (operateType === "add") {
          doAdd(values);
        } else {
          doEdit(values);
        }
      })
      .catch(() => {
        return;
      });
  };

  // 处理新增
  const doAdd = async (values) => {
    const res = await createUser(values);
    if (res?.code === 200) {
      message.success("添加成功", 1);
      setTimeout(() => {
        getList();
        handleCancel();
      }, 1000);
    } else {
      setModalLoading(false);
    }
  };

  // 处理修改
  const doEdit = async (values) => {
    const res = await updateUser(values);
    if (res?.code === 200) {
      message.success("修改成功", 1);
      setTimeout(() => {
        getList();
        handleCancel();
      }, 1000);
    } else {
      setModalLoading(false);
    }
  };

  const onExpand = (keys) => {
    setExpandKeys(keys);
  };

  // 排序
  const onSortChange = (pagination, filters, sorter) => {
    let order;
    if (sorter) {
      let feild = sorter.columnKey;
      let sortBy = sorter.order;
      if (feild !== undefined) {
        if (sortBy === "ascend") {
          // eslint-disable-next-line no-useless-concat
          order = feild + "_" + "asc";
        } else {
          // eslint-disable-next-line no-useless-concat
          order = feild + "_" + "desc";
        }
        setConditions({ ...conditions, order });
      }
    }
    getList({
      ...conditions,
      pageSize: pagination.pageSize,
      page: pagination.current,
      order,
    });
  };

  // 批量操作按钮是否可点击操作
  const hasSelected = selectKeys.length > 0;

  return (
    <div className="member">
      <Row gutter={12}>
        <Col span={6}>
          <Card style={{ height: 850}}>
            <div
              style={{
                display: "flex",
                padding: 10,
                backgroundColor: "white",
                justifyContent: "center",
                borderRadius: 8,
              }}
            >
              <div style={{ width: "55%", fontSize: 16 }}>组织架构</div>
              <div>
                <Space>
                  <Tooltip title="组织架构管理">
                    <Button
                      icon={<FormOutlined />}
                      size="small"
                      type="text"
                      onClick={() => {
                        navigate("/admin/permission/organization");
                      }}
                    ></Button>
                  </Tooltip>
                  <Tooltip title={isExpand ? "折叠" : "展开"}>
                    <Button
                      icon={isExpand ? <DownOutlined /> : <UpOutlined />}
                      size="small"
                      type="text"
                      onClick={onFold}
                    ></Button>
                  </Tooltip>
                  <Tooltip title="刷新组织架构">
                    <Button
                      icon={<SyncOutlined />}
                      size="small"
                      type="text"
                      onClick={onRefresh}
                    ></Button>
                  </Tooltip>
                </Space>
              </div>
            </div>
            <div
              style={{
                marginTop: 5,
                backgroundColor: "white",
                // height: "100%",
                borderRadius: 8,
                overflowY: "auto",
                paddingLeft: 10,
                paddingTop: 5,
              }}
            >
              {orgTreeList.length > 0 ? (
                <DirectoryTree
                  treeData={orgTreeList}
                  showLine={true}
                  defaultExpandAll={true}
                  height={750}
                  expandAction={false}
                  onSelect={onSelect}
                  expandedKeys={expandKeys}
                  onExpand={onExpand}
                />
              ) : (
                ""
              )}
            </div>
          </Card>
        </Col>
        <Col span={18}>
          <Card style={{ height: 850}}>
            <div
              className="member-right"
            >
              <Card
                className="role-search"
                style={{ marginBottom: 10 }}
                styles={{body: {display: "none"}}}
                title={
                  <Form
                    layout="inline"
                    onFinish={selectFormSubmit}
                    form={filterForm}
                  >
                    <Form.Item label="人员名称" name="nickname">
                      <Input />
                    </Form.Item>
                    <Form.Item label="登录名" name="username">
                      <Input />
                    </Form.Item>
                    <Form.Item label="人员状态" name="status">
                      <Select
                        style={{ width: 120 }}
                        placeholder="请选择状态"
                        allowClear
                      >
                        <Select.Option value={1} label={1}>
                          正常
                        </Select.Option>
                        <Select.Option value={0} label={0}>
                          停用
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Space>
                        <Button type="primary" htmlType="submit">
                          查询
                        </Button>
                        <Button
                          onClick={() => {
                            filterForm.resetFields();
                          }}
                        >
                          重置
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                }
              />
              <Card
                title={
                  <Space>
                    <Button type="primary" onClick={addUser}>
                      新增
                    </Button>
                    <Button type="primary" danger disabled={!hasSelected}>
                      批量删除
                    </Button>
                  </Space>
                }
                style={{ height: 720}}
              >
                <Table
                  dataSource={list}
                  columns={columns({ onStatusChange, statusLoading, handleEdit, handleDelete })}
                  loading={loading}
                  page={page}
                  pagesize={pagesize}
                  perPage={perPage}
                  selectRows={selectRows}
                  pageOptions={[10, 20, 50, 80]}
                  total={total}
                  onChange={onSortChange}
                />
              </Card>
            </div>
          </Card>
        </Col>
      </Row>

      {/* <Card>
        <div style={{ display: "flex", border: "1px solid red" }}>
          <div
            className="member-left"
            style={{ width: "25%", overflow: "auto" }}
          >
            <Card>
              <div
                style={{
                  display: "flex",
                  padding: 10,
                  backgroundColor: "white",
                  justifyContent: "center",
                  borderRadius: 8,
                }}
              >
                <div style={{ width: "55%", fontSize: 16 }}>组织架构</div>
                <div>
                  <Space>
                    <Tooltip title="组织架构管理">
                      <Button
                        icon={<FormOutlined />}
                        size="small"
                        type="text"
                        onClick={() => {
                          navigate("/admin/permission/organization");
                        }}
                      ></Button>
                    </Tooltip>
                    <Tooltip title={isExpand ? "折叠" : "展开"}>
                      <Button
                        icon={isExpand ? <DownOutlined /> : <UpOutlined />}
                        size="small"
                        type="text"
                        onClick={onFold}
                      ></Button>
                    </Tooltip>
                    <Tooltip title="刷新组织架构">
                      <Button
                        icon={<SyncOutlined />}
                        size="small"
                        type="text"
                        onClick={onRefresh}
                      ></Button>
                    </Tooltip>
                  </Space>
                </div>
              </div>
              <div
                style={{
                  marginTop: 5,
                  backgroundColor: "white",
                  height: "100%",
                  borderRadius: 8,
                  overflowY: "auto",
                  paddingLeft: 10,
                  paddingTop: 5,
                }}
              >
                {orgTreeList.length > 0 ? (
                  <DirectoryTree
                    treeData={orgTreeList}
                    showLine={true}
                    defaultExpandAll={true}
                    // height={600}
                    expandAction={false}
                    onSelect={onSelect}
                    expandedKeys={expandKeys}
                    onExpand={onExpand}
                  />
                ) : (
                  ""
                )}
              </div>
            </Card>
          </div>
          <div
            className="member-right"
            style={{ width: "75%", marginLeft: 10 }}
          >
            <Card
              className="role-search"
              style={{ marginBottom: 10 }}
              bodyStyle={{ display: "none" }}
              title={
                <Form
                  layout="inline"
                  onFinish={selectFormSubmit}
                  form={filterForm}
                >
                  <Form.Item label="人员名称" name="nickname">
                    <Input />
                  </Form.Item>
                  <Form.Item label="登录名" name="username">
                    <Input />
                  </Form.Item>
                  <Form.Item label="人员状态" name="status">
                    <Select
                      style={{ width: 120 }}
                      placeholder="请选择状态"
                      allowClear
                    >
                      <Select.Option value={1} label={1}>
                        正常
                      </Select.Option>
                      <Select.Option value={0} label={0}>
                        停用
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Space>
                      <Button type="primary" htmlType="submit">
                        查询
                      </Button>
                      <Button
                        onClick={() => {
                          filterForm.resetFields();
                        }}
                      >
                        重置
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              }
            />
            <Card
              title={
                <Space>
                  <Button type="primary" onClick={addUser}>
                    新增
                  </Button>
                  <Button type="primary" danger disabled={!hasSelected}>
                    批量删除
                  </Button>
                </Space>
              }
            >
              <Table
                dataSource={list}
                columns={columns({ handleEdit, handleDelete })}
                loading={loading}
                page={page}
                pagesize={pagesize}
                perPage={perPage}
                selectRows={selectRows}
                pageOptions={[10, 20, 50, 80]}
                total={total}
                onChange={onSortChange}
              />
            </Card>
          </div>
        </div>
      </Card> */}
      {/* 新增、编辑弹窗 */}
      <SaveModal
        show={saveModalShow}
        type={operateType}
        handleCancel={handleCancel}
        form={form}
        handleSubmit={handleSubmit}
        loading={modalLoading}
      />
    </div>
  );
}
