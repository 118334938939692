import { Button, Popover, Space, Tag } from "antd"
import { RightCircleOutlined } from '@ant-design/icons';
import dayjs from "dayjs"
import { Link } from "react-router-dom";

const columns = ({ handleEdit, handleDelete, dispatchMenu }) => [
    {
        title: "角色名称",
        width: 120,
        align: "center",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
    },
    {
        title: "权限字符",
        width: 100,
        align: "center",
        dataIndex: "role_key",
        key: "role_key",
        ellipsis: true,
    },
    {
        title: "显示顺序",
        width: 100,
        align: "center",
        dataIndex: "sort",
        key: "sort",
        sorter: () => { },
    },
    {
        title: "状态",
        width: 70,
        align: "center",
        dataIndex: "status",
        key: "status",
        render: text => (
            text === 1 ? <Tag color="green">正常</Tag> : <Tag color="red">停用</Tag>
        )
    },
    {
        title: "创建时间",
        width: 150,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: text => (
            dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "操作",
        width: 280,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        fixed: 'right',
        render: (_, record) => (
            <Space>
                <Button type="primary" onClick={() => { handleEdit(record) }}>编辑</Button>
                <Button type="primary" danger onClick={() => { handleDelete(record) }}>删除</Button>
                <Popover
                    placement="left"
                    // trigger="click"
                    content={
                        <Space>
                            <Button block type='primary' ghost onClick={() => { dispatchMenu(record) }}>菜单权限</Button>
                            <Link to="/admin/permission/role/user" state={{ id: record.id, roleName: record.name }}>
                                <Button block type='primary' ghost>分配用户</Button>
                            </Link>
                        </Space>
                    }>
                    <Button type="primary" ghost><RightCircleOutlined />更多操作</Button>
                </Popover>

            </Space>
        )
    },
]

export default columns