import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import {
  filterPitchers,
  getPlayletListRank,
  getPlayletPackage,
} from "../../../api/statistics";

const { RangePicker } = DatePicker;

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [conditions, setConditions] = useState([]);
  const [playletOptions, setPlayletOptions] = useState([]);
  const [pitcherOptions, setPitcherOptions] = useState([]);

  const [form] = Form.useForm()

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getPlayletListRank(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 获取所有的短剧
  const getPlaylets = async () => {
    const res = await getPlayletPackage();
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.book_name, value: item.book_id };
      });
      setPlayletOptions(newLists);
    }
  };

  // 获取筛选的投手信息
  const getPitchers = async () => {
    const res = await filterPitchers({ type: "playlet" });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(newList);
    }
  };

  const getData = () => {
    Promise.all([getList(), getPlaylets(), getPitchers()]);
  };

  // 表单筛选查询
  const onSubmit = (values) => {
    let rangeDate;
    if (values?.range_date) {
      rangeDate = values.range_date.map((date) => {
        return date.format("YYYYMMDD");
      });
    }
    values.range_date = rangeDate;
    setConditions(values);
    getList(values);
    setPerPage(20);
    setPage(1);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 排序
  const onTableChange = (pagination, filters, sorter) => {
    let field = sorter?.field;
    let orderType = sorter?.order;
    let params = {};
    if (orderType && orderType === "descend" && field) {
      params = { ...conditions, order: field + "_" + "desc" };
    } else {
      params = conditions;
    }
    getList({
      ...params,
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
  };

  return (
    <>
      <Card
        title={
          <Form onFinish={onSubmit} labelCol={{ span: 4 }} form={form}>
            <Row gutter={24}>
              <Col span={7}>
                <Form.Item label="日期" name="range_date">
                  <RangePicker />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="UP值范围" name="up_range">
                  <Form.Item
                    name="up_min"
                    style={{ display: "inline-block" }}
                  >
                    <InputNumber
                      placeholder="最小金额 (>=)"
                      style={{
                        width: 120,
                      }}
                    />
                  </Form.Item>
                  <span style={{ marginLeft: -10, marginRight: 5 }}>-</span>
                  <Form.Item
                    name="up_max"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <InputNumber
                      placeholder="最大金额 (不含)"
                      style={{
                        width: 120,
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="付费率" name="rate_range">
                  <Form.Item
                    name="pay_min"
                    style={{ display: "inline-block" }}
                  >
                    <InputNumber
                      placeholder="最小金额 (>=)"
                      style={{
                        width: 120,
                      }}
                    />
                  </Form.Item>
                  <span style={{ marginLeft: -10, marginRight: 5 }}>-</span>
                  <Form.Item
                    name="pay_max"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <InputNumber
                      placeholder="最大金额 (不含)"
                      style={{
                        width: 120,
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={7}>
                <Form.Item label="投手" name="admin_id">
                  <Select
                    allowClear
                    placeholder="选择投手"
                    showSearch
                    filterOption={filterOption}
                    options={pitcherOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="短剧" name="book_id">
                  <Select
                    allowClear
                    placeholder="选择短剧"
                    showSearch
                    filterOption={filterOption}
                    options={playletOptions}
                    style={{ width: 250 }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label colon={false}>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      确定
                    </Button>
                    <Button onClick={() => form.resetFields()}>重置</Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      >
        <Table
          dataSource={list}
          columns={columns()}
          loading={loading}
          total={total}
          perPage={perPage}
          page={page}
          onChange={onTableChange}
          pagesize={pagesize}
          pageOptions={[20, 30]}
          bordered={true}
        />
      </Card>
    </>
  );
}
