import { Card, Drawer } from "antd";
import React, { useState } from "react";
import Table from "../../../../../components/TableNoSelection";
import columns from "./columns";

export default function Index(props) {
  const [loading, setLoading] = useState(false)
  const onTabChange = (key) => {
    setLoading(true)
    props.tabChange(key);
    setLoading(false)
  };

  return (
    <Drawer
      title="盈亏详情"
      open={props.show}
      onClose={props.onclose}
      maskClosable={false}
      width={850}
      loading={props.loading}
    >
      <Card
        tabList={props.tabList}
        onTabChange={onTabChange}
        activeTabKey={props.activeTabKey}
        tabProps={{ size: "small" }}
      >
        <Table
          dataSource={props.list}
          columns={columns()}
          pagination={false}
          bordered={true}
          loading={loading}
        />
      </Card>
    </Drawer>
  );
}
