import { Image } from "antd";

const columns = () => [
  {
    title: "落地页",
    width: 100,
    align: "center",
    dataIndex: "landing",
    key: "landing",
    render: (_, record) => (
      <>
        <div style={{ display: "flex" }}>
          <div>
            <Image src={record?.landing?.image_url} preview={false} width={150}/>
          </div>
          <div style={{paddingTop: 18, width: 180}}>
            <div style={{
                 height: '50%', 
                lineHeight: 3
                }}>{record?.title}</div>
            <div>ID: {record?.site_id}</div>
          </div>
        </div>
      </>
    ),
  },
  {
    title: "状态",
    width: 100,
    align: "center",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "创建时间",
    width: 120,
    align: "center",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "来源账户",
    width: 120,
    align: "center",
    dataIndex: "origin_advertiser_id",
    key: "origin_advertiser_id",
  },
];

export default columns
