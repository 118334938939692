import { distributorAssocAdmin, getBoundPackageList } from "@/api/asset";
import { Button, Card, Form, message, Modal, Pagination, Radio } from "antd";
import React, { useState } from "react";
import Table from "../../../../components/TableHasTotal";
import columns from "./columns";

const { confirm } = Modal;

export default function Index(props) {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectKeys, setSelectKeys] = useState([]);

  const [form] = Form.useForm();

  // 全选或全不选
  const selectRows = selectRows => {
    console.log(selectRows);
    setSelectKeys(selectRows);
  };

  // 关闭弹窗
  const onClose = () => {
    form.resetFields();
    setList([])
    setSelectKeys([])
    props.onClose()
  }

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getBoundPackageList(params);
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newList = lists.map(item => {
        item.key = item.distributor_id;
        return item;
      });
      setTotal(data?.total);
      setList(newList);
    }
    setLoading(false);
  };

  // 根据剧场查询获取绑定的小程序信息
  const onFormSubmit = async values => {
    if (!values?.app_id) {
      message.error("请选择要绑定账号的剧场");
      return false;
    }
    getList(values);
  };

  // 分页
  const onPageChange = page => {
    let newPage = page - 1;
    getList({ page: newPage });
  };

  // 确认绑定账户
  const handleBind = () => {
    if (selectKeys.length === 0) {
      message.error("请选择你要绑定的番茄平台账号");
      return false;
    }
    confirm({
      title: "确定绑定该账户?",
      onOk: async () => {
        let filterItem = list.find(item => {
          return item.distributor_id === selectKeys[0];
        });
        let params = {
          app_id: form.getFieldValue("app_id"),
          distributor_id: filterItem?.distributor_id,
          app_name: filterItem?.app_name,
          nickname: filterItem?.nick_name,
        };
        const res = await distributorAssocAdmin(params);
        if (res?.code === 200) {
          message.success("操作账户绑定成功");
          setTimeout(() => {
            onClose()
          }, 1000);
        }
      },
    });
  };

  return (
    <Modal
      open={props.show}
      onCancel={onClose}
      width={650}
      footer={null}
    //   okText="确认绑定"
    //   onOk={handleBind}
    >
      <Form layout="inline" onFinish={onFormSubmit} form={form}>
        <Form.Item label="选择剧场" name="app_id">
          <Radio.Group
            options={props?.packageList?.filter((item) => {
                return item?.app_type === 1011
            })}
          />
        </Form.Item>
        <Form.Item label colon={false}>
          <Button type="primary" htmlType="submit">
          确认绑定
          </Button>
        </Form.Item>
      </Form>
      {/* <Card
        extra={
          <Pagination
            defaultCurrent={1}
            defaultPageSize={50}
            hideOnSinglePage={true}
            total={total}
            onChange={onPageChange}
          />
        }
        style={{ marginTop: 10, maxHeight: 500, overflow: "auto" }}
      >
        <Table
          dataSource={list}
          columns={columns()}
          type="radio"
          pagination={false}
          selectRows={selectRows}
          loading={loading}
        />
      </Card> */}
    </Modal>
  );
}
