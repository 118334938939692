import { Button, Space, Tag } from "antd"
import dayjs from "dayjs"

const columns = ({ updateTitle, deleteSource }) => [
    {
        title: "ID",
        width: 80,
        align: "center",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "素材标题",
        width: 200,
        align: "center",
        dataIndex: "title",
        key: "title",
        ellipsis: true,
    },
    {
        title: "标签",
        width: 120,
        align: "center",
        dataIndex: "label",
        key: "label",
        render: value => (
            value ? <Tag color="#2db7f5">{value}</Tag> : '-'
        )
    },
    {
        title: "上传者",
        width: 120,
        align: "center",
        dataIndex: "uploader",
        key: "uploader",
        render: (_,record) => {
            return <>
                <div>{record?.admin_user?.nickname}</div>
                <div>({record?.admin_user?.username})</div>
            </>
        }
    },
    {
        title: "上传时间",
        width: 150,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "操作",
        width: 150,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        render: (_, record) => (
            <Space>
                <Button type="primary" size="small" onClick={() => {updateTitle(record)}}>修改标题</Button>
                <Button type="primary" size="small" danger onClick={() => {deleteSource(record)}}>删除素材</Button>
            </Space>
        )
    },
]

export default columns