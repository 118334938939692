import { getRequest, postRequest } from "./javaRequest"

// 获取ipu/arpu用户占比
export const getDeliveryARPU = data => getRequest('/delivery/admin/iaa/ecpm/getIpuArpu', data)

// 短剧ecpm获取档位
export const getDeliveryEcpmLevel = data => getRequest('/delivery/admin/iaa/ecpm/getLevel', data)

// 获取ipu/ecpm用户占比
export const getDeliveryEcpm = data => getRequest('/delivery/admin/iaa/ecpm/getIpuEcpm', data)

//获取达成/非达成用户的平均arpu值
export const getDeliveryAvgArpu = data => getRequest('/delivery/admin/iaa/ecpm/getAvgArpu', data)

// 小说ecpm获取档位
export const getDeliveryEcpmNovelLevel = data => getRequest('/delivery/admin/novel/ecpm/getLevel', data)

// 获取小说ipu/arpu用户占比
export const getDeliveryNovelARPU = data => getRequest('/delivery/admin/novel/ecpm/getIpuArpu', data)

// 获取小说ipu/ecpm用户占比
export const getDeliveryNovelEcpm = data => getRequest('/delivery/admin/novel/ecpm/getIpuEcpm', data)

// 短剧ecpm列表
export const getPlayletEcpmList = data => postRequest('/delivery/admin/stats/ecpm/income', data)

// 短剧ecpm列表
export const getPlayletECPStatistics = data => postRequest('/delivery/admin/stats/ecpm/income/total', data)

// 小说ecpm列表
export const getNovelEcpmList = data => getRequest('/delivery/admin/novel/ecpm/getEcpmList', data)

// 小说ecpm列表
export const getNovelEcpmStatistics = data => getRequest('/delivery/admin/novel/ecpm/getEcpmTotal', data)

export const getDailyProfit = data => getRequest('/delivery/admin/iaa/profit/daily', data, { severTip: false })

export const getDailyProfitGroup = data => getRequest('/delivery/admin/iaa/profit/daily/group', data)

// 查询用户等级，是否是超管和代理商
export const getUserLevel = data => postRequest('/delivery/admin/stats/ecpm/income/getUserLevel', data)

// 获取策略列表
export const getAutoStrategies = () => getRequest('/delivery/admin/iaa/auto/strategies')

// 获取策略详情
export const getStrategiesDetail = (data) => getRequest('/delivery/admin/iaa/auto/strategy/detail', data)

// 获取策略类型
export const getStrategyTypes = () => getRequest('/delivery/admin/iaa/auto/strategyTypes')

// 新增或编辑策略
export const addOrUpdateStrategy = (data) => postRequest('/delivery/admin/iaa/auto/addOrUpdateStrategy', data)

// 启用或关闭策略
export const changeStrategyStatus = (data) => postRequest('/delivery/admin/iaa/auto/changeStatus', data)

// 策略详情列表
export const autoStrategyDetail = (data) => postRequest('/delivery/admin/iaa/auto/strategyLogDetail', data)

// 策略详情开关
export const changeAdStatus = (data) => postRequest('/delivery/admin/iaa/auto/changeAdStatus', data)

// 保存广告规则
export const saveAdvertiseRule = data => postRequest('/delivery/admin/advert/rule/save', data)

// 查询广告规则
export const getAdvertiseRule = data => getRequest('/delivery/admin/advert/rule/info', data)

// 查询广告规则自动补日志
export const getAdvertiseRuleLog = data => postRequest('/delivery/admin/advert/rule/log', data)

// 添加广告回传
export const addAdvertiseBack = data => postRequest('/delivery/admin/advert/back/add', data)

// 更新回传
export const updateAdvertiseBack = data => postRequest('/delivery/admin/advert/back/update', data)

// 获取回传详情
export const getAdvertiseBack = data => getRequest('/delivery/admin/advert/back/detail', data)

// 获取回传配置列表
export const getAdvertiseBackList = data => getRequest('/delivery/admin/advert/back/list', data)

// 获取回传配置执行日志
export const getAdvertiseBackLog = data => getRequest('/delivery/admin/advert/back/log', data)

// 新增或编辑回传配置
export const backConfigHandle = data => postRequest('/delivery/admin/advertiseBackConfig/back/add', data)

// 回传配置详情
export const backConfigDetail = data => getRequest('/delivery/admin/advertiseBackConfig/back/detail', data)

// 回传配置详情
export const backConfigList = data => getRequest('/delivery/admin/advertiseBackConfig/back/list', data)

// 回传配置开关
export const backConfigSwitch = data => getRequest('/delivery/admin/advertiseBackConfig/back/changeAll', data)

// 获取回传配置开关
export const getBackConfigSwitch = data => getRequest('/delivery/admin/advertiseBackConfig/back/switch', data)

// 投手绩效排行统计列表
export const getPerformanceList = data => getRequest('/delivery/admin/iaa/profit/daily/profit/rank', data)

// 分页查询用户请假信息
export const getLeaveList = data => getRequest('/delivery/admin/adminLeave/list', data)

// 新增用户请假信息
export const addLeave = data => postRequest('/delivery/admin/adminLeave/add', data)

// 删除用户请假信息
export const deleteLeave = data => postRequest('/delivery/admin/adminLeave/delete', data)

// 修改用户请假信息
export const updateLeave = data => postRequest('/delivery/admin/adminLeave/update', data)
