import { Button, Card, DatePicker, Form, Table, Typography } from 'antd'
import React from 'react'
import columns from './columns'
import { useState } from 'react'
import { useEffect } from 'react'
import { materialUpStatistics } from '../../../api'


const { RangePicker } = DatePicker
const { Text } = Typography

export default function Index() {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = async (params = {}) => {
        setLoading(true)
        const res = await materialUpStatistics(params)
        const { data } = res
        if (res?.code === 200) {
            let list = data?.lists
            let newList = list.map((item) => {
                item.key = item.id
                return item
            })
            setList(newList)
            setLoading(false)
        }
    }

    // 根据日期筛选
    const onSearch = values => {
        let createdAt
        if (values["range_date"]) {
            createdAt = values["range_date"].map((date) => {
                return date.format("YYYYMMDD")
            })
        }
        getData({ create_time: createdAt })
    }

    return (
        <div className='up-statistics'>
            <Card title={
                <Form layout='inline' onFinish={onSearch}>
                    <Form.Item name='range_date'>
                        <RangePicker size='small' />
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' size='small' htmlType='submit'>确定</Button>
                    </Form.Item>
                </Form>
            }
            >
                <Table
                    sticky
                    dataSource={list}
                    columns={columns()}
                    bordered={true}
                    pagination={false}
                    loading={loading}
                    summary={(pageData) => {
                        let totalVideoCount = 0
                        let totalImageCount = 0
                        let totalTitleCount = 0
                        pageData.forEach(({ video_count, image_count, title_count }) => {
                            totalVideoCount += video_count
                            totalImageCount += image_count
                            totalTitleCount += title_count

                        });
                        return (
                            <>
                                {
                                    pageData && pageData.length > 0 ?
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={2} align='center'>
                                                <Text type="danger" strong={true}>汇总</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} align='center'>
                                                <Text>{totalImageCount}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={3} align='center'>
                                                <Text>{totalVideoCount}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={4} align='center'>
                                                <Text>{totalTitleCount}</Text>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row> : ''
                                }
                            </>
                        );
                    }}
                />
            </Card>
        </div>
    )
}
