import {
  Button,
  Card,
  Col,
  Drawer,
  Row,
  Space,
  Table,
  Tooltip,
  Upload,
  message,
  Form,
  Radio,
  Select,
  DatePicker,
  Input,
  List,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { attachVideoAdd } from "../../../../api";
import {
  breakPointMultipartUpload,
  pauseUpload,
  resumeUpload,
} from "../../../../lib/TosClient";
import { createFromIconfontCN } from "@ant-design/icons";
import {
  advertiseMaterialPush,
  asyncFilterList,
} from "../../../../api/advertise";
import "./index.less";

const { Dragger } = Upload;
const { RangePicker } = DatePicker;

export default function Index(props) {
  const [activeTabKey, setActiveTabKey] = useState("tab1");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(21)
  const [total, setTotal] = useState(0)
  const [conditions, setConditions] = useState([]);
  const [attach, setAttach] = useState([]);
  const { isShow, closeVideo, handleSure } = props;
  const [fileQueuedList, setFileQueuedList] = useState([]);
  const [files, setfiles] = useState([]);
  const [uploaderDisable, setUploaderDisable] = useState(true);
  const [selectRowKeys, setSelectRowKeys] = useState([]);
  const [aboveActive, setAboveActive] = useState(false);
  const [belowActive, setBelowActive] = useState(false);
  const [sort, setSort] = useState("asc");
  const [checkedEleValue, setCheckEleValue] = useState(0);

  const [form] = Form.useForm();

  useEffect(() => {
    getList({pageSize: 21});
  }, [isShow]);

  // 获取视频素材
  const getList = async (params = {}) => {
    setListLoading(true);
    const res = await asyncFilterList({ ...params })
    if (res?.code === 200) {
      const {data} = res
      setList(data?.lists);
        setPage(data?.page)
        setTotal(data?.total)
        setListLoading(false);
    }
  };

  // 分页
  const handlePagesize = (page, size) => {
    setPage(page)
    setPerPage(size)
    getList({...conditions, page, pageSize: size})
  };

  // 条件查询
  const onHandleSearch = (values) => {
    setListLoading(true);
    let createdAt;
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.range_date;
    }
    let params = { ...values, create_time: createdAt, pageSize: 21 };
    setConditions(params);
    getList(params)
  };

  // 点击选中素材
  const onSelect = (record) => {
    let newRows = [];
    if (selectRowKeys?.includes(record?.id)) {
      newRows = selectRowKeys?.filter((item) => {
        return item !== record?.id;
      });
    } else {
      if (!record?.attach_video?.oss_url) {
        message.error("该素材的视频url地址为空,不能推送", 2);
        return false;
      }
      newRows = [...selectRowKeys, record?.id];
    }
    setSelectRowKeys(newRows);
  };

  // 当页全选
  const selectAll = () => {
    let filtered = list.filter((item) => {
      return !selectRowKeys.includes(item.id);
    });

    if (filtered.length > 0) {
      let ids = filtered.map((item) => {
        return item.id;
      });
      setSelectRowKeys([...selectRowKeys, ...ids]);
    }
  };

  // 取消全选
  const selectCancel = () => {
    setSelectRowKeys([]);
  };

  // 确定选中的视频素材
  const handleSubmit = async () => {
    setLoading(true);
    let selectVideos = [];
    list.map((item) => {
      if (selectRowKeys.includes(item.id) && item?.attach_video?.oss_url) {
        let label = {
          attach_id: item?.file_id,
          url: item?.attach_video?.oss_url,
          advertiser_id: props?.advertiserId,
        };
        selectVideos.push(label);
      }
    });
    await advertiseMaterialPush({ push_data: selectVideos });
    setTimeout(() => {
      message.success("操作成功", 2);
      handleSure();
      closeModal();
      setLoading(false);
    }, 2500);
  };

  // 上传文件的状态信息（大小，进度等）
  const columns = [
    {
      title: "文件名",
      dataIndex: "filename",
      key: "filename",
      width: 100,
      align: "center",
    },
    {
      title: "大小",
      dataIndex: "size",
      key: "size",
      width: 80,
      align: "center",
      render: (value) => {
        let size = parseInt(value) / 1024 / 1024;
        return size.toFixed(2) + "M";
      },
    },
    {
      title: "进度",
      dataIndex: "progress",
      key: "progress",
      width: 80,
      align: "center",
    },
    {
      title: "状态",
      dataIndex: "statusName",
      key: "statusName",
      width: 100,
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      width: 80,
      align: "center",
      render: (_, record) => (
        <Space>
          {record.status === "UPLOADING" ? (
            <Tooltip title="暂停">
              <Button
                icon={<PauseOutlined />}
                type="primary"
                ghost
                onClick={() => handlePauseUpload(record)}
              ></Button>
            </Tooltip>
          ) : (
            ""
          )}
          {record.status === "PAUSE" ? (
            <Tooltip title="恢复上传">
              <Button
                icon={<PlayCircleOutlined />}
                type="primary"
                ghost
                onClick={() => recoverUpload(record)}
              ></Button>
            </Tooltip>
          ) : (
            ""
          )}
          <Button icon={<DeleteOutlined />} onClick={() => onRemove(record)}>
            移除
          </Button>
        </Space>
      ),
    },
  ];

  const tabList = [
    {
      key: "tab1",
      tab: "素材库",
    },
    {
      key: "tab2",
      tab: "本地上传",
    },
  ];

  // 标签切换
  const onTabChange = async (value) => {
    setActiveTabKey(value);
  };

  // 暂停上传
  const handlePauseUpload = (record) => {
    pauseUpload(record?.objName);
    const newFileList = fileQueuedList.map((item) => {
      if (item.key === record.key) {
        item.status = "PAUSE";
      }
      return item;
    });
    setFileQueuedList(newFileList);
  };

  // 重新恢复上传
  const recoverUpload = async (record) => {
    const filtered = files.filter((file) => {
      return file.uid === record.key;
    });
    let file = filtered[0];
    let name = file.name;
    let ext = name.substring(name.lastIndexOf("."));
    let path = record.objName;
    const res = await resumeUpload(path, file, { actualProgress });

    // 处理保存素材到attach表中
    if (res?.statusCode === 200) {
      const { data } = res;
      let videoURL = data?.Location;
      let md5 = data?.ETag;
      let size = file?.size;
      await attachVideoAdd({
        md5,
        name,
        path,
        ext,
        size,
        oss_url: videoURL,
        advertiser_id: props?.advertiserId,
      });
    }
  };

  // 选择视频时触发事件
  const handleChange = ({ file, fileList }) => {
    if (fileList.length > 10) {
      message.error("一次性最多可上传10条视频素材");
      return false;
    }
    let fileArr = files;
    fileArr.push(file);
    let filterList = fileList.filter((file) => {
      let fileNames = [];
      if (fileQueuedList) {
        fileNames = fileQueuedList.map((item) => {
          return item?.filename;
        });
      }
      return !fileNames.includes(file.name);
    });
    let newList = [];
    if (filterList && filterList.length > 0) {
      newList = filterList.map((item) => {
        return {
          key: item?.uid,
          filename: item?.name,
          size: item?.size,
          progress: "0%",
          status: "START",
          statusName: "待开始",
        };
      });
    }
    setfiles(fileArr);
    setFileQueuedList([...fileQueuedList, ...newList]);
    setUploaderDisable(false);
  };

  // 移除
  const onRemove = (record) => {
    let newList = fileQueuedList.filter((item) => {
      return item.filename !== record.filename;
    });
    let newFiles = files.filter((item) => {
      return item.name !== record.filename;
    });
    setFileQueuedList(newList);
    setfiles(newFiles);
  };

  // 主要用于手动上传
  const beforeUpload = () => {
    return false;
  };

  const uploadProps = {
    name: "file",
    onChange: handleChange,
    showUploadList: false,
    multiple: true,
    beforeUpload,
  };

  // 实时更新进度
  const actualProgress = (p, objName, file) => {
    let updateFileList = fileQueuedList;
    let res = updateFileList.map((item) => {
      //文件上传中时更新文件状态和进度条
      if (item.key === file?.uid) {
        let progress = parseFloat(p * 100);
        item.progress = progress.toFixed(2) + "%";
        item.status = progress === 100 ? "UPLOADED" : "UPLOADING";
        item.statusName = progress === 100 ? "已完成" : "上传中";
        item.objName = objName;
      }
      return item;
    });
    setFileQueuedList(res);
  };

  let attachment = [];
  // 点击开始批量上传
  const handleUpload = async () => {
    setUploaderDisable(true);
    if (files && files.length > 0) {
      let date = dayjs().format("YYYY-MM-DD");
      let filtered = files.filter((item) => {
        return item.size > 130 * 1024 * 1024;
      });
      if (filtered && filtered.length > 0) {
        let name = filtered[0]?.name;
        message.error(`视频${name}超过130M, 请压缩后再上传`);
        setUploaderDisable(false);
        return false;
      }
      files.map(async (file) => {
        let name = file.name;
        let ext = name.substring(name.lastIndexOf("."));
        let prefix = name.substring(0, name.lastIndexOf("."));
        let path = `uploads/${date}/video/${prefix}${ext}`;
        const res = await breakPointMultipartUpload(path, file, {
          actualProgress,
        });
        if (res?.statusCode === 200) {
          const { data } = res;
          // 处理保存素材到attach表中
          let videoURL = data?.Location;
          attachment.push(videoURL);
          setAttach(attachment);
          let md5 = data?.ETag;
          let size = file?.size;
          if (videoURL !== undefined) {
            await attachVideoAdd({
              md5,
              name,
              path,
              ext,
              size,
              oss_url: videoURL,
              advertiser_id: props?.advertiserId,
            });
          }
        }
      });
    }
  };

  // 关闭弹窗
  const closeModal = () => {
    setFileQueuedList([]);
    setfiles([]);
    closeVideo();
    setAttach([]);
    setSelectRowKeys([]);
  };

  // 确定全部上传完成
  const onSubmit = () => {
    if (fileQueuedList.length === 0) {
      closeModal();
      return false;
    }
    let filtered = fileQueuedList.filter((item) => {
      return item.status !== "UPLOADED";
    });
    if (filtered && filtered.length > 0) {
      message.error("还未完成上传，如果暂时不上传请按取消", 2);
      return false;
    }
    handleSure();
    closeModal();
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  const MyIcon = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/c/font_3965974_nemuvicw5a.js", // 在 iconfont.cn 上生成
  });

  // 对视频进行排序操作
  const getSortBtnItem = () => {
    const items = [
      { title: "按消耗", value: 1 },
      { title: "按转化", value: 2 },
      { title: "使用数", value: 3 },
      { title: "被拒数", value: 4 },
    ];
    return items.map((item, key) => {
      return (
        <Button
          type="text"
          onClick={() => {
            handleSort(item.value);
          }}
          key={key}
        >
          <div style={{ display: "flex" }}>
            <div>{item.title}</div>
            <div
              style={{
                marginTop: -1,
                fontSize: 12,
                marginLeft: 2,
                color: "#999",
              }}
            >
              <div
                style={{ marginBottom: -12 }}
                className={
                  aboveActive && item.value === checkedEleValue
                    ? "sort-live"
                    : ""
                }
              >
                <CaretUpOutlined />
              </div>
              <div
                className={
                  belowActive && item.value === checkedEleValue
                    ? "sort-live"
                    : ""
                }
              >
                <CaretDownOutlined />
              </div>
            </div>
          </div>
        </Button>
      );
    });
  };

  // 各元素排序高亮显示
  const handleSort = (value) => {
    setCheckEleValue(value);
    if (sort === "asc") {
      setSort("desc");
      setBelowActive(true);
      setAboveActive(false);
    } else {
      setSort("asc");
      setAboveActive(true);
      setBelowActive(false);
    }

    const items = [
      { title: "cost", value: 1 },
      { title: "convert", value: 2 },
      { title: "use_num", value: 3 },
      { title: "audit_fail_num", value: 4 },
    ];
    let filterd = items.filter((item) => {
      return item.value === value;
    });
    let sortType = sort === "asc" ? "desc" : "asc";
    let params = { order: filterd[0]?.title + "_" + sortType };
    getList({ ...conditions, ...params });
  };

  return (
    <>
      <Drawer
        open={isShow}
        onClose={closeModal}
        width={850}
        maskClosable={false}
        destroyOnClose={true}
        placement="right"
        title={<div>上传素材到头条</div>}
      >
        <Card
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={onTabChange}
          tabProps={{ size: "small" }}
        >
          {activeTabKey === "tab1" ? (
            <>
              <Card
                type="inner"
                size="small"
                style={{ paddingTop: 10 }}
                title={
                  <Form
                    initialValues={{ type: 1, show_type: 2 }}
                    form={form}
                    onFinish={onHandleSearch}
                    size="small"
                  >
                    <Row gutter={24}>
                      <Col span={8}>
                        <Form.Item label="素材归属" name="show_type">
                          <Radio.Group>
                            <Radio value={1}>公有</Radio>
                            <Radio value={2}>私有</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item label="低效素材" name="is_low">
                          <Radio.Group>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="转化素材" name="is_convert">
                          <Radio.Group>
                            <Radio value={1}>转化</Radio>
                            <Radio value={0}>非转化</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item label="时间筛选" name="range_date">
                          <RangePicker />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="素材名称" name="title">
                          <Input allowClear placeholder="素材名称" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item label="所属素材手" name="uid">
                          <Select
                            allowClear
                            placeholder="选择素材手"
                            showSearch
                            filterOption={filterOption}
                            options={props?.sucaiOptions}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="所属分类" name="category">
                          <Select
                            allowClear
                            placeholder="选择素材分类"
                            showSearch
                            filterOption={filterOption}
                            options={props?.categoryOptions}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item label="标签" name="label">
                          <Select
                            allowClear
                            placeholder="选择素材标签"
                            showSearch
                            filterOption={filterOption}
                            options={props?.labels}
                            mode="multiple"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label
                      colon={false}
                      labelCol={{ span: 20 }}
                      style={{ marginTop: -10, marginBottom: 5 }}
                    >
                      <Space>
                        <Button type="primary" htmlType="submit">
                          确定
                        </Button>
                        <Button onClick={() => form.resetFields()}>重置</Button>
                      </Space>
                    </Form.Item>
                  </Form>
                }
              >
                <div style={{ display: "flex" }}>
                  <div style={{width: '45%'}}>
                    <Space size="small">
                      <Typography.Text style={{ color: "#999" }}>
                        共 {total} 个视频
                      </Typography.Text>
                      <Button type="link" onClick={selectAll}>全选当页</Button>
                      <Button type="link" onClick={selectCancel}>取消选择</Button>
                    </Space>
                  </div>
                  <div style={{ width: "55%", textAlign: "right" }}>
                    <Space size="small">{getSortBtnItem()}</Space>
                  </div>
                </div>
                <List
                  grid={{
                    gutter: 15,
                    column: 3,
                  }}
                  pagination={{
                    position: "both",
                    total,
                    onChange: handlePagesize,
                    defaultPageSize: perPage,
                    pageSizeOptions: [21, 33],
                    current: page,
                    size: 'small'
                  }}
                  dataSource={list}
                  loading={listLoading}
                  renderItem={(item) => (
                    <List.Item key={item.id}>
                      <div
                        style={{
                          overflowX: "hidden",
                          width: 190,
                          marginLeft: -12,
                          position: "relative",
                        }}
                      >
                        <div
                          style={{ padding: 2, marginLeft: "8%" }}
                          className={
                            selectRowKeys.includes(item.id) ? "live" : ""
                          }
                          onClick={() => onSelect(item)}
                        >
                          <div style={{ textAlign: "center", marginBottom: 5 }}>
                            <div style={{ fontWeight: "bold" }}>
                              <span style={{ fontSize: 12 }}>
                                {item.type === 1 ? "竖版视频" : "横版大图"}
                              </span>
                              {item.is_convert ? (
                                <Space>
                                  <MyIcon type="icon-qiang" />
                                  <span>({item.convert})</span>
                                </Space>
                              ) : (
                                ""
                              )}
                            </div>
                            <Tooltip title={item.title}>{item.title}</Tooltip>
                          </div>
                          {item.file_id ? (
                            <video
                              width="100%"
                              src={item?.attach_video?.oss_url}
                              alt=""
                              controls
                              preload="none"
                              poster={`${item?.attach_video?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                            />
                          ) : (
                            <img
                              width="100%"
                              src={item?.attach_img?.oss_url}
                              alt=""
                            />
                          )}
                          <div
                            style={{
                              display: "flex",
                              backgroundColor: "#eee",
                              justifyContent: "space-evenly",
                              width: "100%",
                              overflow: "hidden",
                            }}
                          >
                            <div style={{ marginRight: 3, color: "red" }}>
                              ￥{item?.cost}
                            </div>
                            <div style={{ marginRight: 3, color: "green" }}>
                              使用:{item?.use_num}
                            </div>
                            <div>拒:{item?.audit_fail_num}</div>
                          </div>
                        </div>
                        {selectRowKeys.includes(item.id) ? (
                          <div
                            style={{
                              fontSize: 35,
                              position: "absolute",
                              left: "50%",
                              top: "36%",
                            }}
                          >
                            <MyIcon type="icon-xuanzhong-copy" />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </List.Item>
                  )}
                />
              </Card>
              <Card
                style={{ marginTop: 10 }}
                title={
                  selectRowKeys.length > 0 ? (
                    <div>已选中 {selectRowKeys.length} 个素材</div>
                  ) : (
                    ""
                  )
                }
                extra={
                  <Space>
                    <Button>取消</Button>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      确定
                    </Button>
                  </Space>
                }
              ></Card>
            </>
          ) : (
            <>
              <div style={{ height: 100 }}>
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <div>
                      将文件拖到此处，或<Button type="link">点击上传</Button>
                    </div>
                  </p>
                </Dragger>
              </div>

              {fileQueuedList.length > 0 ? (
                <>
                  <div
                    style={{ width: "100%", textAlign: "right", marginTop: 10 }}
                  >
                    <Button
                      type="primary"
                      disabled={uploaderDisable}
                      onClick={handleUpload}
                    >
                      开始上传
                    </Button>
                  </div>
                  <Card
                    size="small"
                    style={{ marginTop: 10, height: 370, overflowY: "auto" }}
                  >
                    <Table
                      dataSource={fileQueuedList}
                      columns={columns}
                      pagination={false}
                    />
                  </Card>
                </>
              ) : (
                ""
              )}

              {attach.length > 0 ? (
                <Card
                  title={
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: 10 }}>
                        已上传完成 {attach.length || 0}/10:
                      </div>
                      <div>
                        {attach.map((value, key) => {
                          return (
                            <video
                              src={value}
                              controls=""
                              key={key}
                              width={20}
                              style={{ marginRight: 8 }}
                            ></video>
                          );
                        })}
                      </div>
                    </div>
                  }
                  extra={
                    <Space>
                      <Button>取消</Button>
                      <Button type="primary" onClick={onSubmit}>
                        确定
                      </Button>
                    </Space>
                  }
                  bodyStyle={{ display: "none" }}
                  style={{ marginTop: 10 }}
                ></Card>
              ) : (
                ""
              )}
            </>
          )}
        </Card>
      </Drawer>
    </>
  );
}
