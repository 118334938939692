import React from "react";
import { HashRouter } from "react-router-dom";
import AppRouter from "./router/index";
import "@bytedance-ad/mui-react/dist/style.css";
import "./App.less";
import CheckVersion from "./CheckVersion";

function App() {
  return (
    <div className="app">
      <HashRouter>
        <CheckVersion />
        <AppRouter />
      </HashRouter>
    </div>
  );
}

export default App;
