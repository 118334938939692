
const columns = () => [
  {
    title: "用户",
    width: 120,
    align: "center",
    dataIndex: "user",
    key: "user",
    render: (_,record) => (
      <>
        <div>{record?.user?.nickname}</div>
        <div>{record?.user?.openid}</div>
      </>
    )
  },
  {
    title: "日期",
    width: 100,
    align: "center",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "书籍",
    width: 180,
    align: "center",
    dataIndex: "book",
    key: "book",
    ellipsis: true,
    render: (_, record) => (
      record?.book?.title
    )
  },
  {
    title: "章节",
    width: 100,
    align: "center",
    dataIndex: "chapternum",
    key: "chapternum",
  },
  {
    title: "阅读秒数",
    width: 160,
    align: "center",
    dataIndex: "second",
    key: "second",
  },
];

export default columns;
