import React, {useCallback} from 'react';
import {
  Card,
  Form,
  TimePicker,
  Switch,
  Space,
  Button,
  Select,
  Spin,
  Radio,
  InputNumber,
} from 'antd';
import { merge } from 'lodash'

import { useSubmit, useInit, useRulesMapper } from './hooks'
import { BatchCopy } from './components'
import './index.less'

const format = 'HH:mm';

const AutoPut = () => {
  const [formData] = Form.useForm();

  const { originData, initLoading, accounts, filterOption, onClearAccounts  } = useInit({ formData })

  const { onSubmit, submitLoading } = useSubmit({ originData })

  const { rulesMapper, advertiserTotalCostRule } = useRulesMapper({ formData })

  const advertiserSelectType = Form.useWatch('advertiserSelectType', formData);
  const advertiserList = Form.useWatch('advertiserList', formData);

  // 确认批量复制
  const onConfirmBatchCopy = useCallback((list) => {
    const filteredAccounts = accounts.filter(item => {
      return list.includes(Number(item.value))
    })

    const r = merge(filteredAccounts, advertiserList)

    formData.setFieldsValue({
      advertiserList: r,
    })
  }, [accounts, advertiserList, formData])

  return (
    <Spin spinning={initLoading}>
      <Card title="自动投放设置" className="auto-put-wrapper">
        <Form onFinish={onSubmit} form={formData} layout="horizontal">
          <Card title="自动补计划开关" size="small">
            {/* 开关 */}
            <Form.Item label="开关" name="status">
              <Switch />
            </Form.Item>
          </Card>

          <Card title="广告账户设置" size="small">
            <Form.Item label="选择广告账户" name="advertiserSelectType" rules={rulesMapper.commonSelect}>
              <Radio.Group>
                <Radio.Button value="specified">
                  手动选择广告账广
                </Radio.Button>
                <Radio.Button value="condition">
                  规则自动选择账户
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            {/* 和一键开户页面相同的接口及逻辑 */}
            <>
              {
                advertiserSelectType === 'specified' && (
                  <>
                    <BatchCopy onConfirm={onConfirmBatchCopy} />
                    <div className="select-account">
                      <Form.Item label="选择广告账户" name="advertiserList" rules={rulesMapper.commonSelect}>
                        <Select
                          mode="multiple"
                          placeholder="请选择广告账户 (可多选)"
                          showSearch
                          filterOption={filterOption}
                          options={accounts}
                          autoClearSearchValue={false}
                          labelInValue
                        />
                      </Form.Item>
                      <Button type="primary" onClick={onClearAccounts}>清空</Button>
                    </div>
                  </>
                )
              }
            </>

            <>
              {
                advertiserSelectType === 'condition' && (
                  <>
                    <div className="form-inline-range">
                      <Form.Item
                        label="广告账户总消耗区间"
                        name="advertiserTotalCost"
                        rules={advertiserTotalCostRule}
                        dependencies={['advertiserTotalCostMax']}
                        required
                      >
                        <InputNumber min={0} precision={2} style={{ width: '150px' }} />
                      </Form.Item>
                      <span>至</span>
                      <Form.Item
                        label
                        noStyle
                        colon={false}
                        name="advertiserTotalCostMax"
                        rules={advertiserTotalCostRule}
                        dependencies={['advertiserTotalCost']}
                      >
                        <InputNumber min={0} precision={2} style={{ width: '150px' }} />
                      </Form.Item>
                    </div>
                    <Form.Item label="广告账户总消耗TopN" name="advertiserTopNum" rules={rulesMapper.commonInput}>
                      <InputNumber min={0} precision={0} style={{ width: '150px' }} />
                    </Form.Item>
                  </>
                )
              }
            </>
          </Card>

          <Card title="广告设置" size="small">
            <div className="advert-setting-inline">
              <Form.Item label="广告消耗大于等于" rules={rulesMapper.commonInput} name="advertCost">
                <InputNumber min={0} precision={2} style={{ width: '150px' }} />
              </Form.Item>
              <div className="divider-text-inline">且</div>
              <Form.Item label="广告总消耗TopN" rules={rulesMapper.commonInput} required={false} name="advertTopNum">
                <InputNumber min={0} precision={0} style={{ width: '150px' }} />
              </Form.Item>
            </div>
            <Form.Item label="要创建广告数量" rules={rulesMapper.commonInput} name="advertCount">
              <InputNumber min={0} precision={0} style={{ width: '150px' }} />
            </Form.Item>
          </Card>

          <Card title="广告素材设置" size="small">
            <Form.Item label="广告素材来源，账户内消耗前TopN的广告" name="materialAdvertTopNum" rules={rulesMapper.commonInput}>
              <InputNumber min={0} precision={0} style={{ width: '150px' }} />
            </Form.Item>
          </Card>

          {/* 选择启用时间, 时分 */}
          <Card title="执行时间" size="small">
            <Form.Item label="每天执行时间" name="time" rules={rulesMapper.commonSelect}>
              <TimePicker format={format} allowClear={false}></TimePicker>
            </Form.Item>
          </Card>

          <Form.Item label colon={false} style={{ marginTop: '20px' }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={submitLoading}>
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  )
}

export default AutoPut
