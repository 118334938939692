import React, { useEffect } from "react";
import { useState } from "react";
import ResultComponent from "../Public/ResultIaa";
import { playletResult } from "@/api/playlet";
import { getAdvertiseBackList } from '@/api/delivery'
import { useLocation } from "react-router-dom";

const FORMAT = 'YYYY-MM-DD HH:mm:ss'

// iaa 广告回传
export default function Result() {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [isSuper, setIsSuper] = useState(false);

  const { state, search } = useLocation();
  const searchParams = new URLSearchParams(search.substring(1));
  let ad_id = null;
  if (searchParams.size > 0) {
    ad_id = searchParams.get("ad_id");
  }

  useEffect(() => {
    getList();
  }, []);

  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getAdvertiseBackList({
      // appType: 1011,
      startTime: params?.day?.[0]?.format?.(FORMAT) || undefined,
      endTime: params?.day?.[1]?.format?.(FORMAT) || undefined,
      adId: state?.ad_id || params?.aid || ad_id,
    });
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.list);
      setIsSuper(data?.is_super);
      setLoading(false);
      setTotal(data?.total);
    }
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPageSize(size);
    getList({ ...conditions, page, pageSize: size });
  };

  const onSearch = params => {
    setConditions(params);
    getList(params);
  };

  // 传到子组件的参数
  const propsData = {
    list,
    total,
    page,
    loading,
    pagesize,
    perPage: pageSize,
    onSearch,
    app_type: 1011,
    ad_id: state?.ad_id || ad_id,
    isSuper,
  };
  return <ResultComponent {...propsData} />;
}
