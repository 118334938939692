import {Card, Tabs} from "antd";
import ProfitStatisticsIAP from "./IAP";
import ProfitStatisticsIAA from "./IAA";

const onChange = (key) => {
  console.log(key);
};

const items = [
  {
    key: '1',
    label: 'IAA统计',
    children: (<ProfitStatisticsIAA/>),
  },
  {
    key: '2',
    label: 'IAP统计',
    children: (<ProfitStatisticsIAP/>)
  }
];

const Page = () => {
  return (
      <Card style={{
            width: '100%',
          }}
      >
        <Tabs size="large" defaultActiveKey="1" items={items} onChange={onChange} />
      </Card>)
}

export default Page;