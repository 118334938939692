import React, { useEffect, useState } from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./book_columns";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Statistic,
} from "antd";
import {
  getNovelEcpmList,
  getNovelEcpmStatistics,
} from "../../../../api/delivery";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export default function Book(props) {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [statisticsData, setStatisticsData] = useState({});
  const [conditions, setConditions] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    if (!params?.range_date) {
      let current = dayjs().format("YYYY-MM-DD");
      params.startDate = current;
      params.endDate = current;
    } else {
      let rangeDate = params?.range_date;
      params.startDate = rangeDate[0];
      params.endDate = rangeDate[1];
    }
    params.statsDim = "book";
    params.pageNum = page;
    params.pageSize = perPage;
    const res = await getNovelEcpmList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.list);
      setTotal(data?.total);
    }
    setLoading(false);
  };

  // 总计数据
  const ecpmStatistics = async (params = {}) => {
    if (!params?.range_date) {
      let current = dayjs().format("YYYY-MM-DD");
      params.startDate = current;
      params.endDate = current;
    } else {
      let rangeDate = params?.range_date;
      params.startDate = rangeDate[0];
      params.endDate = rangeDate[1];
    }
    params.statsDim = "book";
    const res = await getNovelEcpmStatistics(params);
    if (res?.code === 200) {
      const { data } = res;
      setStatisticsData(data);
    }
  };

  const getData = () => {
    Promise.all([getList(), ecpmStatistics()]);
  };

  // 表单筛选查询
  const onSubmit = () => {
    let values = form.getFieldsValue();
    let rangeDate;
    if (values?.dates) {
      rangeDate = values.dates.map((date) => {
        return date.format("YYYY-MM-DD");
      });
      delete values.dates;
      values.range_date = rangeDate;
    }
    setConditions(values);
    Promise.all([getList(values), ecpmStatistics(values)]);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
  };

  // 排序
  const onSortChange = (pagination, filters, sorter) => {
    let order;
    let orderByAsc
    if (sorter) {
      let feild = sorter.columnKey;
      let sortBy = sorter.order;
      if (feild !== undefined) {
        order = feild;
        if (sortBy === "ascend") {
          orderByAsc = 1;
        } else if (sortBy === "descend") {
          orderByAsc = 0;
        }
      }
      if (sortBy !== undefined) {
        setConditions({ ...conditions, orderBy: order, orderByAsc });
        getList({
          ...conditions,
          pageSize: pagination.pageSize,
          pageNum: pagination.current,
          orderBy: order,
          orderByAsc,
        });
      } else {
        delete conditions.orderBy
        delete conditions.orderByAsc
        getList({
          ...conditions,
          pageSize: pagination.pageSize,
          pageNum: pagination.current,
        })
      }
    }
  };

  return (
    <>
      <Card
        title={
          <Form layout="inline" form={form}>
            <Form.Item label="日期" name="dates" labelCol={{ span: 3 }}>
              <RangePicker />
            </Form.Item>
            <Form.Item label="小说名称" name="bookId">
              <Select
                allowClear
                placeholder="选择小说"
                options={props.books}
                showSearch
                filterOption={filterOption}
                style={{ width: 150 }}
              />
            </Form.Item>
            <Form.Item label="投手" name="adminId">
              <Select
                allowClear
                showSearch
                placeholder="请选择投手"
                options={props.pitchers}
                style={{ width: 150 }}
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item label colon={false}>
              <Space>
                <Button type="primary" onClick={onSubmit}>
                  确定
                </Button>
                <Button onClick={() => form.resetFields()}>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
        extra={
          <div style={{width: 200}}>
            <Row gutter={12}>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总计消耗</div>}
                  value={statisticsData?.cost}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总收益</div>}
                  value={statisticsData?.totalAdCash}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>新增收益</div>}
                  value={statisticsData?.todayAdCash}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={7}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总计回收</div>}
                  value={statisticsData?.money}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总回收率</div>}
                  value={statisticsData?.totalRecoveryRate + "%"}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={9}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>新增回收率</div>}
                  value={statisticsData?.newRecoveryRate + "%"}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
            </Row>
          </div>
        }
      >
        <Table
          dataSource={list}
          columns={columns()}
          total={total}
          page={page}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
          pageOptions={[20]}
          onChange={onSortChange}
        />
      </Card>
    </>
  );
}
