import {
  Button,
  Card,
  Checkbox,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
  Upload,
  message,
} from "antd";
import React, { createRef } from "react";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import { useState } from "react";
import { useEffect } from "react";
import {
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  accountDispatch,
  accountUnbind,
  batchCopyBind,
  excelExportUnbind,
  excelImportBind,
  getAdvertiserList,
  getDispatchAdvertisers,
  getFilterTouShou,
  getManagerAccountInfo,
  getPitcherAdminUser,
} from "../../../api/advertise";
import GuanModal from "./Guan";
import { Link } from "react-router-dom";

const { Search } = Input;
const { Option } = Select;
const { confirm } = Modal;

export default function Advertise() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchType, setSearchType] = useState(1);
  const [show, setShow] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [guanList, setGuanList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [dispatchTotal, setDispatchTotal] = useState(0);
  const [targetList, setTargetList] = useState([]);
  const [filterTargetList, setFilterTargetList] = useState([]);
  const [targetTotal, setTargetTotal] = useState(0);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [targetLoading, setTargetLoading] = useState(false);
  const [dispatchCheckedList, setDispatchCheckedList] = useState([]);
  const [targetCheckedList, setTargetCheckedList] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [guanShow, setGuanShow] = useState(false);
  const [accountUnbindShow, setAccountUnbindShow] = useState(false);
  const [roleKey, setRoleKey] = useState("");
  const [accountInfo, setAccountInfo] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false);
  const [unbindLoading, setUnbindLoading] = useState(false);
  const [excelModalShow, setExcelModalShow] = useState(false);
  const [excelImportModalShow, setExcelImportModalShow] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState(1);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [pitcherOptions, setPictherOptions] = useState([]);
  const [excelImportInfo, setExcelImportInfo] = useState([]);
  const [excelImportList, setExcelImportList] = useState([]);
  const [copyInfo, setCopyInfo] = useState([]);
  const [copyList, setCopyList] = useState([]);
  const [filterImportList, setFilterImportList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const [excelForm] = Form.useForm();
  const [copyForm] = Form.useForm();
  const [excelImportForm] = Form.useForm();
  const ref = createRef();

  // 获取广告主列表
  const getList = async (params = {}) => {
    setLoading(true);
    let values;
    if (params.hasOwnProperty("pageSize")) {
      values = params;
    } else {
      values = { ...params, pageSize: 10 };
    }
    const res = await getAdvertiserList(values);
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setTotal(data?.total);
      setRoleKey(data?.role_key);
      setLoading(false);
    }
  };

  const onChange = (value) => {
    setSearchType(value);
  };

  const selectBefore = (
    <Select defaultValue={1} onChange={onChange}>
      <Option value={1}>广告主名称</Option>
      <Option value={2}>广告主ID</Option>
    </Select>
  );

  // 关键词查询
  const onSearch = (value) => {
    if (searchType === 1) {
      getList({ account_name: value });
    } else {
      getList({ advertiser_id: value });
    }
  };

  // 分配弹窗
  const dispatch = async () => {
    const res = await getPitcherAdminUser();
    const { data } = res;
    setShow(true);
    if (res?.code === 200) {
      let pitcher = data?.pitcher;
      let newPitcher = pitcher.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setAdminList(newPitcher);
      let guan = data?.guan;
      let NewGuan = guan.map((item) => {
        return { label: item?.account_name, value: item?.account_id };
      });
      setGuanList(NewGuan);
    }
  };

  // 关闭弹窗
  const close = () => {
    setShow(false);
    setDataSource([]);
    setFilterDataSource([]);
    setDispatchTotal(0);
    setTargetList([]);
    setFilterTargetList([]);
    setTargetTotal(0);
    setDisplayLoading(false);
    setTargetLoading(false);
    setConfirmLoading(false);
  };

  // 分页设置
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ page, pageSize: size });
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 获取分配的广告账号
  const getDistributeAdvertiser = async (params = {}) => {
    const res = await getDispatchAdvertisers(params);
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let selectList = data?.selectList;
      setDataSource(list);
      setDispatchTotal(list.length);
      setFilterDataSource(list?.slice(0, 1000));
      setTargetList(selectList);
      setTargetTotal(selectList.length);
      setFilterTargetList(selectList?.slice(0, 1000));
      setDisplayLoading(false);
      setTargetLoading(false);
    }
  };

  // 投手账号筛选
  const adminChange = (value) => {
    setTargetLoading(true);
    let params = { ...conditions, admin_id: value };
    setConditions(params);
    getDistributeAdvertiser(params);
  };

  // 管家账号筛选
  const pidChange = (value) => {
    setDisplayLoading(true);
    let params = { ...conditions, pid: value };
    setConditions(params);
    getDistributeAdvertiser(params);
  };

  // 管家账号关键字模糊匹配
  const onDispatchValueChange = (e) => {
    let value = e.target.value;
    let filtered = dataSource.filter((item) => {
      return item.account_name?.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    });
    setDispatchTotal(filtered.length);
    setFilterDataSource(filtered.slice(0, 1000));
  };

  // 已分配账号关键词模糊匹配
  const onTargetValueChange = (e) => {
    let value = e.target.value;
    let filtered = targetList.filter((item) => {
      return item.account_name?.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    });
    setTargetTotal(filtered.length);
    setFilterTargetList(filtered.slice(0, 1000));
  };

  // 待分配账号全选 | 取消全选
  const onDispatchCheckChange = (e) => {
    let value = e.target.checked;
    if (value) {
      let checkedList = dataSource.map((item) => {
        return item.account_id;
      });
      setDispatchCheckedList(checkedList);
    } else {
      setDispatchCheckedList([]);
    }
  };

  // 待分配账号单个选择或取消选择
  const onDispatchItemChange = (e, accountId) => {
    let value = e.target.checked;
    if (value) {
      setDispatchCheckedList([...dispatchCheckedList, accountId]);
    } else {
      let newCheckList = dispatchCheckedList.filter((value) => {
        return value !== accountId;
      });
      setDispatchCheckedList(newCheckList);
    }
  };

  const checkAll = dispatchTotal === dispatchCheckedList.length;
  const indeterminate =
    dispatchCheckedList.length > 0 &&
    dispatchCheckedList.length < dispatchTotal;

  // 已分配账号全选 | 取消全选
  const onTargetCheckChange = (e) => {
    let value = e.target.checked;
    if (value) {
      let checkedList = targetList.map((item) => {
        return item.account_id;
      });
      setTargetCheckedList(checkedList);
    } else {
      setTargetCheckedList([]);
    }
  };

  // 已分配账号单个选择或取消选择
  const onTargetItemChange = (e, accountId) => {
    let value = e.target.checked;
    if (value) {
      setTargetCheckedList([...targetCheckedList, accountId]);
    } else {
      let newCheckList = targetCheckedList.filter((value) => {
        return value !== accountId;
      });
      setTargetCheckedList(newCheckList);
    }
  };

  const targetCheckAll = targetTotal === targetCheckedList.length;
  const targetIndeterminate =
    targetCheckedList.length > 0 && targetCheckedList.length < targetTotal;

  // 穿梭框已选中的项向右移，删除
  const rightMove = () => {
    let removeItems = targetList.filter((item) => {
      return !targetCheckedList.includes(item?.account_id);
    });
    setTargetList(removeItems);
    setFilterTargetList(removeItems.slice(0, 1000));
    setTargetTotal(removeItems.length);
    setTargetCheckedList([]);

    let addItems = targetList.filter((item) => {
      return targetCheckedList.includes(item?.account_id);
    });
    setFilterDataSource([...addItems, ...filterDataSource]);
    setDataSource([...addItems, ...filterDataSource]);
    setDispatchTotal([...addItems, ...dataSource].length);
    setConditions({ ...conditions, direction: "right" });
  };

  // 穿梭框已选中的项向左移，增加
  const leftMove = () => {
    let removeItems = dataSource.filter((item) => {
      return !dispatchCheckedList.includes(item?.account_id);
    });
    setDataSource(removeItems);
    setFilterDataSource(removeItems.slice(0, 1000));
    setDispatchTotal(removeItems.length);
    setDispatchCheckedList([]);

    let addItems = dataSource.filter((item) => {
      return dispatchCheckedList.includes(item?.account_id);
    });
    let newItems = [...addItems, ...targetList];
    setFilterTargetList(newItems.slice(0, 1000));
    setTargetList(newItems);
    setTargetTotal(newItems.length);
    setConditions({ ...conditions, direction: "left" });
  };

  // 表单提交
  const onSubmit = async () => {
    setConfirmLoading(true);
    // 只有切换才提交数据
    if (conditions.hasOwnProperty("direction")) {
      const res = await accountDispatch({
        ...conditions,
        updateAdvertiserIds: targetList,
      });
      if (res && res?.code === 200) {
        message.success("操作成功", 1);
        setTimeout(() => {
          setConfirmLoading(false);
          setShow(false);
        }, 1000);
      } else {
        setConfirmLoading(false);
      }
    } else {
      setConfirmLoading(false);
      message.error("请先切换广告主账号", 1);
      return false;
    }
  };

  // 显示管家管理弹窗
  const showGuanModal = () => {
    setGuanShow(true);
  };

  const guanClose = () => {
    setGuanShow(false);
  };

  // 账号解除绑定
  const unbind = (record) => {
    confirm({
      title: "确定解绑该账号?",
      content: "账号解绑将清除该账户下的广告和已上传的素材",
      onOk: async () => {
        const res = await accountUnbind({ account_id: record?.account_id });
        if (res?.code === 200) {
          message.success("账号：" + record?.account_id + "解绑成功", 1);
          setTimeout(() => {
            getList();
          }, 1000);
        }
      },
    });
  };

  // 通过管家账号解绑
  const accountBatchUnbind = () => {
    setAccountUnbindShow(true);
  };

  const accountUnbindClose = () => {
    setAccountUnbindShow(false);
    setAccountInfo([]);
    getList();
  };

  // 管家账号输入筛选广告账号
  const accountFilter = async (values) => {
    setAccountLoading(true);
    const res = await getManagerAccountInfo(values);
    if (res?.code === 200) {
      const { data } = res;
      setAccountLoading(false);
      setAccountInfo(data);
    }
  };

  // 处理批量解绑
  const handleBatchUnbind = async () => {
    setUnbindLoading(true);
    const res = await accountUnbind({ pid: accountInfo?.account_id });
    if (res?.code === 200) {
      message.success(
        "管家账号：" + accountInfo?.account_id + "所有账号解绑成功",
        1
      );
      setTimeout(() => {
        setUnbindLoading(false);
        accountUnbindClose();
      }, 1000);
    }
  };

  const handleChange = ({ file }) => {
    setExcelFile(file);
  };

  // excel 文件上传前验证，及手动上传
  const beforeUpload = (file) => {
    let isExcel = false;
    if (
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      isExcel = true;
    }
    if (!isExcel) {
      message.error(`只支持xls,xlsx格式的excel文件`);
      // 报错则列表阻止展现
      return Upload.LIST_IGNORE;
    }
    // 手动上传
    return false;
  };

  // 上传组件属性配置
  const uploadProps = {
    name: "file",
    onChange: handleChange,
    maxCount: 1,
    beforeUpload,
  };

  const tabList = [
    { key: 1, tab: "excel文件导入" },
    { key: 2, tab: "广告账户批量复制" },
  ];

  const onTabChange = (key) => {
    setActiveTabKey(key);
    excelImportForm.resetFields();
    copyForm.resetFields();
  };

  function clearBr(key) {
    key = key.replace(/<\/?.+?>/g, "::");
    key = key.replace(/[\r\n]/g, "::");
    return key;
  }

  // 复制批量绑定
  const copySure = async (values) => {
    let accountIds = clearBr(values.account_ids);
    accountIds = accountIds.split(":").filter((value) => value);
    if (accountIds.length > 10) {
      message.error(
        "批量复制绑定一次性账户最多支持10条, 太多可使用excel文件导入绑定"
      );
      return false;
    }
    let params = { admin_id: values?.admin_id, account_ids: accountIds };
    const res = await batchCopyBind(params);
    if (res?.code === 200) {
      message.success("批量复制导入成功");
      const { data } = res;
      setCopyInfo(data?.info);
      setCopyList(data?.lists);
      copyForm.resetFields();
      excelImportForm.resetFields();
    }
  };

  // excel导入解绑弹窗打开
  const exportExcel = async () => {
    const res = await getFilterTouShou();
    if (res?.code === 200) {
      const { data } = res;
      const lists = data?.lists;
      const newLists = lists.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setPictherOptions(newLists);
    }
    setExcelModalShow(true);
  };

  // excel导入绑定弹窗
  const importExcel = async () => {
    const res = await getFilterTouShou();
    if (res?.code === 200) {
      const { data } = res;
      const lists = data?.lists;
      const newLists = lists.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setPictherOptions(newLists);
    }
    setExcelImportModalShow(true);
  };

  // excel导入解绑弹窗关闭
  const excelClose = () => {
    setExcelModalShow(false);
    setUploadLoading(false);
    setExcelFile(null);
    excelForm.resetFields();
  };

  // excel导入绑定弹窗关闭
  const excelImportClose = () => {
    setExcelImportModalShow(false);
    setUploadLoading(false);
    setExcelFile(null);
    setExcelImportInfo([]);
    setExcelImportList([]);
    excelImportForm.resetFields();
  };

  // 处理excel文件导入解绑
  const excelFileSubmit = async () => {
    excelForm
      .validateFields()
      .then(async () => {
        setUploadLoading(true);
        let adminId = excelForm.getFieldValue("admin_id");
        let isDel = excelForm.getFieldValue('is_del')
        const formData = new FormData();
        formData.append("file", excelFile);
        formData.append("admin_id", adminId);
        formData.append('is_del', isDel)
        const res = await excelExportUnbind(formData);
        if (res?.code === 200) {
          message.success("批量导入解绑成功");
          setTimeout(() => {
            excelClose();
          }, 2000);
        } else {
          setUploadLoading(false);
        }
      })
      .catch(() => {});
  };

  // 处理excel文件导入绑定
  const excelFileImportSubmit = async () => {
    excelImportForm
      .validateFields()
      .then(async () => {
        setUploadLoading(true);
        let adminId = excelImportForm.getFieldValue("admin_id");
        const formData = new FormData();
        formData.append("file", excelFile);
        formData.append("admin_id", adminId);
        const res = await excelImportBind(formData);
        if (res?.code === 200) {
          message.success("批量导入成功");
          const { data } = res;
          setExcelImportInfo(data?.info);
          setExcelImportList(data?.lists);
          setExcelFile(null);
          excelImportForm.resetFields();
        }
        setUploadLoading(false);
      })
      .catch(() => {});
  };

  const importColumns = () => [
    {
      title: "广告账号ID",
      width: 120,
      align: "center",
      dataIndex: "account_id",
      key: "account_id",
    },
    {
      title: "广告账号名称",
      width: 150,
      align: "center",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "绑定状态",
      width: 150,
      align: "center",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        let color =
          value === "success"
            ? "green"
            : value === "warning"
            ? "volcano"
            : "#f50";
        let tag =
          value === "success"
            ? "成功"
            : value === "warning"
            ? "已绑定"
            : "未找到";
        return (
          <Tag color={color} key={value}>
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "绑定投手",
      width: 120,
      align: "center",
      dataIndex: "pitcher",
      key: "pitcher",
    },
  ];

  // 广告账户搜索
  const onAccountSearch = (value) => {
    let val = parseInt(value);
    let list = excelImportList;
    if (val) {
      let filtered = list.filter((item) => {
        return parseInt(item.account_id) === val;
      });
      setFilterImportList(filtered);
    } else {
      setFilterImportList([]);
      setExcelImportList(list);
    }
  };

  return (
    <Card
      title={
        <Space>
          <Button type="primary" onClick={dispatch}>
            分配
          </Button>
          {roleKey?.split(",")?.includes("agent") ? (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={showGuanModal}
            >
              管家管理
            </Button>
          ) : (
            ""
          )}
          {roleKey?.split(",")?.includes("super") ? (
            <>
              <Button type="primary" danger onClick={accountBatchUnbind}>
                管家账号解绑
              </Button>
            </>
          ) : (
            ""
          )}
          {roleKey?.split(",")?.includes("super") ||
          roleKey?.split(",")?.includes("agent") ? (
            <Link to={`/admin/batch_plan/advertiser_list/bid_limit_set`}>
              账户限价设置
            </Link>
          ) : (
            ""
          )}
          <Button danger ghost onClick={exportExcel}>
            excel导入解绑
          </Button>
          <Button type="primary" ghost onClick={importExcel}>
            excel导入绑定
          </Button>
        </Space>
      }
      extra={
        <Form layout="inline">
          <Form.Item>
            <Search
              placeholder={searchType === 1 ? "广告主名称" : "广告主ID"}
              allowClear
              enterButton="搜索"
              addonBefore={selectBefore}
              onSearch={onSearch}
            />
          </Form.Item>
        </Form>
      }
    >
      <Table
        dataSource={list}
        columns={columns({ unbind })}
        total={total}
        page={page}
        pagesize={pagesize}
        perPage={perPage}
        loading={loading}
      />

      {/* 分配弹窗 */}
      <Modal
        open={show}
        onCancel={close}
        width={600}
        maskClosable={false}
        destroyOnClose={true}
        onOk={onSubmit}
        confirmLoading={confirmLoading}
      >
        <Form layout="vertical">
          <Form.Item
            label="后台账号"
            name="admin_id"
            rules={[{ required: true, message: "请选择账号" }]}
          >
            <Select
              options={adminList}
              allowClear
              showSearch
              filterOption={filterOption}
              onChange={adminChange}
              size="small"
            />
          </Form.Item>
          <Form.Item
            label="管家账号"
            name="pid"
            rules={[{ required: true, message: "请选择管家账号" }]}
          >
            <Select
              options={guanList}
              allowClear
              showSearch
              filterOption={filterOption}
              onChange={pidChange}
              size="small"
            />
          </Form.Item>
          <Form.Item label="分配广告主">
            <div style={{ display: "flex" }}>
              {/* 待分配的 */}
              <div style={{ width: "49%" }}>
                <Card
                  title={
                    <Space>
                      <Checkbox
                        onChange={onDispatchCheckChange}
                        indeterminate={indeterminate}
                        checked={checkAll}
                      />
                      <span style={{ fontWeight: "normal" }}>
                        {displayLoading ? <Spin /> : dispatchTotal} 项
                      </span>
                    </Space>
                  }
                  size="small"
                >
                  <div style={{ marginBottom: 10 }}>
                    <Input
                      placeholder="请输入搜索内容"
                      size="small"
                      allowClear
                      onChange={onDispatchValueChange}
                    />
                  </div>
                  {displayLoading ? (
                    <Spin />
                  ) : (
                    <ul style={{ height: 250, overflow: "auto" }}>
                      {filterDataSource?.map((item) => {
                        return (
                          <li
                            key={item?.account_id}
                            style={{ marginBottom: 5 }}
                          >
                            <span style={{ marginRight: 5 }}>
                              <Checkbox
                                checked={dispatchCheckedList.includes(
                                  item?.account_id
                                )}
                                onChange={(e) =>
                                  onDispatchItemChange(e, item?.account_id)
                                }
                              />
                            </span>
                            <Typography.Text
                              style={{ width: 180, fontSize: 13 }}
                              ellipsis={{
                                tooltip: `${item.account_name} - ${item.account_id}`,
                              }}
                            >
                              {item.account_name} - {item.account_id}
                            </Typography.Text>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Card>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  marginLeft: 12,
                  marginRight: 12,
                  paddingTop: "26%",
                }}
              >
                <div style={{ marginBottom: 5 }} onClick={leftMove}>
                  <Button
                    icon={<RightOutlined />}
                    disabled={dispatchCheckedList.length === 0}
                    type="primary"
                    size="small"
                  ></Button>
                </div>
                <div onClick={rightMove}>
                  <Button
                    icon={<LeftOutlined />}
                    disabled={targetCheckedList.length === 0}
                    type="primary"
                    size="small"
                  ></Button>
                </div>
              </div>

              {/* 已分配的 */}
              <div style={{ width: "49%" }}>
                <Card
                  title={
                    <Space>
                      <Checkbox
                        onChange={onTargetCheckChange}
                        indeterminate={targetIndeterminate}
                        checked={targetCheckAll}
                      />
                      <span style={{ fontWeight: "normal" }}>
                        {targetLoading ? <Spin /> : targetTotal} 项
                      </span>
                    </Space>
                  }
                  size="small"
                >
                  <div style={{ marginBottom: 10 }}>
                    <Input
                      placeholder="请输入搜索内容"
                      size="small"
                      allowClear
                      onChange={onTargetValueChange}
                    />
                  </div>
                  {targetLoading ? (
                    <Spin />
                  ) : (
                    <ul style={{ height: 250, overflow: "auto" }}>
                      {filterTargetList?.map((item) => {
                        return (
                          <li key={item.account_id} style={{ marginBottom: 5 }}>
                            <span style={{ marginRight: 5 }}>
                              <Checkbox
                                checked={targetCheckedList.includes(
                                  item?.account_id
                                )}
                                onChange={(e) =>
                                  onTargetItemChange(e, item?.account_id)
                                }
                              />
                            </span>
                            <Typography.Text
                              style={{ width: 180, fontSize: 13 }}
                              ellipsis={{
                                tooltip: `${item.account_name} - ${item.account_id}`,
                              }}
                            >
                              {item.account_name} - {item.account_id}
                            </Typography.Text>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Card>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      {/* 管家管理弹窗 */}
      <Modal
        open={guanShow}
        onCancel={guanClose}
        width={750}
        maskClosable={false}
        destroyOnClose={true}
      >
        <GuanModal />
      </Modal>

      {/* 管家批量解绑账号 */}
      <Modal
        open={accountUnbindShow}
        onCancel={accountUnbindClose}
        width={750}
        maskClosable={false}
        destroyOnClose={true}
        style={{ height: 650, overflowY: "auto" }}
        footer={null}
      >
        <Divider />
        <Card
          title={
            <Form layout="inline" onFinish={accountFilter}>
              <Form.Item label="管家账号" name="pid">
                <Input allowClear placeholder="输入管家账号id" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </Form.Item>
            </Form>
          }
          loading={accountLoading}
        >
          <Card
            type="inner"
            title={
              <Descriptions column={2}>
                <Descriptions.Item label="管家账号ID">
                  {accountInfo?.account_id}
                </Descriptions.Item>
                <Descriptions.Item label="管家账号名称">
                  {accountInfo?.account_name}
                </Descriptions.Item>
              </Descriptions>
            }
          >
            {accountInfo?.advertiser_ids}
          </Card>
          <div style={{ marginTop: 10, textAlign: "right" }}>
            <Button
              type="primary"
              onClick={handleBatchUnbind}
              loading={unbindLoading}
            >
              解绑所有账号
            </Button>
          </div>
        </Card>
      </Modal>

      {/* excel导入解绑 */}
      <Modal
        open={excelModalShow}
        onCancel={excelClose}
        maskClosable={false}
        destroyOnClose={true}
        onOk={excelFileSubmit}
        confirmLoading={uploadLoading}
      >
        <Form form={excelForm} initialValues={{is_del: 1}}>
          <Form.Item
            label="投手"
            name="admin_id"
            labelCol={{ xs: { span: 6 } }}
            wrapperCol={{ xs: { span: 8 } }}
            rules={[{ required: true, message: "请选择投手" }]}
          >
            <Select
              allowClear
              placeholder="选择投手"
              options={pitcherOptions}
            />
          </Form.Item>
          <Form.Item
            label="Excel文件上传"
            layout="inline"
            name="excel_file"
            labelCol={{ xs: { span: 6 } }}
            rules={[{ required: true, message: "请上传excel文件" }]}
          >
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item label colon={false} name='is_del'
            labelCol={{ xs: { span: 6 } }}
            wrapperCol={{ xs: { span: 24 } }}>
            <Radio.Group options={[
              {label: '删除账号下的广告及素材', value: 1},
              {label: '只解绑账号', value: 0},
            ]} />
          </Form.Item>
        </Form>
      </Modal>

      {/* excel导入绑定 */}
      <Drawer
        open={excelImportModalShow}
        onClose={excelImportClose}
        width={700}
        maskClosable={false}
        destroyOnClose={true}
        placement="right"
        title={activeTabKey === 1 ? "excel文件导入绑定" : "账户批量复制绑定"}
      >
        <Card
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={onTabChange}
          size="small"
        >
          {activeTabKey === 1 ? (
            <>
              <Form form={excelImportForm}>
                <Form.Item
                  label="投手"
                  name="admin_id"
                  labelCol={{ xs: { span: 4 } }}
                  wrapperCol={{ xs: { span: 8 } }}
                  rules={[{ required: true, message: "请选择投手" }]}
                >
                  <Select
                    allowClear
                    placeholder="选择投手"
                    options={pitcherOptions}
                  />
                </Form.Item>
                <Form.Item
                  label="Excel文件上传"
                  layout="inline"
                  name="excel_file"
                  labelCol={{ xs: { span: 6 } }}
                  rules={[{ required: true, message: "请上传excel文件" }]}
                >
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
                <Form.Item label colon={false} labelCol={{ xl: { span: 8 } }}>
                  <Space>
                    <Button onClick={excelImportClose}>取消</Button>
                    <Button
                      type="primary"
                      onClick={excelFileImportSubmit}
                      loading={uploadLoading}
                    >
                      确定
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
              {excelImportInfo && excelImportList.length > 0 ? (
                <Card
                  title={
                    <Form layout="inline" size="small">
                      <Form.Item label="账户id" name="account_id">
                        <Input.Search
                          allowClear
                          enterButton="搜索"
                          onSearch={onAccountSearch}
                        />
                      </Form.Item>
                    </Form>
                  }
                  size="small"
                >
                  <div style={{ marginBottom: 10 }}>
                    <Space direction="vertical">
                      <Typography.Title level={5}>
                        {excelImportInfo?.total}
                      </Typography.Title>
                      {excelImportInfo?.success ? (
                        <Typography.Text type="success">
                          {excelImportInfo?.success}
                        </Typography.Text>
                      ) : (
                        ""
                      )}
                      {excelImportInfo?.warning ? (
                        <Typography.Text type="warning">
                          {excelImportInfo?.warning}
                        </Typography.Text>
                      ) : (
                        ""
                      )}
                      {excelImportInfo?.danger ? (
                        <Typography.Text type="danger">
                          {excelImportInfo?.danger}
                        </Typography.Text>
                      ) : (
                        ""
                      )}
                    </Space>
                  </div>
                  <Table
                    dataSource={
                      filterImportList.length > 0
                        ? filterImportList
                        : excelImportList
                    }
                    columns={importColumns()}
                    pagination={false}
                  />
                </Card>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <Form form={copyForm} onFinish={copySure}>
                <Form.Item
                  label="投手"
                  name="admin_id"
                  labelCol={{ xs: { span: 6 } }}
                  wrapperCol={{ xs: { span: 10 } }}
                  rules={[{ required: true, message: "请选择投手" }]}
                >
                  <Select
                    allowClear
                    placeholder="选择投手"
                    options={pitcherOptions}
                  />
                </Form.Item>
                <Form.Item
                  label="复制账户"
                  name="account_ids"
                  labelCol={{ xs: { span: 6 } }}
                  wrapperCol={{ xs: { span: 10 } }}
                  rules={[{ required: true, message: "请复制账户" }]}
                >
                  <Form.Item name="account_ids" style={{ marginBottom: 0 }}>
                    <Input.TextArea autoSize allowClear></Input.TextArea>
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                      复制多个账户换行
                    </Typography.Text>
                  </Form.Item>
                </Form.Item>
                <Form.Item label colon={false} labelCol={{ xs: { span: 14 } }}>
                  <Button type="primary" htmlType="submit">
                    确定
                  </Button>
                </Form.Item>
              </Form>
              <Card
                title={
                  <Space direction="vertical">
                    <Typography.Title level={5}>
                      {copyInfo?.total}
                    </Typography.Title>
                    {copyInfo?.success ? (
                      <Typography.Text type="success">
                        {copyInfo?.success}
                      </Typography.Text>
                    ) : (
                      ""
                    )}
                    {copyInfo?.warning ? (
                      <Typography.Text type="warning">
                        {copyInfo?.warning}
                      </Typography.Text>
                    ) : (
                      ""
                    )}
                    {copyInfo?.danger ? (
                      <Typography.Text type="danger">
                        {copyInfo?.danger}
                      </Typography.Text>
                    ) : (
                      ""
                    )}
                  </Space>
                }
                size="small"
              >
                <Table
                  dataSource={copyList}
                  columns={importColumns()}
                  pagination={false}
                />
              </Card>
            </>
          )}
        </Card>
      </Drawer>
    </Card>
  );
}
