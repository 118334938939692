import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Table from '../../../../components/TableNoSelection'
import columns from './columns'
import { Button, Card, Modal, Space, Typography } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { getNovelChapterIdx, getNovelChapterList } from '../../../../api/book'
import { createRef } from 'react'

export default function Index() {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [detail, setDetail] = useState({})

    const { state } = useLocation()
    const navigate = useNavigate()
    const ref = createRef()

    useEffect(() => {
        getList({ book_id: state.id })
    }, [])

    // 获取章节列表
    const getList = async (params = {}) => {
        setLoading(true)
        const res = await getNovelChapterList(params)
        if (res?.code === 200) {
            const { data } = res
            setList(data.lists)
            setLoading(false)
        }
    }

    const showDetail = (record) => {
        setShow(true)
        setDetail(record)
    }

    // 关闭弹窗
    const closeModal = () => {
        setShow(false)
    }

    // 上一章
    const prev = async idx => {
        // 返回到页面顶部
        ref.current.scrollIntoView()
        let id = idx - 1 <= 0 ? 1 : idx - 1
        const res = await getNovelChapterIdx({book_id: detail?.bid, idx: id})
        if (res?.code === 200) {
            const {data: {data}} = res
            setDetail(data)
        }
    }

    // 下一章
    const next = async idx => {
        // 返回到页面顶部
        ref.current.scrollIntoView()
        const res = await getNovelChapterIdx({book_id: detail?.bid, idx: idx+1})
        if (res?.code === 200) {
            const {data: {data}} = res
            setDetail(data)
        }
    }
    console.log(detail);

    return (
        <>
            <div style={{ display: 'flex', marginBottom: 20 }}>
                <div><img src={`https://novel.suyueyun.com/public/${state?.attach?.path}`} /></div>
                <div style={{ marginLeft: 20 }}>
                    <div>
                        <Typography.Title level={5} style={{ color: '#917D61' }}>{state.title}</Typography.Title>
                    </div>
                    <div style={{ marginBottom: 10, color: '#CA9E61' }}>ID: {state?.id}</div>
                    <div style={{ marginBottom: 10 }}>作者：{state?.zuozhe}</div>
                    <div style={{ marginBottom: 10 }}>数字：{state?.zishu}</div>
                    <div>
                        <Typography.Text style={{ color: '#CA9E61' }}>{state.desc}</Typography.Text>
                    </div>
                </div>
            </div>
            <Card
                extra={
                    <Button type='link' size='middle' onClick={() => navigate(-1, { state: null })}>返回</Button>
                }
            >
                <Table
                    dataSource={list}
                    columns={columns({ state, showDetail })}
                    pagination={false}
                    loading={loading}
                />
            </Card>

            {/* 查看章节详情 */}
            <Modal
                open={show}
                width={750}
                onCancel={closeModal}
                style={{ height: 600, overflowY: 'auto' }}
                footer={null}
            >
                <div ref={ref}></div>
                <Typography.Title level={4}>{detail.title}</Typography.Title>
                <div dangerouslySetInnerHTML={{ __html: detail.content }} style={{ fontSize: 16, lineHeight: 2 }} />
                <Space style={{display: 'flex', justifyContent: 'center'}}>
                    {
                        detail.idx > 1 ? <Button type='link' onClick={() => prev(detail.idx)}>上一章</Button>
                        : ''
                    }
                    <Button type='link' onClick={()=>next(detail?.idx)}>下一章</Button>
                </Space>
            </Modal>
        </>
    )
}
