import { Button, Space } from "antd"
import { Link } from "react-router-dom";

const columns = () => [
    {
        title: "优化师",
        width: 100,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        // fixed: 'left',
    },
    {
        title: "日期",
        width: 110,
        align: "center",
        dataIndex: "day",
        key: "day",
    },
    {
        title: "消耗",
        width: 95,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "回收",
        width: 100,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "回收率",
        width: 105,
        align: "center",
        dataIndex: "hui_shou_lv",
        key: "hui_shou_lv",
    },
    {
        title: "支付人数",
        width: 105,
        align: "center",
        dataIndex: "pay_user",
        key: "pay_user",
    },
    {
        title: "付费成本",
        width: 90,
        align: "center",
        dataIndex: "pay_cost",
        key: "pay_cost",
    },
    {
        title: "客单价",
        width: 90,
        align: "center",
        dataIndex: "per_money",
        key: "per_money",
    },
    {
        title: "在消耗计划",
        width: 100,
        align: "center",
        dataIndex: "ad_count",
        key: "ad_count",
    },
    {
        title: "审核通过计划",
        width: 105,
        align: "center",
        dataIndex: "success_plan",
        key: "success_plan",
    },
    {
        title: "所有计划",
        width: 105,
        align: "center",
        dataIndex: "all_plan",
        key: "all_plan",
    },
    {
        title: "在消耗账户数",
        width: 80,
        align: "center",
        dataIndex: "account_count",
        key: "account_count",
      },
    {
        title: "操作",
        width: 200,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        render: (_, record) => (
            <Space size='small'>
                <Button type="primary" size="small">
                    <Link to="/admin/statistics/novel/recycle/trend" state={{id: record?.admin_id}}>付费趋势</Link>
                </Button>
                <Button type="primary" size="small" ghost>
                    <Link to="/admin/statistics/novel/push/toutiao" state={{...record, tab: 'tab1'}}>头条数据</Link>
                </Button>
            </Space>
        )
    },
]

export default columns