import { useMemo, useState } from "react";
import { Form, message } from "antd";
import dayjs from "dayjs";

import { addLeave, deleteLeave, updateLeave } from "@/api/delivery";

// 新增, 修改, 删除
const useLeave = ({ updateList }) => {
  const [form] = Form.useForm();
  // 是否展示弹窗
  const [visible, setVisible] = useState(false);
  // 确认按钮的 loading 状态
  const [loading, setLoading] = useState(false);
  // 当前编辑的数据
  const [editData, setEditData] = useState(null);

  // 是否是编辑状态
  const isEdit = useMemo(() => {
    return editData !== null;
  }, [editData]);

  // 新增或编辑请假逻辑
  const onConfirmLeave = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const [start, end] = values?.rangeDate || [];

      const params = {
        // 如果是编辑的话, 需要传入当前编辑的 id
        id: editData?.id || undefined,
        adminId: values?.adminId,
        leaveStartTime: start?.format?.("YYYY-MM-DD"),
        leaveEndTime: end?.format?.("YYYY-MM-DD"),
      };

      if (isEdit) {
        await updateLeave(params);
      } else {
        await addLeave(params);
      }
      message.success("操作成功");
      onCloseLeave();
      updateList();
    } catch (err) {
      message.error("操作失败");
    } finally {
      setLoading(false);
    }
  };

  // 新增请假
  const onAddLeave = async () => {
    setVisible(true);
  };

  // 编辑请假
  const onEditLeave = async col => {
    setEditData(col);
    setVisible(true);

    const rangeDate = [col?.leaveStartTime, col?.leaveEndTime].map(e => {
      const d = dayjs(e);
      return d.isValid() ? d : null;
    });

    form.setFieldsValue({
      adminId: col?.adminId,
      rangeDate,
    });
  };

  // 删除请假
  const onDeleteLeave = async id => {
    try {
      await deleteLeave({ id });
      message.success("删除成功");
      updateList();
    } catch (err) {
      message.error("删除失败");
    }
  };

  // 关闭弹窗
  const onCloseLeave = () => {
    resetForm();
    setVisible(false);
  };

  // 重置表单
  const resetForm = () => {
    form.resetFields();
    setEditData(null);
  };

  return {
    visible,
    isEdit,
    leaveModalForm: form,
    leaveModalConfirmLoading: loading,
    onEditLeave,
    onAddLeave,
    onDeleteLeave,
    onCloseLeave,
    onConfirmLeave,
  };
};

export default useLeave;
