import { Button, Card, Space } from 'antd'
import React from 'react'
import Table from '../../../../components/TableNoSelection'
import { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { materialDataDetail } from '../../../../api'
import columns from './detailColumns'

export default function DataDetail() {
    const [list, setList] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [conditions, setConditions] = useState([])

    const { state } = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        getList()
    }, [])

    // 获取列表数据
    const getList = async (params = {}) => {
        const res = await materialDataDetail({ ...params, id: state.id })
        const { data } = res
        if (res?.code === 200) {
            setList(data?.lists)
            setTotal(data?.total)
            setLoading(false)
        }
    }

    // 排序
    const onChange = (pagination, filters, sorter) => {
        let field = sorter.field
        let orderType = sorter.order === 'ascend' ? 'asc' : 'desc'
        let order = `${field}_${orderType}`
        if (field !== undefined) {
            setConditions({order})
            getList({ order })
        }
    }

    // 每页显示条数切换回调
    const pagesize = (page, size) => {
        setPage(page)
        getList({...conditions, pageSize: size, page})
    }

    return (
        <Card title={
            <Space size='large'>
                素材效果数据详情
                <Button type='link' onClick={() => {navigate(-1)}}>返回</Button>
            </Space>
        }>
            <Table
                dataSource={list}
                columns={columns()}
                total={total}
                page={page}
                bordered={true}
                onChange={onChange}
                pagesize={pagesize}
                loading={loading}
            />
        </Card>
    )
}
