import { Button, Card, Modal, Space, message } from "antd";
import React from "react";
import Table from "../../../../components/TableNoSelection";
import { useState } from "react";
import columns from "./columns";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changeDetail,
  deleteChangeAdvertiser,
} from "../../../../api/advertise";
import { useEffect } from "react";

const { confirm } = Modal;

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getlist();
  }, []);

  const getlist = async (params = {}) => {
    const res = await changeDetail({ ...params, id: state.id });
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 删除广告账号
  const deleteAccount = (record) => {
    confirm({
      title: `确认从该转化中删除 ${record.advertiser_id} 广告账户?`,
      onOk: async () => {
        const res = await deleteChangeAdvertiser({
          id: record?.pid,
          change_ad_id: record?.id,
          advertiser_id: record?.advertiser_id,
        });
        if (res?.code === 200) {
          message.success("删除成功", 1);
          setTimeout(() => {
            getlist();
          }, 1000);
        }
      },
    });
  };

  const pagesize = (page, size) => {
    setPage(page);
    getlist({ pageSize: size, page });
  };

  return (
    <Card
      title={
        <Space size="large">
          转化集合详情
          <Button
            type="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
        </Space>
      }
    >
      <Table
        dataSource={list}
        columns={columns({ deleteAccount })}
        total={total}
        page={page}
        loading={loading}
        pagesize={pagesize}
      />
    </Card>
  );
}
