import {
  Space,
  Button,
  Radio,
  Card,
  Table,
  message,
  Row,
  Col,
  Tooltip,
  Flex,
  Input,
} from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  backConfigList,
  backConfigSwitch,
  getBackConfigSwitch,
} from "@/api/delivery";
import useTableList from "@/hooks/useTableList";
import styles from "./index.module.less";
import { switchOptions, switchOptionKeys } from "./constant";
import AddOrEditDrawer from "./AddOrEditDrawer";
import DetailDrawer from "./DetailDrawer";

export default function Index() {
  const [name, setName] = useState("");
  const [visible, setVisible] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [id, setId] = useState(null);
  const [checkValue, setCheckValue] = useState(switchOptionKeys.open);

  const [conditions, setConditions] = useState({
    name: undefined,
    page: 1,
    pageSize: 10,
  });

  const { loading, data } = useTableList(backConfigList, conditions);
  const { list = [], total = 0 } = data;

  const columns = [
    {
      title: "规则名称",
      width: 120,
      dataIndex: "name",
      align: "center",
    },
    {
      title: "回传窗口",
      width: 120,
      dataIndex: "backWindowView",
      align: "center",
    },
    {
      title: "回传模式",
      width: 120,
      dataIndex: "backModeView",
      align: "center",
    },
    {
      title: "关键行为指标",
      width: 120,
      dataIndex: "keyBehaviorsView",
      align: "center",
      render: (_, record) => {
        return _?.join("、") || "";
      },
    },
    {
      title: "操作",
      dataIndex: "operation",
      // align: "center",
      width: 150,
      render: (_, record) => (
        <Space size={12}>
          <a onClick={() => onAddOrEdit(record.id)}>编辑</a>
          <a onClick={() => onAddOrEdit(record.id, true)}>详情</a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getSwitch();
  }, []);

  const getSwitch = async () => {
    try {
      const res = await getBackConfigSwitch();
      if (res?.code === 200) {
        setCheckValue(res?.data ?? switchOptionKeys.open);
      }
    } catch (err) {
      console.log(err, "errerrerr");
    }
  };

  const onAddOrEdit = (id, isDetail = false) => {
    isDetail ? setDetailVisible(true) : setVisible(true);
    setId(id);
  };

  const onChangeTable = (pagination, filters, sorter, action) => {
    if (action.action === "paginate") {
      const { current, pageSize } = pagination;
      setConditions({ ...conditions, page: current, pageSize });
      return;
    }
  };

  const onSearch = () => {
    setConditions({ ...conditions, name });
  };

  const onChange = async e => {
    const val = e.target.value;
    try {
      const res = await backConfigSwitch({ status: val });
      if (res?.code === 200) {
        setCheckValue(val);
        message.success(
          val === switchOptionKeys.open ? "开启成功" : "关闭成功"
        );
      }
    } catch (err) {
      console.log(err, "errerrerr");
    }
  };

  const title = (
    <div className={styles.titleWrapper}>
      <Row align="middle">
        <Col span={2} className={styles.titleText}>
          媒体名称
        </Col>
        <Col>番茄平台</Col>
      </Row>
      <Row align="middle">
        <Col span={2} className={styles.titleText}>
          <Space>
            <span>平台回传</span>
            <Tooltip title="开启，则可在推广链上自定义配置任意回传规则；关闭，则使用番茄平台回传规则">
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col>
          <Radio.Group
            options={switchOptions}
            onChange={onChange}
            value={checkValue}
          />
        </Col>
      </Row>
      {checkValue === switchOptionKeys.open && (
        <Flex justify="space-between" className={styles.handleWrapper}>
          <Space align="center" size={16}>
            <div>规则名称：</div>
            <Input
              placeholder="请输入规则名称"
              style={{ width: 240 }}
              onChange={e => setName(e.target.value || undefined)}
            />
            <Button type="primary" onClick={onSearch}>
              查询
            </Button>
          </Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onAddOrEdit(null)}
          >
            新增规则
          </Button>
        </Flex>
      )}
    </div>
  );

  return (
    <Card
      title={title}
      classNames={{
        header: styles.headerWrapper,
      }}
    >
      {checkValue === switchOptionKeys.open && (
        <Table
          dataSource={list}
          columns={columns}
          loading={loading}
          onChange={onChangeTable}
          rowKey="id"
          pagination={{
            total,
            showSizeChanger: false,
            showQuickJumper: false,
            showTotal: total => `共 ${total} 条`,
            current: conditions.page || 1,
            pageSize: conditions.pageSize || 10,
          }}
        />
      )}

      {visible && (
        <AddOrEditDrawer
          open={visible}
          id={id}
          onClose={isRefresh => {
            setVisible(false);
            isRefresh && setConditions({ ...conditions });
          }}
        />
      )}
      {detailVisible && (
        <DetailDrawer
          open={detailVisible}
          id={id}
          onClose={() => setDetailVisible(false)}
        />
      )}
    </Card>
  );
}
