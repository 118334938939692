import React, { useState } from 'react';
import { Form, Button, Input, Space } from 'antd'

const BatchCopy = (props) => {
  // 是否展示 input
  const [showInput, setShowInput] = useState(false)
  // 输入的文本值
  const [text, setText] = useState()

  const clearBr = (key) => {
    key = key.replace(/<\/?.+?>/g, "::");
    key = key.replace(/[\r\n]/g, "::");
    return key;
  }

  // 确认批量添加
  const onConfirm = () => {
    const value = text || ''

    // 解析 value
    const values = clearBr(value)
    const list = values.split(':').filter(e => e).map(Number)

    props.onConfirm?.(list)

    setText(undefined)
  }

  const onChangeText = (event) => {
    setText(event.target.value)
  }

  return (
    <>
      {showInput && (
        <>
          <Form.Item label="批量复制账户" >
            <Input.TextArea autoSize placeholder="复制多个账户需要换行" value={text} onChange={onChangeText} />
          </Form.Item>
          <Space style={{ marginBottom: '20px' }}>
            <Button type="primary" onClick={onConfirm}>确定</Button>
            <Button onClick={() => setShowInput(false)}>取消</Button>
          </Space>
        </>
      )}
      {
        !showInput && (
          <Button onClick={() => setShowInput(true)}>批量复制</Button>
        )
      }
    </>
  )
}

export default React.memo(BatchCopy)
