import { Modal, Typography } from 'antd'
import React from 'react'
import AppIcon from './icons/app.png'
import QuickAppIcon from './icons/quick_app.png'
import LinkIcon from './icons/link.png'
import MiniProgramIcon from './icons/mini_program.png'
import './index.less'
import { useState } from 'react';

const { Title, Text } = Typography;
const { confirm } = Modal

export default function Index(props) {
    const [checkedValue, setCheckedValue] = useState(props?.landingType || props.options[0]?.value)
    const { options } = props

    const onChecked = value => {
        confirm({
            title: '提示',
            content: '切换推广目的将会清空您已填写的所有内容，是否继续切换？',
            cancelText: '取消',
            okText: '确定',
            onOk: () => {
                setCheckedValue(value)
                props.onChecked(value)
            }
        });
    }

    const getIcon = key => {
        switch(key) {
            case 'APP':
                return AppIcon
            case 'THIRD_EXTERNAL':
                return LinkIcon
            case 'QUICK_APP':
                return QuickAppIcon
            case 'MICRO_GAME':
                return MiniProgramIcon
        }
    }

    return (
        <div style={{ display: 'flex' }}>
            {
                options.map((item, key) => {
                    return <div
                        className={['checked-card', item.value === checkedValue ? 'checked-card-live' : ''].join(' ')}
                        onClick={() => { onChecked(item.value) }}
                        key={key}
                    >
                        <div className='checked-card-title'>
                            <Title level={5} style={{ color: (item.value === checkedValue) ? '#2a55e5' : '', width: '80%' }}>{item.title}</Title>
                            <div>
                                <img alt='' src={getIcon(item.value)} width={50} />
                            </div>
                        </div>
                        <div className='checked-card-text'>
                            <Text className={item.value === checkedValue ? 'text-live' : ''}>{item.description}</Text>
                        </div>
                    </div>
                })
            }
        </div>

    )
}
