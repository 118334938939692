import { DELETE_USER_INFO, SAVE_USER_INFO } from "../constant";

let username = localStorage.getItem("username");
let token = localStorage.getItem("admin_token");
let nickname = localStorage.getItem("nickname");
let shortname = localStorage.getItem("shortname");
let avatar = localStorage.getItem("avatar");
let nicknameId = localStorage.getItem("nicknameId");
let is_super = localStorage.getItem("is_super");

const initState = {
  username: username || "",
  nickname: nickname || "",
  shortname: shortname || "",
  token: token || "",
  avatar: avatar || "",
  isLogin: username && token ? true : false,
  nicknameId: nicknameId || "",
  is_super: is_super || "",
};

export default function loginReducer(preState = initState, action) {
  const { data, type } = action;
  let newState;
  switch (type) {
    case SAVE_USER_INFO:
      newState = {
        username: data.username,
        nickname: data.nickname,
        shortname: data.shortname,
        avatar: data.avatar,
        token: data.token,
        isLogin: true,
        nicknameId: data.nicknameId,
        is_super: data.is_super,
      };
      break;
    case DELETE_USER_INFO:
      newState = {
        username: "",
        nickname: "",
        shortname: "",
        token: "",
        avatar: "",
        isLogin: false,
        nicknameId: "",
        is_super: "",
      };
      break;
    default:
      newState = preState;
      break;
  }
  return newState;
}
