import { Button, Card } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Info from './Info'
import Read from './Read'
import Recharge from './Recharge'
import Consume from './Consume'
import Sign from './Sign'
import Reward from './Reward'
import { getOrderUserCharge } from '../../../api/statistics'

export default function Index() {
    const [activeTabKey, setActiveTabKey] = useState(1)
    const [recharge, setRecharge] = useState(0)

    const {state} = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        userRechargeTotal()
    }, [])

    // 下单用户累计充值
    const userRechargeTotal = async () => {
        const res = await getOrderUserCharge({uid: state?.uid})
        const {data} = res
        setRecharge(data?.total)
    }
    
    const tabList = [
        {
            key: 1,
            tab: "用户信息",
        },
        {
            key: 2,
            tab: "阅读记录",
        },
        {
            key: 3,
            tab: "充值记录",
        },
        {
            key: 4,
            tab: "消费记录",
        },
        {
            key: 5,
            tab: "签到记录",
        },
        {
            key: 6,
            tab: "打赏记录",
        },
    ]

    const contentList = {
        1: <Info {...state} recharge={recharge}/>,
        2: <Read  {...state} />,
        3: <Recharge {...state} />,
        4: <Consume {...state} />,
        5: <Sign {...state} />,
        6: <Reward {...state} />,
      }

    // 切换标签栏
    const onTabChange = key => {
        setActiveTabKey(key)
    }

    return (
        <Card
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={(key) => {
                onTabChange(key)
            }}
            title='用户信息'
            extra={
                <Button onClick={() => navigate(-1)}>返回</Button>
            }
        >
            {contentList[activeTabKey]}
        </Card>
    )
}
