import { Button, Card, Form, Input, Modal, Select, Space, message } from 'antd'
import Table from '../../../components/TableHasTotal'
import columns from './columns'
import React, { useState } from 'react'
import SaveModal from './saveModal'
import { addRole, deleteRole, editRole, roleList } from '../../../api/permission'
import { useEffect } from 'react'
import DispatchModal from './dispatchModal'

const { confirm } = Modal

export default function Role() {
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [operationType, setOperationType] = useState()
  const [tableLoading, setTableLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [selectKeys, setSelectKeys] = useState([])
  const [conditions, setConditions] = useState([])
  const [page, setPage] = useState(1)
  const [dispatchModalShow, setDispatchModalShow] = useState(false)
  const [menuChecked, setMenuChecked] = useState([])

  const [form] = Form.useForm()
  const [filterForm] = Form.useForm()
  const [menuForm] = Form.useForm()

  useEffect(() => {
    getList()
  }, [])


  // 获取列表数据
  const getList = async (params = {}) => {
    const res = await roleList(params)
    const { data } = res
    if (res?.code === 200) {
      const lists = data?.lists
      const newList = lists.map((item) => {
        item.key = item.id
        return item
      })
      setTotal(data?.total)
      setList(newList)
      setTableLoading(false)
    }
  }

  // 全选或全不选
  const selectRows = selectRows => {
    setSelectKeys(selectRows)
  }

  // 筛选表单提交
  const selectFormSubmit = values => {
    setConditions(values)
    getList({ ...conditions, ...values })
  }

  // 关闭弹窗
  const modalClose = () => {
    form.resetFields()
    setLoading(false)
    setShowModal(false)
  }

  // 添加弹窗
  const handleAdd = () => {
    setShowModal(true)
    setOperationType('add')
  }

  // 编辑弹窗
  const handleEdit = record => {
    setShowModal(true)
    setOperationType('edit')
    form.setFieldsValue(record)
  }

  // 删除
  const handleDelete = record => {
    confirm({
      title: `确定删除角色-${record.name} ?`,
      onOk: async () => {
        const res = await deleteRole({ ids: [record.id] })
        if (res?.code === 200) {
          message.success('删除成功', 1)
          const newList = list.filter((item) => {
            return item.id !== record.id
          })
          setList(newList)
        }
      }
    })
  }

  // 批量删除
  const batchDelete = async () => {
    confirm({
      title: '确定要删除选中的项 ?',
      onOk: async () => {
        const res = await deleteRole({ ids: selectKeys })
        if (res?.code === 200) {
          message.success('删除成功', 1)
          const newList = list.filter((item) => {
            return selectKeys.indexOf(item.id) === -1
          })
          setList(newList)
        }
      }
    })

  }

  // 处理表单提交
  const submit = async values => {
    setLoading(true)
    if (operationType === 'add') {
      doFormAdd(values)
    } else {
      doFormEdit(values)
    }
  }

  // 处理添加
  const doFormAdd = async values => {
    const res = await addRole(values)
    if (res?.code === 200) {
      message.success('添加成功', 1)
      getList()
      modalClose()
    } else {
      setLoading(false)
    }
  }

  // 处理表单编辑
  const doFormEdit = async values => {
    const res = await editRole(values)
    if (res?.code === 200) {
      message.success('修改成功', 1)
      getList()
      modalClose()
    } else {
      setLoading(false)
    }
  }

  // 每页显示条数切换回调
  const pagesize = (page, size) => {
    setPage(page)
    setConditions({ ...conditions, pageSize: size, page })
    getList({ ...conditions, pageSize: size, page })
  }

  // 显示排序
  const handleChange = (...sorter) => {
    let orderObj = sorter[2]
    let orderStr
    if (orderObj.order !== undefined) {
      orderStr = orderObj.field + '_' + orderObj.order.replace('end', '')
      setConditions({ ...conditions, order: orderStr })
      getList({ ...conditions, order: orderStr })
    }
  }

  // 分配角色菜单
  const dispatchMenu = record => {
    let menusChecked = record.menus
    setMenuChecked({ num: Math.random(), data: menusChecked })
    menuForm.setFieldsValue(record)
    setDispatchModalShow(true)
  }

  // 关闭菜单权限弹窗
  const dispatchModalClose = type => {
    // 重新调用接口主要是为了能获取最新的菜单权限，方便弹窗时选中已更新的菜单
    if (type === 'confirm') {
      getList()
    }
    menuForm.resetFields()
    setDispatchModalShow(false)

  }

  // 批量操作按钮是否可点击操作
  const hasSelected = selectKeys.length > 0

  return (
    <div className='role'>
      <Card
        className='role-search'
        style={{ marginBottom: 10 }}
        bodyStyle={{ display: 'none' }}
        title={
          <Form layout='inline' onFinish={selectFormSubmit} form={filterForm}>
            <Form.Item label="角色名称" name='name'>
              <Input />
            </Form.Item>
            <Form.Item label="权限字符" name='role_key'>
              <Input />
            </Form.Item>
            <Form.Item label="角色状态" name='status'>
              <Select style={{ width: 120 }} placeholder="请选择状态" allowClear>
                <Select.Option value={1}>正常</Select.Option>
                <Select.Option value={0}>停用</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type='primary' htmlType='submit'>查询</Button>
                <Button onClick={() => { filterForm.resetFields() }}>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        } />

      <Card title={
        <Space>
          <Button type='primary' onClick={handleAdd}>新增</Button>
          <Button type='primary' danger disabled={!hasSelected} onClick={batchDelete}>批量删除</Button>
        </Space>
      }>
        <Table
          columns={columns({ handleEdit, handleDelete, dispatchMenu })}
          dataSource={list}
          loading={tableLoading}
          selectRows={selectRows}
          pagesize={pagesize}
          page={page}
          onChange={handleChange}
          total={total}
        />
      </Card>

      {/* 添加弹窗 */}
      <SaveModal
        showModal={showModal}
        close={modalClose}
        saveType={operationType}
        submit={submit}
        form={form}
        loading={loading}
      />

      {/* 分配菜单权限弹窗 */}
      <DispatchModal
        dispatchModalShow={dispatchModalShow}
        onClose={dispatchModalClose}
        form={menuForm}
        menuChecked={menuChecked}
      />
    </div>
  )
}
