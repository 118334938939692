import React, { useEffect, useMemo, useState } from "react";
import { getAdvertiseBackLog } from "@/api/delivery";
import { Button, Card, Table, Tag, Tooltip } from "antd";

const Detail = props => {
  const { id } = props;

  // 列表数据
  const [list, setList] = useState([]);
  // loading
  const [loading, setLoading] = useState(false);

  // 获取详情列表数据
  const getList = async () => {
    try {
      setLoading(true);
      const res = await getAdvertiseBackLog({ configId: id });

      const list = Array.isArray(res?.data) ? res?.data : [];

      setList(list);
    } catch (err) {
      console.error(err); // Optionally log the error
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => {
    // running/done/fail
    const statusList = [
      { label: "运行中", key: "running", color: "processing" },
      { label: "完成", key: "done", color: "success" },
      { label: "失败", key: "fail", color: "error" },
    ];

    return [
      {
        title: "id",
        dataIndex: "id",
        key: "id",
        width: 180,
      },
      // {
      //   title: "创建时间",
      //   dataIndex: "createTime",
      //   key: "createTime",
      //   width: 100,
      // },
      // {
      //   title: "更新时间",
      //   dataIndex: "updateTime",
      //   key: "updateTime",
      //   width: 100,
      // },
      // {
      //   title: "回传配置id",
      //   dataIndex: "configId",
      //   key: "configId",
      //   width: 120,
      // },
      // {
      //   title: "投手id",
      //   dataIndex: "adminId",
      //   key: "adminId",
      //   width: 120,
      // },
      {
        title: "广告id",
        dataIndex: "adId",
        key: "adId",
        width: 180,
      },
      {
        title: "广告名称",
        dataIndex: "adName",
        key: "adName",
        width: 200,
      },
      {
        title: "原转化数",
        width: 120,
        align: "center",
        dataIndex: "convertNum",
        key: "convertNum",
      },
      {
        title: "回传时间",
        dataIndex: "backTime",
        key: "backTime",
        width: 130,
      },
      {
        title: "C端用户id",
        dataIndex: "deviceId",
        key: "deviceId",
        width: 100,
      },
      {
        title: "点击id",
        dataIndex: "clickId",
        key: "clickId",
        width: 150,
        ellipsis: true,
        render: (text) => {
          return (
            <Tooltip title={text} placement="topLeft">
              {text}
            </Tooltip>
          )
        },
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 80,
        render: text => {
          const status = statusList.find(e => {
            return e.key === text;
          });
          if (!status) {
            return null;
          }
          return <Tag color={status?.color}>{status?.label}</Tag>;
        },
      },
      {
        title: "失败原因",
        dataIndex: "failReason",
        key: "failReason",
        width: 180,
        align: "center",
      },
      // {
      //   title: "统计时间",
      //   dataIndex: "startTime",
      //   key: "startTime",
      //   width: 180,
      //   render: (_, record) => {
      //     const times = [record?.startTime, record?.endTime];
      //     return times.join(" - ");
      //   },
      // },
      {
        title: "总ARPU值",
        dataIndex: "arpu",
        key: "arpu",
        width: 100,
      },
    ];
  }, []);

  // 初始化获取数据的逻辑
  useEffect(() => {
    if (!id) {
      return;
    }
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card
      title="回传详情"
      extra={
        <Button type="primary" onClick={props.closeDetail}>
          返回
        </Button>
      }
    >
      <Table
        scroll={{ scrollToFirstRowOnChange: true, x: 1300 }}
        columns={columns}
        dataSource={list}
        loading={loading}
      />
    </Card>
  );
};

export default React.memo(Detail);
