import { Button, Col, Popover, Row, Space, Switch, Typography } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const options = [
  "现代言情",
  "历史架空",
  "古代言情",
  "社会风云",
  "悬疑推理",
  "仙侠奇缘",
  "游戏科幻",
  "玄幻仙侠",
  "都市异能",
  "精彩书库",
  "年代种田",
];

const columns = ({ onStatusChange, itemLoading }) => [
  {
    title: "视频",
    width: 180,
    align: "center",
    dataIndex: "video",
    key: "video",
    ellipsis: true,
    fixed: "left",
    render: (_, record) => {
      return (
        <>
          <Row justify="start">
            <Col span={6} style={{ marginLeft: -10 }}>
              <Popover
                placement="right"
                content={
                  <video
                    alt=""
                    controls
                    src={record?.attach_video?.oss_url}
                    controlslist="nodownload"
                    preload="none"
                    width={350}
                    poster={`${record?.attach_video?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                  />
                }
              >
                <video
                  alt=""
                  src={record?.attach_video?.oss_url}
                  width={20}
                  poster={`${record?.attach_video?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                />
              </Popover>
            </Col>
            <Col span={4}>
              <div style={{ fontSize: 12 }}>
                {record?.title}
                <div style={{ fontSize: 12 }}>ID:{record?.file_id}</div>
              </div>
            </Col>
          </Row>
        </>
      );
    },
  },
  {
    title: "标签",
    width: 120,
    align: "center",
    dataIndex: "label",
    key: "label",
    editable: true,
    render: (text) => {
      if (text) {
        let arr = text.split(",");
        return arr.map((tag, key) => {
          return (
            <div key={key}>
              <Link>{tag || "-"}</Link>
            </div>
          );
        });
      } else {
        return <Link>-</Link>;
      }
    },
  },
  {
    title: "素材转化",
    width: 80,
    align: "center",
    dataIndex: "convert",
    key: "convert",
    sorter: {},
    render: (value) =>
      value ? (
        <Typography.Text type="success">{value}</Typography.Text>
      ) : (
        <Typography.Text type="danger">{value}</Typography.Text>
      ),
  },
  {
    title: "NoBid素材转化",
    width: 80,
    align: "center",
    dataIndex: "nobid_convert",
    key: "nobid_convert",
  },
  {
    title: "消耗",
    width: 80,
    align: "center",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "上传时间",
    width: 130,
    align: "center",
    dataIndex: "create_time",
    key: "create_time",
    render: (text) => dayjs.unix(text).format("YYYY-MM-DD HH:mm::ss"),
  },
  {
    title: "视频名称",
    width: 130,
    align: "center",
    dataIndex: "video_name",
    key: "video_name",
    render: (_, record) => {
      let videoName = record?.attach_video?.name;
      let name = videoName
        ? videoName.substr(0, videoName.lastIndexOf("."))
        : "-";
      return <Typography.Text style={{ fontSize: 12 }}>{name}</Typography.Text>;
    },
  },
  {
    title: "头条素材id",
    width: 140,
    align: "center",
    ellipsis: true,
    dataIndex: "creative_id",
    key: "creative_id",
  },
  {
    title: "操作",
    align: "center",
    width: 120,
    dataIndex: "operation",
    key: "operation",
    fixed: "right",
    render: (_, record) => (
      <>
        <Switch
          checkedChildren="开放"
          unCheckedChildren="关闭"
          checked={record?.is_open === 1}
          loading={itemLoading === record?.id}
          onChange={(status, e) => onStatusChange(record, status, e)}
        />
      </>
    ),
  },
];

export default columns;
