import { Button, Card, Form, Input, Radio, Select, Space, message } from "antd";
import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addChange,
  getAdvertiserList,
  getChannel,
  getOfficialBook,
  getPackageQuick,
  getPromotionUrl,
} from "../../../api/advertise";
import { getPackage } from "../../../api/promotion";
import { getSelectPromotion } from "../../../api/playlet";

export default function Add() {
  const [transType, setTransType] = useState("QUICK_APP");
  const [advertiseList, setAdvertiseList] = useState([]);
  const [source, setSource] = useState("");
  const [application, setApplication] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [bookOptions, setBookOptions] = useState([]);
  const [promotionOptions, setPromotionOptions] = useState([]);
  const [bindingOptions, setBindingOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copyInput, setCopyInput] = useState(false);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const ref = createRef();

  useEffect(() => {
    getAdvertisers();
    getData({ type: 1 });
  }, []);

  // 批量获取广告主
  const getAdvertisers = async (params = {}) => {
    const res = await getAdvertiserList(params);
    const { data } = res;
    if (res?.code === 200) {
      const lists = data?.lists;
      const options = lists.map((item) => {
        return { label: item?.account_name, value: item?.account_id };
      });
      setAdvertiseList(options);
    }
  };

  // 获取所有的 应用， 包名，下载地址等
  const getData = async (params = {}) => {
    const res = await getPackage(params);
    const { data } = res;
    if (res?.code === 200) {
      let quickPackage = data?.lists;
      let newQuick = quickPackage?.map((item) => {
        return {
          label: item.aliasname,
          value: item.package || item.app_type,
          app_type: item.app_type,
          app_package: item.app_package,
          download_url: item.download_url,
        };
      });
      setApplication(newQuick);
    }
  };

  const getSourceOptions = async () => {
    const res = await getPackageQuick();
    const { data } = res;
    if (res?.code === 200) {
      setSourceOptions(data?.book_source);
    }
  };

  const changeType = (e) => {
    let type = e.target.value;
    setTransType(type);
    setPromotionOptions([]);
    if (type === "THIRD_EXTERNAL") {
      getSourceOptions();
    } else {
      if (type === "MINI_PROGRAME") {
        getData({ type: 5, merge_type: 4 });
      } else if (type === "TIK_PROGRAME") {
        getData({ type: 6 });
      } else {
        getData({ type: 1 });
      }
    }

    setTimeout(() => {
      form.resetFields([
        "app_name",
        "package",
        "application_type",
        "download_url",
        "book_id",
        "source",
      ]);
    }, 200);
  };

  const platletPromotions = async (params = {}) => {
    // 只获取开户的推广链接
    params.status = 1;
    const res = await getSelectPromotion(params);
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map((item) => {
        return {
          label: item?.title + ": " + item.promotion_url,
          value: item.id,
        };
      });
      setPromotionOptions(newList);
    }
  };

  // 应用切换事件
  const appChange = async (e) => {
    const checkValue = e.target.value;
    const filterd = application.filter((item) => {
      return checkValue === item.value;
    });
    // 回填中文包名称
    form.setFieldsValue({ app_name: filterd[0]?.label });
    form.setFieldsValue({ promotion_id: "" });
    let filterPackage;
    // 回填包名
    if (transType === "APP") {
      filterPackage = application.filter((item) => {
        return checkValue === item.value;
      });

      form.setFieldsValue({ package: filterPackage[0]?.app_package });
      form.setFieldsValue({ download_url: filterPackage[0]?.download_url });
      // 获取推广链接
      getPromotions({ bag: checkValue });
    } else if (transType === "QUICK_APP") {
      filterPackage = application.filter((item) => {
        return checkValue === item.value;
      });
      form.setFieldsValue({ package: filterPackage[0]?.value });
      // 获取推广链接
      getPromotions({ bag: checkValue });
    } else if (transType === "MINI_PROGRAME") {
      platletPromotions({ app_type: checkValue, platform: 1, pageSize: 0 });
    } else if (transType === "TIK_PROGRAME") {
      let newCheckValue = parseInt(checkValue)
      let params = {
        app_type: newCheckValue,
        platform: 2,
        pageSize: 0,
        type: 6,
      }
      if (checkValue === '2007-1') {
        params.external_action = 'AD_CONVERT_TYPE_GAME_ADDICTION'
      } else if (checkValue === '2007-2') {
        params.external_action = 'AD_CONVERT_TYPE_PAY'
      }
      console.log(params);
      platletPromotions(params);
    }
  };

  // 获取投放的书籍信息
  const getBooks = async (type) => {
    const res = await getOfficialBook({ type });
    if (res?.code === 200) {
      let list = res?.data;
      let newList = list.map((item) => {
        return { label: item.title, value: item?.id ? item?.id : item?.bookid };
      });
      setBookOptions(newList);
    }
  };

  const getPromotions = async (params = {}) => {
    const res = await getPromotionUrl(params);
    const { data } = res;
    if (res?.code === 200) {
      let list = data.map((item) => {
        return { label: item?.remark + ": " + item.url, value: item.id };
      });
      setPromotionOptions(list);
    }
  };

  // 显示复制账户input框
  const advertiserCopy = () => {
    setCopyInput(true);
  };

  const copyInputClose = () => {
    setCopyInput(false);
  };

  function clearBr(key) {
    key = key.replace(/<\/?.+?>/g, "::");
    key = key.replace(/[\r\n]/g, "::");
    return key;
  }

  // 处理copy的广告账户
  const copySure = () => {
    let value = ref.current.resizableTextArea.textArea.value;
    let values = clearBr(value);
    let params = values.split(":").filter((value) => value);
    let newParams = params.map((value) => {
      return Number(value);
    });
    let selectList = advertiseList.filter((item) => {
      return newParams.includes(item.value);
    });

    let advertiserIds = selectList.map((item) => {
      return item.value
    })

    form.setFieldsValue({ advertiser_ids: advertiserIds });
  };

  // 获取绑定的快应用
  const getQuickApp = async (type) => {
    const res = await getChannel({ type });
    if (res.code === 200) {
      let list = res?.data;
      let newList = list.map((item) => {
        return { label: item?.user?.nickname, value: item.uid, key: item.id };
      });
      // 过滤掉undefined的情况
      let newLists = newList.filter((item) => {
        return item.label !== undefined;
      });
      setBindingOptions(newLists);
    }
  };

  // 书籍来源切换
  const sourceChange = async (e) => {
    form.setFieldsValue({ uid: "" });
    const checkValue = e.target.value;
    setSource(checkValue);
    // 获取公众号绑定的渠道
    getQuickApp(checkValue);

    // 获取公众号投放书籍
    getBooks(checkValue);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 表单布局
  const layout = {
    labelCol: { sm: { span: 4 } },
    wrapperCol: { xl: { span: 16 } },
  };

  // 返回
  const goBack = () => {
    navigate(-1);
    form.resetFields();
  };

  // 表单提交
  const submit = async (values) => {
    setLoading(true);
    const res = await addChange(values);
    let code = res?.code;
    if (code === 200 || code === 202) {
      if (code === 200) {
        message.success("添加转化成功", 1);
      }
      setTimeout(() => {
        setLoading(false);
        navigate(-1);
      }, 1000);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="trans_add">
      <Card title="转化添加">
        <Form
          {...layout}
          form={form}
          onFinish={submit}
          initialValues={{
            convert_source_type: transType,
            convert_type: "AD_CONVERT_TYPE_PAY",
            put_select: 1,
          }}
        >
          <Form.Item label colon={false}>
            {copyInput ? (
              <>
                <Input.TextArea autoSize ref={ref}></Input.TextArea>
                <div
                  style={{ width: "100%", display: 'flex' }}
                >
                  <div style={{width: '80%', fontSize: 12, color: 'red'}}>复制多个账户换行</div>
                  <div style={{marginTop: 10}}>
                    <Space>
                      <Button type="primary" onClick={copySure}>
                        确定
                      </Button>
                      <Button onClick={copyInputClose}>取消</Button>
                    </Space>
                  </div>
                </div>
              </>
            ) : (
              <Button type="primary" ghost onClick={advertiserCopy}>
                批量复制账户
              </Button>
            )}
          </Form.Item>

          <Form.Item
            name="advertiser_ids"
            label="批量所选广告主"
            rules={[{ required: true, message: "请选择广告主" }]}
          >
            <Select
              mode="multiple"
              showSearch
              allowClear
              options={advertiseList}
              placeholder="请选择广告主 (可多选)"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              autoClearSearchValue={false}
            />
          </Form.Item>
          <Form.Item name="convert_source_type" label="转化方式">
            <Radio.Group onChange={changeType}>
              <Radio value="APP">应用（事件）</Radio>
              <Radio value="THIRD_EXTERNAL">落地页（线索事件）</Radio>
              <Radio value="QUICK_APP">一跳（事件）</Radio>
              <Radio value="MINI_PROGRAME">微信小程序</Radio>
              <Radio value="TIK_PROGRAME">抖音小程序</Radio>
            </Radio.Group>
          </Form.Item>
          {transType === "APP" ||
          transType === "QUICK_APP" ||
          transType === "MINI_PROGRAME" ||
          transType === "TIK_PROGRAME" ? (
            <Form.Item
              name="application_type"
              label="应用选择"
              rules={[{ required: true, message: "请选择应用" }]}
            >
              <Radio.Group options={application} onChange={appChange} />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item
            name="name"
            label="转化名称"
            rules={[{ required: true, message: "转化名称不能为空" }]}
          >
            <Input placeholder="请输入转化名称" />
          </Form.Item>
          {transType === "APP" ? (
            <Form.Item name="download_url" label="下载地址">
              <Input placeholder="请输入下载地址" />
            </Form.Item>
          ) : transType === "THIRD_EXTERNAL" ? (
            <Form.Item name="source" label="书籍来源">
              <Radio.Group options={sourceOptions} onChange={sourceChange} />
            </Form.Item>
          ) : (
            ""
          )}

          {transType === "THIRD_EXTERNAL" && source === 1 ? (
            <Form.Item name="put_select" label="投放选择">
              <Radio.Group>
                <Radio value={1}>公众号</Radio>
                <Radio value={2} disabled>
                  快应用(功能未更新，暂不可用)
                </Radio>
              </Radio.Group>
            </Form.Item>
          ) : (
            ""
          )}

          {transType === "APP" || transType === "QUICK_APP" ? (
            <>
              <Form.Item name="package" label="包名">
                <Input placeholder="请输入包名" />
              </Form.Item>
              <Form.Item name="app_name" label="应用中文名">
                <Input />
              </Form.Item>
            </>
          ) : (
            ""
          )}
          {transType === "MINI_PROGRAME" || transType === "TIK_PROGRAME" ? (
            <Form.Item name="app_name" label="应用中文名">
              <Input />
            </Form.Item>
          ) : (
            ""
          )}
          {transType === "THIRD_EXTERNAL" ? (
            <Form.Item
              name="uid"
              label="选择要绑定的渠道"
              rules={[{ required: true, message: "选择要绑定的渠道" }]}
            >
              <Select
                allowClear
                placeholder="选择要绑定的渠道"
                options={bindingOptions}
                showSearch
                filterOption={filterOption}
              />
            </Form.Item>
          ) : (
            ""
          )}
          {transType === "THIRD_EXTERNAL" ? (
            <Form.Item
              name="bookid"
              label="选择投放书籍"
              rules={[{ required: true, message: "选择投放书籍" }]}
            >
              <Select
                allowClear
                placeholder="选择投放书籍"
                options={bookOptions}
                showSearch
                filterOption={filterOption}
              />
            </Form.Item>
          ) : (
            ""
          )}
          {transType === "APP" ||
          transType === "QUICK_APP" ||
          transType === "MINI_PROGRAME" ||
          transType === "TIK_PROGRAME" ? (
            <Form.Item
              name="promotion_id"
              label="选择推广链接"
              rules={[{ required: true, message: "请选择推广链接" }]}
            >
              <Select
                allowClear
                placeholder="请选择推广链接"
                options={promotionOptions}
                showSearch
                filterOption={filterOption}
              />
            </Form.Item>
          ) : (
            ""
          )}

          <Form.Item
            labelCol={{ sm: { span: 10 }, xl: { span: 10 } }}
            label
            colon={false}
          >
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                提交
              </Button>
              <Button onClick={goBack}>返回</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
