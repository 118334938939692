import { Col, Row, Switch } from "antd";

const columns = ({ onStatusChange, statusLoading }) => [
  {
    title: "名称",
    width: 80,
    align: "center",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "充值档位",
    width: 360,
    dataIndex: "content",
    key: "content",
    render: (_, record) => {
      let items = record?.items;
      return (
        <Row>
          {items.map((item) => {
            return (
              <Col span={12} key={item?.key}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      marginRight: 15,
                    }}
                  >
                    第{item.key}档
                  </div>
                  <div style={{ width: 110 }}>
                    充值金额: {parseFloat(item?.money)}
                  </div>
                  <div>赠送金币: {parseInt(item?.gift)}</div>
                </div>
              </Col>
            );
          })}
        </Row>
      );
    },
  },
  {
    title: "最高出价限制",
    width: 100,
    align: "center",
    dataIndex: "bid_limit",
    key: "bid_limit",
    editable: true,
    render: (value) => (
      parseFloat(value)
    )
  },
  {
    title: "状态",
    width: 100,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (value, record) => (
      <Switch
        checkedChildren="开启"
        unCheckedChildren="关闭"
        defaultChecked={value === 1}
        onChange={(status, e) => onStatusChange(record, status, e)}
        loading={statusLoading === record?.id}
      />
    ),
  },
];

export default columns;
