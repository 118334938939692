import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { filterPitchers } from "../../api/statistics";
import { useLocation } from "react-router-dom";

const Pitchers = ({ unLimit, onChange, value, form }) => {
  const [pitchers, setPitchers] = useState([]);
  const { state } = useLocation();

  useEffect(() => {
    getPitchers();
  }, []);

  const getPitchers = async () => {
    const res = await filterPitchers({ type: "playlet", unLimit });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitchers(newList);

      if (state?.admin_id) {
        form.setFieldValue("admin_id", state?.admin_id);
      } else {
        const is_super = localStorage.getItem("is_super") === 'true';
        const nicknameId = localStorage.getItem("nicknameId");
        !is_super && form.setFieldValue("admin_id", Number(nicknameId));
      }
    }
  };

  const onChangeValue = (val) => {
    onChange?.(val);
  };

  return (
    <Select
      allowClear
      placeholder="请选择"
      value={value}
      onSelect={onChangeValue}
      onClear={onChangeValue}
      options={pitchers}
      style={{ width: 150 }}
    />
  );
};

export default Pitchers;