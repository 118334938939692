import { Button, Popconfirm, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { numDiv, numMulti } from "../../../util/calculate";

const statusTxt = {
  OK: '投放中',
  DELETED: '已删除',
  PROJECT_OFFLINE_BUDGET: '项目超出预算PROJECT_PREOFFLINE_BUDGET 项目接近预算',
  TIME_NO_REACH: '未到达投放时间',
  TIME_DONE: '已完成',
  NO_SCHEDULE: '不在投放时段', 
  AUDIT: '新建审核中',
  REAUDIT: '修改审核中',
  FROZEN: '已终止',
  AUDIT_DENY: '审核不通过',
  OFFLINE_BUDGET: '广告超出预算',
  OFFLINE_BALANCE: '账户余额不足',
  PREOFFLINE_BUDGET: '广告接近预算',
  DISABLED: '已暂停',
  PROJECT_DISABLED: '已被项目暂停',
  LIVE_ROOM_OFF: '关联直播间不可投',
  PRODUCT_OFFLINE: '关联商品不可投',
  AWEME_ACCOUNT_DISABLED: '关联抖音账号不可投AWEME_ANCHOR_DISABLED 锚点不可投',
  DISABLE_BY_QUOTA: '已暂停（配额达限）',
  CREATE: '新建',
  ADVERTISER_OFFLINE_BUDGET: '账号超出预算',
  ADVERTISER_PREOFFLINE_BUDGET: '账号接近预算'
}

const columns = ({ updatePlanStatus, deletePlan, editBid, saveBid, showTimeDivision, updateBidNum, handleEable, qiliangDetail }) => [
  {
    title: "广告名称",
    width: 130,
    align: "center",
    dataIndex: "promotion_name",
    key: "promotion_name",
    fixed: "left",
  },
  {
    title: "广告ID",
    width: 120,
    align: "center",
    dataIndex: "promotion_id",
    key: "promotion_id",
    render: (value, record) => (
      <Link
        to={`https://ad.oceanengine.com/superior/promote-manage/ad?aadvid=${record?.advertiser_id}&searchInput=%2522${value}%2522`}
        target="_blank"
      >
        {value}
      </Link>
    ),
  },
  {
    title: "所属账户",
    width: 130,
    align: "center",
    dataIndex: "advertiser_name",
    key: "advertiser_name",
  },
  {
    title: "广告状态",
    width: 120,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (_, record) => {
      return (
        <>
          <div style={{ marginBottom: 10 }}>{statusTxt[record?.status]}</div>
          {record?.status === "OK" ||
          record?.status === "AD_STATUS_DELIVERY_OK" ||
          record?.status === "PROJECT_OFFLINE_BUDGET" ||
          record?.status === "PROJECT_PREOFFLINE_BUDGET" ||
          record?.status === "PREOFFLINE_BUDGET" ? (
            <div>
              <Space>
                <Button
                  type="primary"
                  size="small"
                  ghost
                  onClick={() => updatePlanStatus(record, "DISABLE")}
                >
                  暂停
                </Button>
                <Button
                  type="primary"
                  size="small"
                  danger
                  onClick={() => deletePlan(record)}
                >
                  删除
                </Button>
              </Space>
            </div>
          ) : record?.status === "DISABLED" ||
            record?.status === "AD_STATUS_DISABLE" ? (
            <div>
              <Space>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => updatePlanStatus(record, "ENABLE")}
                >
                  启动
                </Button>
                <Button
                  type="primary"
                  size="small"
                  danger
                  onClick={() => deletePlan(record)}
                >
                  删除
                </Button>
              </Space>
            </div>
          ) : (
            ""
          )}
        </>
      );
    },
  },
  {
    title: "广告预算",
    width: 90,
    align: "center",
    dataIndex: "budget",
    key: "budget",
  },
  {
    title: "出价",
    width: 70,
    align: "center",
    dataIndex: "cpa_bid",
    key: "cpa_bid",
    render: (_, record) => {
      let cpaBid = record?.cpa_bid ?? 0;
      let adStatus = record?.status;
      if (cpaBid > 0 && adStatus != "DELETED") {
        return (
          <Popconfirm
            title="新出价"
            description={() => editBid(record)}
            onConfirm={() => saveBid(record)}
            okText="修改"
            cancelText="取消"
          >
            <Button
              type="link"
              size="small"
              onClick={() => updateBidNum(record)}
            >
              {cpaBid}
            </Button>
          </Popconfirm>
        );
      } else {
        return cpaBid;
      }
    },
  },
  {
    title: "消耗",
    width: 90,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    render: (_, record) => (
      <>
        {record?.metrics?.stat_cost}
        <Button
          type="link"
          size="small"
          onClick={() => showTimeDivision(record)}
        >
          查看详情
        </Button>
      </>
    ),
  },
  {
    title: "转化成本",
    width: 100,
    align: "center",
    dataIndex: "convert_cost",
    key: "convert_cost",
    render: (_, record) => (
      record?.metrics?.conversion_cost
    )
  },
  {
    title: "转化数",
    width: 100,
    align: "center",
    dataIndex: "convert",
    key: "convert",
    render: (_, record) => (
      record?.metrics?.convert_cnt
    )
  },
  {
    title: "转化率(%)",
    width: 90,
    align: "center",
    dataIndex: "convert_rate",
    key: "convert_rate",
    render: (_, record) => (
      record?.metrics?.conversion_rate
    )
  },
  {
    title: "激活数",
    width: 80,
    align: "center",
    dataIndex: "active",
    key: "active",
    render: (_, record) => (
      record?.metrics?.active
    )
  },
  {
    title: "激活成本",
    width: 80,
    align: "center",
    dataIndex: "active_cost",
    key: "active_cost",
    render: (_, record) => (
      record?.metrics?.active_cost
    )
  },
  {
    title: "激活率",
    width: 80,
    align: "center",
    dataIndex: "active_rate",
    key: "active_rate",
    render: (_, record) => (
      record?.metrics?.active_rate
    )
  },
  {
    title: "展示数",
    width: 100,
    align: "center",
    dataIndex: "show",
    render: (_, record) => (
      record?.metrics?.show_cnt
    )
  },
  {
    title: "点击数",
    width: 100,
    align: "center",
    dataIndex: "click",
    key: "click",
    render: (_, record) => (
      record?.metrics?.click_cnt
    )
  },
  {
    title: "点击率",
    width: 100,
    align: "center",
    dataIndex: "ctr",
    key: "ctr",
    render: (_, record) => (
      record?.metrics?.ctr
    )
  },
  {
    title: "平均点击单价",
    width: 80,
    align: "center",
    dataIndex: "avg_click_costs",
    key: "avg_click_costs",
    render: (_, record) => (
      record?.metrics?.cpc_platform
    )
  },
  {
    title: "平均千次展示费用",
    width: 100,
    align: "center",
    dataIndex: "avg_show_cost",
    key: "avg_show_cost",
    render: (_, record) => (
      record?.metrics?.cpm_platform
    )
  },
  {
    title: "一键起量",
    width: 120,
    align: "center",
    dataIndex: "start_measure",
    key: "start_measure",
    render: (_, record) => {
      let cpaBid = parseFloat(record?.cpa_bid)
      let status = record?.raise_status
      return cpaBid > 0 ? (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div onClick={() => handleEable(record)}>
              {status === "RAISING" ? (
                <Link>起量中</Link>
              ) : status === "HAS_PRERAISE" ? (
                <Link>已预约</Link>
              ) : status === "ENABLE_RAISE" ? (
                <Link>启用</Link>
              ): ''}
            </div>
            <div style={{ color: "#EBEBED", marginLeft: 5, marginRight: 5 }}>
              |
            </div>
            <div onClick={() => qiliangDetail(record)}>
              <Link>详情</Link>
            </div>
          </div>
          <Typography.Text type="secondary" style={{ fontSize: 10 }}>
            花一笔钱加速广告曝光
          </Typography.Text>
        </>
      ) : ''
    },
  },
];

export default columns
