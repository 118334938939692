import { Button, Col, Popover, Rate, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import { MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const options = [
  "现代言情",
  "历史架空",
  "古代言情",
  "社会风云",
  "悬疑推理",
  "仙侠奇缘",
  "游戏科幻",
  "玄幻仙侠",
  "都市异能",
  "精彩书库",
  "年代种田",
];

const columns = ({
  materialDel,
  materialLock,
  materialShare,
  handleRemark,
  isSuper,
  isOur,
  onRecommendChange,
  updateShowType,
}) =>
  isOur || isSuper
    ? [
        {
          title: "视频",
          width: 180,
          align: "center",
          dataIndex: "video",
          key: "video",
          ellipsis: true,
          fixed: "left",
          render: (_, record) => {
            return (
              <>
                <div>
                  <Rate
                    count={1}
                    style={{ fontSize: 14 }}
                    onChange={(value) => onRecommendChange(record, value)}
                    defaultValue={record?.is_recommend}
                  />
                </div>
                <Row justify="start">
                  <Col span={6} style={{ marginLeft: -10 }}>
                    <Popover
                      placement="right"
                      content={
                        <video
                          alt=""
                          controls
                          src={record?.attach_video?.oss_url}
                          controlslist="nodownload"
                          preload="none"
                          width={350}
                          poster={`${record?.attach_video?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                        />
                      }
                      destroyTooltipOnHide={true}
                    >
                      <video
                        alt=""
                        src={record?.attach_video?.oss_url}
                        width={23}
                        poster={`${record?.attach_video?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                      />
                    </Popover>
                  </Col>
                  <Col span={4}>
                    <div style={{ fontSize: 12 }}>
                      {record?.title}
                      <div style={{ fontSize: 12 }}>ID:{record?.file_id}</div>
                      {record?.is_del === 1 ? (
                        <Typography.Text type="danger" style={{ fontSize: 13 }}>
                          已删除
                        </Typography.Text>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            );
          },
        },
        {
          title: "标签",
          width: 120,
          align: "center",
          dataIndex: "label",
          key: "label",
          editable: true,
          render: (text) => {
            if (text) {
              let arr = text.split(",");
              return arr.map((tag, key) => {
                return (
                  <div key={key}>
                    <Link>{tag || "-"}</Link>
                  </div>
                );
              });
            } else {
              return <Link>-</Link>;
            }
          },
        },
        {
          title: "素材转化",
          width: 80,
          align: "center",
          dataIndex: "convert",
          key: "convert",
          sorter: {},
          render: (value) =>
            value ? (
              <Typography.Text type="success">{value}</Typography.Text>
            ) : (
              <Typography.Text type="danger">{value}</Typography.Text>
            ),
        },
        {
          title: "NoBid素材转化",
          width: 80,
          align: "center",
          dataIndex: "nobid_convert",
          key: "nobid_convert",
          sorter: {},
        },
        {
          title: "消耗",
          width: 80,
          align: "center",
          dataIndex: "cost",
          key: "cost",
          sorter: {},
        },
        {
          title: "是否低效素材",
          width: 60,
          align: "center",
          dataIndex: "is_low",
          key: "is_low",
          render: (value) =>
            value === 1 ? (
              <Typography.Text type="danger" style={{ fontSize: 12 }}>
                是
              </Typography.Text>
            ) : (
              <Typography.Text style={{ fontSize: 12 }}>否</Typography.Text>
            ),
        },
        {
          title: "被拒数",
          width: 70,
          align: "center",
          dataIndex: "audit_fail_num",
          key: "audit_fail_num",
          sorter: {},
        },
        {
          title: "上传者",
          width: 100,
          align: "center",
          dataIndex: "nickname",
          key: "nickname",
          ellipsis: true,
        },
        {
          title: "绑定计划数",
          width: 80,
          align: "center",
          dataIndex: "plan_nums",
          key: "plan_nums",
          render: (value) => value || 0,
        },
        {
          title: "消耗计划数",
          width: 80,
          align: "center",
          dataIndex: "plan_cost_nums",
          key: "plan_cost_nums",
          render: (value) => value || 0,
        },
        {
          title: "平均转化成本",
          width: 90,
          align: "center",
          dataIndex: "convert_cost",
          key: "convert_cost",
          render: (value) => value || 0,
        },
        {
          title: "上传时间",
          width: 130,
          align: "center",
          dataIndex: "create_time",
          key: "create_time",
          render: (text) => dayjs.unix(text).format("YYYY-MM-DD HH:mm::ss"),
        },
        {
          title: "视频名称",
          width: 130,
          align: "center",
          dataIndex: "video_name",
          key: "video_name",
          render: (_, record) => {
            let videoName = record?.attach_video?.name;
            let name = videoName
              ? videoName.substr(0, videoName.lastIndexOf("."))
              : "-";
            return (
              <Typography.Text style={{ fontSize: 12 }}>{name}</Typography.Text>
            );
          },
        },
        {
          title: "归属",
          width: 80,
          align: "center",
          dataIndex: "show_type",
          key: "show_type",
          render: (text, record) =>
            isSuper ? (
              <Link onClick={() => updateShowType(record)}>{text === 1 ? "公有" : "私有"}</Link>
            ) : text === 1 ? (
              "公有"
            ) : (
              "私有"
            ),
        },
        {
          title: "投放类别",
          width: 80,
          align: "center",
          dataIndex: "platform",
          key: "platform",
          render: (text) =>
            text === "novel" ? "小说" : text === "playlet" ? "短剧" : "-",
        },
        {
          title: "素材类型",
          width: 100,
          align: "center",
          dataIndex: "type",
          key: "type",
          render: (text) =>
            text === 1 ? "竖版视频" : text === 2 ? "横版视频" : "大图横图",
        },
        {
          title: "分类",
          width: 80,
          align: "center",
          dataIndex: "category",
          key: "category",
          render: (text) => options[text],
        },
        {
          title: "是否上锁",
          width: 80,
          align: "center",
          dataIndex: "lock",
          key: "lock",
          render: (text) => (text === 0 ? "未上锁" : "已上锁"),
        },
        {
          title: "头条素材id",
          width: 140,
          align: "center",
          ellipsis: true,
          dataIndex: "creative_id",
          key: "creative_id",
        },
        {
          title: "操作",
          align: "center",
          width: 260,
          dataIndex: "operation",
          key: "operation",
          fixed: "right",
          render: (_, record) => (
            <>
              {isSuper ? (
                <Space size="small">
                  <Button size="small">
                    <Link
                      to={`/admin/material/video/video_data_detail`}
                      state={{ ...record }}
                    >
                      数据详情
                    </Link>
                  </Button>
                  <Button type="primary" size="small" ghost>
                    <Link
                      to={`/admin/material/video/video_creative_cost`}
                      state={{ ...record }}
                    >
                      每日消耗
                    </Link>
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      materialShare(record);
                    }}
                  >
                    素材共享
                  </Button>
                  <Popover
                    placement="left"
                    content={
                      <>
                        <div>
                          <Space>
                            {record?.is_del === 0 ? (
                              <Button
                                type="primary"
                                size="small"
                                danger
                                style={{ width: 120 }}
                                onClick={() => {
                                  materialDel(record.id);
                                }}
                              >
                                删除素材
                              </Button>
                            ) : (
                              ""
                            )}

                            <Button
                              type="primary"
                              size="small"
                              onClick={() => {
                                handleRemark(record);
                              }}
                            >
                              备注|状态修改
                            </Button>
                          </Space>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <Space>
                            <Button
                              type="dashed"
                              size="small"
                              danger
                              style={{ width: 120 }}
                              onClick={() => {
                                materialLock(record);
                              }}
                            >
                              {record.lock === 0 ? "素材上锁" : "素材解锁"}
                            </Button>
                            {/* <Button type="primary" style={{ width: 120 }} onClick={() => { materialShare(record) }}>{record.locked === 0 ? '素材共享' : '取消共享'}</Button> */}
                          </Space>
                        </div>
                      </>
                    }
                  >
                    <MoreOutlined />
                  </Popover>
                </Space>
              ) : (
                <Space>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      materialShare(record);
                    }}
                  >
                    素材共享
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    danger
                    style={{ width: 120 }}
                    onClick={() => {
                      materialDel(record.id);
                    }}
                  >
                    删除素材
                  </Button>
                  <Popover
                    placement="left"
                    content={
                      <>
                        <div>
                          <Space>
                            <Button
                              type="primary"
                              size="small"
                              onClick={() => {
                                handleRemark(record);
                              }}
                            >
                              备注|状态修改
                            </Button>
                          </Space>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <Space>
                            <Button
                              type="dashed"
                              size="small"
                              danger
                              style={{ width: 120 }}
                              onClick={() => {
                                materialLock(record);
                              }}
                            >
                              {record.lock === 0 ? "素材上锁" : "素材解锁"}
                            </Button>
                            {/* <Button type="primary" style={{ width: 120 }} onClick={() => { materialShare(record) }}>{record.locked === 0 ? '素材共享' : '取消共享'}</Button> */}
                          </Space>
                        </div>
                      </>
                    }
                  >
                    <MoreOutlined />
                  </Popover>
                </Space>
              )}
            </>
          ),
        },
      ]
    : [
        {
          title: "视频",
          width: 180,
          align: "center",
          dataIndex: "video",
          key: "video",
          ellipsis: true,
          fixed: "left",
          render: (_, record) => {
            return (
              <>
                <div>
                  <Rate
                    count={1}
                    style={{ fontSize: 14 }}
                    onChange={(value) => onRecommendChange(record, value)}
                    defaultValue={record?.is_recommend}
                  />
                </div>
                <Row justify="start">
                  <Col span={6} style={{ marginLeft: -10 }}>
                    <Popover
                      placement="right"
                      content={
                        <video
                          alt=""
                          controls
                          src={record?.attach_video?.oss_url}
                          controlslist="nodownload"
                          preload="none"
                          width={350}
                          poster={`${record?.attach_video?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                        />
                      }
                    >
                      <video
                        alt=""
                        src={record?.attach_video?.oss_url}
                        width={20}
                        poster={`${record?.attach_video?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                      />
                    </Popover>
                  </Col>
                  <Col span={4}>
                    <div style={{ fontSize: 12 }}>
                      {record?.title}
                      <div style={{ fontSize: 12 }}>ID:{record?.file_id}</div>
                      {record?.is_del === 1 ? (
                        <Typography.Text type="danger" style={{ fontSize: 13 }}>
                          已删除
                        </Typography.Text>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            );
          },
        },
        {
          title: "标签",
          width: 120,
          align: "center",
          dataIndex: "label",
          key: "label",
          editable: true,
          render: (text) => {
            if (text) {
              let arr = text.split(",");
              return arr.map((tag, key) => {
                return (
                  <div key={key}>
                    <Link>{tag || "-"}</Link>
                  </div>
                );
              });
            } else {
              return <Link>-</Link>;
            }
          },
        },
        {
          title: "素材转化",
          width: 80,
          align: "center",
          dataIndex: "convert",
          key: "convert",
          sorter: {},
          render: (value) =>
            value ? (
              <Typography.Text type="success">{value}</Typography.Text>
            ) : (
              <Typography.Text type="danger">{value}</Typography.Text>
            ),
        },
        {
          title: "NoBid素材转化",
          width: 80,
          align: "center",
          dataIndex: "nobid_convert",
          key: "nobid_convert",
          sorter: {},
        },
        {
          title: "消耗",
          width: 80,
          align: "center",
          dataIndex: "cost",
          key: "cost",
          sorter: {},
        },
        {
          title: "是否低效素材",
          width: 60,
          align: "center",
          dataIndex: "is_low",
          key: "is_low",
          render: (value) =>
            value === 1 ? (
              <Typography.Text type="danger" style={{ fontSize: 12 }}>
                是
              </Typography.Text>
            ) : (
              <Typography.Text style={{ fontSize: 12 }}>否</Typography.Text>
            ),
        },
        {
          title: "被拒数",
          width: 70,
          align: "center",
          dataIndex: "audit_fail_num",
          key: "audit_fail_num",
        },
        {
          title: "上传者",
          width: 100,
          align: "center",
          dataIndex: "nickname",
          key: "nickname",
          ellipsis: true,
        },
        {
          title: "上传时间",
          width: 130,
          align: "center",
          dataIndex: "create_time",
          key: "create_time",
          render: (text) => dayjs.unix(text).format("YYYY-MM-DD HH:mm::ss"),
        },
        {
          title: "视频名称",
          width: 130,
          align: "center",
          dataIndex: "video_name",
          key: "video_name",
          render: (_, record) => {
            let videoName = record?.attach_video?.name;
            let name = videoName
              ? videoName.substr(0, videoName.lastIndexOf("."))
              : "-";
            return (
              <Typography.Text style={{ fontSize: 12 }}>{name}</Typography.Text>
            );
          },
        },
        {
          title: "归属",
          width: 80,
          align: "center",
          dataIndex: "show_type",
          key: "show_type",
          render: (text) => (text === 1 ? "公有" : "私有"),
        },
        {
          title: "投放类别",
          width: 80,
          align: "center",
          dataIndex: "platform",
          key: "platform",
          render: (text) =>
            text === "novel" ? "小说" : text === "playlet" ? "短剧" : "-",
        },
        {
          title: "素材类型",
          width: 100,
          align: "center",
          dataIndex: "type",
          key: "type",
          render: (text) =>
            text === 1 ? "竖版视频" : text === 2 ? "横版视频" : "大图横图",
        },
        {
          title: "分类",
          width: 80,
          align: "center",
          dataIndex: "category",
          key: "category",
          render: (text) => options[text],
        },
        {
          title: "是否上锁",
          width: 80,
          align: "center",
          dataIndex: "lock",
          key: "lock",
          render: (text) => (text === 0 ? "未上锁" : "已上锁"),
        },
        {
          title: "头条素材id",
          width: 140,
          align: "center",
          ellipsis: true,
          dataIndex: "creative_id",
          key: "creative_id",
        },
        {
          title: "操作",
          align: "center",
          width: 260,
          dataIndex: "operation",
          key: "operation",
          fixed: "right",
          render: (_, record) => (
            <>
              {isSuper ? (
                <Space size="small">
                  <Button size="small">
                    <Link
                      to={`/admin/material/video/video_data_detail`}
                      state={{ ...record }}
                    >
                      数据详情
                    </Link>
                  </Button>
                  <Button type="primary" size="small" ghost>
                    <Link
                      to={`/admin/material/video/video_creative_cost`}
                      state={{ ...record }}
                    >
                      每日消耗
                    </Link>
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      materialShare(record);
                    }}
                  >
                    素材共享
                  </Button>
                  <Popover
                    placement="left"
                    content={
                      <>
                        <div>
                          <Space>
                            {record?.is_del === 0 ? (
                              <Button
                                type="primary"
                                size="small"
                                danger
                                style={{ width: 120 }}
                                onClick={() => {
                                  materialDel(record.id);
                                }}
                              >
                                删除素材
                              </Button>
                            ) : (
                              ""
                            )}

                            <Button
                              type="primary"
                              size="small"
                              onClick={() => {
                                handleRemark(record);
                              }}
                            >
                              备注|状态修改
                            </Button>
                          </Space>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <Space>
                            <Button
                              type="dashed"
                              size="small"
                              danger
                              style={{ width: 120 }}
                              onClick={() => {
                                materialLock(record);
                              }}
                            >
                              {record.lock === 0 ? "素材上锁" : "素材解锁"}
                            </Button>
                            {/* <Button type="primary" style={{ width: 120 }} onClick={() => { materialShare(record) }}>{record.locked === 0 ? '素材共享' : '取消共享'}</Button> */}
                          </Space>
                        </div>
                      </>
                    }
                  >
                    <MoreOutlined />
                  </Popover>
                </Space>
              ) : (
                <Space>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      materialShare(record);
                    }}
                  >
                    素材共享
                  </Button>
                  {record?.is_del === 0 ? (
                    <Button
                      type="primary"
                      size="small"
                      danger
                      style={{ width: 120 }}
                      onClick={() => {
                        materialDel(record.id);
                      }}
                    >
                      删除素材
                    </Button>
                  ) : (
                    ""
                  )}

                  <Popover
                    placement="left"
                    content={
                      <>
                        <div>
                          <Space>
                            <Button
                              type="primary"
                              size="small"
                              onClick={() => {
                                handleRemark(record);
                              }}
                            >
                              备注|状态修改
                            </Button>
                          </Space>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <Space>
                            <Button
                              type="dashed"
                              size="small"
                              danger
                              style={{ width: 120 }}
                              onClick={() => {
                                materialLock(record);
                              }}
                            >
                              {record.lock === 0 ? "素材上锁" : "素材解锁"}
                            </Button>
                            {/* <Button type="primary" style={{ width: 120 }} onClick={() => { materialShare(record) }}>{record.locked === 0 ? '素材共享' : '取消共享'}</Button> */}
                          </Space>
                        </div>
                      </>
                    }
                  >
                    <MoreOutlined />
                  </Popover>
                </Space>
              )}
            </>
          ),
        },
      ];

export default columns;
