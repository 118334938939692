import { Form, Input, Modal, Radio, Tree, message } from "antd";
import React, { Children } from "react";
import { useState } from "react";
import { getMenuTree, menuAdd, menuUpdate } from "../../../api/permission";

const { Search } = Input;
const { DirectoryTree } = Tree;

export default function AddModal(props) {
  const [selectModalShow, setSelectModalShow] = useState(false);
  const [treeTitle, setTreeTitle] = useState("-");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { show } = props;
  const [form] = Form.useForm();

  const getTreeList = async (param = []) => {
    const res = await getMenuTree(param);
    if (res?.code === 200) {
      const {data} = res
      setList(data?.lists);
    }
  };

  const layout = {
    labelCol: { xl: { span: 6 } },
    wrapperCol: { xl: { span: 16 } },
  };

  // 选择上级菜单
  const seletMenu = () => {
    setSelectModalShow(true);
    getTreeList();
  };

  // 选中树形结构
  const onSelect = (_, info) => {
    let title = info.node.title;
    setTreeTitle(title);
    form.setFieldsValue({ pid: info.node.id });
  };

  const onClose = () => {
    form.resetFields()
    setLoading(false)
    props.onClose()
  }

  // 点击确定选择上级组织
  const onSure = () => {
    if (treeTitle === form.getFieldValue("name")) {
      message.error("不能选择自己作为上级", 1);
      return;
    }
    form.setFieldsValue({ pname: treeTitle });
    setSelectModalShow(false);
  };

  // 处理菜单添加提交
  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const values = form.getFieldValue();
        const res = await menuAdd(values)
        if (res?.code === 200) {
          message.success("菜单添加成功", 2);
          setTimeout(() => {
            onClose();
          }, 100);
        }
        setLoading(false);
      })
      .catch(() => {
        return;
      });
  };

  return (
    <>
      <Modal
        title="添加菜单"
        open={show}
        onCancel={onClose}
        width={600}
        onOk={handleSubmit}
        confirmLoading={loading}
        maskClosable={false}
      >
        <Form
          {...layout}
          initialValues={{
            pname: "主目录",
            pid: 0,
            status: 1,
            sort: 100,
          }}
          form={form}
        >
          <Form.Item hidden name="pid">
            <Input />
          </Form.Item>
          <Form.Item label="上级菜单" name="pname">
            <Search
              autoComplete="off"
              readOnly
              onClick={seletMenu}
              allowClear
            />
          </Form.Item>
          <Form.Item label="菜单名称" name="title" rules={[{ required: true }]}>
            <Input placeholder="输入菜单名称" allowClear />
          </Form.Item>
          <Form.Item
            label="跳转地址"
            name="value"
            tooltip="只需要当前菜单的链接地址，不需要填父级的链接地址"
            rules={[{ required: true }]}
          >
            <Input placeholder="输入跳转地址" allowClear />
          </Form.Item>
          <Form.Item label="显示顺序" name="sort">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="菜单图标" name="icon">
            <Input placeholder="输入菜单图标" allowClear />
          </Form.Item>
          <Form.Item label="菜单状态" name="status">
            <Radio.Group>
              <Radio value={0}>隐藏</Radio>
              <Radio value={1}>显示</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>

      {/* 树形结果展示数据弹窗 */}
      <Modal
        title="菜单选择"
        open={selectModalShow}
        onCancel={() => {
          setSelectModalShow(false);
        }}
        width={320}
        style={{ top: "25%" }}
        onOk={onSure}
      >
        <Form>
          <Form.Item label="关键字">
            <Search />
          </Form.Item>
          <Form.Item labelCol={{ sm: { span: 4 } }} label colon={false}>
            {list.length > 0 ? (
              <DirectoryTree
                treeData={list}
                showLine={true}
                defaultExpandAll={true}
                height={250}
                expandAction={false}
                onSelect={onSelect}
              />
            ) : (
              ""
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
