// 树形结构数组扁平化处理
export function treeToArray(tree) {
    var res = []
    for (const item of tree) {
        const { children, ...i } = item
        if (children && children.length) {
            res = res.concat(treeToArray(children))
        }
        res.push(i)
    }
    return res
}

/**
 * 数组转树形结构
 * @param {array} list 被转换的数组
 * @param {number|string} root 根节点（最外层节点）的 id
 * @return array
 */
export function arrayToTree(list, root = 0) {
    const result = [] // 用于存放结果
    const map = {} // 用于存放 list 下的节点
  
    // 1. 遍历 list，将 list 下的所有节点以 id 作为索引存入 map
    for (const item of list) {
      map[item.id] = { ...item } // 浅拷贝
    }
  
    // 2. 再次遍历，将根节点放入最外层，子节点放入父节点
    for (const item of list) {
      // 3. 获取节点的 id 和 父 id
      const { id, pid } = item // ES6 解构赋值
      // 4. 如果是根节点，存入 result
      if (item.pid === root) {
        result.push(map[id])
      } else {
        // 5. 反之，存入到父节点
        map[pid].children
          ? map[pid].children.push(map[id])
          : (map[pid].children = [map[id]])
      }
    }
  
    // 将结果返回
    return result
}


/**
 * 判断字符长度，含中英文数字
 */
export function getStringLength (str) {
  let realLength = 0
  let len = str.length
  let charCode = -1
  for (let i = 0; i< len; i++) {
    charCode = str.charCodeAt(i)
    if (charCode > 0 && charCode <= 128) realLength += 0.5
    else realLength += 1
  }
  return realLength
}

/**
 * 截取中文字符
 * @param {*} str 
 * @param {*} start 
 * @param {*} end 
 * @returns 
 */
export function sliceChineseString(str, start, end) {
  let startIndex = start;
  let endIndex = end;
  let chineseCharCount = 0;
  
  for (let i = 0; i < str.length; i++) {
    if (str[i].charCodeAt(0) > 255) { // 判断字符是否为中文字符
      chineseCharCount++;
    }
    
    if (chineseCharCount === start) {
      startIndex = i;
    } else if (chineseCharCount === end) {
      endIndex = i;
    }
  }
  
  if (start > chineseCharCount || start > end) {
    throw new Error('Invalid parameters');
  }
  
  return str.slice(startIndex, endIndex);
}
