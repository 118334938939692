import React, { useEffect } from "react";
import { useState } from "react";
import { Card } from "antd";
import IaaBack from "./IaaBack";
import IAPBack from "./IapBack"

const FORMAT = "YYYY-MM-DD HH:mm:ss";

// iaa 广告回传
export default function Result() {
  const [activeTabKey, setActiveTabKey] = useState(1);

  const tabList = [
    { key: 1, tab: "IAA虚拟回传" },
    { key: 2, tab: "IAP虚拟回传" },
  ];

  const onTabChange = key => {
    setActiveTabKey(key);
  };

  const contentList = {
    1: <IaaBack />,
    2: <IAPBack />,
  };

  return (
    <Card
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      size="small"
    >
      {contentList[activeTabKey]}
    </Card>
  );
}
