import { Col, Row, Space, Switch, Typography } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const columns = ({
  realCost,
  onStatusChange,
  statusLoading,
  showDetail,
  superRole,
  agentRole,
}) => [
  {
    title: "推广信息",
    width: 250,
    dataIndex: "info",
    key: "info",
    ellipsis: true,
    render: (_, record, index) => {
      return (
        <>
          {superRole ? (
            <ul>
              <li>
                投手:
                <Typography.Text type="danger">
                  {record?.nickname}
                </Typography.Text>
              </li>
              <li>
                剧场名称:
                <Typography.Text>{record?.playlet_name}</Typography.Text>
              </li>
              <li>
                短剧名称: <Typography.Text>{record?.book_name}</Typography.Text>
              </li>
              <li>
                推广外链ID:
                <Link
                  style={{ fontSize: 14 }}
                  onClick={() =>
                    showDetail(record?.promotion_primary_id, record?.content)
                  }
                >
                  {record?.promotion_id}
                </Link>
              </li>
              <li>
                推广链接名称:
                <Typography.Text
                  style={{ width: 150 }}
                  ellipsis={{ tooltip: record?.promotion }}
                >
                  {record?.promotion}
                </Typography.Text>
              </li>
            </ul>
          ) : (
            <Row gutter={24}>
              <Col span={5}>序号：{index + 1}</Col>
              <Col span={19}>
                <ul>
                  <li>
                    投手:
                    <Typography.Text type="danger">
                      {record?.nickname}
                    </Typography.Text>
                  </li>
                  <li>
                    剧场名称:{" "}
                    <Typography.Text>{record?.playlet_name}</Typography.Text>
                  </li>
                  <li>
                    短剧名称:{" "}
                    <Typography.Text>{record?.book_name}</Typography.Text>
                  </li>
                  <li>
                    推广外链ID:
                    <Link
                      style={{ fontSize: 14 }}
                      onClick={() =>
                        showDetail(
                          record?.promotion_primary_id,
                          record?.content
                        )
                      }
                    >
                      {record?.promotion_id}
                    </Link>
                  </li>
                  <li>
                    推广链接名称:
                    <Typography.Text
                      style={{ width: 150 }}
                      ellipsis={{ tooltip: record?.promotion }}
                    >
                      {record?.promotion}
                    </Typography.Text>
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </>
      );
    },
  },
  {
    title: "投放信息",
    width: 150,
    dataIndex: "put_info",
    key: "put_info",
    ellipsis: true,
    render: (_, record) => {
      return (
        <ul>
          <li>
            投放时间：
            <Typography.Text type="danger">
              {record?.promotion_config?.send_time
                ? dayjs(record?.promotion_config?.send_time).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : null}
            </Typography.Text>
          </li>
          <li>
            首发时间：
            <Typography.Text>
              {record?.promotion_config?.launch_time
                ? dayjs(record?.promotion_config?.launch_time).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : null}
            </Typography.Text>
          </li>
          <li>
            收费集点：
            <Typography.Text>
              {record?.promotion_config?.recharge_part}
            </Typography.Text>
          </li>
          <li>
            单集价格：
            <Typography.Text>
              {record?.promotion_config?.pre_price}
            </Typography.Text>
          </li>
          <li>
            投放状态：
            <Space>
              <Switch
                checkedChildren="开启"
                unCheckedChildren="关闭"
                defaultChecked={record?.promotion_status === 1}
                onChange={(status, e) => onStatusChange(record, status, e)}
                loading={statusLoading === record?.promotion_primary_id}
              />
              <Link
                to={
                  agentRole
                    ? `/admin/statistics/theater/push_plt/toutiao_agtplt?promotion_id=${record?.promotion_id}&user_id=${record?.admin_id}`
                    : `/admin/statistics/theater/push_plt/toutiao_plt?promotion_id=${record?.promotion_id}&user_id=${record?.admin_id}`
                }
                target="_blank"
              >
                头条列表
              </Link>
            </Space>
          </li>
        </ul>
      );
    },
  },
  {
    title: "用户人数",
    width: 120,
    dataIndex: "member_info",
    key: "member_info",
    render: (_, record) => (
      <ul>
        <li>
          <Typography.Text type="danger">
            新增用户数: {record?.new_user}
          </Typography.Text>
        </li>
        <li>首日充值人数: {record?.count}</li>
        <li>首日充值笔数: {record?.first_pay_num}</li>
        <li>新用户付费率: {record?.new_pay_rate || 0}</li>
      </ul>
    ),
  },
  {
    title: "充值金额",
    width: 120,
    dataIndex: "money",
    key: "money",
    sorter: superRole ? {} : (a, b) => a.total_money - b.total_money,
    // sortDirections: ["descend"],
    render: (_, record) => (
      <ul>
        <li>
          <Typography.Text type="danger">
            累计充值: {record?.total_money || 0}
          </Typography.Text>
        </li>
        <li>当日充值金额: {record?.first_day_pay || 0}</li>
        <li>当日首充金额: {record?.first_day_first_pay || 0}</li>
        <li>付费成本: {record?.paycosts || 0}</li>
      </ul>
    ),
  },
  {
    title: "累计回本率",
    width: 120,
    dataIndex: "cost",
    key: "cost",
    sorter: (a, b) => a.cost - b.cost,
    // sortDirections: ["descend"],
    render: (_, record) => {
      let key = `promotion_id_${record?.promotion_primary_id}`;
      return (
        <ul>
          <li>
            <Space>
              <Typography.Text type="danger">
                今日消耗: {record?.cost || 0}
              </Typography.Text>
            </Space>
            <Typography.Text type="success">
              {realCost[key] !== undefined && realCost[key] >= 0
                ? realCost[key]
                : null}
            </Typography.Text>
          </li>
          <li>回收: {record?.first_day_pay || 0}</li>
          <li>回收率: {record?.huishoulv || 0}</li>
          <li>今日复充率: {record?.repeat_rate || 0}</li>
          <li>首日客单价: {record?.kedans || 0}</li>
        </ul>
      );
    },
  },
];

export default columns;
