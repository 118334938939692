import { Space, Button, Switch, Card, Popconfirm, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { getAutoStrategies, changeStrategyStatus } from "@/api/delivery";
import AddOrEditModel from "./AddOrEditModel";
import Detail from "./Detail";

export default function Index() {
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState(null);
  const [pageToggle, setPageToggle] = useState(true);

  const columns = [
    {
      title: "策略名称",
      width: 120,
      dataIndex: "name",
      align: "center",
    },
    {
      title: "策略类型",
      width: 100,
      dataIndex: "stype",
      align: "center",
      render: (_, record) => {
        const textMap = new Map([["AUTO_CLOSED", "关停策略"]]);
        return textMap.get(_);
      },
    },
    {
      title: "策略内容",
      width: 200,
      dataIndex: "strategyDesc",
      align: "center",
    },
    {
      title: "创建时间",
      width: 120,
      dataIndex: "createTime",
      align: "center",
    },
    {
      title: "状态",
      width: 120,
      dataIndex: "status",
      align: "center",
      render: (_, record) => (
        <Popconfirm
          title={`确认要${record.status === 1 ? "禁用" : "开启"}吗？`}
          onConfirm={() => {
            const key = record.status === 1 ? 0 : 1;
            onChangeStatus(record.id, key);
          }}
        >
          <Switch
            checkedChildren="开启"
            unCheckedChildren="禁用"
            checked={record.status === 1}
          />
        </Popconfirm>
      ),
    },
    {
      title: "操作",
      dataIndex: "operation",
      // align: "center",
      width: 150,
      render: (_, record) => (
        <Space size={12}>
          <a onClick={() => onAddOrEdit(record.id)}>编辑</a>
          <Popconfirm
            title="确定要删除吗？"
            onConfirm={() => onChangeStatus(record.id, -1)}
          >
            <a>删除</a>
          </Popconfirm>
          <a onClick={() => onTogglePage(record.id)}>详情</a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getList();
  }, []);

  const getList = async (params = {}) => {
    try {
      const res = await getAutoStrategies();
      if (res?.code === 200) {
        setList(res?.data || []);
      }
    } catch (err) {
      console.log(err, "errerrerr");
    }
  };

  const onAddOrEdit = (id) => {
    setVisible(true);
    setId(id);
  };

  const onChangeStatus = async (id, status) => {
    const params = {
      id,
      status,
    };
    try {
      const res = await changeStrategyStatus(params);
      if (res?.code === 200) {
        message.success("操作成功");
        getList();
      }
    } catch (err) {
      console.log(err, "errerrerr");
    }
  };

  const onTogglePage = (id) => {
    setPageToggle(false);
    setId(id);
  };

  return (
    <>
      {pageToggle ? (
        <Card
          title={
            <Button
              type="primary"
              onClick={() => onAddOrEdit(null)}
              disabled={list.length > 0}
            >
              新增策略
            </Button>
          }
        >
          <Table dataSource={list} columns={columns} />
          {visible && (
            <AddOrEditModel
              open={visible}
              id={id}
              onClose={(isRefresh) => {
                setVisible(false);
                isRefresh && getList();
              }}
            />
          )}
        </Card>
      ) : (
        <Detail
          id={id}
          onBack={() => {
            setPageToggle(true);
            getList();
          }}
        />
      )}
    </>
  );
}
