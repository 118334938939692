import { Typography } from "antd";
import dayjs from "dayjs";

const columns = () => [
  {
    title: "ID",
    width: 90,
    align: "center",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "消费内容",
    width: 200,
    align: "center",
    dataIndex: "zid",
    key: "zid",
    render: (_, record) => (
      <>
      <Typography.Text strong>{record?.book_chapter?.book?.title}</Typography.Text>
      <div>（{record?.book_chapter?.title}）</div>
      </>
    )
  },
  {
    title: "消费书币",
    width: 100,
    align: "center",
    dataIndex: "money",
    key: "money",
  },
  {
    title: "消费书券",
    width: 100,
    align: "center",
    dataIndex: "coupon_score",
    key: "coupon_score",
  },
  {
    title: "消费时间",
    width: 160,
    align: "center",
    dataIndex: "addtime",
    key: "addtime",
    render: value => (
        dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    )
  },
];

export default columns;
