import React from "react";
import welcome from "../../assets/images/welcome.png";
import "./index.less";

export default function Index() {
  const nickname = localStorage.getItem("nickname");
  return (
    <div className="welcome">
      <img src={welcome} alt=''/>
      <div>{nickname}</div>
    </div>
  );
}
