import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
} from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import columns from "./monitorColumns";
import { getMonitorCheck } from "../../api/selfcheck";

export default function Monitor(props) {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);

  // 当前路径
  const pathKey = useLocation().pathname;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    getList({});
  }, [pathKey]);

  // 列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    console.log(params, 'pppadar')
    const res = await getMonitorCheck(params);
    if (res?.code === 200) {
      const { data } = res;
      let lists = data.lists;
      setList(lists);
      setTotal(data?.total);
      //setIsSuperRole(data?.is_super)
      setLoading(false);
    }
  };

  // 列表条件查询
  const onSearch = async (values) => {
    let date;
    if (values["date"] && values["date"] != undefined) {
      date = values["date"].format("YYYYMMDD");
      values.date = date;
    }
    console.log(values, 'valuesssss')
    getList(values);
    setConditions(values);
    if (values.advertiser && typeof (values.advertiser) === 'string') {
      // setAccountNums(-1)
      // setPromotionNums(-1)
      // const res = await getPromotionsFromPlaylet({advertiser: values.keyword, date})
      // if (res?.code === 200) {
      //   const {data} = res
      //   setAccountNums(data?.account_nums)
      //   setPromotionNums(data?.promotion_nums)
      // }
    }
    props.onSearch(values);
  };

  // 分页
  const onShowChange = (page, size) => {
    console.log('onshowchangee', size, page, 'dafads')
    setPage(page);
    setPerPage(size);
    console.log(conditions, 'ccc')
    let values = conditions?.date ? conditions : {};
    values.page = page;
    values.pageSize = size;
    console.log(values, 'dasdfa')
    getList(values);
  };
  // 每页显示条数切换回调
  const pagesize = (page, size) => {
    setPage(page)
    console.log(conditions, 'cdasfa')
    setConditions({ ...conditions, pageSize: size, page })
    getList({ ...conditions, pageSize: size, page })
  }

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  const switchover = (record, tab) => {
    props.switchover(record, tab);
  };

  // 表单重置
  const formReset = () => {
    form.resetFields();
    props.onSearch([]);
  };


  return (
    <>
      <Card
        title={
          <Form
            form={form}
            onFinish={onSearch}
            initialValues={{
              admin_id: props?.admin_id || props?.conditions?.admin_id || null,
              keyword:
                props?.conditions?.keyword || props?.conditions?.advertiser_id,
            }}
          >
            <Row gutter={16}>
              <Col span={7}>
                <Form.Item label="日期筛选" name="date">
                  <DatePicker size="small" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item style={{ marginLeft: 20 }}>
                  <Space>
                    <Button type="primary" htmlType="submit" size="small">
                      确定
                    </Button>
                    <Button onClick={formReset} size="small">
                      重置
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      ghost
                      onClick={() => navigate(-1)}
                    >
                      返回
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
        headStyle={{ marginTop: 10 }}
        bodyStyle={{ padding: 0 }}
        size="small"
      >
        <Table
          sticky
          size="small"
          dataSource={list}
          columns={columns()}
          loading={loading}
          bordered={true}
          scroll={{ x: 500 }}
          pagination={{
            total: total,
            showSizeChanger: true,
            showQuickJumper: props.total > 50 ? true : false,
            showTotal: (total) => `共 ${total} 条`,
            pageSizeOptions: [10, 15, 20, 30],
            current: page || 1,
            onChange: onShowChange,
            pageSize: perPage,
          }}
        />
      </Card>
    </>
  );
}
