import { useCallback, useState } from 'react';
import { message } from "antd";

import { saveAdvertiseRule } from '@/api/delivery';
import { FORMAT } from '../constants'

const useSubmit = ({ originData }) => {
  // 提交的 loading
  const [loading, setLoading] = useState(false);

  // 保存的时候提交数据
  const onSubmit = useCallback(async (values) => {
    try {
      setLoading(true);
      const data = {
        status: values?.status ? 'enable' : 'disable',
        ruleCondition: {
          advertiserSelectType: values?.advertiserSelectType,
          advertiserList: values?.advertiserList?.map?.((e) => {
            return { advertiserName: e?.label, advertiserId: e?.value }
          }) || [],
          advertiserTotalCost: values?.advertiserTotalCost,
          advertiserTotalCostMax: values?.advertiserTotalCostMax,
          advertiserTopNum: values?.advertiserTopNum,
          advertCount: values?.advertCount,
          advertCost: values?.advertCost,
          advertTopNum: values?.advertTopNum,
          materialAdvertTopNum: values?.materialAdvertTopNum,
        },
        timeCondition: {
          time: values?.time?.format(FORMAT),
        },
      }

      const params = {
        ...(originData || {}),
        status: data?.status,
        ruleCondition: {
          ...originData?.ruleCondition,
          ...data?.ruleCondition,
        },
        timeCondition: {
          ...originData?.timeCondition,
          ...data.timeCondition,
        },
      }

      await saveAdvertiseRule(params)
      message.success('提交成功')
    } catch {
      message.error('提交失败')
    } finally {
      setLoading(false);
    }
  }, [originData]);

  return {
    onSubmit,
    submitLoading: loading,
  }
}

export default useSubmit
