import { Card, Form, Input, Select } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getBookList } from '../../../api/book'
import Table from '../../../components/TableNoSelection'
import columns from './columns'
import { useNavigate } from 'react-router-dom'

const {Search} = Input

export default function Index() {
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [loading, setLoading] = useState(false)
    const [conditions, setConditions] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        getList()
    }, [])

    const getList = async (params = {}) => {
        setLoading(true)
        const res = await getBookList(params)
        if (res?.code === 200) {
            const {data} = res
            setList(data?.lists)
            setTotal(data?.total)
            setLoading(false)
        }
    }


    // 小说名称筛选
    const onSearch = value => {
        getList({title: value})
    }

    // 状态筛选
    const statusChange = value => {
        getList({xstype: value})
        setConditions({xstype: value})
    }

    // 点击跳转小说章节
    const redirectChapter = record => {
        navigate('/admin/batch_plan/novel_list/chapter', {state: {...record}})
    }   

    // 分页
    const pagesize = (page, size) => {
        setPage(page)
        setPerPage(size)
        getList({...conditions, page, pageSize: size})
    }

    return (
        <>
            <Card 
                title={
                    <Form layout='inline'>
                        <Form.Item label='小说状态'>
                            <Select options={[
                                {label: '连载中', value: 0},
                                {label: '已完结', value: 1},
                            ]} allowClear placeholder='请选择' style={{width: 180}} onChange={statusChange}/>
                        </Form.Item>
                    </Form>
                    
                }
                extra={
                    <Search 
                        placeholder='输入小说名称'
                        allowClear
                        enterButton="搜索"
                        onSearch={onSearch}
                    />
                }
            >
                <Table 
                    dataSource={list}
                    columns={columns({redirectChapter})}
                    total={total}
                    page={page}
                    perPage={perPage}
                    loading={loading}
                    pagesize={pagesize}
                />
            </Card>
        </>
    )
}
