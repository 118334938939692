import { postRequest } from "./request"

// 消息列表
export const getMessage = data => postRequest('/admin/message', data)

// 消息标记已读
export const updateMessageRead = data => postRequest('/admin/message/read_save', data)

// 消息详情
export const getMessageDetail = data => postRequest('/admin/message/info', data)

// 最新消息通知列表
export const getMessageNoticeList = data => postRequest('/admin/message/notice', data)