import {
  Button,
  DatePicker,
  Card,
  Table,
  Space,
  Popconfirm,
  Form,
  Select,
  message,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { autoStrategyDetail, changeAdStatus } from "@/api/delivery";
import dayjs from "dayjs";
import useTableList from "@/hooks/useTableList";
import qs from "qs";

const { RangePicker } = DatePicker;

export default function Index({ id, onBack }) {
  const [conditions, setConditions] = useState({
    id,
    startTime: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    status: 2,
    page: 1,
    pageSize: 10,
  });

  const { loading, data } = useTableList(autoStrategyDetail, conditions);
  const { list = [], total = 0 } = data;

  const [form] = Form.useForm();

  const textMap = new Map([
    [1, { text: "已删除", show: false }],
    [2, { text: "已关闭", btnText: "打开", show: true }],
    [3, { text: "关闭失败", btnText: "关闭", show: true }],
    [4, { text: "重新打开", show: false }],
  ]);

  const statusOptions = Array.from(textMap).map(([key, value]) => ({
    value: key,
    label: value.text,
  }));

  const rangePresets = [
    {
      label: "今日",
      value: [dayjs().startOf("day"), dayjs()],
    },
    {
      label: "昨日",
      value: [
        dayjs().subtract(1, "d").startOf("day"),
        dayjs().subtract(1, "d").endOf("day"),
      ],
    },
    {
      label: "近三天",
      value: [dayjs().subtract(2, "d"), dayjs()],
    },
    {
      label: "近一周",
      value: [dayjs().subtract(6, "d"), dayjs()],
    },
  ];

  const columns = [
    {
      title: "广告账户",
      width: 120,
      dataIndex: "advertiser",
      align: "center",
    },
    {
      title: "广告名称",
      width: 120,
      dataIndex: "promotionName",
      align: "center",
      render: (_, record) => {
        return <Tooltip title={record.promotionId}>{_}</Tooltip>
      },
    },
    {
      title: "管理员id",
      width: 120,
      dataIndex: "adminId",
      align: "center",
    },
    {
      title: "状态",
      width: 120,
      dataIndex: "status",
      align: "center",
      render: (_, record) => {
        return textMap.get(_)?.text || "";
      },
    },
    {
      title: "消耗",
      width: 120,
      dataIndex: "cost",
      align: "center",
      render: (_, record) => {
        return <Tooltip title={record.message}>{_}</Tooltip>
      },
    },
    {
      title: "更新时间",
      width: 120,
      dataIndex: "updateTime",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "operation",
      // align: "center",
      width: 150,
      render: (_, record) =>
        textMap.get(record.status)?.show && (
          <Space size={12}>
            <Popconfirm
              title={`确定要${textMap.get(record.status)?.btnText}吗？`}
              onConfirm={() => onChangeStatus(record.id)}
            >
              <a>{textMap.get(record.status)?.btnText}</a>
            </Popconfirm>
            <a onClick={() => onJugglePage(record)}>跳转</a>
          </Space>
        ),
    },
  ];

  const onJugglePage = (record) => {
    const { advertiser, promotionId, updateTime } = record;
    const date = updateTime.split(' ')?.[0] || dayjs().format('YYYY-MM-DD');
    const startTime = dayjs(date).subtract(2, 'd').format('YYYY-MM-DD');

    const queryObj = {
      aadvid: advertiser,
      filter_st: encodeURIComponent(`"${startTime}"`),
      filter_et: encodeURIComponent(`"${date}"`),
      filter_keyword: encodeURIComponent(`"${promotionId}"`),
      filter_search_type: encodeURIComponent('"9"'),
    };

    const queryString = qs.stringify(queryObj);

    const url = `https://ad.oceanengine.com/promotion/promote-manage/ad?${queryString}`;
    console.log(url)

    window.open(url);
  }

  const onChangeStatus = async (id) => {
    try {
      const res = await changeAdStatus({ id });
      if (res?.code === 200) {
        message.success("操作成功");
        setConditions({ ...conditions });
      } else {
        message.success(`操作失败，${res?.message}`);
      }
    } catch (err) {
      console.log(err, "errerrerr");
    }
  };

  const onChangeTable = (pagination, filters, sorter, action) => {
    if (action.action === "paginate") {
      const { current, pageSize } = pagination;
      setConditions({ ...conditions, page: current, pageSize });
      return;
    }
  };

  // 表单重置
  const onReset = () => {
    form.resetFields();
    const resetParams = {
      id,
      startTime: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      status: 2,
      page: 1,
      pageSize: 10,
    };

    setConditions(resetParams);
  };

  const onSubmit = (values) => {
    const { status, range_date } = values;
    const rangeDate =
      range_date?.map((date) => {
        return date.format("YYYY-MM-DD HH:mm:ss");
      }) || [];

    const startTime = rangeDate[0];
    const endTime = rangeDate[1];

    const submitParams = {
      status,
      startTime,
      endTime,
      page: 1,
      pageSize: 10,
    };

    setConditions({ ...conditions, ...submitParams });
  };

  return (
    <Card
      title={
        <Form
          layout="inline"
          onFinish={onSubmit}
          form={form}
          initialValues={{
            range_date: [dayjs().startOf("day"), dayjs()],
            status: 2,
          }}
        >
          <Form.Item label="日期" name="range_date">
            <RangePicker showTime presets={rangePresets} />
          </Form.Item>
          <Form.Item label="状态" name="status">
            <Select
              options={statusOptions}
              style={{ width: 200 }}
              allowClear
              placeholder="选择状态"
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
              <Button onClick={onReset}>重置</Button>
            </Space>
          </Form.Item>
        </Form>
      }
      extra={
        <Button type="primary" onClick={onBack}>
          返回
        </Button>
      }
    >
      <Table
        dataSource={list}
        columns={columns}
        pagination={{
          total,
          showSizeChanger: false,
          showQuickJumper: false,
          showTotal: (total) => `共 ${total} 条`,
          current: conditions.page || 1,
          pageSize: conditions.pageSize || 10,
        }}
        loading={loading}
        onChange={onChangeTable}
      />
    </Card>
  );
}
