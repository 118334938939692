import {
  Card,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  Statistic,
  Table,
  Tooltip,
  message,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getDailyProfitGroup } from "../../../../api/delivery";
import SearchComponent from "../../Profit/IAA/SearchComponent";
import _ from "lodash";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import IAADetail from "./IAADetail";

const columns = [
  {
    title: "组名",
    dataIndex: "presentation",
    render: (text) => <b>{text}</b>,
  },
  {
    title: "日期",
    dataIndex: "date",
    render: (text) => <b>{text}</b>,
  },
  {
    title: (
      <Tooltip title="当日所有广告的消耗数据总和">
        消耗{" "}
        <ExclamationCircleOutlined
          style={{ fontSize: "16px", color: "#08c" }}
        />
      </Tooltip>
    ),
    dataIndex: "cost",
    render: (text) => text.toFixed(2),
    sorter: (a, b) => a.cost - b.cost,
  },
  {
    title: (
      <Tooltip title="当日ecpm收益数据，包含历史消耗产生的收益数据">
        ecpm收益{" "}
        <ExclamationCircleOutlined
          style={{ fontSize: "16px", color: "#08c" }}
        />
      </Tooltip>
    ),
    dataIndex: "ecpm",
    render: (text) => text.toFixed(2),
    sorter: (a, b) => a.ecpm - b.ecpm,
  },
  {
    title: "赔付消耗",
    colSpan: 1,
    dataIndex: "compensationCost",
    render: (text) => text.toFixed(2),
    sorter: (a, b) => a.compensationCost > b.compensationCost,
  },
  {
    title: "回收率",
    dataIndex: "rate",
    render: (text) => (text * 100).toFixed(2) + "%",
    sorter: (a, b) => a.rate - b.rate,
  },
  {
    title: (
      <Tooltip title="盈亏值">
        盈亏{" "}
        <ExclamationCircleOutlined
          style={{ fontSize: "16px", color: "#08c" }}
        />
      </Tooltip>
    ),
    dataIndex: "profit",
    render: (text) => text.toFixed(2),
    sorter: (a, b) => a.profit - b.profit,
  },
];

const IAAGroupPage = () => {
  const [summary, setSummary] = useState([]);
  const [data, setData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaultDate = [dayjs().startOf("M"), dayjs().startOf("d")];

  useEffect(() => {
    const params = {
      start: defaultDate[0].format("YYYY-MM-DD"),
      end: defaultDate[1].format("YYYY-MM-DD"),
    };
    getData(params);
  }, []);

  const handleExpand = (expanded, rowKey) => {
    console.log(expandedRowKeys, expanded, rowKey);
    let filteredList = _.filter(
      expandedRowKeys,
      (item) => item !== rowKey?.key
    );
    setExpandedRowKeys(
      expanded ? [...expandedRowKeys, rowKey?.key] : filteredList
    );
  };

  const getData = async (params) => {
    setLoading(true);
    setExpandedRowKeys([]);
    await getDailyProfitGroup(params)
      .then((response) => {
        if (response?.code === 200) {
          //清空expandedKeys
          const { data } = response;
          const dataWithKey = _.map(
            data?.dailyProfitItemList,
            (item, index) => {
              const timestamp = Date.now();
              return {
                ...item,
                key:
                  index + item?.presentation + new Date(timestamp).toString(),
              };
            }
          );

          setData(dataWithKey);
          setSummary({
            cost: data?.totalCost.toFixed(2),
            ecpm: data?.totalEcpm.toFixed(2),
            rate: (data?.rate * 100).toFixed(2),
            profit: data?.totalProfit.toFixed(2),
            adminId: params?.adminId,
          });
        }
      })
      .catch((error) => {
        message.error(error || "系统错误", 2);
      })
      .finally((f) => {
        setLoading(false);
      });
  };

  return (
    <div className="container">
      <Space className="sp" direction="vertical" size="large">
        <Spin spinning={loading}>
          <Space>
            <SearchComponent
              defaultDate={defaultDate}
              onSearch={(params) => getData(params)}
            />
          </Space>
          <Row gutter={16}>
            <Col span={6}>
              <Card>
                <Statistic title="总消耗" value={summary?.cost} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic title="总回收" value={summary?.ecpm} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic title="总回收率" value={summary?.rate} suffix="%" />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="总盈亏"
                  value={summary?.profit}
                  valueStyle={{
                    color: summary?.profit > 0 ? "#cf1322" : "#3f8600",
                  }}
                />
              </Card>
            </Col>
          </Row>
          <Table
            expandable={{
              expandedRowKeys: expandedRowKeys,
              onExpand: handleExpand,
              expandRowByClick: true,
              expandedRowRender: (record) => (
                <IAADetail data={record?.appDetail} date={record.date} />
              ),
            }}
            className="tb"
            columns={columns}
            dataSource={data}
            bordered
          />
        </Spin>
      </Space>
    </div>
  );
};

export default IAAGroupPage;
