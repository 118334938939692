import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import AudienceTarget from "./AudienceTarget";
import Schedule from "./Schedule";
import { useNavigate } from "react-router-dom";
import RowSelectTable from "../../../../components/TableHasTotal";
import { CloseOutlined } from "@ant-design/icons";
import {
  getProductListAsset,
  getProductPlatAsset,
} from "../../../../api/advertise";
import { getAdvertiserIdsFromAsset, getAssetList } from "../../../../api/asset";
import dayjs from "dayjs";

export default function Index(props) {
  const [deliveryMode, setDeliveryMode] = useState("PROCEDURAL");
  // 定向默认值
  const [curValue, setCurValue] = useState({
    district: "REGION",
    city: [
      34, 50, 35, 44, 62, 45, 52, 12, 13, 31, 42, 23, 46, 41, 43, 22, 32, 36,
      21, 15, 64, 63, 51, 61, 14, 65, 54, 53, 33, 371600, 370500, 371400,
      371700, 370100, 370800, 371500, 370200, 371100, 370900, 370700, 371000,
      370600, 370300, 370400,
    ],
    region_versio: "1.0.0",
    location_type: "ALL",
    gender: "NONE",
    age: ["NONE"],
  });

  // 排序与预算默认值
  const [budgetValue, setBudgetValue] = useState({
    pricing: "PRICING_OCPM",
    start_time: "",
    end_time: "",
    schedule_type: "SCHEDULE_FROM_NOW",
    schedule_frame: "unlimited",
    schedule_time: "",
    bid_type: "CUSTOM",
    bid_speed: "FAST",
    budget_mode: "BUDGET_MODE_DAY",
  });
  const [assetList, setAssetList] = useState([]);
  const [nextLoading, setNextLoading] = useState(false);
  const [productPlatformShow, setProductPlatformShow] = useState(false);
  const [platformLoading, setPlatformLoading] = useState(false);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [productList, setProductList] = useState([]);
  const [platformSelectType, setPlatformSelectType] = useState("NO_MAP");
  const [advertiserList, setAdvertiserList] = useState([]);
  const [advertiserLoading, setAdvertiserLoading] = useState(false);
  const [externalAction, setExternalAction] = useState("");

  useEffect(() => {
    // 只拿审核通过的资产
    getList({ audit_status: "AUDIT_ACCEPTED" });
  }, []);

  useEffect(() => {
    getReturnPreData(props?.projectData);
  }, [props]);

  const [form] = Form.useForm();
  const [platformForm] = Form.useForm();
  const navigate = useNavigate();

  // 获取当前用户的资产选择数据
  const getList = async (params = {}) => {
    params.pageSize = 0;
    const res = await getAssetList(params);
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map((item) => {
        return {
          label: item?.remark,
          value: item?.asset_id,
          promotion_id: item?.in_promotion_id,
        };
      });
      setAssetList(newList);
    }
  };

  // 切换资产获取账户信息
  const onAssetChange = async (value) => {
    setAdvertiserLoading(true);
    let filterItem = assetList.find((item) => {
      return item.value === value;
    });
    const res = await getAdvertiserIdsFromAsset({
      asset_id: value,
      promotion_id: filterItem?.promotion_id,
    });
    if (res?.code === 200) {
      const { data } = res;
      setAdvertiserList(data?.advertisers);
      setExternalAction(data?.external_action);
    }
    let projectName = filterItem?.label + "_" + dayjs().format("YYYY-MM-DD HH:mm:ss");
    form.setFieldsValue({ project_name: projectName });
    setAdvertiserLoading(false);
  };

  // 移除选择
  const selectedAccountRemove = (advertiserId) => {
    let filterItems = advertiserList.filter((item) => {
      return item.advertiser_id !== advertiserId;
    });
    setAdvertiserList(filterItems);
  };

  // 获取定向的表单数据
  const getAddienceTargetData = (values) => {
    setCurValue({ ...curValue, ...values });
  };

  // 获取排期与预算表单数据
  const getScheduleFormData = (values) => {
    setBudgetValue({ ...budgetValue, ...values });
  };

  // 投放模式切换
  const deliveryModeChange = (e) => {
    let value = e.target.value;
    let budget_mode;
    if (value === "MANUAL") {
      budget_mode = "BUDGET_MODE_INFINITE";
    } else {
      budget_mode = "BUDGET_MODE_DAY";
    }
    setBudgetValue({ ...budgetValue, budget_mode });
    form.setFieldsValue({ budget_mode });
    setDeliveryMode(value);
  };

  // 产品库选择切换
  const platformChange = (e) => {
    let value = e.target.value;
    setPlatformSelectType(value);
  };

  // 选择商品库弹窗
  const selectProductPlatform = async () => {
    let assetId = form.getFieldValue("asset_id") || 0;
    setProductPlatformShow(true);
    const res = await getProductPlatAsset({ asset_id: assetId });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.list?.map((item) => {
        return { label: item?.name, value: item?.platform_id };
      });
      setPlatformOptions(list);
    }
  };

  const closeproductPlatform = () => {
    setProductPlatformShow(false);
  };

  // 商品库切换
  const changeSelect = async (value) => {
    let assetId = form.getFieldValue("asset_id") || 0;
    setPlatformLoading(true);
    const res = await getProductListAsset({
      asset_id: assetId,
      platform_id: value,
    });
    if (res?.code === 200) {
      setPlatformLoading(false);
      const { data } = res;
      const list = data?.list?.map((item) => {
        item.key = item.product_id + "@" + item.name + "@" + item.platform_id;
        return item;
      });
      setProductList(list);
    }
  };

  // 选中商品
  const selectRows = (rows) => {
    if (rows.length > 1) return message.error("只能选择一个商品", 2);
    setSelectedRowKeys(rows);
  };

  const productSelected = () => {
    if (selectedRowKeys.length === 0) return message.error("请选择商品", 2);
    closeproductPlatform();
  };

  // 返回上一步时的数据回显
  const getReturnPreData = (data) => {
    setExternalAction(data?.external_action);
    setAdvertiserList(data?.advertiserList || []);
    if (Object.keys(data).length > 0) {
      curValue.age = data?.age;
      curValue.gender = data?.gender;
      budgetValue.schedule_type = data?.schedule_type;
      budgetValue.start_time = data?.start_time;
      budgetValue.end_time = data?.end_time;
      budgetValue.range_date = [dayjs(data?.start_time), dayjs(data?.end_time)];
      budgetValue.schedule_frame = data?.schedule_frame;
      budgetValue.schedule_time = data?.schedule_time;
      budgetValue.bid_type = data?.bid_type;
      budgetValue.bid_speed = data?.bid_speed;
      budgetValue.budget_optimize_switch = data?.budget_optimize_switch;
      budgetValue.budget_mode = data?.budget_mode;
    }
    setCurValue({ ...curValue });
    setBudgetValue({ ...budgetValue });
    form.setFieldsValue(data);
  };

  // 创建项目点击下一步
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        setNextLoading(true);
        let values = form.getFieldsValue();
        let advertiserIds = advertiserList.map((item) => {
          return item.advertiser_id;
        });
        let filterAssetItem = assetList.find((item) => {
          return item.value === values.asset_id;
        });

        // 商品库信息
        if (values?.product_setting && values?.product_setting === "SINGLE") {
          if (selectedRowKeys.length === 0) {
            message.error("已开启产品库，请选择商品", 2);
            setNextLoading(false);
            return false;
          }
          let rows = selectedRowKeys[0].split("@");
          values.product_id = rows[0];
          values.platform_product_name = rows[1];
          values.product_platform_id = rows[2];
        }
        values.promotion_id = filterAssetItem?.promotion_id;
        values.external_action = externalAction;
        values.advertiserList = advertiserList;
        values.advertiser_ids = advertiserIds;
        values.age = curValue.age;
        values.gender = curValue.gender;
        values.city = curValue.city;
        values = { ...values, ...budgetValue };
        if (externalAction === "AD_CONVERT_TYPE_ACTIVE") {
          values.deep_bid_type = "ROI_COEFFICIENT";
        }
        props.onSubmit(values);
        setNextLoading(false);
      })
      .catch(() => {
        console.log("表单提交有误");
        return false;
      });
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  return (
    <>
      <Card
        title={<div style={{ fontWeight: "bold" }}>创建项目</div>}
        styles={{ header: { paddingTop: 10, paddingBottom: 10 } }}
        style={{ border: "1px solid transparent" }}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            project_operation: "ENABLE",
            marketing_goal: "VIDEO_AND_IMAGE",
            delivery_mode: deliveryMode,
            product_setting: platformSelectType,
          }}
        >
          <Card type="inner" title="投放账户" style={{ marginBottom: 10 }}>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="选择资产"
                  name="asset_id"
                  rules={[{ required: true, message: "请选择资产" }]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="请选择资产"
                    options={assetList}
                    style={{ width: "80%" }}
                    onChange={onAssetChange}
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              {advertiserList.length > 0 ? (
                <Col span={12}>
                  <ul
                    style={{
                      border: "1px solid #F0F0F0",
                      padding: 10,
                      width: "80%",
                      height: 300,
                      overflow: "auto",
                    }}
                  >
                    <li style={{ textAlign: "center" }}>
                      默认批量全部资产账户
                    </li>
                    {advertiserLoading ? (
                      <Spin />
                    ) : (
                      advertiserList.map((item) => {
                        return (
                          <li
                            key={item.advertiser_id}
                            style={{
                              marginTop: 10,
                              backgroundColor: "#EBF1F4",
                              padding: 5,
                              display: "flex",
                            }}
                          >
                            <div style={{ width: "90%" }}>
                              <div>{item?.advertise?.account_name}</div>
                              <div>{item?.advertise?.account_id}</div>
                            </div>
                            <CloseOutlined
                              style={{ cursor: "pointer", marginLeft: 10 }}
                              onClick={() => {
                                selectedAccountRemove(item.advertiser_id);
                              }}
                            />
                          </li>
                        );
                      })
                    )}
                  </ul>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Card>
          <Card
            type="inner"
            title="投放模式与场景"
            style={{ marginBottom: 10 }}
          >
            <Form.Item label="投放模式" name="delivery_mode">
              <Radio.Group
                options={[
                  { label: "手动投放", value: "MANUAL" },
                  { label: "自动投放", value: "PROCEDURAL" },
                ]}
                onChange={deliveryModeChange}
                optionType="button"
              />
            </Form.Item>
          </Card>
          <Card type="inner" title="定向" style={{ marginBottom: 10 }}>
            <AudienceTarget
              getFormData={getAddienceTargetData}
              value={curValue}
            />
          </Card>
          <Card type="inner" title="产品库" style={{ marginBottom: 10 }}>
            <Form.Item
              label="产品库"
              name="product_setting"
              rules={[{ required: true, message: "请选择产品库" }]}
            >
              <Radio.Group
                options={[
                  { label: "不启用", value: "NO_MAP" },
                  { label: "启用", value: "SINGLE" },
                ]}
                optionType="button"
                onChange={platformChange}
              />
            </Form.Item>
            {platformSelectType === "SINGLE" ? (
              <Button type="primary" onClick={selectProductPlatform}>
                选择产品库
              </Button>
            ) : (
              ""
            )}
            {selectedRowKeys.length > 0 ? (
              <div
                style={{
                  marginTop: 10,
                  backgroundColor: "#F5F5F5",
                  fontWeight: "bold",
                }}
              >
                {selectedRowKeys[0].split("@")[1]}
              </div>
            ) : (
              ""
            )}
          </Card>
          <Card type="inner" title="排期与预算" style={{ marginBottom: 10 }}>
            <Schedule
              delivery_mode={deliveryMode}
              getScheduleFormData={getScheduleFormData}
              externalAction={externalAction}
              value={budgetValue}
            />
          </Card>
          <Card title="项目名称与状态" style={{ marginBottom: 10 }}>
            <Form.Item
              label="项目名称"
              name="project_name"
              rules={[{ required: true, message: "请输入项目名称" }]}
            >
              <Input
                allowClear
                placeholder="输入项目名称"
                style={{ width: "50%" }}
              />
            </Form.Item>
            <Form.Item label="状态" name="project_operation">
              <Radio.Group
                options={[
                  { label: "开启", value: "ENABLE" },
                  { label: "关闭", value: "DISABLE" },
                ]}
                optionType="button"
              />
            </Form.Item>
          </Card>
          <Form.Item style={{ textAlign: "center" }}>
            <Space>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={nextLoading}
              >
                下一步新建广告
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>

      {/* 产品库弹窗 */}
      <Modal
        open={productPlatformShow}
        onCancel={closeproductPlatform}
        width={650}
        onOk={productSelected}
      >
        <Form form={platformForm}>
          <Form.Item label="产品库" name="product_platform">
            <Select
              placeholder="选择产品库"
              allowClear
              options={platformOptions}
              showSearch
              filterOption={filterOption}
              onChange={changeSelect}
              style={{ width: "95%" }}
            />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item>
                <Card
                  title="商品列表"
                  styles={{ header: { backgroundColor: "#F8F8F9" } }}
                  style={{ height: 250, width: 575, overflowY: "auto" }}
                >
                  <RowSelectTable
                    columns={[
                      {
                        title: "商品id",
                        dataIndex: "product_id",
                        key: "product_id",
                      },
                      {
                        title: "商品名称",
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: "商品描述",
                        dataIndex: "description",
                        key: "description",
                      },
                    ]}
                    selectRows={selectRows}
                    loading={platformLoading}
                    dataSource={productList}
                    pagination={false}
                  />
                </Card>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
