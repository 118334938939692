import { Switch, Modal, Form, Input, Select, InputNumber, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  getStrategiesDetail,
  getStrategyTypes,
  addOrUpdateStrategy,
} from "@/api/delivery";

const { Option } = Select;

export default function AddOrEditModel(props) {
  const { open, id, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getTypes();
    id && getDetail();
  }, []);

  // 获取策略详情
  const getDetail = async () => {
    const res = await getStrategiesDetail({ id });
    console.log(res, "resssss");
    if (res?.code === 200) {
      const { autoCloseStrategy = {}, ...rest } = res?.data || {};
      form.setFieldsValue({...autoCloseStrategy, ...rest});
    }
  };

  // 获取策略类型
  const getTypes = async () => {
    const textMap = new Map([["AUTO_CLOSED", "关停策略"]]);
    const res = await getStrategyTypes();
    if (res?.code === 200) {
      const data =
        res?.data?.map((item) => ({
          value: item,
          label: textMap.get(item),
        })) || [];
      setOptions(data);
    }
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    console.log(values, "valuesss");
    const params = {
      id: id ?? undefined,
      name: values.name,
      strategyType: values.strategyType,
      autoCloseStrategy: {
        period: values.period,
        cost: values.cost,
      },
      status: values.status ? 1 : 0,
    };
    try {
      const res = await addOrUpdateStrategy(params);
      if (res?.code === 200) {
        message.success('操作成功');
        onClose(true);
      }
    } catch (err) {
      console.log(err, "errerrerr");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={id ? "编辑策略" : "新增策略"}
      open={open}
      closable={false}
      destroyOnClose
      footer={[
        <Button key="back" onClick={() => onClose(false)}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          {id ? "编辑" : "新增"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        initialValues={{
          strategyType: "AUTO_CLOSED",
          status: true,
        }}
      >
        <Form.Item
          name="name"
          label="策略名称"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="请输入策略名称" />
        </Form.Item>
        <Form.Item
          name="strategyType"
          label="策略类型"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select allowClear placeholder="请选择策略类型" options={options} />
        </Form.Item>

        <Form.Item label="周期">
          <Form.Item
            name="period"
            noStyle
            rules={[
              {
                required: true,
                message: "请输入周期",
              },
            ]}
          >
            <InputNumber min={0} placeholder="请输入" />
          </Form.Item>
          <span
            style={{
              marginInlineStart: 8,
            }}
          >
            小时(h)
          </span>
        </Form.Item>

        <Form.Item label="消耗">
          <Form.Item
            name="cost"
            noStyle
            rules={[
              {
                required: true,
                message: "请输入消耗",
              },
            ]}
          >
            <InputNumber min={0} max={10} placeholder="请输入" />
          </Form.Item>
          <span
            style={{
              marginInlineStart: 8,
            }}
          >
            元
          </span>
        </Form.Item>
        <Form.Item name="status" label="状态" valuePropName="checked">
          <Switch checkedChildren="开启" unCheckedChildren="关闭" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
