import {Table} from "antd";


const columns = [

    {
        title: '小程序名称',
        dataIndex: 'appName',
        render: (text) => text,
    },
    {
        title: '消耗',
        dataIndex: 'cost',
        render: (text) => text.toFixed(2),
        sorter: (a, b) => a.cost - b.cost,
    },
    {
        title: 'ecpm收益',
        dataIndex: 'ecpm',
        render: (text) => text.toFixed(2),
        sorter: (a, b) => a.ecpm - b.ecpm,
    },
    {
        title: '回收率',
        dataIndex: 'rate',
        render: (text) => (text*100).toFixed(2) + '%',
        sorter: (a, b) => a.rate - b.rate,
    }
];


const IAADetail = ({data}) => {


    return (<Table columns={columns} dataSource={data} />)

}

export default IAADetail;