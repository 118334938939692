const columns = () => [
    {
        title: "日期",
        width: 80,
        align: "center",
        dataIndex: "day",
        key: "day",
    },
    {
        title: "投手",
        width: 80,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        ellipsis: true,
    },
    {
        title: "短剧",
        width: 80,
        align: "center",
        dataIndex: "book_name",
        key: "book_name",
        ellipsis: true,
    },
    {
        title: "剧场",
        width: 80,
        align: "center",
        dataIndex: "playlet_name",
        key: "playlet_name",
        ellipsis: true,
    },
    {
        title: "消耗",
        width: 80,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "回收",
        width: 80,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
]

export default columns