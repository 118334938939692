import { Checkbox, Form, Typography } from "antd";
import React, { useRef, useState } from "react";
import "./index.less";

const { Text } = Typography;

export default function Index(props) {
  const [genderSelected, setGenderSelected] = useState();
  const [checkedValues, setCheckedValues] = useState([]);
  const [formDatas, setFormDatas] = useState({})
  let gender = [
    { label: "不限", value: "NONE" },
    { label: "男", value: "GENDER_MALE" },
    { label: "女", value: "GENDER_FEMALE " },
  ];

  let ageData = [
    { label: "不限", value: "NONE" },
    { label: "18-23", value: "AGE_BETWEEN_18_23" },
    { label: "24-30", value: "AGE_BETWEEN_24_30" },
    { label: "31-40", value: "AGE_BETWEEN_31_40" },
    { label: "41-49", value: "AGE_BETWEEN_41_49" },
    { label: "50+", value: "AGE_ABOVE_50" },
  ];

  const ref = useRef();

  // 点击选中性别
  const genderClick = (value) => {
    setFormDatas({...formDatas, gender: value})
    props.getFormData({...formDatas, gender: value});
    setGenderSelected(value);
  };

  // 年龄选择切换
  const onAgeChecked = (e, value) => {
    e.stopPropagation();
    e.preventDefault();
    let params;
    if (value === "NONE") {
      params = ["NONE"];
    } else {
      if (checkedValues.includes(value)) {
        let filterValues = checkedValues.filter((item) => {
          return item !== value && item !== "NONE";
        });
        if (filterValues.length === 0) {
          params = ["NONE"];
        } else {
          params = filterValues;
        }
      } else {
        let filterValues = [...checkedValues, value].filter((item) => {
          return item !== "NONE";
        });
        params = filterValues;
      }
    }
    setFormDatas({...formDatas, age: params})
    props.getFormData({...formDatas, age: params});
    setCheckedValues(params);
  };

  return (
    <>
      <Form.Item
        label={
          <Text strong style={{ fontSize: 14 }}>
            性别
          </Text>
        }
      >
        <ul className="gender_list">
          {gender.map((item) => {
            return (
              <li
                key={item.value}
                className={item.value === genderSelected || item.value === props?.value?.gender ? "active" : ""}
                onClick={() => genderClick(item.value)}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
      </Form.Item>
      <Form.Item
        label={
          <Text strong style={{ fontSize: 14 }}>
            年龄
          </Text>
        }
      >
        <ul className="age_list">
          {ageData.map((item) => {
            return (
              <li
                key={item.value}
                onClick={(e) => onAgeChecked(e, item.value)}
                className={checkedValues.includes(item.value) || props?.value?.age?.includes(item.value) ? "active" : ""}
              >
                <Checkbox
                  value={item.value}
                  ref={ref}
                  checked={checkedValues.includes(item.value) || props?.value?.age?.includes(item.value)}
                >
                  {item.label}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </Form.Item>
    </>
  );
}
