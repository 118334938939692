import React from "react"
import { Col, Row, Table } from "antd"

export default function Index(props) {
  const onShowChange = (page, size) => {
    props.pagesize(page, size)
  }
  return (
    <Row>
      <Col xl={{span: props.span || 24}}>
        <Table
          rowKey="id"
          dataSource={props.dataSource}
          rowClassName={(_, index) => (index % 2 === 1 ? "tableBac" : "")}
          columns={props.columns}
          pagination={props.pagination === false ? 
            false : {
              total: props.total,
              showSizeChanger: true,
              showQuickJumper: props.total > 50 ? true : false,
              showTotal: (total) => `共 ${total} 条`,
              pageSizeOptions: props.pageOptions || [10, 15, 20, 30],
              current: props.page || 1,
              onChange: onShowChange,
              pageSize: props.perPage ? props.perPage : 10
            } 
          }
          virtual={props?.virtual || false}
          loading={props.loading}
          scroll={props?.virtual ? { x: 1000, y: 400 } : {x: 1000}}
          bordered={props.bordered === undefined ? false : true}
          onChange={props.onChange}
          size={props.size || 'small'}
          components={props.components || null}
          sortDirections={['descend', 'ascend']}
        />
      </Col>
    </Row>
    
  )
}
