import { Button, Tag } from "antd";

const columns = ({onUpdate}) => [
  {
    title: "ID",
    width: 80,
    align: "center",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "短剧名称",
    width: 120,
    align: "center",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "剧场名称",
    width: 100,
    align: "center",
    dataIndex: "theater",
    key: "theater",
    render: (_, record) => record?.package?.aliasname,
  },
  {
    title: "首发时间",
    width: 130,
    align: "center",
    dataIndex: "first_issue",
    key: "first_issue",
  },
  {
    title: "投手",
    width: 130,
    align: "center",
    dataIndex: "pitcher_nickname",
    key: "pitcher_nickname",
  },
  {
    title: "状态",
    width: 130,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (value) => (
        value === 0 ? <Tag>等待投放</Tag> : value === 1 ? <Tag color="success">投放进行中</Tag> : <Tag color="warning">新剧已结束</Tag>
    )
  },
  {
    title: "操作",
    width: 100,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (_, record) => (
        <Button type="primary" onClick={() => onUpdate(record)}>编辑</Button>
    )
  },
];

export default columns;
