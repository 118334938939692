import {useState, useMemo, useEffect, useCallback} from 'react';
import { Tag, Button } from 'antd'
import { getAdvertiseRuleLog } from '@/api/delivery'
import { groupBy } from 'lodash'

import { FORMAT, statusOptions } from '../constants'

export const useTable = ({ form }) => {
  // 表格数据
  const [table, setTable] = useState([])
  // 表格的 total
  const [total, setTotal] = useState(0)
  const [pageInfo, setPageInfo] = useState({
    pageNum: 1,
    pageSize: 20,
  })

  // 表格的 loading
  const [tableLoading, setTableLoading] = useState(false)
  // 搜索按钮的 loading
  const [searchLoading, setSearchLoading] = useState(false)

  // 获取搜索数据
  const getSearchData = useCallback(() => {
    const { status, time } = form.getFieldsValue()

    const [start, end] = time || []

    return {
      status,
      startDate: start?.format?.(FORMAT),
      endDate: end?.format?.(FORMAT),
    }
  }, [form])

  // 获取表格数据
  const getList = useCallback(async ({ _pageInfo } = {}) => {
    try {
      setTableLoading(true)
      // TODO: 这里整理 search
      const searchData = getSearchData()
      const params = {
        ...searchData,
        ...(_pageInfo || pageInfo),
      }

      const res = await getAdvertiseRuleLog(params)

      const data = res?.data || {}

      setTable(data?.list || [])
      setTotal(data?.total || 0)
    } catch {
      setTable([])
      setTotal(0)
    } finally {
      setTableLoading(false)
    }
  }, [getSearchData, pageInfo])

  // 搜索
  const onSearch = async () => {
    setSearchLoading(true)

    const _pageInfo = {
      ...pageInfo,
      pageNum: 1,
    }
    setPageInfo(_pageInfo)
    await getList({ _pageInfo })

    setSearchLoading(false)
  }

  // 重置
  const onReset = async () => {
    form.resetFields()

    await onSearch()
  }

  const pagination = useMemo(() => {
    return {
      pageSize: pageInfo?.pageSize,
      current: pageInfo?.pageNum,
      total: total,
      showSizeChanger: true,
      pageSizeOptions: [10 ,20 , 50, 100],
      showQuickJumper: true,
      showTotal: (total) => `共 ${total} 条`,
      onChange: async (page, pageSize) => {
        const _pageInfo = { pageNum: page, pageSize }
        // 如果修改了 pageSize, 那么就把 pageNum 设置为 1
        if (pageSize !== pageInfo.pageSize) {
          _pageInfo.pageNum = 1
        }
        setPageInfo(_pageInfo)
        await getList({ _pageInfo })
      },
    }
  }, [pageInfo, total, getList])

  // 初始化的时候, 获取列表数据
  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    table,
    pagination,
    tableLoading,
    searchLoading,
    onSearch,
    onReset,
  }
}

export const useColumns = () => {
  const statusMapper = useMemo(() => {
    return groupBy(statusOptions, 'value')
  }, [])

  const columns = useMemo(() => {
    return [
      {
        title: 'id',
        width: 60,
        align: 'left',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: '广告账户id/名称',
        width: 120,
        dataIndex: 'advertiserId',
        key: 'advertiserId',
        render: (_, record) => {
          const context = record?.execContext || {}
          return (
            <>
              <div>{context?.advertiserId}</div>
              <div>{context?.advertiserName}</div>
            </>
          )
        },
      },
      {
        title: '项目id/名称',
        width: 120,
        dataIndex: 'projectId',
        key: 'projectId',
        render: (_, record) => {
          const context = record?.execContext || {}
          return (
            <>
              <div>{context?.projectId}</div>
              <div>{context?.projectName}</div>
            </>
          )
        },
      },
      {
        title: '广告id/名称',
        width: 120,
        dataIndex: 'promotionId',
        key: 'promotionId',
        render: (_, record) => {
          const context = record?.execContext || {}
          return (
            <>
              <div>{context?.promotionId}</div>
              <div>{context?.promotionName}</div>
            </>
          )
        },
      },
      {
        title: '广告消耗',
        width: 120,
        dataIndex: 'cost',
        key: 'cost',
        render: (_, record) => {
          return record?.execContext?.cost
        },
      },
      {
        title: '复制广告id/名称',
        width: 120,
        dataIndex: 'copyPromotionId',
        key: 'copyPromotionId',
        render: (_, record) => {
          const result = record?.execResult
          const context = record?.execContext || {}

          const search = new URLSearchParams({
            aadvid: context?.advertiserId,
            filter_keyword: encodeURIComponent(`"${result?.promotionId}"`),
          })
          const href = `https://ad.oceanengine.com/promotion/promote-manage/ad?${search?.toString?.()}`

          return (
            <>
              <a target="_blank" href={href} rel="noreferrer">{result?.promotionId}</a>
              <div>{result?.promotionName}</div>
            </>
          )
        },
      },
      {
        title: '执行时间',
        width: 120,
        dataIndex: 'createTime',
        key: 'createTime',
      },
      {
        title: '执行状态',
        width: 120,
        dataIndex: 'status',
        align: 'center',
        key: 'status',
        render: text => {
          const data = statusMapper[text]?.[0]
          return (
            <Tag color={data?.color}>{data?.label}</Tag>
          )
        },
      },
      {
        title: '执行失败原因',
        width: 200,
        dataIndex: 'failReason',
        key: 'failReason',
      },
    ]
  }, [statusMapper])

  return {
    columns,
  }
}
