import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import React from "react";
import { useState } from "react";
import Table from "../../components/TableNoSelection";
import { useEffect } from "react";
import columns from "./columns";
import {
  getOrderChannels,
  getOrderItems,
  getOrderPromotion,
  payRatio,
} from "../../api/statistics";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState(1);
  const [channelOptions, setChannelOptions] = useState([]);
  const [promotionOptons, setPromotionOptions] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [ratioShow, setRatioShow] = useState(false);
  const [ratioLoading, setRatioLoading] = useState(false);
  const [ratioList, setRatioList] = useState([])

  useEffect(() => {
    getList();
    getChannel();
    getPromotion();
  }, []);

  // 获取订单明细列表
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getOrderItems({ ...params });
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 获取渠道列表
  const getChannel = async () => {
    const res = await getOrderChannels();
    const { data } = res;
    if (res?.code === 200) {
      let lists = data?.lists;
      let newList = lists?.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setChannelOptions(newList);
    }
  };

  // 获取推广链接
  const getPromotion = async () => {
    const res = await getOrderPromotion();
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item?.remark, value: item?.id };
      });
      setPromotionOptions(newList);
    }
  };

  const onChange = (value) => {
    setSearchType(value);
  };

  const selectBefore = (
    <Select defaultValue={1} onChange={onChange}>
      <Option value={1}>用户名</Option>
      <Option value={2}>订单号</Option>
    </Select>
  );

  // 关键词查询
  const onSearch = (value) => {
    if (searchType === 1) {
      getList({ nickname: value });
    } else {
      getList({ pay_id: value });
    }
  };

  // 日期筛选查询
  const dateChange = (value) => {
    let payTime;
    payTime = value?.map((date) => {
      return date.format("YYYYMMDD");
    });
    let params = { ...conditions, pay_time: payTime };
    setConditions(params);
    getList(params);
  };

  // 推广链接筛选查询
  const promotioinChange = (value) => {
    let params = { ...conditions, promotion_id: value };
    setConditions(params);
    getList(params);
  };

  // 渠道筛选查询
  const channelChange = (value) => {
    let params = { ...conditions, cid: value };
    setConditions(params);
    getList(params);
  };

  // 支付状态筛选查询
  const statusChange = (value) => {
    let params = { ...conditions, status: value };
    setConditions(params);
    getList(params);
  };

  // 订单状态筛选
  const payTypeChange = (value) => {
    setConditions({ ...conditions, pay_type: value });
    getList({ ...conditions, pay_type: value });
  };

  // 分页查询
  const pagesize = (page, size) => {};

  const onHandleChange = (pagination, filters) => {
    let page = pagination.current;
    let pageSize = pagination.pageSize;
    let params = { ...conditions, page, pageSize, ...filters };
    setPage(page);
    setPageSize(pageSize);
    setConditions(params);
    getList(params);
  };

  // 小说名称筛选
  const novelOnSearch = (value) => {
    setConditions({ ...conditions, book_title: value });
    getList({ ...conditions, book_title: value });
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 支付占比显示字段
  const ratioColumns = [
    {
      title: "单笔充值金额",
      width: 100,
      align: "center",
      dataIndex: "money",
      key: "money",
    },
    {
      title: "总计充值金额",
      width: 100,
      align: "center",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "充值人数",
      width: 100,
      align: "center",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "充值占比",
      width: 100,
      align: "center",
      dataIndex: "ratio",
      key: "ratio",
    },
    {
      title: "复充率",
      width: 100,
      align: "center",
      dataIndex: "repeatRate",
      key: "repeatRate",
    },
  ];

  const getRatioList = async(params = {}) => {
    setRatioLoading(true)
    const res = await payRatio(params)
    if (res?.code === 200) {
      const {data} = res
      let lists = data?.lists?.map((item) => {
        item.key = item.id
        return item
      })
      setRatioList(lists)
      setRatioLoading(false)
    }
  }

  // 打开支付占比弹窗
  const showRatio = () => {
    setRatioShow(true)
    getRatioList(conditions)
  }

  // 关闭支付占比弹窗
  const modalClose = () => {
    setRatioShow(false)
  }

  return (
    <div className="order">
      <Card
        headStyle={{ paddingTop: 20 }}
        title={
          <Space size="middle">
            {/* <Button type='primary'>导出</Button> */}
            <Form>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="支付时间" name="range_date">
                    <RangePicker onChange={dateChange} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Search
                    placeholder={searchType === 1 ? "输入用户名" : "输入订单号"}
                    allowClear
                    enterButton="搜索"
                    addonBefore={selectBefore}
                    onSearch={onSearch}
                  />
                </Col>
                <Col span={8}>
                  <Form.Item label="小说">
                    <Search
                      placeholder="请输入小说名称"
                      allowClear
                      enterButton="确定"
                      onSearch={novelOnSearch}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label="推广链接" name="promotion_id">
                    <Select
                      placeholder="选择推广链接"
                      allowClear
                      options={promotionOptons}
                      showSearch
                      filterOption={filterOption}
                      onChange={promotioinChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="渠道" name="uid">
                    <Select
                      placeholder="选择渠道筛选"
                      allowClear
                      options={channelOptions}
                      showSearch
                      filterOption={filterOption}
                      onChange={channelChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="订单状态" name="pay_type">
                    <Select
                      placeholder="选择订单状态"
                      allowClear
                      options={[
                        { label: "普通订单", value: 2 },
                        { label: "VIP会员", value: 1 },
                      ]}
                      onChange={payTypeChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="支付状态" name="status">
                    <Select
                      placeholder="选择支付状态"
                      allowClear
                      options={[
                        { label: "已支付", value: 1 },
                        { label: "未支付", value: 0 },
                      ]}
                      onChange={statusChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} style={{ textAlign: "right" }}>
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" onClick={showRatio}>查看支付占比</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Space>
        }
      >
        <Table
          dataSource={list}
          columns={columns()}
          total={total}
          page={page}
          loading={loading}
          pagesize={pagesize}
          perPage={pageSize}
          onChange={onHandleChange}
        />
      </Card>

      {/* 查看支付占比弹窗 */}
      <Modal
        open={ratioShow}
        onCancel={modalClose}
        width={650}
        footer={null}
        style={{ height: 700, overflowY: "auto" }}
      >
        <Divider />
        <Table
            dataSource={ratioList}
            columns={ratioColumns}
            loading={ratioLoading}
            pagination={false}
            bordered={true}
          />

      </Modal>
    </div>
  );
}
