import { postRequest } from "./request"

// 小说列表
export const getBookList = data => postRequest('/admin/novel/list', data)

// 小说章节列表
export const getNovelChapterList = data => postRequest('/admin/novel/chapter', data)

// 小说章节第几章
export const getNovelChapterIdx = data => postRequest('/admin/novel/chapter_idx', data)

// 获取读者
export const getReaders = data => postRequest('/admin/user/reader', data)

// 获取所有的小说书籍
export const getAllBooks = data => postRequest('/admin/book/filter_all', data)